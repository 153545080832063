/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { Team } from '../../../../core';

@Component({
  selector: 'app-avatar-team, [appAvatarTeam]',
  templateUrl: './avatar-team.component.html'
})
export class AvatarTeamComponent implements OnInit {
  @Input()
  set appTeam(team: Team | null) {
    this.team = team;

    this.getBackground();
  }

  @Input()
  set appSize(size: string) {
    this.size = size;
  }

  constructor() {}

  team!: Team | null;
  teamClassList: string[] = [
    'bg-primary-A400',
    'bg-blue-grey-600',
    'bg-secondary-600',
    'bg-tertiary-400'
  ];

  background!: string;
  size = '32px';

  ngOnInit(): void {}

  getBackground(): void {
    const name: number = this.team?.name.length || 0;
    const i: number = Math.abs(name % this.teamClassList.length);

    this.background = this.teamClassList[i] || 'bg-primary-A400';
  }
}
