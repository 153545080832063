/** @format */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Skill } from '../../../../core';

@Component({
  selector: 'app-card-skill, [appCardSkill]',
  templateUrl: './card-skill.component.html'
})
export class CardSkillComponent implements OnInit {
  @Output() removedFromTrajectory = new EventEmitter<Skill>();

  @Input()
  set appSkill(skill: Skill) {
    this.skill = skill;
  }

  @Input()
  set appDraggable(draggable: boolean) {
    this.draggable = draggable;
  }

  skill!: Skill;

  draggable = false;

  constructor() {}

  ngOnInit(): void {}
}
