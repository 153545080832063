<!-- @format -->

<section>
	<footer class="skill-task-description-footer">
		<div class="flex items-center justify-between w-full">
			<a
				*ngIf="{
					value: skillTaskIndex - 1,
					taskList: skill.taskList || []
				} as previous"
				class="btn btn-primary transparent md"
				[class.disabled]="previous.value < 0"
				[routerLink]="[
					'../',
					previous.taskList[previous.value]
						? previous.taskList[previous.value].id
						: previous.taskList[previous.value + 1].id
				]"
				[queryParamsHandling]="'merge'"
			>
				<figure class="relative">
					<i class="absolute" appSvgIcon appIcon="arrow-back" appSquare="20"></i>
				</figure>
				<!-- prettier-ignore -->
				<span
					class="block"
					i18n="Просмотр навыка|Кнопка: Предыдущая@@app-skill-tasks-description-editor-footer.previous"
				>
					Предыдущая
				</span>
			</a>
			<ng-container [ngSwitch]="skillIsEdit">
				<ng-container *ngSwitchCase="true">
					<form class="w-full" [formGroup]="skillForm" autocomplete="off">
						<fieldset [disabled]="skillFormIsSubmitting">
							<fieldset class="form-control-unset">
								<!-- prettier-ignore -->
								<input
									class="text-subtitle-1 text-center truncate w-full p-0 m-0"
									placeholder="Введите название задачи"
									i18n-placeholder="Просмотр навыка|Поле ввода: Введите название задачи@@app-skill-tasks-description-editor-footer.input-title"
									name="title"
									id="title"
									formControlName="title"
								/>
								<label for="title" class="hidden"></label>
							</fieldset>
						</fieldset>
					</form>
					<ng-container [ngSwitch]="!!skill.taskList[skillTaskIndex + 1]">
						<ng-container *ngSwitchCase="true">
							<ng-container *ngTemplateOutlet="next"></ng-container>
						</ng-container>
						<ng-container *ngSwitchDefault>
							<button class="btn btn-primary transparent md" (click)="onAddSkillTask()">
								<figure class="relative">
									<i class="absolute" appSvgIcon appIcon="plus" appSquare="24"></i>
								</figure>
								<!-- prettier-ignore -->
								<span
                  class="block"
                  i18n="Просмотр навыка|Кнопка: Добавить задачу@@app-skill-tasks-description-editor-footer.add"
                >
                  Добавить задачу
                </span>
							</button>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngSwitchDefault>
					<span class="block text-subtitle-1 line-clamp-1">
						{{ skillTask.title }}
					</span>
					<ng-container *ngTemplateOutlet="next"></ng-container>
				</ng-container>
			</ng-container>
		</div>
	</footer>
	<ng-template #next>
		<a
			*ngIf="{
				value: skillTaskIndex + 1,
				taskList: skill.taskList || []
			} as next"
			class="btn btn-primary transparent md"
			[class.disabled]="next.value > skill.taskList.length - 1"
			[routerLink]="[
				'../',
				next.taskList[next.value]
					? next.taskList[next.value].id
					: next.taskList[next.value - 1].id
			]"
			[queryParamsHandling]="'merge'"
		>
			<!-- prettier-ignore -->
			<span
				class="block"
				i18n="Просмотр навыка|Кнопка: Следующая@@app-skill-tasks-description-editor-footer.next"
			>
				Следующая
			</span>
			<figure class="relative">
				<i class="absolute -scale-x-1" appSvgIcon appIcon="arrow-back" appSquare="20"></i>
			</figure>
		</a>
	</ng-template>
</section>
