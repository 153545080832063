/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Event as RouterEvent, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, fromEvent, map, startWith, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PlatformService, Progress, ProgressService } from '../../../core';

@Component({
  selector: 'app-breadcrumbs, [appBreadcrumbs]',
  templateUrl: './breadcrumbs.component.html'
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  routerEvents$!: Subscription;

  progress!: Progress;
  progress$!: Subscription;

  progressIsEstimate!: boolean;
  progressIsEstimate$!: Subscription;

  historyForward$!: Subscription;
  historyBack$!: Subscription;

  backPath$ = new BehaviorSubject<string>('');
  backPathIsInvisible: boolean = false;
  backPathAdditionalTitle: string = '';

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private platformService: PlatformService,
    private progressService: ProgressService
  ) {}

  ngOnInit(): void {
    this.routerEvents$ = this.router.events
      .pipe(
        filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd),
        // @ts-ignore
        map((routerEvent: RouterEvent) => routerEvent.snapshot),
        startWith(this.activatedRoute.snapshot),
        /** Avoid child redirect */

        debounceTime(10)
      )
      .subscribe({
        next: () => {
          const isInvisibleList: string[] = [
            '/profile',
            '/profile/on-boarding',
            '/library/skills',
            '/library/drafts',
            '/library/trajectories',
            '/progress/my/dashboard',
            '/progress/my/results',
            '/team'
          ];

          // prettier-ignore
          this.backPathIsInvisible = isInvisibleList.some((path: string) => this.location.path() === path);
          this.backPath$.next(this.getBackPath());
        }
      });

    this.progress$ = this.progressService.progress$.subscribe({
      next: (progress: Progress) => (this.progress = progress)
    });

    this.progressIsEstimate$ = this.progressService.progressIsEstimate$.subscribe({
      next: (progressIsEstimate: boolean) => (this.progressIsEstimate = progressIsEstimate)
    });

    if (this.platformService.isBrowser()) {
      const window: Window = this.platformService.getWindow();

      // prettier-ignore
      this.historyForward$ = fromEvent(window, 'forward').subscribe({
        next: () => console.debug('History forward')
      });

      /** Replace History API default behaviour */

      this.historyBack$ = fromEvent(window, 'back').subscribe({
        next: () => {
          const backPath: string = this.backPath$.getValue();

          if (!!backPath) {
            window.history.pushState(null, '', backPath);
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    [
      this.progress$,
      this.progressIsEstimate$,
      this.routerEvents$,
      this.historyForward$,
      this.historyBack$
    ].forEach($ => $?.unsubscribe());
  }

  onBack(): void {
    this.router.navigate([this.backPath$.getValue()]).then(() => console.debug('Route changed'));
  }

  getBackPath(): string {
    this.backPathAdditionalTitle = '';

    const location: string = this.location.path();

    const isMyProgress: RegExp = /\/progress\/my\/progress\/\S*/;
    const isMyResult: RegExp = /\/progress\/my\/results\/\S*/;

    const isCreate: RegExp = /\/library\/create\/\S*/;
    const isDrafts: RegExp = /\/library\/drafts\/\S*/;
    const isEdit: RegExp = /\/library\/edit\/\S*/;
    const isSkill: RegExp = /\/library\/skills\/\S*/;

    const isTrajectory: RegExp = /\/library\/trajectories\/\S*/;
    const isTrajectoryEdit: RegExp = /\/library\/trajectories\/(\w+)\/edit$/;
    const isTrajectoryEditAdd: RegExp = /\/library\/trajectories\/(\w+)\/edit\/add$/;

    const isTeammateProgressDashboard: RegExp = /\/progress\/(\d+)\/(dashboard|results)$/m;
    const isTeammateProgress: RegExp = /\/progress\/(\d+)\/progress\/\S*/;
    const isTeammateTrajectories: RegExp = /\/progress\/(\d+)\/trajectory\/\S*/;
    const isTeammateResult: RegExp = /\/progress\/(\d+)\/results\/\S*/;

    const isEstimateDetail: RegExp = /\/progress\/(\d+|my)\/estimate\/\S*\/\S*/;
    const isEstimate: RegExp = /\/progress\/(\d+|my)\/estimate\/\S*/;

    switch (true) {
      case isCreate.test(location): {
        this.backPathAdditionalTitle = $localize`:Хлебные крошки|Название страницы - Создание навыка@@app-breadcrumbs.is-create:Создание навыка`;

        /** [library/create/{skillId}/...] => [library/drafts] */

        return '/library/drafts';
      }
      case isDrafts.test(location): {
        this.backPathAdditionalTitle = $localize`:Хлебные крошки|Название страницы - Просмотр черновика@@app-breadcrumbs.is-drafts:Просмотр черновика`;

        /** [library/drafts/{draftId}/...] => [library/drafts] */

        return '/library/drafts';
      }
      case isEdit.test(location): {
        this.backPathAdditionalTitle = $localize`:Хлебные крошки|Название страницы - Редактирование черновика@@app-breadcrumbs.is-edit:Редактирование черновика`;

        /** [library/edit/{draftId}/...] => [library/drafts] */

        /** This redirect will be changed to [/library/drafts/{draftId}] by quit prompt */

        return '/library/drafts';
      }
      case isSkill.test(location): {
        this.backPathAdditionalTitle = $localize`:Хлебные крошки|Название страницы - Просмотр навыка@@app-breadcrumbs.is-skill:Просмотр навыка`;

        /** [library/skills/{skillId}/...] => [library/skills] */

        return '/library/skills';
      }
      case isTrajectoryEditAdd.test(location): {
        this.backPathAdditionalTitle = $localize`:Хлебные крошки|Название страницы - Добавление навыков@@app-breadcrumbs.is-trajectory-edit-add:Добавление навыков`;

        /** [library/trajectories/{trajectoryId}/edit/add] => [library/trajectories/{trajectoryId}/edit] */

        const [_, id]: string[] = location.match(isTrajectoryEditAdd) as Array<string>;

        return '/library/trajectories/' + id + '/edit';
      }
      case isTrajectoryEdit.test(location): {
        this.backPathAdditionalTitle = $localize`:Хлебные крошки|Название страницы - Редактирование траектории@@app-breadcrumbs.is-trajectory-edit:Редактирование траектории`;

        /** [library/trajectories/{trajectoryId}/edit] => [library/trajectories/{trajectoryId}] */

        const [_, id]: string[] = location.match(isTrajectoryEdit) as Array<string>;

        return '/library/trajectories/' + id;
      }
      case isTrajectory.test(location): {
        this.backPathAdditionalTitle = $localize`:Хлебные крошки|Название страницы - Просмотр траектории@@app-breadcrumbs.is-trajectory:Просмотр траектории`;

        /** [library/trajectories/{trajectoryId}/...] => [library/trajectories] */

        return '/library/trajectories';
      }
      case isMyProgress.test(location): {
        /** [progress/my/progress/{progressId}/...] => [progress/my/dashboard] */

        return '/progress/my/dashboard';
      }
      case isMyResult.test(location): {
        /** [progress/my/results/progress/{progressId}/...] => [progress/my/dashboard] */

        return '/progress/my/results';
      }
      case isTeammateProgressDashboard.test(location): {
        /** [progress/{id}/dashboard, progress/{id}/results] => [team] */

        return '/team';
      }
      case isTeammateProgress.test(location): {
        /** [progress/{id}/progress/{progressId}/...] => [progress/{id}/dashboard] */

        const [_, id]: string[] = location.match(isTeammateProgress) as Array<string>;

        return '/progress/' + id + '/dashboard';
      }
      case isTeammateTrajectories.test(location): {
        /** [progress/{id}/trajectory/{trajectoryId}/...] => [progress/{id}/dashboard] */

        const [_, id]: string[] = location.match(isTeammateTrajectories) as Array<string>;

        return '/progress/' + id + '/dashboard';
      }
      case isTeammateResult.test(location): {
        /** [progress/{id}/results/progress/{progressId}/...] => [progress/{id}/dashboard] */

        const [_, id]: string[] = location.match(isTeammateResult) as Array<string>;

        return '/progress/' + id + '/results';
      }
      case isEstimateDetail.test(location): {
        /** [progress/{id}/estimate/{progressId}/...] => [progress/{id}/estimate/{progressId}] */

        const [_, id]: string[] = location.match(isEstimateDetail) as Array<string>;

        return '/progress/' + id + '/estimate';
      }
      case isEstimate.test(location): {
        /** [progress/{id}/estimate] => [progress/{id}/dashboard] */

        const [_, id]: string[] = location.match(isEstimate) as Array<string>;

        return '/progress/' + id + '/dashboard';
      }
      default: {
        return '';
      }
    }
  }
}
