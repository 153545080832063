<!-- @format -->

<section>
	<app-overlay
		[appState]="state"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && onClose($event)"
	>
		<div class="relative w-[512px] bg-white rounded-lg p-10 pb-8">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="onClose(false)"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div class="flex flex-col">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-5 mb-8"
          i18n="Создание навыка|Заголовок: Выберите шаблон действия@@app-skill-overlay-trajectory-overlay-add.add-title"
        >
					Укажите название траектории
				</span>
				<form [formGroup]="createForm" (ngSubmit)="onSubmitForm()" autocomplete="off">
					<fieldset [disabled]="createFormIsSubmitting">
						<fieldset class="form-control relative mb-8">
							<!-- prettier-ignore -->
							<input
                id="title"
                type="text"
                class="peer"
                placeholder="Название траектории"
                formControlName="title"
                i18n-placeholder="Создание траектории|Поле ввода: Название траектории@@app-skill-overlay-trajectory-overlay-add.input-placeholder-trajectory"
                #inputTitle
              />
							<label
								for="title"
								class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
							>
								<app-error-label
									[appInputElement]="inputTitle"
									[appFormGroup]="createForm"
									[appFormControlName]="'title'"
								></app-error-label>
							</label>
						</fieldset>
						<div class="flex items-center justify-end">
							<button
								class="btn btn-primary inline md !pl-3.5"
								[disabled]="createForm.invalid || createFormIsSubmitting"
							>
								<figure class="relative">
									<i
										class="absolute"
										appSvgIcon
										appIcon="done"
										appSquare="24"
										appViewBox="0 0 24 24"
									></i>
								</figure>
								<!-- prettier-ignore -->
								<span
                  class="block"
                  i18n="Создание траектории|Кнопка: Сохранить@@app-skill-overlay-trajectory-overlay-add.add-trajectory"
                >
                  Сохранить
                </span>
							</button>
						</div>
					</fieldset>
				</form>
			</div>
		</div>
	</app-overlay>
</section>
