/** @format */

import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { HelperService, PlatformService } from '../../../core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { filter, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

export const getErrorMap = (error: string): any => {
  switch (error) {
    case 'title':
      return {
        required: $localize`:Поле ввода|Ошибка - Заполните название@@app-error-label.title-required:Заполните название`,
        minlength: $localize`:Поле ввода|Ошибка - Минимальное количество символов в названии@@app-error-label.title-minlength:Минимальное количество символов в названии $requiredLength$`
      };
    case 'name':
      return {
        required: $localize`:Поле ввода|Ошибка - Заполните имя@@app-error-label.name-required:Заполните имя`,
        firstCharIsDigit: $localize`:Поле ввода|Ошибка - Имя не может начинаться с цифры@@app-error-label.name-firstCharIsDigit:Имя не может начинаться с цифры`,
        firstCharIsDash: $localize`:Поле ввода|Ошибка - Имя не может начинаться с тире@@app-error-label.name-firstCharIsDash:Имя не может начинаться с тире`,
        isDomainName: $localize`:Поле ввода|Ошибка - Имя содержит недопустимые символы@@app-error-label.name-isDomainName:Имя содержит недопустимые символы`,
        maxlength: $localize`:Поле ввода|Ошибка - Максимальное количество символов в имени@@app-error-label.name-maxlength:Максимальное количество символов в имени $requiredLength$`
      };
    case 'surname':
      return {
        required: $localize`:Поле ввода|Ошибка - Заполните фамилию@@app-error-label.surname-required:Заполните фамилию`,
        maxlength: $localize`:Поле ввода|Ошибка - Максимальное количество символов в фамилии@@app-error-label.surname-maxlength:Максимальное количество символов в фамилии $requiredLength$`
      };
    case 'phone':
      return {
        required: $localize`:Поле ввода|Ошибка - Заполните телефон@@app-error-label.phone-required:Заполните телефон`
      };
    case 'email':
      return {
        required: $localize`:Поле ввода|Ошибка - Заполните E-mail@@app-error-label.email-required:Заполните E-mail`,
        email: $localize`:Поле ввода|Ошибка - Некорректный E-mail@@app-error-label.email:Некорректный E-mail`,
        isUnique: $localize`:Поле ввода|Ошибка - E-mail уже добавлен@@app-error-label.email-isUnique:E-mail уже добавлен`
      };
    case 'password':
    case 'newPassword': {
      return {
        required: $localize`:Поле ввода|Ошибка - Заполните пароль@@app-error-label.password-required:Заполните пароль`,
        minlength: $localize`:Поле ввода|Ошибка - Минимальная длина пароля@@app-error-label.password-minlength:Минимальная длина пароля $requiredLength$`
      };
    }
    case 'password_confirmation':
    case 'newPassword_confirmation': {
      return {
        required: $localize`:Поле ввода|Ошибка - Заполните пароль@@app-error-label.password-required:Заполните пароль`,
        minlength: $localize`:Поле ввода|Ошибка - Минимальная длина пароля@@app-error-label.password-minlength:Минимальная длина пароля $requiredLength$`,
        isSame: $localize`:Поле ввода|Ошибка - Пароли не совпадают@@app-error-label.password-isSame:Пароли не совпадают`
      };
    }
    default: {
      return null;
    }
  }
};

@Component({
  selector: 'app-error-label',
  templateUrl: './error-label.component.html'
})
export class ErrorLabelComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('elError') elError!: ElementRef;
  @ViewChild('elParentError') elParentError!: ElementRef;

  @Input()
  set appFormControlName(formControlName: string) {
    this.formControlName = formControlName;
  }

  @Input()
  set appFormGroup(formGroup: FormGroup) {
    this.formGroup = formGroup;
  }

  @Input()
  set appLinkedControlNames(names: string[]) {
    this.linkedControlNames = names;
  }

  @Input()
  set appInputElement(inputElement: HTMLInputElement) {
    const placeholder: string | null = inputElement.getAttribute('placeholder');

    this.label = String(placeholder);
    this.labelDefault = this.label;
  }

  errorLabel$!: Subscription;

  linkedControlNames: string[] = [];
  formControlName!: string;
  formGroup$!: Subscription;
  formGroupControl$!: Subscription;
  formGroup!: FormGroup;
  formId!: number;

  label!: string;
  labelDefault!: string;

  shift: number = 0;
  duration: number = 20; // кол-во пикселей в секунду
  durationBoost: number = 0;

  constructor(
    private platformService: PlatformService,
    private helperService: HelperService,
    private changeDetection: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.formId = this.helperService.getHashCode(Object.keys(this.formGroup.controls));

    /** Backend handler */

    this.errorLabel$ = this.platformService.errorLabel$
      .pipe(
        tap((error: any) => {
          this.label = error[this.formControlName] ?? this.labelDefault;
        }),
        /** Trigger only if errorLabel$ has error and match form and control */

        filter((error: any) => {
          const hasError = !!Object.keys(error).length;

          const isMatchForm = this.formId === this.platformService.errorLabelFormId$.getValue();
          const isMatchFormControlName = error[this.formControlName];

          return hasError && isMatchForm && isMatchFormControlName;
        })
      )
      .subscribe({
        next: () => {
          // @ts-ignore
          const abstractControl: AbstractControl = this.formGroup.get(this.formControlName);

          /** If control valid, but errorLabel$ has error, mean it like server error */

          abstractControl.valid && abstractControl.setErrors({ serverError: true });
        },
        error: (error: any) => console.error(error)
      });

    /** Frontend handler */

    // @ts-ignore
    this.formGroupControl$ = this.formGroup
      .get(this.formControlName)
      ?.valueChanges.pipe(
        tap(() => {
          const errorLabelFormId$ = this.platformService.errorLabelFormId$;

          /** Set this form to active */

          this.formId !== errorLabelFormId$.getValue() && errorLabelFormId$.next(this.formId);
        })
      )
      .subscribe({
        next: () => {
          [this.formControlName, ...this.linkedControlNames].forEach(
            this.processControl.bind(this)
          );
        },
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    [this.errorLabel$, this.formGroup$, this.formGroupControl$].forEach($ => $?.unsubscribe());
  }

  private processControl(controlName: string): void {
    // @ts-ignore
    const control: AbstractControl = this.formGroup.get(controlName);

    /** Trigger only if invalid AND !pristine */

    if (control.invalid && !control.pristine) {
      const validationErrors: ValidationErrors = control.errors || {};

      /** Cycle all over errors */

      for (const errorKey of Object.keys(validationErrors)) {
        let error = getErrorMap(controlName);

        /** If error exist and exist match rule in errorMap */

        if (error && error[errorKey]) {
          let errorLabel = error[errorKey];

          /** Set value to label */

          if (typeof validationErrors[errorKey] === 'object') {
            for (const key of Object.keys(validationErrors[errorKey])) {
              errorLabel = errorLabel.replace('$' + key + '$', validationErrors[errorKey][key]);
            }
          }

          this.platformService.setErrorLabel(controlName, errorLabel);
        }
      }
    } else {
      setTimeout(() => {
        /** Убираем ошибки только если мы не устанавливали isSame на поле.
         *  Ошибку isSame убирает, код ниже
         */

        const controlHasErrorIsSame = 'isSame' in (control?.errors || {});

        if (!controlHasErrorIsSame) {
          if (this.formControlName === controlName) this.label = this.labelDefault;

          this.platformService.setErrorLabel(controlName, null);
        }
      });
    }

    setTimeout(() => {
      // @ts-ignore

      /** isSame у формы */
      const formHasErrorIsSame = 'isSame' in (this.formGroup.errors || {});

      /** isSame у поля */
      const controlHasErrorIsSame = 'isSame' in (control?.errors || {});

      /** Добавляем ошибку. Форма не валидна(есть isSame), а поле валидно (чтоб не перетирать ошибки поля) */
      const addingError = control.valid && formHasErrorIsSame;

      /** Убираем ошибку если у поля есть isSame, а у формы нет isSame */
      const removingError = controlHasErrorIsSame && !formHasErrorIsSame;

      if (addingError) {
        let error = getErrorMap(controlName);

        /** Если есть текст ошибки у поля, тогда ставим ее */
        if (error && error.isSame) {
          let errorLabel = error.isSame;

          this.platformService.setErrorLabel(controlName, errorLabel);

          control.setErrors({ isSame: false });
        }
      }

      if (removingError) {
        control.setErrors(null);

        this.platformService.setErrorLabel(controlName, null);

        if (this.formControlName === controlName) this.label = this.labelDefault;
      }
    });
  }

  ngAfterViewChecked(): void {
    if (this.elParentError && this.elError) {
      const parentWidth = this.elParentError.nativeElement.offsetWidth;
      const childWidth = this.elError.nativeElement.offsetWidth;

      this.shift = parentWidth - childWidth;
      this.durationBoost = Math.abs(this.shift / this.duration) * 2;

      this.changeDetection.detectChanges();
    }
  }
}
