/** @format */

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { APP_CONFIG, Configuration, PlatformService, User, UserService } from '../../../../core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header-logo',
  templateUrl: './header-logo.component.html'
})
export class HeaderLogoComponent implements OnInit, OnDestroy {
  user$!: Subscription;

  isShownAside$!: Subscription;
  isShownAside!: boolean;

  isInTeam!: boolean;
  isInTeamInProfile!: boolean;

  isCanary: boolean | undefined = false;

  constructor(
    @Inject(APP_CONFIG)
    private configuration: Configuration,
    private platformService: PlatformService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.isCanary = this.configuration.isCanary;

    this.isInTeam = this.platformService.isInTeam();

    this.isShownAside$ = this.platformService.isShownAside.subscribe({
      next: (isShownAside: boolean) => (this.isShownAside = isShownAside),
      error: (error: any) => console.error(error)
    });

    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => (this.isInTeamInProfile = !!user.team),
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.isShownAside$, this.user$].forEach($ => $?.unsubscribe());
  }

  setAsideIsShow(): void {
    this.platformService.isShownAside.next(!this.platformService.isShownAside.getValue());
  }
}
