/** @format */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-icon, [appSvgIcon]',
  templateUrl: './svg-icon.component.html'
})
export class SvgIconComponent {
  @Input()
  set appSquare(square: string | number) {
    this.square = square;

    !this.viewBox && (this.viewBox = `0 0 ${square} ${square}`);
  }

  @Input()
  set appViewBox(viewBox: string) {
    this.viewBox = viewBox;
  }

  @Input()
  set appWidth(width: string | number) {
    this.width = width;

    !this.viewBox && (this.viewBox = `0 0 ${width} 0`);
  }

  @Input()
  set appHeight(height: string | number) {
    this.height = height;

    !this.viewBox && (this.viewBox = `0 0 0 ${height}`);
  }

  @Input()
  set appIcon(icon: string) {
    this.icon = icon;
  }

  square!: string | number;
  viewBox!: string;

  width!: string | number;
  height!: string | number;

  icon: string | undefined;
}
