/** @format */

import LinkTool from '@editorjs/link';

export class DabsterLinkTool extends LinkTool {
  private snackbarInfo: any;

  constructor(data: any) {
    super(data);

    this.snackbarInfo = data.config.snackbarInfo;
  }

  render() {
    return super.render();
  }

  static get pasteConfig() {
    return {
      patterns: {
        link: /https?:\/\/\S+$/i
      }
    };
  }

  async onPaste(event: any) {
    switch (event.type) {
      case 'pattern': {
        const url = event.detail.data;

        // @ts-ignore
        this.nodes.input.innerHTML = url;
        super.fetchLinkData(url);
        break;
      }
    }
  }

  fetchingFailed(errorMessage: string) {
    this.snackbarInfo(errorMessage);

    // @ts-ignore
    this.applyErrorStyle();
  }
}
