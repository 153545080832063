<!-- @format -->
<section class="block relative" [class.hidden]="!editorJSReady">
	<div id="editorJS" class="skill-task-description-editor w-full"></div>
	<!-- prettier-ignore -->
	<div
    *ngIf="skillIsEditIndividual && ['ready', 'done'].includes(!!skillTask && !!skillTask.status ? skillTask.status : '')"
		appHintFollow
		class="absolute top-0 left-0 bottom-0 right-0 w-full h-full z-[1] cursor-not-allowed"
		i18n-hint-follow="Редактор|Подсказка: Вы не можете редактировать выполненное действие — сначала верните его в работу@@app-skill-tasks-description-editor.blocked-task-hint"
		data-hint-follow="Вы не можете редактировать выполненное действие — сначала верните его в работу"
	></div>
</section>
