/** @format */

import { Directive, Inject, OnDestroy, OnInit } from '@angular/core';
import { Crisp, PlatformService, SnackbarService, User, UserService } from '../../core';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { tap } from 'rxjs/operators';

@Directive({
  selector: '[appCrisp]'
})
export class AppCrispDirective implements OnInit, OnDestroy {
  user$!: Subscription;
  user!: User;

  isShownFooter$!: Subscription;

  isShownAside$!: Subscription;

  isMobileAside$!: Subscription;
  isMobileAside!: boolean;

  $crisp: any;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private userService: UserService,
    private platformService: PlatformService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    Crisp.default
      .getCrisp()
      .then(($crisp: any) => {
        this.$crisp = $crisp;
        this.$crisp.push(['on', 'session:loaded', () => this.onLoaded()]);
      })
      .catch(() => console.debug('Crisp initialization failed'));
  }

  ngOnDestroy(): void {
    [this.user$, this.isShownAside$, this.isMobileAside$, this.isShownFooter$].forEach($ =>
      $?.unsubscribe()
    );
  }

  onLoaded(): void {
    this.$crisp.push(['safe', true]);

    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => {
        this.user = user;

        if (!!Object.keys(user).length) {
          const fullName: string = user.name + ' ' + user.surname;
          const team: string = user?.team?.name || 'Вне команды';

          this.$crisp.push(['set', 'user:nickname', [fullName + ' | ' + team]]);
        } else {
          this.$crisp.push(['set', 'user:nickname', ['Неизвестный | Неавторизованный']]);
        }
      },
      error: (error: any) => console.error(error)
    });

    this.isShownAside$ = this.platformService.isShownAside.subscribe({
      next: (isShownAside: boolean) => {
        if (isShownAside || !Object.keys(this.user).length) {
          this.onShow();

          this.setPosition();
        } else {
          this.onHide();
        }
      },
      error: (error: any) => console.error(error)
    });

    this.isMobileAside$ = this.platformService.isMobileAside
      .pipe(tap((isMobileAside: boolean) => (this.isMobileAside = isMobileAside)))
      .subscribe({
        next: () => this.setPosition(),
        error: (error: any) => console.error(error)
      });

    this.isShownFooter$ = this.snackbarService.isShownFooter$.subscribe({
      next: () => this.setPosition(),
      error: (error: any) => console.error(error)
    });
  }

  onShow(): void {
    const timeout: any = setTimeout(() => {
      this.$crisp.do('chat:show');

      clearTimeout(timeout);
    }, 200);
  }

  onHide(): void {
    this.$crisp.do('chat:hide');
  }

  setPosition(): void {
    const isShownFooter: boolean = this.snackbarService.isShownFooter$.getValue();
    const isMobileAside: boolean = this.platformService.isMobileAside.getValue();

    const crispChatBox: HTMLElement | null = this.document.querySelector('#crisp-chatbox');

    if (crispChatBox) {
      const crispChat: Element | null = crispChatBox.querySelector('div > div:first-child > div');
      const crispIcon: Element | null = crispChatBox.querySelector('div > a[role="button"]');

      if (isShownFooter && !isMobileAside) {
        if (crispChat)
          crispChat.setAttribute('style', 'bottom: 72px !important; width: 400px !important;');

        if (crispIcon) crispIcon.setAttribute('style', 'bottom: 72px !important;');
      } else {
        if (crispChat)
          crispChat.setAttribute('style', 'bottom: 14px !important; width: 400px !important; ');

        if (crispIcon) crispIcon.setAttribute('style', 'bottom: 14px !important;');
      }
    }
  }
}
