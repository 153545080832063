/**
 *
 * Window code snippet is based on
 * https://juristr.com/blog/2016/09/ng2-get-window-ref/
 *
 * @format
 */

import {
  Inject,
  Injectable,
  LOCALE_ID,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, EMPTY, fromEvent, Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { Configuration } from '../models';
import { APP_CONFIG } from './configuration.service';

const getWindow = (): Window => window;

@Injectable()
export class PlatformService {
  /** GLOBAL LAYERS */

  isMobileAside = new BehaviorSubject<boolean>(false);

  isShownAside = new BehaviorSubject<boolean>(false);
  isShownHttp = new BehaviorSubject<boolean>(false);
  isShownOverlay = new BehaviorSubject<boolean>(false);

  /** ERROR LABEL */

  errorLabelFormId$ = new BehaviorSubject<number>(0);
  errorLabel$ = new BehaviorSubject<any>({});

  /** SORTING */

  sortingType$ = new BehaviorSubject<string>('created_at');
  sortingReverse$ = new BehaviorSubject<boolean>(false);

  renderer2: Renderer2;

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: string,
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(LOCALE_ID)
    public locale: string,
    private rendererFactory2: RendererFactory2,
    @Inject(APP_CONFIG)
    private configuration: Configuration
  ) {
    this.renderer2 = rendererFactory2.createRenderer(null, null);
  }

  isInTeam(): boolean {
    if (this.isBrowser()) {
      const window: Window = this.getWindow();
      const hostname: string = window.location.hostname;
      const rootDomain: string = this.configuration.rootDomain;

      switch (true) {
        // TEST .BRANCH_PREFIX.dabster-kw.ru
        case rootDomain.includes('.BRANCH_PREFIX'): {
          // prettier-ignore
          return hostname.replace(rootDomain.replace('.BRANCH_PREFIX.', ''), '').split('.').length > 2;
        }
        // DEV .dev.dabster-kw.ru
        case rootDomain.includes('.dev'): {
          return hostname.replace(rootDomain.replace('.dev.', ''), '').split('.').length > 2;
        }
        // PROD .dabster-kw.ru
        case rootDomain[0].includes('.'): {
          return hostname.replace(rootDomain.slice(1), '').split('.').length > 1;
        }
      }
    }

    return false;
  }

  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  getWindow(): Window {
    return getWindow();
  }

  getLocale(): string {
    return this.locale;
  }

  getScroll(debounce: number = 100): Observable<Event> {
    const content: HTMLElement | null = this.document.querySelector('.app-content');

    if (content) {
      return fromEvent(content, 'scroll').pipe(debounceTime(debounce));
    }

    return EMPTY;
  }

  getScrollBottom(debounce: number = 400): Observable<Event> {
    return this.getScroll(debounce).pipe(
      filter((event: Event) => {
        const element: any = event.target;

        const scrollHeight: number = element.scrollHeight;
        const scrollTop: number = element.scrollTop;

        const clientHeight: number = element.clientHeight;

        /** 72 it's a bottom padding of content */

        return scrollHeight - scrollTop < clientHeight + 72;
      })
    );
  }

  setScrollToggle(toggle: boolean): void {
    if (this.isBrowser()) {
      const window = this.getWindow();
      const className = 'overlay';

      if (this.document.body.clientHeight > window.innerHeight) {
        this.renderer2[toggle ? 'addClass' : 'removeClass'](this.document.body, className);
      } else {
        this.renderer2.removeClass(this.document.body, className);
      }
    }
  }

  setErrorLabel(control: string, label: string | null): void {
    this.errorLabel$.next({
      ...this.errorLabel$.getValue(),
      [control]: label
    });
  }

  getCardListLimit(): number {
    const lines: number = 4;

    let cardListLimit: number = 10;

    if (this.isBrowser()) {
      const window: Window = this.getWindow();
      const width: number = window.innerWidth;

      if (width > 1160) {
        cardListLimit = lines * 4;
      } else if (width <= 1160 && width >= 905) {
        cardListLimit = lines * 3;
      } else {
        cardListLimit = lines * 2;
      }
    }

    return cardListLimit;
  }
}
