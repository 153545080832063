/** KW: Delete button handler */
/** https://editorjs.io/the-first-plugin */

// @ts-ignore
// prettier-ignore
class DeleteButtonHandler {
  constructor(editor) {
    this.editor = editor;

    this.onInit();
  }

  onInit() {
    this.editor.listeners.on(window, "keydown", (event) => {
      if (event.key === "Delete") {
        const redactor = this.editor.ui.nodes.redactor.querySelectorAll(".ce-block--selected");

        const indexesToDelete = []
        redactor.forEach((block) => {
          const i = Array.from(block.parentNode.children).indexOf(block);
          indexesToDelete.push(i)
        })

        indexesToDelete.reverse().forEach(index => {
          setTimeout(() => this.editor.blocks.delete(index), 0)
        })
      }
    }, false);
  }

  render() {
    return false;
  }

  save() {
    return false;
  }
}

module.exports = DeleteButtonHandler
