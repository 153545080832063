/** @format */

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ProgressService,
  SnackbarService,
  TeamMember,
  TeamMemberPagination,
  TeamService,
  Trajectory,
  TrajectoryAssigned,
  TrajectoryService,
  User,
  UserService
} from '../../../../core';
import { delay, of, Subscription, switchMap } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, debounceTime, first, tap } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-trajectory-title',
  templateUrl: './title.component.html'
})
export class TrajectoryTitleComponent implements OnInit, OnDestroy {
  @ViewChild('textAreaElement') textAreaElement!: ElementRef;

  user$!: Subscription;
  user!: User;

  trajectory!: Trajectory;
  trajectory$!: Subscription;

  trajectoryForm: FormGroup;
  trajectoryForm$!: Subscription;
  trajectoryFormIsSubmitting = false;

  trajectoryIsEdit!: boolean;
  trajectoryIsEdit$!: Subscription;

  trajectoryIsProgress!: boolean;
  trajectoryIsProgress$!: Subscription;

  trajectoryIsResult!: boolean;
  trajectoryIsResult$!: Subscription;

  trajectorySetTeammateState: string = 'select';
  trajectorySetTeammateToggle!: boolean;

  teamMemberSelected: TeamMember | undefined;
  teamMemberList: TeamMember[] = [];

  trajectoryRemoveToggle!: boolean;
  trajectoryProgressRemoveToggle!: boolean;

  trajectoryAssigned!: any;
  trajectoryAssignedToggle!: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private trajectoryService: TrajectoryService,
    private teamService: TeamService,
    private progressService: ProgressService,
    private location: Location,
    private snackbarService: SnackbarService
  ) {
    this.trajectoryForm = this.formBuilder.group({
      title: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => (this.user = user)
    });

    this.trajectory$ = this.trajectoryService.trajectory$.subscribe({
      next: (trajectory: Trajectory) => {
        this.trajectory = trajectory;

        this.trajectoryForm.patchValue(this.trajectory, {
          emitEvent: false
        });
      }
    });

    this.trajectoryIsEdit$ = this.trajectoryService.trajectoryIsEdit$.subscribe({
      next: (trajectoryIsEdit: boolean) => (this.trajectoryIsEdit = trajectoryIsEdit)
    });

    this.trajectoryIsProgress$ = this.trajectoryService.trajectoryIsProgress$.subscribe({
      next: (trajectoryIsProgress: boolean) => (this.trajectoryIsProgress = trajectoryIsProgress)
    });

    this.trajectoryIsResult$ = this.trajectoryService.trajectoryIsResult$.subscribe({
      next: (trajectoryIsResult: boolean) => (this.trajectoryIsResult = trajectoryIsResult)
    });

    this.trajectoryForm$ = this.trajectoryForm.valueChanges.pipe(debounceTime(100)).subscribe({
      next: (value: any) => {
        this.trajectoryService.trajectory$.next({
          ...this.trajectory,
          ...value
        });
      }
    });
  }

  ngOnDestroy(): void {
    [
      this.user$,
      this.trajectory$,
      this.trajectoryIsEdit$,
      this.trajectoryIsProgress$,
      this.trajectoryForm$
    ].forEach($ => $?.unsubscribe());
  }

  onGotoEdit(): void {
    this.router
      .navigate(['edit'], {
        relativeTo: this.activatedRoute
      })
      .then(() => console.debug('Route changed'));
  }

  onToggleRemoveTrajectory(): void {
    this.trajectoryRemoveToggle = true;
  }

  onRemoveTrajectory(): void {
    this.trajectoryRemoveToggle = false;

    this.trajectoryService.removeTrajectory(this.trajectory.id).subscribe({
      next: (trajectory: Trajectory) => {
        this.router.navigate(['/library/trajectories']).then(() => {
          this.snackbarService.info(
            $localize`:Снекбар|Сообщение - Траектория была удалена@@app-snackbar.trajectory-deleted:Траектория была удалена`,
            {
              timeout: 3000,
              icon: 'done',
              progress: true,
              progressClass: 'bg-secondary-600'
            }
          );
        });
      }
    });
  }

  onToggleSetTeammateTrajectory(toggle: boolean): void {
    const setState = (): void => {
      this.trajectorySetTeammateToggle = toggle;

      const stateTimeout: number = setTimeout(() => {
        this.trajectorySetTeammateState = 'select';
        this.teamMemberSelected = undefined;

        clearTimeout(stateTimeout);
      }, 300);
    };

    if (toggle) {
      this.teamService
        .getTeamMembers({ trajectoryId: this.trajectory.id })
        .pipe(
          tap((teamMemberPagination: TeamMemberPagination) => {
            this.teamMemberList = teamMemberPagination.items.filter((teamMember: TeamMember) => {
              return teamMember.id !== this.user.team_member_id;
            });
          })
        )
        .subscribe({
          next: () => setState()
        });
    } else {
      setState();
    }
  }

  onSetTeammateTrajectory(teamMemberId: number | undefined): void {
    const setTrajectory = (callback: any): void => {
      this.trajectoryService
        .setTrajectoryToProgress(Number(teamMemberId), { trajectory: this.trajectory.id })
        .pipe(
          // prettier-ignore
          switchMap((trajectoryAssigned: TrajectoryAssigned) => of({ ...trajectoryAssigned, assigned: true })),
          catchError((error: any) => of({ ...error.data, assigned_already: true }))
        )
        .subscribe({
          next: (progress: any) => callback(progress)
        });
    };

    if (teamMemberId !== this.user.team_member_id) {
      setTrajectory(() => (this.trajectorySetTeammateState = 'done'));
    } else {
      setTrajectory((trajectoryAssigned: TrajectoryAssigned) => {
        /** Assign trajectory and redirect */
        if (!!trajectoryAssigned.assigned) {
          let path: string = this.location.path();

          path = path.replace('library/trajectories', 'progress/my/trajectory');
          path = path.replace(String(this.trajectory.id), trajectoryAssigned.id);

          this.router.navigate([path]).then(() => console.debug('Route changed'));
        }
        /** If trajectory already assigned */
        if (!!trajectoryAssigned.assigned_already) {
          this.trajectoryAssigned = trajectoryAssigned;
          this.trajectoryAssignedToggle = true;
        }
      });
    }
  }

  onRemoveTrajectoryProgress(): void {
    this.trajectoryProgressRemoveToggle = false;
    // @ts-ignore
    this.trajectoryService.setTrajectoryRemoveProgress(this.trajectory.progress.id).subscribe({
      next: () => {
        // prettier-ignore
        const teamMemberId: string | number | undefined = this.user.id === this.trajectory.progress?.user?.team_member_id ? 'my' : this.trajectory.progress?.user?.team_member_id;

        this.router
          .navigate(['/progress', teamMemberId, 'dashboard'])
          .then(() => console.debug('Route changed'));
      },
      error: (error: any) => console.error(error)
    });
  }

  onGotoTeam(): void {
    this.trajectorySetTeammateToggle = false;

    /** Await modal close, avoid router animation glitches  */

    of(true)
      .pipe(first(), delay(300))
      .subscribe({
        next: () => {
          this.router
            .navigate(['/team'], { queryParams: { addToTeam: 1 } })
            .then(() => console.debug('Route changed'));
        }
      });
  }

  onGotoTrajectory(): void {
    this.trajectoryAssignedToggle = false;

    /** Await modal close, avoid router animation glitches  */

    of(true)
      .pipe(first(), delay(300))
      .subscribe({
        next: () => {
          this.router
            .navigate(['/progress/my/trajectory', this.trajectoryAssigned?.id])
            .then(() => console.debug('Route changed'));
        }
      });
  }
}
