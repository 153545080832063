/** @format */

import { Inject, Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SnackbarService } from './snackbar.service';
import { PlatformService } from './platform.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class HelperService {
  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private snackbarService: SnackbarService,
    private platformService: PlatformService
  ) {}

  getPlural(list: string[], n: number, round: 'ceil' | 'floor' = 'floor'): string {
    // List meaning words for 1, 2, 5 items

    let index = Math[round](Math.abs(n)) % 100;

    if (index >= 5 && index <= 20) {
      return list[2];
    }

    index %= 10;

    if (index === 1) {
      return list[0];
    }

    if (index >= 2 && index <= 4) {
      return list[1];
    }

    return list[2];
  }

  getRandomInt(max: number = 10, min: number = 0): number {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);
  }

  getFormValidation(form: FormGroup): boolean {
    const formId = this.getHashCode(Object.keys(form.controls));

    if (formId !== this.platformService.errorLabelFormId$.getValue()) {
      this.platformService.errorLabelFormId$.next(formId);
    }

    if (form.valid) {
      return true;
    }

    Object.keys(form.controls).forEach((key: string) => {
      form.get(key)?.markAsTouched({ onlySelf: true });
    });

    return false;
  }

  getFileValidation(file: File, mimeTypes: string[], size: number = 10): boolean {
    const maxSize = 1000 * 1000 * size;

    const isValidMime = !!mimeTypes.length ? mimeTypes.includes(file.type) : true;
    const isValidSize = file.size <= maxSize;

    if (!isValidMime) {
      this.snackbarService.info(
        $localize`:Снекбар|Сообщение - Выбранный вами файл не поддерживается@@app-snackbar.file-not-supported:Выбранный вами файл не поддерживается`,
        {
          icon: 'attention'
        }
      );
    }

    if (!isValidSize) {
      this.snackbarService.info(
        $localize`:Снекбар|Сообщение - Максимально допустимый размер файла@@app-snackbar.file-max-size:Максимально допустимый размер файла до ${size} МБ`,
        {
          icon: 'attention'
        }
      );
    }

    return isValidMime && isValidSize;
  }

  getCustomValidation(customValidation: string, payload?: any): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors | null => {
      switch (customValidation) {
        case 'isUnique': {
          const { list, key }: { list: any[]; key: string } = payload();

          if (list.some((item: any) => item[key] === abstractControl.value)) {
            return { isUnique: false };
          }

          return null;
        }
        case 'isSame': {
          const { left, right }: { left: string; right: string } = payload;

          const formGroup: FormGroup = abstractControl as FormGroup;

          if (formGroup.get(left)?.value !== formGroup.get(right)?.value) {
            return { isSame: false };
          }

          return null;
        }
        case 'firstCharIsDigit': {
          if (new RegExp('^\\d').test(abstractControl.value)) {
            return { firstCharIsDigit: false };
          }
          return null;
        }
        case 'firstCharIsDash': {
          if (new RegExp('^-').test(abstractControl.value)) {
            return { firstCharIsDash: false };
          }
          return null;
        }
        case 'isDomainName': {
          if (new RegExp('[^0-9a-zA-Z\\s-]').test(abstractControl.value)) {
            return { isDomainName: false };
          }
          return null;
        }
        default: {
          return null;
        }
      }
    };
  }

  getHashCode(source: any): number {
    let hash = 0;

    const str = JSON.stringify(source);

    if (!!str.length) {
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);

        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
      }
    }

    return hash;
  }

  getRouteParams(snapshot: ActivatedRouteSnapshot): any[] {
    const params: any = [];
    const getParams = (activatedRouteSnapshot: ActivatedRouteSnapshot) => {
      params.push(activatedRouteSnapshot.params);

      activatedRouteSnapshot.firstChild && getParams(activatedRouteSnapshot.firstChild);
    };

    getParams(snapshot);

    return params;
  }

  async clipboardCopy(text: string): Promise<void> {
    if (this.platformService.isBrowser()) {
      const window: Window = this.platformService.getWindow();

      if (!window.navigator.clipboard) {
        const textArea: HTMLTextAreaElement = this.document.createElement('textarea');
        textArea.value = text;

        // Avoid scrolling to bottom

        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        this.document.body.appendChild(textArea);

        textArea.focus();
        textArea.select();

        this.document.execCommand('copy');
        this.document.body.removeChild(textArea);

        return;
      }

      await window.navigator.clipboard.writeText(text);
    }
  }
}
