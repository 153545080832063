<!-- @format -->

<div class="flex flex-col items-start w-full">
	<h4
		class="text-headline-4 mb-8"
		i18n="Ошибка|Заголовок: У вас нет доступа@@app-auth-error.error-title"
	>
		У вас нет доступа
	</h4>
	<ng-container [ngSwitch]="statusCode">
		<ng-container *ngSwitchCase="401">
			<!-- prettier-ignore -->
			<p
				class="text-body-1 mb-6"
				i18n="Ошибка|Параграф: Пожалуйста, войдите в Dabster@@app-auth-error.error-401-paragraph"
			>
				Пожалуйста, войдите в Dabster. Если у вас нет аккаунта, запросите приглашение у
				администратора своей команды.
			</p>
			<a
				class="btn btn-primary inline md"
				data-pw="button-login"
				[routerLink]="['/auth/login']"
				i18n="Ошибка|Ссылка: Войти в Dabster@@app-auth-error.error-401-link"
			>
				Войти в Dabster
			</a>
		</ng-container>
		<ng-container *ngSwitchCase="403">
			<!-- prettier-ignore -->
			<p
				class="text-body-1 mb-6"
				i18n="Ошибка|Параграф: Похоже, вы не состоите в этой команде@@app-auth-error.error-403-paragraph"
			>
				Похоже, вы не состоите в этой команде. Попробуйте войти в другой аккаунт.
			</p>
			<button
				class="btn btn-primary inline md"
				(click)="onLogout()"
				i18n="Ошибка|Кнопка: Войти в другой аккаунт@@app-auth-error.error-403-button"
			>
				Войти в другой аккаунт
			</button>
		</ng-container>
	</ng-container>
</div>
