<!-- @format -->

<footer
	class="flex items-center bg-white effect-02dp tablet:pl-0"
	[class.pl-64]="!isFullscreen"
>
	<div class="flex items-center justify-between w-full py-2 px-8" data-pw="box-status">
		<ng-container [ngSwitch]="true">
			<button
				class="btn btn-gray transparent md pointer-events-none min-w-[15rem]"
				data-pw="button-state-no-changes"
				*ngSwitchCase="webSocketStatus.status === 3"
			>
				<figure class="relative">
					<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
				</figure>
				<!-- prettier-ignore -->
				<span
					class="block text-body-2 text-blue-grey-700"
					i18n="Просмотр навыка|Текст: Изменений нет@@app-footer-skill.state-no-changes"
				>
					Изменений нет
				</span>
			</button>
			<button
				class="btn btn-gray transparent md pointer-events-none min-w-[15rem]"
				*ngSwitchCase="webSocketStatus.status === 1"
			>
				<figure class="relative">
					<i
						class="absolute text-tertiary-A400"
						appSvgIcon
						appIcon="cross"
						appSquare="24"
					></i>
				</figure>
				<!-- prettier-ignore -->
				<span
					class="block text-body-2 text-blue-grey-700"
					i18n="Просмотр навыка|Текст: Не удалось сохранить@@app-footer-skill.state-cannot-save"
				>
					Не удалось сохранить
				</span>
			</button>
			<button
				class="btn btn-gray transparent md pointer-events-none min-w-[15rem]"
				data-pw="button-state-saving"
				*ngSwitchCase="webSocketStatus.status === 2"
			>
				<figure class="relative animate-spin transform rotate-180">
					<i
						class="absolute text-secondary-500"
						appSvgIcon
						appIcon="reload"
						appSquare="20"
					></i>
				</figure>
				<!-- prettier-ignore -->
				<span
					class="block text-body-2 text-blue-grey-700"
					i18n="Просмотр навыка|Текст: Сохраняем@@app-footer-skill.state-saving"
				>
					Сохраняем...
				</span>
			</button>
			<button
				class="btn btn-gray transparent md pointer-events-none min-w-[15rem]"
				data-pw="button-state-saved"
				*ngSwitchCase="webSocketStatus.status === 0"
			>
				<figure class="block relative">
					<i
						class="absolute text-secondary-500"
						appSvgIcon
						appIcon="done"
						appSquare="24"
					></i>
				</figure>
				<!-- prettier-ignore -->
				<span
					class="block text-body-2 text-blue-grey-700"
					i18n="Просмотр навыка|Текст: Сохранено в черновиках@@app-footer-skill.state-saved"
				>
					Сохранено в черновиках
				</span>
			</button>
		</ng-container>
		<button
			class="btn btn-primary transparent md"
			data-pw="button-add-action"
			(click)="onAddSkillTask()"
		>
			<figure class="relative">
				<i class="absolute" appSvgIcon appIcon="plus" appSquare="24"></i>
			</figure>
			<span
				class="block"
				i18n="Просмотр навыка|Кнопка: Добавить задачу@@app-footer-skill.task-add"
			>
				Добавить задачу
			</span>
		</button>
		<div class="flex items-center justify-end space-x-4">
			<div class="hidden md:block">
				<button
					class="btn btn-primary inline md"
					data-pw="button-skill-submit"
					(click)="onFinish()"
				>
					<figure class="relative">
						<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
					</figure>
					<span
						class="block"
						i18n="Просмотр навыка|Кнопка: Завершить@@app-footer-skill.skill-submit"
					>
						Завершить
					</span>
				</button>
			</div>
			<div class="block md:hidden">
				<button
					class="btn btn-primary inline md"
					data-pw="button-skill-submit"
					(click)="onFinish()"
				>
					<figure class="relative">
						<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
					</figure>
				</button>
			</div>
		</div>
	</div>
</footer>
