/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Skill, Trajectory, TrajectoryService } from '../../../../../core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-trajectory-overlay-publish',
  templateUrl: './publish.component.html'
})
export class TrajectoryOverlayPublishComponent implements OnInit, OnDestroy {
  state!: boolean;
  state$!: Subscription;

  trajectory!: Trajectory;
  trajectory$!: Subscription;
  trajectoryIsSubmitting!: boolean;

  constructor(private trajectoryService: TrajectoryService) {}

  ngOnInit(): void {
    this.state$ = this.trajectoryService.trajectoryPublishModalToggle.subscribe({
      next: (state: boolean) => (this.state = state)
    });

    this.trajectory$ = this.trajectoryService.trajectory$.subscribe({
      next: (trajectory: Trajectory) => (this.trajectory = trajectory)
    });
  }

  ngOnDestroy(): void {
    [this.state$, this.trajectory$].forEach($ => $?.unsubscribe());
  }

  onClose(toggle: boolean): void {
    this.trajectoryService.trajectoryPublishModalToggle.next(toggle);
  }

  onPublishTrajectory(): void {
    if (this.trajectoryService.trajectoryValidation(this.trajectory)) {
      this.trajectoryIsSubmitting = true;

      const body: any = {
        title: this.trajectory.title,
        skills: this.trajectory.skills.map((skill: Skill) => skill.id)
      };

      // prettier-ignore
      this.trajectoryService.publishTrajectory(this.trajectory.id, body).subscribe({
        next: (trajectory: Trajectory) => this.trajectoryService.trajectoryConfirmationModalOnPublish.emit(trajectory)
      })
    }
  }
}
