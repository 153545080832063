<!-- @format -->

<div class="relative">
	<div class="flex">
		<ng-content select="[slot=trigger]"></ng-content>
	</div>
	<div *ngIf="hintShow" class="absolute" [ngClass]="hintClassList">
		<ng-content select="[slot=hint]"></ng-content>
	</div>
</div>
