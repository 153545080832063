/** https://editorjs.io/internationalization */

// prettier-ignore
export const DabsterI18n = {
  messages: {
    ui: {
      "blockTunes": {
        "toggler": {
          "Click to tune": $localize`:Редактор|Интерфейс - Нажмите, чтобы настроить@@editor-ui.toggler.Click-to-tune:Нажмите, чтобы настроить`,
          "or drag to move": $localize`:Редактор|Интерфейс - или перетащите@@editor-ui.toggler.or-drag-to-move:или перетащите`
        },
      },
      "inlineToolbar": {
        "converter": {
          "Convert to": $localize`:Редактор|Интерфейс - Конвертировать в@@editor-ui.converter.Convert-to:Конвертировать в`
        }
      },
      "toolbar": {
        "toolbox": {
          "Add": $localize`:Редактор|Интерфейс - Добавить@@editor-ui.toolbox.Add:Добавить`
        }
      }
    },
    toolNames: {
      "Text": $localize`:Редактор|Название инструментов - Параграф@@editor-toolNames.Text:Параграф`,
      "Heading": $localize`:Редактор|Название инструментов - Заголовок@@editor-toolNames.Heading:Заголовок`,
      "List": $localize`:Редактор|Название инструментов - Список@@editor-toolNames.List:Список`,
      "Warning": $localize`:Редактор|Название инструментов - Примечание@@editor-toolNames.Warning:Примечание`,
      "Checklist": $localize`:Редактор|Название инструментов - Чеклист@@editor-toolNames.Checklist:Чеклист`,
      "Quote": $localize`:Редактор|Название инструментов - Цитата@@editor-toolNames.Quote:Цитата`,
      "Code": $localize`:Редактор|Название инструментов - Код@@editor-toolNames.Code:Код`,
      "Delimiter": $localize`:Редактор|Название инструментов - Разделитель@@editor-toolNames.Delimiter:Разделитель`,
      "Raw HTML": $localize`:Редактор|Название инструментов - HTML-фрагмент@@editor-toolNames.Raw-HTML:HTML-фрагмент`,
      "Table": $localize`:Редактор|Название инструментов - Таблица@@editor-toolNames.Table:Таблица`,
      "Link": $localize`:Редактор|Название инструментов - Ссылка@@editor-toolNames.Link:Ссылка`,
      "Marker": $localize`:Редактор|Название инструментов - Маркер@@editor-toolNames.Marker:Маркер`,
      "Bold": $localize`:Редактор|Название инструментов - Полужирный@@editor-toolNames.Bold:Полужирный`,
      "Italic": $localize`:Редактор|Название инструментов - Курсив@@editor-toolNames.Italic:Курсив`,
      "InlineCode": $localize`:Редактор|Название инструментов - Моноширинный@@editor-toolNames.InlineCode:Моноширинный`,
      "Underline": $localize`:Редактор|Название инструментов - Подчеркнутый@@editor-toolNames.Underline:Подчеркнутый`,
      "Image": $localize`:Редактор|Название инструментов - Изображение@@editor-toolNames.Image:Изображение`,
      "Attaches": $localize`:Редактор|Название инструментов - Файл@@editor-toolNames.Attaches:Файл`,
    },
    tools: {
      "paragraph": {
        "Align left": $localize`:Редактор|Инструменты параграф - По левому краю@@editor-tools.paragraph.Align-left:По левому краю`,
        "Align center": $localize`:Редактор|Инструменты параграф - По центру@@editor-tools.paragraph.Align-center:По центру`,
        "Align right": $localize`:Редактор|Инструменты параграф - По правому краю@@editor-tools.paragraph.Align-right:По правому краю`,
        "Align justify": $localize`:Редактор|Инструменты параграф - По ширине@@editor-tools.paragraph.Align-justify:По ширине`,
      },
      "header": {
        "Placeholder": $localize`:Редактор|Инструменты заголовок - Введите заголовок@@editor-tools.header.Placeholder:Введите заголовок`
      },
      "quote": {
        "quotePlaceholder": $localize`:Редактор|Инструменты цитата - “ Введите цитату ”@@editor-tools.quote.quotePlaceholder:“ Введите цитату ”`,
        "captionPlaceholder": $localize`:Редактор|Инструменты цитата - Укажите автора@@editor-tools.quote.captionPlaceholder:— Укажите автора`,
        "alignLeft": $localize`:Редактор|Инструменты цитата - По левому краю@@editor-tools.quote.alignLeft:По левому краю`,
        "alignCenter": $localize`:Редактор|Инструменты цитата - По центру@@editor-tools.quote.alignCenter:По центру`
      },
      "list": {
        "Ordered": $localize`:Редактор|Инструменты список - Нумерованный@@editor-tools.list.Ordered:Нумерованный`,
        "Unordered": $localize`:Редактор|Инструменты список - Маркированный@@editor-tools.list.Unordered:Маркированный`,
      },
      "warning": {
        "titlePlaceholder": $localize`:Редактор|Инструменты предупреждение - Заголовок@@editor-tools.warning.Title:Заголовок`,
        "messagePlaceholder": $localize`:Редактор|Инструменты предупреждение - Сообщение@@editor-tools.warning.Message:Сообщение`,
      },
      "link": {
        "Add a link": $localize`:Редактор|Инструменты ссылка - Вставьте ссылку@@editor-tools.link.Add-a-link:Вставьте ссылку`
      },
      "image": {
        "With border": $localize`:Редактор|Инструменты изображение - Добавить рамку@@editor-tools.image.With-border:Добавить рамку`,
        "Stretch image": $localize`:Редактор|Инструменты изображение - Растянуть@@editor-tools.image.Stretch-image:Растянуть`,
        "With background": $localize`:Редактор|Инструменты изображение - Добавить фон@@editor-tools.image.With-background:Добавить фон`,
        "Caption": $localize`:Редактор|Инструменты изображение - Подпись@@editor-tools.image.Caption:Подпись`,
        "Select an Image": $localize`:Редактор|Инструменты изображение - Выберите изображение@@editor-tools.image.Select-an-Image:Выберите изображение`,
        "Couldn't upload image. Please try another.": $localize`:Редактор|Инструменты изображение - Не удалось загрузить изображение.@@editor-tools.image.Couldn't-upload-image:Не удалось загрузить изображение. Пожалуйста, попробуйте другое.`,
        "Align left": $localize`:Редактор|Инструменты изображение - По левому краю@@editor-tools.image.Align-left:По левому краю`,
        "Align center": $localize`:Редактор|Инструменты изображение - По центру@@editor-tools.image.Align-center:По центру`,
        "Align right": $localize`:Редактор|Инструменты изображение - По правому краю@@editor-tools.image.Align-right:По правому краю`,
      },
      "linkTool": {
        "Couldn't fetch the link data": $localize`:Редактор|Инструменты ссылка - Не удалось получить данные ссылки@@editor-tools.linkTool.Couldn't-fetch:Не удалось получить данные ссылки`,
        "Couldn't get this link data, try the other one": $localize`:Редактор|Инструменты ссылка - Не удалось получить данные этой ссылки@@editor-tools.linkTool.Couldn't-get:Не удалось получить данные этой ссылки, попробуйте другую` ,
        "Wrong response format from the server": $localize`:Редактор|Инструменты ссылка - Неверный формат ответа от сервера@@editor-tools.linkTool.Wrong-response:Неверный формат ответа от сервера`,
        "Link": $localize`:Редактор|Инструменты ссылка - Ссылка@@editor-tools.linkTool.Link:Ссылка`
      },
      "table": {
        "With headings": $localize`:Редактор|Инструменты таблица - С заголовками@@editor-tools.table.With-headings:С заголовками`,
        "Without headings": $localize`:Редактор|Инструменты таблица - Без заголовков@@editor-tools.table.Without-headings:Без заголовков`,
        "Add column to left": $localize`:Редактор|Инструменты таблица - Добавить столбец слева@@editor-tools.table.Add-column-to-left:Добавить столбец слева`,
        "Add column to right": $localize`:Редактор|Инструменты таблица - Добавить столбец справа@@editor-tools.table.Add-column-to-right:Добавить столбец справа`,
        "Delete column": $localize`:Редактор|Инструменты таблица - Удалить столбец@@editor-tools.table.Delete-column:Удалить столбец`,
        "Add row above": $localize`:Редактор|Инструменты таблица - Добавить строку сверху@@editor-tools.table.Add-row-above:Добавить строку сверху`,
        "Add row below": $localize`:Редактор|Инструменты таблица - Добавить строку снизу@@editor-tools.table.Add-row-below:Добавить строку снизу`,
        "Delete row": $localize`:Редактор|Инструменты таблица - Удалить строку@@editor-tools.table.Delete-row:Удалить строку`,
        "Heading": $localize`:Редактор|Инструменты таблица - Заголовок@@editor-tools.table.Heading:Заголовок`
      },
      "attaches": {
        "buttonText": $localize`:Редактор|Инструменты файл - Выберите файл для загрузки@@editor-tools.attaches.buttonText:Выберите файл для загрузки`,
        "errorMessage": $localize`:Редактор|Инструменты файл - Загрузка файла не удалась@@editor-tools.attaches.errorMessage:Загрузка файла не удалась`,
        "errorFileSizeValidation": $localize`:Редактор|Инструменты файл - Максимально допустимый размер файла до 1 ГБ@@editor-tools.attaches.errorFileSizeValidation:Максимально допустимый размер файла до 1 ГБ`
      },
      "embed": {
        "Enter a caption": $localize`:Редактор|Инструменты медиа - Введите подпись@@editor-tools.embed.Enter-a-caption:Введите подпись`
      },
      "stub": {
        "The block can not be displayed correctly.": $localize`:Редактор|Инструменты заглушка - Блок не может быть отображен@@editor-tools.stub.The-block-can-not-be-displayed-correctly:Блок не может быть отображен`
      },
      "code": {
        "languagePlaceholder": $localize`:Редактор|Инструменты код - Выбор языка программирования@@editor-tools.code.languagePlaceholder:Язык`,
        "languageSearchPlaceholder": $localize`:Редактор|Инструменты код - Поиск языка программирования@@editor-tools.code.languageSearchPlaceholder:Найти`,
      },
    },
    blockTunes: {
      "delete": {
        "Delete": $localize`:Редактор|Настройки блока - Удалить@@editor-blockTunes.delete.Delete:Удалить`
      },
      "moveUp": {
        "Move up": $localize`:Редактор|Настройки блока - Переместить вверх@@editor-blockTunes.moveUp.Move-up:Переместить вверх`
      },
      "moveDown": {
        "Move down": $localize`:Редактор|Настройки блока - Переместить вниз@@editor-blockTunes.moveDown.Move-down:Переместить вниз`
      }
    },
  },
}
