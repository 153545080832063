<!-- @format -->

<figure
	class="flex items-center justify-center rounded-full overflow-hidden"
	[style.min-width]="size"
	[style.min-height]="size"
	[style.width]="size"
	[style.height]="size"
>
	<ng-container [ngSwitch]="true">
		<img
			class="block w-full h-full"
			*ngSwitchCase="!!user && !!user.avatar"
			[src]="user.avatar"
			[alt]="user.name + ' ' + user.surname"
		/>
		<figure
			class="flex items-center justify-center w-full h-full"
			[ngClass]="[background, textClass]"
			*ngSwitchCase="!!user && !user.avatar"
		>
			{{ user.name.slice(0, 1) + user.surname.slice(0, 1) }}
		</figure>
		<figure
			class="flex flex-shrink-0 w-8 h-8 bg-blue-grey-50 rounded-2xl border border-dashed border-blue-grey-200"
			*ngSwitchDefault
		></figure>
	</ng-container>
</figure>
