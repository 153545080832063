import { make } from './dabster-image-ui';

/**
 * Working with Block Tunes
 */
export default class Tunes {
  /**
   * @param {object} tune - image tool Tunes managers
   * @param {object} tune.api - Editor API
   * @param {object} tune.actions - list of user defined tunes
   * @param {Function} tune.onChange - tune toggling callback
   */
  constructor({ api, actions, onChange }) {
    this.api = api;
    this.actions = actions;
    this.onChange = onChange;
    this.buttons = [];

    this.alignButtonName = ['alignLeft', 'alignCenter', 'alignRight'];
  }

  /**
   * Available Image tunes
   *
   * @returns {{name: string, icon: string, title: string}[]}
   */
  static get tunes() {
    return [
      {
        name: 'alignLeft',
        icon: '<svg class="block" width="20" height="20" viewBox="0 0 20 20"><use xlink:href="assets/icons/sprite.svg#editor-image-align-left"></use></svg>',
        title: 'Align left',
      },
      {
        name: 'alignCenter',
        icon: '<svg class="block" width="20" height="20" viewBox="0 0 20 20"><use xlink:href="assets/icons/sprite.svg#editor-image-align-center"></use></svg>',
        title: 'Align center',
      },
      {
        name: 'alignRight',
        icon: '<svg class="block" width="20" height="20" viewBox="0 0 20 20"><use xlink:href="assets/icons/sprite.svg#editor-image-align-right"></use></svg>',
        title: 'Align right',
      },
      {
        name: 'withBorder',
        icon: '<svg class="block" width="20" height="20" viewBox="0 0 20 20"><use xlink:href="assets/icons/sprite.svg#editor-image-border"></use></svg>',
        title: 'With border',
      },
      {
        name: 'stretched',
        icon: '<svg class="block" width="17" height="10" viewBox="0 0 17 10"><use xlink:href="assets/icons/sprite.svg#editor-image-stretched"></use></svg>',
        title: 'Stretch image',
      },
      {
        name: 'withBackground',
        icon: '<svg class="block" width="20" height="20" viewBox="0 0 20 20"><use xlink:href="assets/icons/sprite.svg#editor-image-background"></use></svg>',
        title: 'With background',
      },
    ];
  }

  /**
   * Styles
   *
   * @returns {{wrapper: string, buttonBase: *, button: string, buttonActive: *}}
   */
  get CSS() {
    return {
      wrapper: '',
      buttonBase: this.api.styles.settingsButton,
      button: 'image-tool__tune',
      buttonActive: this.api.styles.settingsButtonActive,
    };
  }

  /**
   * Makes buttons with tunes: add background, add border, stretch image
   *
   * @param {ImageToolData} toolData - generate Elements of tunes
   * @returns {Element}
   */
  render(toolData) {
    const wrapper = make('div', this.CSS.wrapper);

    this.buttons = [];

    const tunes = Tunes.tunes.concat(this.actions);

    // Если нет активного выравнивания
    const someActive = this.alignButtonName.some((tuneName) => toolData[tuneName]);
    if(!someActive) {
      this.onChange('alignLeft');
    }

    tunes.forEach(tune => {
      const title = this.api.i18n.t(tune.title);
      const el = make('div', [this.CSS.buttonBase, this.CSS.button], {
        innerHTML: tune.icon,
      });

      el.addEventListener('click', () => {
        this.tuneClicked(tune.name, tune.action);
      });

      el.dataset.tune = tune.name;
      el.classList.toggle(this.CSS.buttonActive, toolData[tune.name]);

      this.buttons.push(el);

      this.api.tooltip.onHover(el, title, {
        placement: 'top',
      });

      wrapper.appendChild(el);
    });

    return wrapper;
  }

  /**
   * Clicks to one of the tunes
   *
   * @param {string} tuneName - clicked tune name
   * @param {Function} customFunction - function to execute on click
   */
  tuneClicked(tuneName, customFunction) {
    if (typeof customFunction === 'function') {
      if (!customFunction(tuneName)) {
        return false;
      }
    }

    const toggleBtn = (tuneName) => {
      const button = this.buttons.find(el => el.dataset.tune === tuneName);

      button.classList.toggle(this.CSS.buttonActive, !button.classList.contains(this.CSS.buttonActive));

      this.onChange(tuneName);
    }

    if(this.alignButtonName.includes(tuneName)) {
      const activeButton = this.buttons.find(el => el.dataset.tune === tuneName);

      // клик по активной кнопке выравнивания
      if(activeButton.classList.contains(this.CSS.buttonActive)) {
        return false;
      } else {
        this.alignButtonName.forEach((alignName) => {
          if(alignName !== tuneName) {
            const button = this.buttons.find(el => el.dataset.tune === alignName);

            if(button.classList.contains(this.CSS.buttonActive)) {
              button.classList.remove(this.CSS.buttonActive);
              this.onChange(alignName);
            }
          } else {
            toggleBtn(tuneName);
          }
        })
      }
    } else {
      toggleBtn(tuneName);
    }
  }
}
