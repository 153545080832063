/** @format */

import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-overlay-image',
  templateUrl: './overlay-image.component.html'
})
export class OverlayImageComponent {
  @HostListener('document:click', ['$event']) documentClick(event: any) {
    if (event.target.dataset.hasOwnProperty('overlayImage')) {
      this.showViewer(event.target.dataset.overlayImage);
    }
  }

  imageSrc!: string;
  imageToggle: boolean = false;

  constructor() {}

  showViewer(src: string): void {
    this.imageSrc = src;
    this.imageToggle = true;
  }
}
