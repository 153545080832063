/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { User, UserService } from '../../../core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  user$!: Subscription;
  user!: User;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => (this.user = user),
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.user$].forEach($ => $?.unsubscribe());
  }
}
