<!-- @format -->

<ng-container>
	<div appDropdown *ngIf="{value: false} as toggle" (toggled)="toggle.value = $event">
		<div
			class="inline-flex items-center justify-start h-6 border border-blue-grey-50 space-x-1 rounded-xl px-2.5 cursor-pointer"
			slot="target"
		>
			<ng-container
				*ngIf="{value: ['teamlead', 'owner'].includes(roleActive)} as isTeamLead"
			>
				<span
					class="text-caption user-select-none w-full"
					data-pw="button-open-role"
					[ngClass]="isTeamLead.value ? 'text-primary-A400' : 'text-blue-grey-500'"
					*ngIf="{value: roleActive} as roleKey"
					i18n="Дропдаун ролей|Название роли@@app-dropdown-role.role"
				>
					<!-- prettier-ignore -->
					{ roleKey.value, select, owner {Владелец} teamlead {Тимлид} teammate {Тиммейт} }
				</span>
				<figure
					class="relative w-4 h-4"
					[ngClass]="isTeamLead.value ? 'text-primary-A400' : 'text-blue-grey-500'"
					[@rotateAnimation]="toggle.value"
				>
					<span
						class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 -rotate-90"
						appSvgIcon
						[appIcon]="'arrow-back-2'"
						appSquare="10"
						viewBox="0 0 7 10"
					></span>
				</figure>
			</ng-container>
		</div>
		<div
			class="flex flex-col bg-white border border-blue-grey-50 rounded -translate-y-6"
			slot="content"
		>
			<ul data-pw="role-options" class="flex flex-col min-w-[128px]">
				<li
					class="flex items-center h-6 space-x-1 text-caption hover:bg-blue-grey-25 cursor-pointer px-2.5"
				>
					<span
						class="user-select-none w-full"
						*ngIf="{value: roleActive} as roleKey"
						i18n="Дропдаун ролей|Название роли@@app-dropdown-role.role"
					>
						<!-- prettier-ignore -->
						{ roleKey.value, select, owner {Владелец} teamlead {Тимлид} teammate {Тиммейт} }
					</span>
					<figure class="relative w-4 h-4 text-secondary-500">
						<i
							class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
							appSvgIcon
							[appIcon]="'done'"
							appSquare="16"
							appViewBox="0 0 24 24"
						></i>
					</figure>
				</li>
				<ng-container *ngFor="let role of roleMap | keyvalue">
					<li
						class="flex space-x-1 text-caption hover:bg-blue-grey-25 cursor-pointer px-2.5 py-1"
						[attr.data-pw]="'button-select-' + role.key"
						*ngIf="roleActive !== role.key"
						(click)="onSelect(role.key)"
					>
						<span
							class="user-select-none w-full"
							*ngIf="{value: role.key} as roleKey"
							i18n="Дропдаун ролей|Название роли@@app-dropdown-role.role"
						>
							<!-- prettier-ignore -->
							{ roleKey.value, select, owner {Владелец} teamlead {Тимлид} teammate {Тиммейт} }
						</span>
					</li>
				</ng-container>
			</ul>
		</div>
	</div>
</ng-container>
