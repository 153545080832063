<!-- @format -->

<div class="flex flex-col w-full">
	<!-- prettier-ignore -->
	<h4
		class="text-headline-4 mb-2"
		i18n="Восстановление пароля|Заголовок: Письмо в пути/Изменить пароль@@app-auth-forgot.title"
	>
		{ !!forgotMessage, select, true {Письмо в пути} false {Изменить пароль} }
	</h4>
	<div class="flex space-x-2 text-body-1 mb-8">
		<span
			class="inline-block"
			i18n="Восстановление пароля|Текст: Вспомнили пароль?@@app-auth-forgot.remember"
		>
			Вспомнили пароль?
		</span>
		<a
			class="block underline"
			[routerLink]="['/auth/login']"
			i18n="Восстановление пароля|Ссылка: Войдите в аккаунт@@app-auth-forgot.login"
		>
			Войдите в аккаунт
		</a>
	</div>
	<ng-container [ngSwitch]="forgotMessage">
		<ng-container *ngSwitchCase="null">
			<!-- prettier-ignore -->
			<span
				class="block text-body-1 mb-6"
				i18n="Восстановление пароля|Параграф: Пожалуйста, введите почту@@app-auth-forgot.paragraph"
			>
				Пожалуйста, введите почту, указанную при регистрации в Dabster
			</span>
			<form [formGroup]="forgotForm" (ngSubmit)="onSubmitForm()" autocomplete="off">
				<fieldset [disabled]="forgotFormIsSubmitting">
					<fieldset class="form-control relative mb-6">
						<!-- prettier-ignore -->
						<input
							id="email"
							type="email"
							class="peer"
							placeholder="E-mail"
							formControlName="email"
							i18n-placeholder="Восстановление пароля|Поле ввода: E-mail@@app-auth-forgot.email"
							#inputEmail
						/>
						<label
							for="email"
							class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
						>
							<app-error-label
								[appInputElement]="inputEmail"
								[appFormGroup]="forgotForm"
								[appFormControlName]="'email'"
							></app-error-label>
						</label>
					</fieldset>
					<button
						class="btn btn-primary inline md mb-8"
						data-pw="button-submit"
						[disabled]="forgotFormIsSubmitting"
						i18n="Восстановление пароля|Кнопка: Отправить@@app-auth-forgot.submit"
					>
						Отправить
					</button>
				</fieldset>
			</form>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<span class="block text-body-1 mb-6">
				{{ forgotMessage }}
			</span>
		</ng-container>
	</ng-container>
</div>
