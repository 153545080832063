<!-- @format -->

<div class="flex flex-col items-start">
	<!-- prettier-ignore -->
	<h4
		class="text-headline-4 mb-2"
		i18n="Регистрация|Заголовок: Ваше приглашение недействительно/Присоединяйтесь к Dabster@@app-auth-registration.title"
	>
		{ invitationError, select, true {Ваше приглашение недействительно} false	{Присоединяйтесь к Dabster} }
	</h4>
	<ng-container [ngSwitch]="invitationError">
		<ng-container *ngSwitchCase="true">
			<!-- prettier-ignore -->
			<p
				class="text-body-1 mt-6"
				i18n="Регистрация|Параграф: Где-то произошла ошибка. Пожалуйста обратитесь за новым приглашением.@@app-auth-registration.error-paragraph"
			>
        Где-то произошла ошибка. Пожалуйста обратитесь за новым приглашением.
			</p>
		</ng-container>
		<ng-container *ngSwitchCase="false">
			<div class="flex space-x-2 text-body-1 mb-8">
				<!-- prettier-ignore -->
				<span
          class="inline-block mr-1"
          i18n="Регистрация|Текст: Уже зарегистрированы?@@app-auth-registration.already"
        >
          Уже зарегистрированы?
        </span>
				<a
					class="block underline"
					[routerLink]="['/auth/login']"
					[queryParamsHandling]="'merge'"
					i18n="Регистрация|Ссылка: Войдите в аккаунт@@app-auth-registration.to-login"
				>
					Войдите в аккаунт
				</a>
			</div>
			<form [formGroup]="registrationForm" (ngSubmit)="onSubmitForm()" autocomplete="off">
				<fieldset [disabled]="registrationFormIsSubmitting">
					<div class="flex space-x-4 w-full">
						<fieldset class="form-control relative w-full mb-4">
							<!-- prettier-ignore -->
							<input
								id="name"
								type="text"
								class="peer"
								placeholder="Имя"
								i18n-placeholder="Регистрация|Поле ввода: Имя@@app-auth-registration.input-name"
								formControlName="name"
								#inputName
							/>
							<label
								for="name"
								class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
							>
								<app-error-label
									[appInputElement]="inputName"
									[appFormGroup]="registrationForm"
									[appFormControlName]="'name'"
								></app-error-label>
							</label>
						</fieldset>
						<fieldset class="form-control relative w-full mb-4">
							<!-- prettier-ignore -->
							<input
								id="surname"
								type="text"
								class="peer"
								placeholder="Фамилия"
								i18n-placeholder="Регистрация|Поле ввода: Фамилия@@app-auth-registration.input-surname"
								formControlName="surname"
								#inputSurname
							/>
							<label
								for="surname"
								class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
							>
								<app-error-label
									[appInputElement]="inputSurname"
									[appFormGroup]="registrationForm"
									[appFormControlName]="'surname'"
								></app-error-label>
							</label>
						</fieldset>
					</div>
					<fieldset class="form-control relative mb-4">
						<!-- prettier-ignore -->
						<input
							id="email"
							type="email"
							class="peer"
							placeholder="E-mail"
							i18n-placeholder="Регистрация|Поле ввода: E-mail@@app-auth-registration.input-email"
							formControlName="email"
							#inputEmail
						/>
						<label
							for="email"
							class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
						>
							<app-error-label
								[appInputElement]="inputEmail"
								[appFormGroup]="registrationForm"
								[appFormControlName]="'email'"
							></app-error-label>
						</label>
					</fieldset>
					<fieldset
						class="form-control relative mb-4"
						*ngIf="{value: false} as isVisible"
					>
						<figure
							class="absolute password i-right"
							(click)="isVisible.value = !isVisible.value"
						>
							<i
								class="absolute text-blue-grey-400"
								appSvgIcon
								[appIcon]="isVisible.value ? 'eye' : 'eye-strikethrough'"
								appSquare="24"
							></i>
						</figure>
						<!-- prettier-ignore -->
						<input
							id="password"
							[type]="isVisible.value ? 'text' : 'password'"
							class="peer"
							placeholder="Придумайте пароль"
							i18n-placeholder="Регистрация|Поле ввода: Придумайте пароль@@app-auth-registration.input-password"
							formControlName="password"
							#inputPassword
						/>
						<label
							for="password"
							class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
						>
							<app-error-label
								[appInputElement]="inputPassword"
								[appFormGroup]="registrationForm"
								[appFormControlName]="'password'"
								[appLinkedControlNames]="['password_confirmation']"
							></app-error-label>
						</label>
					</fieldset>
					<fieldset
						class="form-control relative mb-4"
						*ngIf="{value: false} as isVisible"
					>
						<figure
							class="absolute password i-right"
							(click)="isVisible.value = !isVisible.value"
						>
							<i
								class="absolute text-blue-grey-400"
								appSvgIcon
								[appIcon]="isVisible.value ? 'eye' : 'eye-strikethrough'"
								appSquare="24"
							></i>
						</figure>
						<!-- prettier-ignore -->
						<input
							id="password_confirmation"
							[type]="isVisible.value ? 'text' : 'password'"
							class="peer"
							placeholder="Повторите пароль"
							i18n-placeholder="Регистрация|Поле ввода: Повторите пароль@@app-auth-registration.input-password-confirmation"
							formControlName="password_confirmation"
							#inputPasswordConfirmation
						/>
						<label
							for="password_confirmation"
							class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
						>
							<app-error-label
								[appInputElement]="inputPasswordConfirmation"
								[appFormGroup]="registrationForm"
								[appFormControlName]="'password_confirmation'"
							></app-error-label>
						</label>
					</fieldset>
					<!-- prettier-ignore -->
					<span
						class="block text-caption text-blue-grey-400 mb-2"
						i18n="Регистрация|Параграф: Оставляя свои данные, вы соглашаетесь@@app-auth-registration.terms"
					>
						Оставляя свои данные, вы соглашаетесь с нашей политикой обработки персональных данных.
					</span>
					<!-- prettier-ignore -->
					<span
						class="block text-caption text-blue-grey-400 mb-6"
						i18n="Регистрация|Параграф: Dabster использует куки-файлы для@@app-auth-registration.terms-cookie"
					>
						Dabster использует куки-файлы для возможности своей работы
					</span>
					<button
						class="btn btn-primary inline md"
						data-pw="button-submit"
						[disabled]="registrationFormIsSubmitting"
						i18n="Регистрация|Кнопка: Зарегистрироваться@@app-auth-registration.submit"
					>
						Зарегистрироваться
					</button>
				</fieldset>
			</form>
		</ng-container>
	</ng-container>
</div>
