/** @format */

import { Component, OnInit } from '@angular/core';
import { Skill, SkillService } from '../../../../core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-skill-tabs',
  templateUrl: './tabs.component.html'
})
export class SkillTabsComponent implements OnInit {
  skill$!: Observable<Skill>;

  constructor(private skillService: SkillService) {}

  ngOnInit(): void {
    this.skill$ = this.skillService.skill$;
  }
}
