/** @format */

import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  RendererFactory2,
  ViewChild
} from '@angular/core';
import { PlatformService } from '../../../core';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { dropdownAnimation } from '../../../app-animations';
import { Event as RouterEvent, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-dropdown, [appDropdown]',
  templateUrl: './dropdown.component.html',
  animations: [dropdownAnimation]
})
export class DropdownComponent implements OnInit, OnDestroy {
  @ViewChild('target') target!: ElementRef;
  @ViewChild('content') content!: ElementRef;

  @Output() toggled = new EventEmitter<boolean>();

  @Input()
  set appWidthFit(widthFit: boolean) {
    this.widthFit = widthFit;
  }

  @Input()
  set appAlignment(alignment: string) {
    this.alignment = alignment;
  }

  routeEvents$!: Subscription;
  click$!: Subscription;
  scroll$!: Subscription;

  state$ = new BehaviorSubject<boolean>(false);

  widthFit!: boolean;

  alignment: string = 'left'; // left | right | center

  renderer2: Renderer2;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private platformService: PlatformService,
    private rendererFactory2: RendererFactory2,
    private router: Router
  ) {
    this.renderer2 = rendererFactory2.createRenderer(null, null);
  }

  ngOnInit(): void {
    if (this.platformService.isBrowser()) {
      const window = this.platformService.getWindow();

      this.click$ = fromEvent(window, 'click').subscribe({
        next: (event: any) => {
          if (this.state$.getValue()) {
            this.state$.next(false);
          } else if (this.target.nativeElement.contains(event.target)) {
            this.state$.next(true);
          }
        }
      });

      this.routeEvents$ = this.router.events
        .pipe(filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationStart))
        .subscribe({
          next: () => this.state$.next(false)
        });

      /** https://material.angular.io/components/select/overview */

      this.scroll$ = fromEvent(this.document.querySelector('.app-content') as HTMLElement, 'scroll')
        .pipe(filter(() => this.state$.getValue()))
        .subscribe({
          next: () => this.state$.next(false)
        });

      this.state$
        .pipe(
          tap((state: boolean) => this.toggled.emit(state)),
          debounceTime(10)
        )
        .subscribe({
          next: (state: boolean) => (state ? this.onShow() : this.onHide())
        });
    }
  }

  ngOnDestroy(): void {
    this.onHide();

    [this.click$, this.scroll$, this.state$, this.routeEvents$].forEach($ => $?.unsubscribe());
  }

  onShow(): void {
    const { top, left, height, width } = this.target.nativeElement.getBoundingClientRect();

    this.content.nativeElement.style.top = top + height + 'px';

    if (this.alignment === 'left') {
      this.content.nativeElement.style.left = left + 'px';
    }

    // prettier-ignore
    if (this.alignment === 'center') {
      this.content.nativeElement.style.left = left - (this.content.nativeElement.offsetWidth - width) / 2 + 'px';
    }

    // prettier-ignore
    if (this.alignment === 'right') {
      this.content.nativeElement.style.right = this.document.body.offsetWidth - (left + width) + 'px';
    }

    if (this.widthFit) {
      this.content.nativeElement.style.width = width + 'px';
    }

    this.renderer2.appendChild(this.document.body, this.content.nativeElement);
  }

  onHide(): void {
    this.content && this.renderer2.removeChild(this.document.body, this.content.nativeElement);
  }
}
