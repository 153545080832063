/** @format */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ProgressDashboard,
  ProgressService,
  SnackbarService,
  User,
  UserService
} from '../../../../core';
import { switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-overlay-estimate-complete',
  templateUrl: './overlay-estimate-complete.component.html'
})
export class OverlayEstimateCompleteComponent implements OnInit {
  @Output() clicked = new EventEmitter<boolean>();

  @Input()
  set appToggle(toggle: boolean) {
    this.toggle = toggle;
  }

  @Input()
  set appProgressDashboardId(id: number) {
    this.progressService.getProgressDashboard(id).subscribe({
      next: (progressDashboard: ProgressDashboard) => {
        this.progressDashboard = progressDashboard;

        /** https://kostylworks.atlassian.net/browse/DAB-882 */

        if (this.progressDashboard.deadline === null) {
          this.progressDashboard.deadline = String(new Date());
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  user!: User;

  progressDashboard!: ProgressDashboard;

  toggle: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private progressService: ProgressService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.userService.currentUser.pipe(first()).subscribe({
      next: (user: User) => (this.user = user),
      error: (error: any) => console.error(error)
    });
  }

  onClose(toggle: boolean): void {
    this.toggle = toggle;
    this.clicked.emit(this.toggle);
  }

  onCompleteEstimate(): void {
    this.onClose(false);

    // prettier-ignore
    const userId: string | number = this.user.id === this.progressDashboard.user.id ? 'my' : this.progressDashboard.user.team_member_id;

    // prettier-ignore
    this.progressService
      .setProgressComplete(this.progressDashboard.user.team_member_id)
      .pipe(switchMap(() => {
        return this.router.navigate(['/progress', userId, 'results'], {
          queryParams: {
            deadline: 1
          }
        })
      }))
      .subscribe({
        next: () => {
          this.progressService.onRemoveProgress();
          this.progressService.onRemoveProgressList();

          this.snackbarService.info(
            $localize`:Снекбар|Сообщение - Дедлайн завершен@@app-snackbar.deadline-completed:Дедлайн завершен`,
            {
              icon: 'done'
            }
          );
        },
        error: (error: any) => console.error(error)
      });
  }
}
