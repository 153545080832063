<!-- @format -->

<section>
	<ng-container [ngSwitch]="skillIsEdit">
		<ng-container *ngSwitchCase="true">
			<div
				class="flex flex-col md:flex-row items-start justify-start space-y-4 md:space-y-0 md:space-x-4"
			>
				<ng-container *ngTemplateOutlet="skillImage"></ng-container>
				<div class="flex flex-col items-start justify-start space-y-4 w-full">
					<form class="w-full" [formGroup]="skillForm" autocomplete="off">
						<fieldset [disabled]="skillFormIsSubmitting">
							<fieldset class="form-control-unset">
								<!-- prettier-ignore -->
								<textarea
                  appTextarea
									class="text-body-1 h-auto overflow-y-hidden w-full p-0 m-0"
                  data-pw="skill-description"
									placeholder="Введите описание навыка"
									i18n-placeholder="Просмотр навыка|Поле ввода: Введите описание навыка@@app-skill-description.input-description"
									name="description"
									id="description"
									[rows]="8"
									formControlName="description"
								></textarea>
								<label for="description" class="hidden"></label>
							</fieldset>
						</fieldset>
					</form>
				</div>
			</div>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<div
				class="flex flex-col md:flex-row items-start justify-start space-y-4 md:space-y-0 md:space-x-4"
			>
				<ng-container *ngTemplateOutlet="skillImage"></ng-container>
				<div
					class="flex flex-col items-start justify-start space-y-4 w-full overflow-x-hidden"
				>
					<p
						class="text-body-1 break-words w-full"
						data-pw="skill-description"
						[class.line-clamp-8]="!isShowMore"
						#skillDescription
					>
						{{ skill.description }}
					</p>
					<button
						class="flex items-center justify-start space-x-2 text-button-2 text-blue-grey-400 py-2"
						(click)="isShowMore = !isShowMore"
						*ngIf="isShownShowMore"
					>
						<!-- prettier-ignore -->
						<span class="block" i18n="Просмотр навыка|Текст: Показать меньше/Показать больше@@app-skill-description.show-more"
            >
              { !!isShowMore, select, true {Показать меньше} false {Показать больше} }
            </span>
						<i
							[class.-scale-y-1]="isShowMore"
							appSvgIcon
							appIcon="expand"
							appSquare="20"
						></i>
					</button>
				</div>
			</div>
		</ng-container>
	</ng-container>
	<ng-template #skillImage>
		<div
			class="flex items-center justify-center relative group overflow-hidden rounded-md md:max-w-[248px] md:max-h-[140px] w-full h-[50vw] max-h-[403px] flex-shrink-0"
		>
			<div
				class="absolute w-full h-full cursor-pointer"
				(click)="onShowImageList()"
				*ngIf="skillIsEdit"
			>
				<div class="absolute w-full h-full group-hover:bg-black opacity-10"></div>
				<button
					class="btn btn-on-primary inline sm pointer-events-none absolute top-3 left-3"
				>
					<figure class="relative">
						<i class="absolute" appSvgIcon appIcon="pencil-2" appSquare="20"></i>
					</figure>
				</button>
			</div>
			<picture class="block w-full">
				<source [srcset]="skill.image.url['1280']" media="(max-width: 768px)" />
				<img
					class="block object-cover w-full"
					[srcset]="skill.image.url['640']"
					[alt]="skill.title"
				/>
			</picture>
		</div>
	</ng-template>
	<app-overlay
		[appState]="imageListToggle"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && onResetImage()"
	>
		<div
			class="relative overflow-hidden max-w-[688px] w-full bg-white rounded-lg p-10 pb-8"
		>
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="onResetImage()"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<!-- prettier-ignore -->
			<span
				class="block text-headline-4 mb-8"
				i18n="Просмотр навыка|Заголовок: Выберите обложку для навыка@@app-skill-description.select-image-title"
			>
				Выберите обложку для навыка
			</span>
			<div class="overflow-y-auto overflow-x-hidden mb-8">
				<ul class="grid grid-cols-3 gap-2 rounded-md max-h-[440px] pr-1">
					<li
						class="flex relative group overflow-hidden rounded-md md:w-[193px] md:h-[140px] w-full h-[110px]"
						(click)="imageSelected = image"
						*ngFor="let image of imageList"
					>
						<div class="absolute w-full h-full cursor-pointer">
							<button
								class="btn btn-on-primary inline sm pointer-events-none absolute top-3 right-3 z-10"
								*ngIf="imageSelected?.id === image.id"
							>
								<figure class="relative">
									<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
								</figure>
							</button>
							<div
								class="absolute w-full h-full group-hover:bg-black opacity-10"
								[class.bg-black]="imageSelected?.id === image.id"
							></div>
						</div>
						<img
							class="block object-cover w-full h-full"
							[src]="image.url"
							alt="Select image"
						/>
					</li>
				</ul>
			</div>
			<!-- prettier-ignore -->
			<button
				class="btn btn-primary inline md"
				[disabled]="!imageSelected"
				(click)="onSubmitImage()"
        i18n="Просмотр навыка|Кнопка: Выбрать обложку@@app-skill-description.select-image-button"
			>
				Выбрать обложку
			</button>
		</div>
	</app-overlay>
</section>
