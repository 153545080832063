/** @format */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  PlatformService,
  Progress,
  ProgressService,
  Skill,
  SkillService,
  SkillTask,
  SnackbarService,
  TrajectoryService,
  User,
  UserService
} from '../../../../../../core';
import { forkJoin, map, Subscription, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, tap } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import EditorJSParser from 'editorjs-parser';
import { OutputBlockData } from '@editorjs/editorjs/types/data-formats';
import Prism from 'prismjs';
import { EXTENSIONS, META } from '../editor/plugins/attaches/dabster-attaches';

interface ProgressForm {
  result: FormControl<string | null>;
}

@Component({
  selector: 'app-skill-tasks-description-html',
  templateUrl: './description.component.html'
})
export class SkillTaskDescriptionHTMLComponent implements OnInit {
  @ViewChild('skillTaskHTMLClone') skillTaskHTMLClone!: ElementRef;
  @ViewChild('skillTaskResultClone') skillTaskResultClone!: ElementRef;

  activatedRouteParams$!: Subscription;
  activatedRouteQueryParams$!: Subscription;

  user$!: Subscription;
  user!: User;

  skill!: Skill;
  skill$!: Subscription;

  skillTask!: SkillTask;
  skillTaskParser!: any;
  skillTaskHTML!: string;

  skillTaskTemporaryResults: any = {};

  skillTaskAccordions: any = {};

  descIsShown!: boolean;
  descIsShowMore!: boolean;
  descMoreHeight: number = 0;
  descLessHeight: number = 172;
  descDuration: number = 300;
  descHeight: string = '0px';

  resultIsShown!: boolean;
  resultIsShowMore!: boolean;
  resultMoreHeight: number = 0;
  resultLessHeight: number = 172;
  resultDuration: number = 300;
  resultHeight: string = '0px';

  areaTrigger: any = { delay: 0 };

  progress!: Progress;
  progress$!: Subscription;
  progressIsEdit!: boolean;
  progressIsEstimate!: boolean;
  progressIsResult!: boolean;

  progressForm: FormGroup;
  progressForm$!: Subscription | undefined;
  progressFormIsSubmitting = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private skillService: SkillService,
    private platformService: PlatformService,
    private formBuilder: FormBuilder,
    private progressService: ProgressService,
    private snackbarService: SnackbarService,
    private trajectoryService: TrajectoryService,
    private userService: UserService
  ) {
    this.progressForm = this.formBuilder.group<ProgressForm>({
      result: this.formBuilder.control('')
    });
  }

  ngOnInit(): void {
    /** Set user object */

    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => (this.user = user),
      error: (error: any) => console.error(error)
    });

    /** Set skill object and skill triggers */

    this.activatedRouteParams$ = this.activatedRoute.params
      .pipe(map((data: any) => data.taskId))
      .subscribe({
        next: (taskId: string) => {
          this.skill$?.unsubscribe();
          this.skill$ = this.skillService.skill$
            .pipe(
              filter((skill: Skill) => !!Object.keys(skill).length),
              tap((skill: Skill) => {
                /** для временного хранения вписанного в результат */

                if (this.skillTask) {
                  // prettier-ignore
                  this.skillTaskTemporaryResults[this.skillTask.id] = this.progressForm.get('result')?.value;
                }

                this.skill = skill;

                // @ts-ignore
                // prettier-ignore
                this.skillTask = this.skill.taskList.find((skillTask: SkillTask) => skillTask.id === taskId);

                /** Set progress to textarea */

                const abstractControl: AbstractControl | null = this.progressForm.get('result');

                if (abstractControl) {
                  if (!!this.skillTask.result) {
                    abstractControl.setValue(this.skillTask.result);
                  } else {
                    /** Устанавливаем временно сохраненное или сброс формы */

                    if (this.skillTaskTemporaryResults[this.skillTask.id]) {
                      abstractControl.setValue(this.skillTaskTemporaryResults[this.skillTask.id]);
                    } else {
                      abstractControl.reset();
                    }
                  }
                }
              })
            )
            .subscribe({
              next: () => this.setSkillTaskHTML(this.skillTask),
              error: (error: any) => console.error(error)
            });
        },
        error: (error: any) => console.error(error)
      });

    this.activatedRouteQueryParams$ = this.activatedRoute.queryParams.subscribe({
      next: () => this.setShowMore(true),
      error: (error: any) => console.error(error)
    });

    /** Set progress object and progress triggers */

    // prettier-ignore
    this.progress$ = this.progressService.progress$
      .pipe(
        tap((progress: Progress) => (this.progress = progress)),
        switchMap(() => {
          return forkJoin([
            this.progressService.progressIsEdit$.pipe(first()),
            this.progressService.progressIsEstimate$.pipe(first()),
            this.progressService.progressIsResult$.pipe(first())
          ]);
        })
      )
      .subscribe({
        next: ([progressIsEdit, progressIsEstimate, progressIsResult]: [boolean, boolean, boolean]) => {
          this.progressIsEdit = progressIsEdit;
          this.progressIsEstimate = progressIsEstimate;
          this.progressIsResult = progressIsResult;
        },
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    // prettier-ignore
    [this.user$, this.activatedRouteParams$, this.activatedRouteQueryParams$, this.skill$, this.progress$].forEach($ => $?.unsubscribe());
  }

  setSkillTaskHTML(skillTask: SkillTask): void {
    /**
     * https://github.com/miadabdi/editorjs-parser
     **/

    const configuration: any = {
      paragraph: {
        pClass: 'editor-js-paragraph'
      },
      // code: {
      //   codeBlockClass: 'editor-js-code-block'
      // },
      embed: {
        useProvidedLength: false
      },
      quote: {
        applyAlignment: true
      }
    };

    const custom: any = {
      paragraph: (payload: any) => {
        const alignment: string = 'text-' + (payload.alignment || 'left');
        // prettier-ignore
        return `<p class="${alignment}">${payload.text}</p>`;
      },
      embed: (payload: any) => {
        const isHasCaption = (): string => {
          if (payload.caption.length) {
            return `<div class="editor-js-embed-caption">${payload.caption}</div>`;
          }

          return '';
        };

        return `
          <div class="editor-js-embed">
            <div class="editor-js-embed-wrapper">
                <iframe
                    frameborder="0"
                    allowfullscreen="true"
                    class="editor-js-embed-content"
                    src="${payload.embed}">
                </iframe>
            </div>
            ${isHasCaption()}
          </div>
        `;
      },
      list: (payload: any) => {
        const ulMap: any = {
          ordered: 'ol',
          unordered: 'ul'
        };

        const ul: string = ulMap[payload.style];
        const li: string = 'li';

        const getList = (items: any[]): string => {
          let list: string = `<${ul} class="editor-js-${ul}">`;

          for (let i = 0; i < items.length; i++) {
            list += `<${li} class="editor-js-${li}">` + items[i].content;

            if (!!items[i].items.length) {
              list += getList(items[i].items);
            }

            list += `</${li}>`;
          }

          list += `</${ul}>`;

          return list;
        };

        return getList(payload.items);
      },
      warning: (payload: any) => {
        const message = (): string => {
          // prettier-ignore
          return payload.message ? `<p class="editor-js-warning-content-message">${payload.message}</p>` : '';
        };

        return `
          <div class="editor-js-warning">
            <div class="editor-js-warning-icon">
              <img src="assets/icons/svg/editorjs-warning-icon.svg" alt="warning">
            </div>
            <div class="editor-js-warning-content">
                <span class="editor-js-warning-content-title">${payload.title}</span>
                ${message()}
            </div>
          </div>
        `;
      },
      linkTool: (payload: any) => {
        // prettier-ignore
        return `
          <a class="editor-js-link" href="${payload.link}" target="_blank" rel="noopener">
            <div class="editor-js-link-left">
              <span class="editor-js-link-left-title">${payload.meta.title || $localize`:Редактор|Текст - Нет данных@@app-skill-tasks-description-html.editor-no-link:Нет данных`}</span>
              <p class="editor-js-link-left-description ${payload.meta.description ? '' : 'hidden'}">${payload.meta.description}</p>
              <span class="editor-js-link-left-link">${payload.link}</span>
            </div>
            <div class="editor-js-link-right ${payload.meta.image.url ? '' : 'hidden'}">
              <img class="editor-js-link-right-image" src="${payload.meta.image.url}" alt="${payload.meta.title}">
            </div>
          </a>
        `;
      },
      quote: (payload: any) => {
        const makeText = (text: string): string => {
          const trimHTML: string = text.replace(/(<([^>]+)>)/gi, '<break>');
          const textList: string[] = trimHTML.split('<break>').filter((text: string) => !!text);

          return textList.join('</br>');
        };

        const alignment: string = `text-${payload.alignment}`;

        return `
          <blockquote class="editor-js-blockquote">
            <p class="${alignment}">${makeText(payload.text.replace(/“|”/g, ''))}</p>
            <cite>${makeText(payload.caption)}</cite>
          </blockquote>
        `;
      },
      attaches: (payload: any) => {
        // @ts-ignore
        const fileColor: string = EXTENSIONS[payload.file.extension];

        const fileIcon: string = `
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40" fill="${fileColor}">
              <path d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"/>
            </svg>
          `;

        const downloadIcon: string = `
            <svg xmlns="http://www.w3.org/2000/svg" width="17pt" height="17pt" viewBox="0 0 17 17" fill="#78909C">
              <path d="M9.457 8.945V2.848A.959.959 0 0 0 8.5 1.89a.959.959 0 0 0-.957.957v6.097L4.488 5.891a.952.952 0 0 0-1.351 0 .952.952 0 0 0 0 1.351l4.687 4.688a.955.955 0 0 0 1.352 0l4.687-4.688a.952.952 0 0 0 0-1.351.952.952 0 0 0-1.351 0zM3.59 14.937h9.82a.953.953 0 0 0 .953-.957.952.952 0 0 0-.953-.953H3.59a.952.952 0 0 0-.953.953c0 .532.425.957.953.957zm0 0" fill-rule="evenodd"/>
            </svg>
          `;

        const { formattedSize, sizePrefix } = META(payload.file.size);

        return `
          <a class="editor-js-attaches" target="_blank" href="${payload.file.url}" download>
            <div class="editor-js-attaches-file-icon" data-extension="${payload.file.extension}" style="color: ${fileColor}">${fileIcon}</div>
            <div class="editor-js-attaches-file-info">
              <div class="editor-js-attaches-file-info-title">${payload.title}</div>
              <div class="editor-js-attaches-file-info-size">${formattedSize} ${sizePrefix}</div>
            </div>
            <div class="editor-js-attaches-file-download">${downloadIcon}</div>
          </a>
        `;
      },
      image: (payload: any) => {
        const classList: string = [
          payload.alignLeft ? 'alignLeft' : '',
          payload.alignCenter ? 'alignCenter' : '',
          payload.alignRight ? 'alignRight' : '',
          payload.stretched ? 'stretched' : '',
          payload.withBorder ? 'withBorder' : '',
          payload.withBackground ? 'withBackground' : ''
        ]
          .join(' ')
          .replace(/ +/g, ' ')
          .trim();

        // prettier-ignore
        const isHasDataOverlayImage: string = payload.withBackground ? '' : `data-overlay-image="${payload.file.url}"`;

        const isHasCaption = (): string => {
          if (payload.caption.length) {
            // prettier-ignore
            // return `<figcaption class="editor-js-figure-caption" style="--caption-width:${payload.widthCaption}px;">${payload.caption}</figcaption>`
            return `<figcaption class="editor-js-figure-caption">${payload.caption}</figcaption>`
          }

          return '';
        };

        // prettier-ignore
        return `
          <figure class="editor-js-figure-image ${classList}">
            <div class="editor-js-wrapper-image">
              <img class="editor-js-image" src="${payload.file.url}" ${isHasDataOverlayImage} alt="Image">
             </div>
            ${isHasCaption()}
          </figure>
        `;
      },
      table: (payload: any) => {
        const getColumns = (items: string, withHeadings: boolean): string => {
          let column: string = '';

          for (let i = 0; i < items.length; i++) {
            if (withHeadings) {
              column += '<th class="editor-js-table-th">' + items[i] + '</th>';
            } else {
              column += '<td class="editor-js-table-td">' + items[i] + '</td>';
            }
          }

          return column;
        };

        const getRows = (items: string): string => {
          const withHeadings = (i: number): boolean => i === 0 && payload.withHeadings;

          let row: string = '';

          for (let i = 0; i < items.length; i++) {
            // prettier-ignore
            row += '<tr class="editor-js-table-tr">' + getColumns(items[i], withHeadings(i)) + '</tr>';
          }

          return row;
        };

        return `
          <table class="editor-js-table">
            <tbody class="editor-js-table-tbody">
              ${getRows(payload.content)}
            </tbody>
          </table>
        `;
      },
      code: (payload: { code: string; language: string }) => {
        const code = Prism.highlight(
          payload.code,
          Prism.languages[payload.language],
          payload.language
        );

        const line = payload.code
          .split('\n')
          .map((_, index) => {
            return `<span class="codeflask-description__lines__line">${index + 1}</span>`;
          })
          .join('');

        return `
        <div class="codeflask-description-wrapper">
          <div class="codeflask-description-editor">
            <div class="codeflask-description codeflask-description--has-line-numbers">
              <pre class="codeflask-description__pre codeflask-description__flatten">
                <code class="codeflask-description__code">${code}</code>
                \n
              </pre>
              <div class="codeflask-description__lines">
                ${line}
              </div>
            </div>
          </div>
          <div class="codeflask-description-lang-display">${payload.language}</div>
        </div>
        `;
      }
    };

    /** Avoid https://kostylworks.atlassian.net/browse/DAB-315 */

    skillTask.description.blocks = skillTask.description.blocks.map((block: OutputBlockData) => {
      if (JSON.stringify(block.data) === '[]') {
        return {
          ...block,
          data: {
            text: ''
          }
        };
      }

      return block;
    });

    this.skillTaskParser = new EditorJSParser(configuration, custom);
    this.skillTaskHTML = this.skillTaskParser.parse(skillTask.description);

    /** Сохраняем состояние more/less для описания и результатов. По дефолту less */
    if (!this.skillTaskAccordions.hasOwnProperty(skillTask.id)) {
      this.skillTaskAccordions[skillTask.id] = {
        descIsShowMore: false,
        resultIsShowMore: false
      };
    }

    this.setShowMore(this.resultIsShown && this.resultIsShowMore);
  }

  /**
   * Расчет высоты блоков и установка состояния more/less
   *
   * В параметрах получаем задержку выполнения, чтоб закончилась анимация и получить правильные размеры
   * */

  setShowMore(areaDelay: boolean): void {
    /** Для пересчета размеров textarea */
    this.areaTrigger = { ...this.areaTrigger, delay: areaDelay ? 500 : 0 };

    /** Для описания */
    let countDesc = 0;
    const intervalDesc = setInterval(() => {
      if (this.skillTaskHTMLClone) {
        this.descMoreHeight = this.skillTaskHTMLClone.nativeElement.offsetHeight;

        /**  Показывать more/less ? */
        this.descIsShown = this.descMoreHeight > this.descLessHeight;

        /** Устанавливаем more/less из сохраненного состояния */
        this.descIsShowMore = this.skillTaskAccordions[this.skillTask.id].descIsShowMore;

        // prettier-ignore
        this.descHeight = this.descIsShown ? this.descIsShowMore ? this.descMoreHeight + 'px' : this.descLessHeight + 'px' : this.descMoreHeight + 'px';
      }

      countDesc++;
      if (countDesc > 15) clearInterval(intervalDesc);
    }, 200);

    /** Для результатов */
    let countResult = 0;
    const intervalResult = setInterval(() => {
      if (this.skillTaskResultClone) {
        this.resultMoreHeight = this.skillTaskResultClone.nativeElement.offsetHeight;

        /**  Показывать more/less ? */
        this.resultIsShown = this.resultMoreHeight > this.resultLessHeight;

        /** Устанавливаем more/less из сохраненного состояния */
        this.resultIsShowMore = this.skillTaskAccordions[this.skillTask.id].resultIsShowMore;

        // prettier-ignore
        this.resultHeight = this.resultIsShown ? this.resultIsShowMore ? this.resultMoreHeight + 'px' : this.resultLessHeight + 'px' : this.resultMoreHeight + 'px';
      }

      countResult++;
      if (countResult > 15) clearInterval(intervalResult);
    }, 200);
  }

  setHTMLResult(value: string): string {
    let newValue: string = value;

    newValue = encodeURI(newValue);
    newValue = newValue.replace(/%0A/gm, '<br/>');

    /** Find url in text https://regexr.com/37i6s */

    // prettier-ignore
    const regex: RegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

    const urlList: string[] | null = decodeURI(newValue).match(regex);

    if (!!urlList && !!urlList.length) {
      urlList.forEach((url: string) => {
        // prettier-ignore
        newValue = newValue.replace(url, `<a contentEditable="false" href="${url}" target="_blank" rel="noopener">${url}</a>`);
      });
    }

    return decodeURI(newValue);
  }

  onDescriptionToggle(): void {
    this.descIsShowMore = !this.descIsShowMore;

    /** Сохраняем текущее состояние */
    this.skillTaskAccordions[this.skillTask.id].descIsShowMore = this.descIsShowMore;

    /** Устанавливаем высоту описания */
    this.descHeight = this.descIsShowMore ? this.descMoreHeight + 'px' : this.descLessHeight + 'px';
  }

  onResultToggle(): void {
    this.resultIsShowMore = !this.resultIsShowMore;

    /** Сохраняем текущее состояние */
    this.skillTaskAccordions[this.skillTask.id].resultIsShowMore = this.resultIsShowMore;

    /** Устанавливаем высоту результатов */
    this.resultHeight = this.resultIsShowMore
      ? this.resultMoreHeight + 'px'
      : this.resultLessHeight + 'px';
  }

  onSetSkillTaskResult(): void {
    this.progressService
      .setSkillTaskProgress(this.progress.id, {
        task: this.skillTask.id,
        task_result: this.progressForm.get('result')?.value
      })
      .pipe(switchMap((progress: Progress) => this.progressService.onAddProgress(progress)))
      .subscribe({
        next: (progress: Progress) => {
          if (progress.status === 'ready') {
            this.trajectoryService.getNextSkillOfTrajectory(progress.skill_id).subscribe({
              next: (skill: Skill) => {
                this.skillService.skillNextModalPayload = skill;
                this.skillService.skillNextModalToggle.next(true);
              },
              error: () => {
                this.skillService.skillNextModalPayload = undefined;
                this.skillService.skillNextModalToggle.next(false);
              }
            });
          } else {
            this.snackbarService.info(
              $localize`:Снекбар|Сообщение - Задача освоена@@app-snackbar.task-mastered:Задача освоена`,
              {
                icon: 'done'
              }
            );
          }
        },
        error: (error: any) => console.error(error)
      });
  }

  onSetSkillTaskStatus(status: string): void {
    this.progressService
      .setSkillTaskStatus(this.progress.id, {
        task: this.skillTask.id,
        status
      })
      .pipe(switchMap((progress: Progress) => this.progressService.onAddProgress(progress)))
      .subscribe({
        next: () => {
          const message: any = {
            ['in_progress']: $localize`:Снекбар|Сообщение - Задача в прогрессе@@app-snackbar.task-in-progress:Задача в прогрессе`,
            ['done']: $localize`:Снекбар|Сообщение - Задача подтверждена@@app-snackbar.task-done:Задача подтверждена`
          };

          this.snackbarService.info(message[status], { icon: status === 'done' ? 'done' : 'bolt' });
        },
        error: (error: any) => console.error(error)
      });
  }
}
