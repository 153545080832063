/** @format */

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from './snackbar.service';
import { Router } from '@angular/router';
import { PlatformService } from './platform.service';
import { APP_CONFIG } from './configuration.service';
import { Configuration } from '../models';
import { CookieService } from './cookie.service';

@Injectable()
export class ApiService {
  private api: string;

  constructor(
    @Inject(APP_CONFIG)
    private configuration: Configuration,
    private http: HttpClient,
    private router: Router,
    private snackbarService: SnackbarService,
    private platformService: PlatformService,
    private cookieService: CookieService
  ) {
    this.api = this.cookieService.getItem('apiUrl') || configuration.apiUrl || '/api';
  }

  private getSubdomain(): string | null {
    if (this.platformService.isBrowser()) {
      const location: Location = this.platformService.getWindow().location;
      return location.hostname.split('.').length >= 3 ? location.hostname.split('.')[0] : null;
    }

    return null;
  }

  handleErrors(response: any): Observable<any> {
    /** Special case for errors with payload */

    switch (true) {
      /** Tariff error limit when create skill */

      case response.status === 402 && response.url.endsWith('create'): {
        break;
      }

      /** Try assign already assigned trajectory */

      case response.status === 400 && response.url.endsWith('assignToMember'): {
        break;
      }

      /** Try assign already assigned skill */

      case response.status === 400 && response.url.endsWith('addSkill'): {
        break;
      }

      case response.status === 422 && response.url.endsWith('signUp'): {
        if (Object.keys(response.error.errors).includes('email')) {
          this.snackbarService.info(response.error.errors.email[0], {
            icon: 'attention'
          });
        } else {
          const errors = JSON.parse(JSON.stringify(response.error.errors));

          for (const error of Object.keys(errors)) {
            this.platformService.setErrorLabel(error, errors[error].shift());
          }
        }

        break;
      }

      /** Default behaviour */

      default: {
        switch (true) {
          /** With input errors */

          case 'errors' in response.error: {
            const errors = JSON.parse(JSON.stringify(response.error.errors));

            for (const error of Object.keys(errors)) {
              this.platformService.setErrorLabel(error, errors[error].shift());

              if (['deadline'].includes(error)) {
                this.snackbarService.info(errors[error].shift());
              }
            }

            break;
          }

          /** With snackbar */

          case 'message' in response.error: {
            // prettier-ignore
            const message = response.error.message || $localize`:Снекбар|Сообщение - Неизвестная ошибка@@app-snackbar.unknown-error:Неизвестная ошибка`;

            this.snackbarService.info(message, {
              icon: 'attention'
            });

            break;
          }
          default: {
            this.snackbarService.info(response.statusText, {
              icon: 'attention'
            });
          }
        }
      }
    }

    return throwError(() => response.error);
  }

  get(path: string, params: HttpParams = new HttpParams(), options?: any): Observable<any> {
    const subdomain: string | null = this.getSubdomain();
    const headers = { 'Team-Slug': subdomain ?? '' };

    return this.http
      .get(this.api + path, { params, ...options, headers })
      .pipe(catchError(error => this.handleErrors(error)));
  }

  put(path: string, body: any): Observable<any> {
    const subdomain: string | null = this.getSubdomain();
    const headers = { 'Team-Slug': subdomain ?? '' };

    return this.http
      .put(this.api + path, body, { headers })
      .pipe(catchError(error => this.handleErrors(error)));
  }

  post(path: string, body?: any): Observable<any> {
    const subdomain: string | null = this.getSubdomain();
    const headers = { 'Team-Slug': subdomain ?? '' };

    return this.http
      .post(this.api + path, body, { headers })
      .pipe(catchError(error => this.handleErrors(error)));
  }

  delete(path: string): Observable<any> {
    const subdomain: string | null = this.getSubdomain();
    const headers = { 'Team-Slug': subdomain ?? '' };

    return this.http
      .delete(this.api + path, { headers })
      .pipe(catchError(error => this.handleErrors(error)));
  }
}
