<!-- @format -->

<ng-container>
	<!-- prettier-ignore -->
	<div
		class="app-team-add"
		*ngIf="{ value: !!teamLimit ? teamMembersTotal + teamInvitationSentTotal + teamInvitationTotal <= teamLimit - 1 : true } as invitationAvailable"
		[ngSwitch]="true"
	>
		<!-- prettier-ignore -->
		<ng-container *ngSwitchCase="!!invitationAvailable.value && showTariffNotification && !!teamLimit" [ngTemplateOutlet]="invitationLimitNotification"></ng-container>
		<ng-container *ngSwitchCase="!!invitationAvailable.value">
      <form class="block mb-8" [formGroup]="addForm">
        <fieldset class="team-add-email-control" [disabled]="onSubmitAddForm">
          <fieldset class="form-control relative w-full">
            <!-- prettier-ignore -->
            <input
              #inputEmail
              (focus)="showTariffNotification = false"
              id="email"
              type="email"
              class="peer"
              placeholder="Введите E-mail"
              i18n-placeholder="Добавление в команду|Поле ввода: Введите E-mail@@app-team-add.input-email"
              formControlName="email"
              autocomplete="off"
            />
            <label
              for="email"
              class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
            >
              <app-error-label
                [appInputElement]="inputEmail"
                [appFormGroup]="addForm"
                [appFormControlName]="'email'"
              ></app-error-label>
            </label>
            <div
              class="absolute flex items-center right-4 top-1/2 -translate-y-1/2 space-x-4"
            >
              <app-dropdown-role
                *ngIf="addForm.get('role') as role"
                [appRoleActive]="role.value"
                (selected)="role.setValue($event.roleKey)"
              ></app-dropdown-role>
            </div>
          </fieldset>
          <button
            class="relative btn btn-squared"
            data-pw="button-add-email"
            (click)="onAdd()"
          >
            <i
              class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
              appSvgIcon
              appIcon="plus"
              appSquare="24"
            ></i>
          </button>
        </fieldset>
      </form>
		</ng-container>
    <!-- prettier-ignore -->
    <ng-container *ngSwitchCase="!invitationAvailable.value" [ngSwitch]="addFormIsLoading">
      <span *ngSwitchDefault class="block border-2 border-transparent border-b-2 border-b-primary-A400 rounded-full animate-spin w-10 h-10 mx-auto my-2"></span>
      <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="invitationLimit"></ng-container>
    </ng-container>
    <div class="block mb-8" *ngIf="!!teamInvitationList.length">
      <!-- prettier-ignore -->
      <span
        class="block text-subtitle-1 my-6"
        i18n="Добавление в команду|Текст: Назначьте роли для приглашенных@@app-team-add.roles-set"
      >
        Назначьте роли для приглашенных:
      </span>
      <!-- prettier-ignore -->
      <ul
        class="overflow-x-hidden relative max-h-[227px]"
        [ngClass]="teamInvitationList.length > 4 ? 'overflow-y-auto' : 'overflow-y-hidden'"
      >
        <li
          class="row group items-center border-b border-blue-grey-50 hover:bg-blue-grey-25"
          *ngFor="let teamInvitation of teamInvitationList"
        >
          <div class="column-email flex items-center px-4">
            <app-avatar class="block"></app-avatar>
            <span class="block overflow-hidden text-body-2 overflow-ellipsis pl-2">
              {{ teamInvitation.email }}
            </span>
          </div>
          <div class="column-role">
            <app-dropdown-role
              [appRoleActive]="teamInvitation.role_key"
              (selected)="onChangeRole(teamInvitation, $event)"
            ></app-dropdown-role>
          </div>
          <div class="column-close flex justify-end">
            <button
              class="btn btn-light-gray transparent sm"
              (click)="onRemove(teamInvitation)"
              [disabled]="onSubmitTeamInvitation"
            >
              <figure class="relative">
                <i
                  class="absolute"
                  appSvgIcon
                  appIcon="cross"
                  appHeight="20"
                  appWidth="20"
                  appViewBox="0 0 24 24"
                ></i>
              </figure>
            </button>
          </div>
        </li>
      </ul>
    </div>
    <div class="flex justify-between mt-8">
      <!-- prettier-ignore -->
      <button
        *ngIf="showSkip"
        class="btn btn-gray outline md"
        (click)="finished.emit(true)"
        i18n="Добавление в команду|Кнопка: Пропустить шаг@@app-team-add.roles-skip"
      >
        Пропустить шаг
      </button>
      <!-- prettier-ignore -->
      <button
        class="btn btn-primary inline md"
        data-pw="button-send-invitations"
        [disabled]="!teamInvitationList.length || onSubmitAddForm || onSubmitTeamInvitation"
        (click)="onSubmit()"
        i18n="Добавление в команду|Кнопка: Выслать приглашения@@app-team-add.roles-send"
      >
        Выслать приглашения
      </button>
    </div>
	</div>
	<ng-template #invitationLimitNotification>
		<div class="flex items-start justify-start bg-blue-grey-25 rounded-md p-4 mb-8">
			<i
				appSvgIcon
				appIcon="emoji-smile"
				appSquare="24"
				class="text-primary-A400 mr-2"
			></i>
			<div class="flex flex-col items-start justify-start space-y-2">
				<!-- prettier-ignore -->
				<span
          class="block text-body-2"
          i18n="Добавление в команду|Текст: Вы можете пригласить не более N человек. Для больших команд воспользуйтесь платным тарифом.@@app-team-add.limit-text-notification"
        >
          Вы можете пригласить не более {{ !!teamLimit ? teamLimit - 1 : 'N' }} человек. Для больших команд воспользуйтесь платным тарифом.
        </span>
				<!-- prettier-ignore -->
				<button
          class="btn btn-primary transparent sm !px-0"
          (click)="onGoToTariff()"
          i18n="Добавление в команду|Кнопка: Сравнить тарифы@@app-team-add.go-to-tariff"
        >
          Сравнить тарифы
        </button>
			</div>
		</div>
	</ng-template>
	<ng-template #invitationLimit>
		<div class="flex items-center justify-between bg-tertiary-25 rounded-md p-4">
			<div class="flex items-center justify-start space-x-2">
				<i appSvgIcon appIcon="emoji-sad" appSquare="24" class="text-tertiary-A400"></i>
				<!-- prettier-ignore -->
				<span
          class="block text-body-2"
          i18n="Добавление в команду|Текст: Вы использовали все N мест@@app-team-add.limit-text"
        >
          Вы использовали все {{ teamLimit || 'N' }} мест в команде
        </span>
			</div>
			<!-- prettier-ignore -->
			<button
        class="btn btn-tertiary transparent sm !px-0"
        (click)="onGoToTariff()"
        i18n="Добавление в команду|Кнопка: Перейти на платный тариф@@app-team-add.go-to-premium"
      >
        Перейти на платный тариф
      </button>
		</div>
	</ng-template>
</ng-container>
