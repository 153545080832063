<!-- @format -->

<section>
	<app-overlay
		[appState]="imageToggle"
		[appClassList]="['overlay-gray']"
		(clicked)="imageToggle = $event"
	>
		<div
			class="flex flex-col items-end justify-start space-y-2 w-full max-w-[80vw] rounded-lg p-0.5"
			(click)="imageToggle = false"
		>
			<button
				class="btn btn-on-primary transparent lowercase"
				type="button"
				i18n="Просмотр изображения|Кнопка: Закрыть@@app-image-viewer.button-close"
			>
				Закрыть
			</button>
			<img class="block rounded-lg shadow-xl" [src]="imageSrc" alt="Image" />
		</div>
	</app-overlay>
</section>
