<!-- @format -->

<section>
	<div
		class="skill-task-description-wrapper"
		#skillTaskDescriptionFloat
		[style.--float-duration]="floatDuration + 'ms'"
		[style.--float-delay]="floatDelay + 'ms'"
		[style.--float-y]="floatY + 'px'"
		[style.--float-x]="floatX + 'px'"
		[style.--float-height]="floatHeight + 'px'"
		[style.--float-width]="floatWidth + 'px'"
	>
		<!-- prettier-ignore -->
		<ng-container
      *ngIf="{ 'fullscreen-on': isFullscreen, 'fullscreen-off': !isFullscreen, 'animation-playing': floatIsAnimated } as fullscreenFloat">
      <div class="skill-task-description-fade" [ngClass]="fullscreenFloat"></div>
      <div class="skill-task-description-outer max-w-260 mx-auto" [ngClass]="fullscreenFloat">
        <header class="skill-task-description-header" *ngIf="isFullscreen && !floatIsAnimated">
          <div class="block my-4">
            <ul class="flex items-center justify-start h-8">
              <li class="flex text-button-2">
                <a
                  class="flex items-center justify-start space-x-2 text-button-2 breadcrumb"
                  [routerLink]="['.']"
                  [queryParams]="{isFullscreen: +!isFullscreen || null}"
                  [queryParamsHandling]="'merge'"
                >
                  <i appSvgIcon appIcon="arrow-back" appSquare="20"></i>
                  <span class="block" i18n="Хлебные крошки|Ссылка: Назад@@app-breadcrumbs.back">
                    Назад
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </header>
        <article class="skill-task-description-inner bg-white effect-01dp rounded-md w-full p-6 pb-4">
          <ng-container [ngSwitch]="skillIsEdit">
            <ng-container *ngSwitchCase="true">
              <form class="w-full" [formGroup]="skillForm" autocomplete="off">
                <fieldset [disabled]="skillFormIsSubmitting">
                  <div class="flex items-start justify-between w-full mb-2">
                    <fieldset class="form-control-unset w-full mr-4">
                      <!-- prettier-ignore -->
                      <textarea
                        appTextarea
                        class="text-headline-6 h-auto overflow-y-hidden break-words w-full p-0 m-0"
                        placeholder="Введите название задачи"
                        i18n-placeholder="Просмотр навыка|Поле ввода: Введите название задачи@@app-skill-tasks-description-wrapper.input-title"
                        [readonly]="!!skillIsEditIndividual && ['ready', 'done'].includes(!!skillTask && !!skillTask.status ? skillTask.status : '')"
                        name="title"
                        id="task-title"
                        [rows]="1"
                        formControlName="title"
                      ></textarea>
                      <label for="task-title" class="hidden"></label>
                    </fieldset>
                    <button
                      *ngIf="!['ready', 'done'].includes(!!skillTask && !!skillTask.status ? skillTask.status : '')"
                      class="btn btn-light-gray sm transparent !p-0 mr-1 relative"
                      (click)="onTemplate()"
                    >
                      <span
                        *ngIf="false"
                        class="w-1.5 h-1.5 absolute top-0.5 right-0.5 bg-secondary-500 rounded-full">
                      </span>
                      <i
                        appSvgIcon
                        appIcon="templates"
                        appSquare="24"
                      ></i>
                    </button>
                    <ng-container *ngTemplateOutlet="fullscreenButton"></ng-container>
                  </div>
                  <app-skill-tasks-description-editor></app-skill-tasks-description-editor>
                </fieldset>
              </form>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="flex items-start justify-between w-full mb-4">
                <span class="block text-headline-6 overflow-x-hidden break-words" data-pw="action-title">{{ skillTask.title }}</span>
                <ng-container *ngTemplateOutlet="fullscreenButton"></ng-container>
              </div>
              <app-skill-tasks-description-html></app-skill-tasks-description-html>
            </ng-container>
          </ng-container>
        </article>
      </div>
    </ng-container>
		<!-- prettier-ignore -->
		<app-skill-tasks-description-editor-footer *ngIf="isFullscreen && !floatIsAnimated"></app-skill-tasks-description-editor-footer>
	</div>
	<ng-template #fullscreenButton>
		<a
			class="btn btn-light-gray sm transparent !p-0 mr-1"
			[routerLink]="['.']"
			[queryParams]="{isFullscreen: +!isFullscreen || null}"
			[queryParamsHandling]="'merge'"
		>
			<i
				appSvgIcon
				[appIcon]="isFullscreen ? 'fullscreen-exit' : 'fullscreen'"
				appSquare="24"
			></i>
		</a>
	</ng-template>
</section>
