/** @format */

import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import * as dayjs from 'dayjs';
import * as weekday from 'dayjs/plugin/weekday';
import * as localeData from 'dayjs/plugin/localeData';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(isSameOrBefore);

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html'
})
export class CalendarComponent implements OnInit {
  @Output() selected = new EventEmitter<dayjs.Dayjs>();

  @Input()
  set appSelectedDayjs(selectedDayjs: dayjs.Dayjs | null) {
    if (!!selectedDayjs) {
      this.yearCurrent = this.yearSelected = selectedDayjs.get('year');
      this.monthCurrent = this.monthSelected = selectedDayjs.get('month');
      this.dayCurrent = this.daySelected = selectedDayjs.date();

      this.getYearList();
      this.getMonthList();
      this.getDayList();

      this.setDisablePreviousViewNavigation();
    } else {
      this.yearSelected = null;
      this.monthSelected = null;
      this.daySelected = null;
    }
  }

  @Input()
  set appDisablePrevious(disablePrevious: boolean) {
    this.disablePrevious = disablePrevious;
  }

  calendarCurrent: dayjs.Dayjs = dayjs();
  calendarView: 'day' | 'month' | 'year' = 'day';

  yearSelected!: number | null;
  yearToday!: number;
  yearCurrent!: number;
  yearList: number[] = [];

  monthSelected!: number | null;
  monthToday!: number;
  monthCurrent!: number;
  monthList: number[] = [];

  daySelected!: number | null;
  dayToday!: number;
  dayCurrent!: number;
  dayList: number[][] = [[], [], []];

  labelDays: string[] = dayjs.weekdaysMin(true);
  labelMonths: string[] = dayjs.months();

  disablePrevious!: boolean;
  disablePreviousViewNavigation!: boolean;

  constructor(@Inject(LOCALE_ID) public locale: string) {
    dayjs.locale(this.locale);
  }

  ngOnInit(): void {
    /** https://air-datepicker.com/ru */

    this.yearCurrent = this.yearToday = this.calendarCurrent.get('year');
    this.monthCurrent = this.monthToday = this.calendarCurrent.get('month');
    this.dayCurrent = this.dayToday = this.calendarCurrent.date();

    this.getYearList();
    this.getMonthList();
    this.getDayList();

    this.setDisablePreviousViewNavigation();
  }

  onViewNavigate(direction: boolean): void {
    switch (this.calendarView) {
      case 'day':
        if (direction) {
          const yearNext = this.monthCurrent + 1 >= 12;

          this.monthCurrent = yearNext ? 0 : this.monthCurrent + 1;
          yearNext && this.yearCurrent++;
        } else {
          const yearPrevious = this.monthCurrent - 1 <= -1;

          this.monthCurrent = yearPrevious ? 11 : this.monthCurrent - 1;
          yearPrevious && this.yearCurrent--;
        }

        this.getDayList();

        break;
      case 'month':
        direction ? this.yearCurrent++ : this.yearCurrent--;

        this.getYearList();

        break;
      case 'year':
        direction ? (this.yearCurrent += 10) : (this.yearCurrent -= 10);

        this.getYearList();

        break;
      default:
        console.debug('Unknown direction');

        break;
    }

    this.setDisablePreviousViewNavigation();
  }

  onViewChange(view: 'day' | 'month' | 'year'): void {
    this.calendarView = view;

    this.setDisablePreviousViewNavigation();
  }

  onSelectYear(year: number): void {
    this.yearCurrent = year;

    this.onViewChange('month');
  }

  onSelectMonth(month: number): void {
    this.monthCurrent = month;

    this.onViewChange('day');

    this.getDayList();
  }

  onSelectDay(day: number): void {
    this.dayCurrent = day;

    const dayjsDate = this.getDayjsDate();

    this.yearSelected = dayjsDate.get('year');
    this.monthSelected = dayjsDate.get('month');
    this.daySelected = dayjsDate.date();

    this.selected.emit(dayjsDate);
  }

  getYearList(): void {
    this.yearList = [];

    this.yearList = Array.from(Array(12).keys()).map((year: number, key: number) => {
      key++;

      if (key < 5) {
        return this.yearCurrent - (5 - key);
      } else if (key > 5) {
        return this.yearCurrent + (key - 5);
      } else {
        return this.yearCurrent;
      }
    });
  }

  getMonthList(): void {
    this.monthList = [];

    this.monthList = Array.from(Array(12).keys());
  }

  getDayList(): void {
    this.dayList = [[], [], []];

    const dayjsDate = this.getDayjsDate();

    const firstDayOfMonth = dayjsDate.startOf('month').weekday();
    const daysInMonth = dayjsDate.daysInMonth();
    const daysInMonthPrevious = dayjsDate.subtract(1, 'month').daysInMonth();

    const monthPrevious: number[] = [];
    const monthCurrent: number[] = [];
    const monthNext: number[] = [];

    const grid = Array(42).fill(0);

    grid.forEach((value: number, key: number) => {
      const day = key + 1 - firstDayOfMonth;

      if (key >= firstDayOfMonth && day <= daysInMonth) {
        monthCurrent.push(day);

        const week = 7;

        if (firstDayOfMonth === 0 && key < week) {
          monthPrevious.push(daysInMonthPrevious - week + key + 1);

          grid.pop();
        }

        if (this.disablePrevious) {
          const dayjsDate = this.getDayjsDate(day + 1);

          if (dayjsDate.subtract(1, 'day').isBefore(this.calendarCurrent)) {
            monthPrevious.push(day);

            monthCurrent.shift();
          }
        }
      } else if (key < firstDayOfMonth) {
        monthPrevious.push(daysInMonthPrevious - firstDayOfMonth + key + 1);
      } else {
        monthNext.push(key >= daysInMonth ? monthNext[monthNext.length - 1] + 1 || 1 : 1);
      }
    });

    this.dayList = [monthPrevious, monthCurrent, monthNext];
  }

  getDayjsDate(date: number = this.dayCurrent): dayjs.Dayjs {
    return dayjs().set('year', this.yearCurrent).set('month', this.monthCurrent).set('date', date);
  }

  setDisablePreviousViewNavigation(): void {
    if (this.disablePrevious) {
      this.disablePreviousViewNavigation = ((view: string) => {
        if (view === 'day' || view === 'month') {
          return this.monthCurrent <= this.monthToday && this.yearCurrent === this.yearToday;
        }

        return view === 'year' && this.yearCurrent <= this.yearToday;
      })(this.calendarView);
    }
  }
}
