/** @format */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService, User, UserService } from '../../core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

interface ResetForm {
  password: FormControl<string | null>;
  password_confirmation: FormControl<string | null>;
}

@Component({
  selector: 'app-auth-reset',
  templateUrl: './auth-reset.component.html'
})
export class AuthResetComponent implements OnInit {
  routeQuery$!: Subscription;
  routeQueryParams!: Params;

  user!: User;

  resetForm: FormGroup;
  resetFormIsSubmitting = false;

  resetState: string = 'start'; // 'start' | 'finish'

  constructor(
    private userService: UserService,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    this.resetForm = this.formBuilder.group<ResetForm>(
      {
        password: this.formBuilder.control('', [Validators.required, Validators.minLength(6)]),
        password_confirmation: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(6)
        ])
      },
      {
        validators: [
          this.helperService.getCustomValidation('isSame', {
            left: 'password',
            right: 'password_confirmation'
          })
        ]
      }
    );
  }

  ngOnInit(): void {
    // prettier-ignore
    this.title.setTitle($localize`:Заголовок|Текст - Dabster | Изменить пароль@@app-title.app-auth-reset:Dabster | Изменить пароль`);

    this.routeQuery$ = this.route.queryParams.subscribe({
      next: (queryParams: Params) => {
        this.routeQueryParams = queryParams;

        if (!('token' in this.routeQueryParams)) {
          this.router.navigateByUrl('/auth/login').then(() => console.debug('Route changed'));
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  onSubmitForm(): void {
    if (this.helperService.getFormValidation(this.resetForm)) {
      this.resetFormIsSubmitting = true;

      this.userService
        .postReset({
          ...this.resetForm.value,
          token: this.routeQueryParams['token']
        })
        .subscribe({
          next: data => {
            this.resetState = 'finish';
            this.user = data.user;

            this.resetFormIsSubmitting = false;
          },
          error: () => (this.resetFormIsSubmitting = false)
        });
    }
  }

  onLogin(): void {
    if (this.user.token) {
      this.userService.setAuthorization(this.user);

      this.router.navigate(['/']).then(() => console.debug('Route changed'));
    } else {
      this.userService.removeAuthorization();

      this.router.navigate(['/login']).then(() => console.debug('Route changed'));
    }
  }
}
