/** @format */

import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PlatformService } from '../../services';

/** This guard needs only for location.back() issue */

@Injectable({
  providedIn: 'root'
})
export class EditGuardDeactivate implements CanDeactivate<any> {
  allowed = false;

  constructor(private platformService: PlatformService) {}

  canDeactivate(): Observable<boolean> {
    if (!this.allowed) {
      this.platformService.isShownHttp.next(false);
    }

    return of(this.allowed);
  }
}
