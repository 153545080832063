// source: proto/block.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
goog.exportSymbol('proto.block.Action', null, global);
goog.exportSymbol('proto.block.Block', null, global);
goog.exportSymbol('proto.block.BlockAction', null, global);
goog.exportSymbol('proto.block.BlockAction.PayloadCase', null, global);
goog.exportSymbol('proto.block.BlockMove', null, global);
goog.exportSymbol('proto.block.InsertTaskAction', null, global);
goog.exportSymbol('proto.block.Message', null, global);
goog.exportSymbol('proto.block.Message.PayloadCase', null, global);
goog.exportSymbol('proto.block.MessageType', null, global);
goog.exportSymbol('proto.block.MoveTaskAction', null, global);
goog.exportSymbol('proto.block.SkillPayload', null, global);
goog.exportSymbol('proto.block.SkillPayload.PayloadCase', null, global);
goog.exportSymbol('proto.block.SkillPayloadAction', null, global);
goog.exportSymbol('proto.block.TaskPayload', null, global);
goog.exportSymbol('proto.block.TaskPayload.PayloadCase', null, global);
goog.exportSymbol('proto.block.TaskPayloadAction', null, global);
goog.exportSymbol('proto.block.TechnicalPayload', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.block.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.block.Message.oneofGroups_);
};
goog.inherits(proto.block.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.block.Message.displayName = 'proto.block.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.block.TechnicalPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.block.TechnicalPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.block.TechnicalPayload.displayName = 'proto.block.TechnicalPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.block.SkillPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.block.SkillPayload.oneofGroups_);
};
goog.inherits(proto.block.SkillPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.block.SkillPayload.displayName = 'proto.block.SkillPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.block.TaskPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.block.TaskPayload.oneofGroups_);
};
goog.inherits(proto.block.TaskPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.block.TaskPayload.displayName = 'proto.block.TaskPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.block.MoveTaskAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.block.MoveTaskAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.block.MoveTaskAction.displayName = 'proto.block.MoveTaskAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.block.InsertTaskAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.block.InsertTaskAction.repeatedFields_, null);
};
goog.inherits(proto.block.InsertTaskAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.block.InsertTaskAction.displayName = 'proto.block.InsertTaskAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.block.BlockAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.block.BlockAction.oneofGroups_);
};
goog.inherits(proto.block.BlockAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.block.BlockAction.displayName = 'proto.block.BlockAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.block.Block = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.block.Block, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.block.Block.displayName = 'proto.block.Block';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.block.BlockMove = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.block.BlockMove, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.block.BlockMove.displayName = 'proto.block.BlockMove';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.block.Message.oneofGroups_ = [[3,4,5]];

/**
 * @enum {number}
 */
proto.block.Message.PayloadCase = {
  PAYLOAD_NOT_SET: 0,
  TECHNICAL: 3,
  SKILL: 4,
  TASK: 5
};

/**
 * @return {proto.block.Message.PayloadCase}
 */
proto.block.Message.prototype.getPayloadCase = function() {
  return /** @type {proto.block.Message.PayloadCase} */(jspb.Message.computeOneofCase(this, proto.block.Message.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.block.Message.prototype.toObject = function(opt_includeInstance) {
    return proto.block.Message.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.block.Message} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.block.Message.toObject = function(includeInstance, msg) {
    var f, obj = {
      uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      type: jspb.Message.getFieldWithDefault(msg, 2, 0),
      technical: (f = msg.getTechnical()) && proto.block.TechnicalPayload.toObject(includeInstance, f),
      skill: (f = msg.getSkill()) && proto.block.SkillPayload.toObject(includeInstance, f),
      task: (f = msg.getTask()) && proto.block.TaskPayload.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.block.Message}
 */
proto.block.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.block.Message;
  return proto.block.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.block.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.block.Message}
 */
proto.block.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUuid(value);
        break;
      case 2:
        var value = /** @type {!proto.block.MessageType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 3:
        var value = new proto.block.TechnicalPayload;
        reader.readMessage(value,proto.block.TechnicalPayload.deserializeBinaryFromReader);
        msg.setTechnical(value);
        break;
      case 4:
        var value = new proto.block.SkillPayload;
        reader.readMessage(value,proto.block.SkillPayload.deserializeBinaryFromReader);
        msg.setSkill(value);
        break;
      case 5:
        var value = new proto.block.TaskPayload;
        reader.readMessage(value,proto.block.TaskPayload.deserializeBinaryFromReader);
        msg.setTask(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.block.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.block.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.block.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.block.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTechnical();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.block.TechnicalPayload.serializeBinaryToWriter
    );
  }
  f = message.getSkill();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.block.SkillPayload.serializeBinaryToWriter
    );
  }
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.block.TaskPayload.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.block.Message.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.block.Message} returns this
 */
proto.block.Message.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MessageType type = 2;
 * @return {!proto.block.MessageType}
 */
proto.block.Message.prototype.getType = function() {
  return /** @type {!proto.block.MessageType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.block.MessageType} value
 * @return {!proto.block.Message} returns this
 */
proto.block.Message.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional TechnicalPayload technical = 3;
 * @return {?proto.block.TechnicalPayload}
 */
proto.block.Message.prototype.getTechnical = function() {
  return /** @type{?proto.block.TechnicalPayload} */ (
    jspb.Message.getWrapperField(this, proto.block.TechnicalPayload, 3));
};


/**
 * @param {?proto.block.TechnicalPayload|undefined} value
 * @return {!proto.block.Message} returns this
 */
proto.block.Message.prototype.setTechnical = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.block.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.block.Message} returns this
 */
proto.block.Message.prototype.clearTechnical = function() {
  return this.setTechnical(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.block.Message.prototype.hasTechnical = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SkillPayload skill = 4;
 * @return {?proto.block.SkillPayload}
 */
proto.block.Message.prototype.getSkill = function() {
  return /** @type{?proto.block.SkillPayload} */ (
    jspb.Message.getWrapperField(this, proto.block.SkillPayload, 4));
};


/**
 * @param {?proto.block.SkillPayload|undefined} value
 * @return {!proto.block.Message} returns this
 */
proto.block.Message.prototype.setSkill = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.block.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.block.Message} returns this
 */
proto.block.Message.prototype.clearSkill = function() {
  return this.setSkill(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.block.Message.prototype.hasSkill = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TaskPayload task = 5;
 * @return {?proto.block.TaskPayload}
 */
proto.block.Message.prototype.getTask = function() {
  return /** @type{?proto.block.TaskPayload} */ (
    jspb.Message.getWrapperField(this, proto.block.TaskPayload, 5));
};


/**
 * @param {?proto.block.TaskPayload|undefined} value
 * @return {!proto.block.Message} returns this
 */
proto.block.Message.prototype.setTask = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.block.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.block.Message} returns this
 */
proto.block.Message.prototype.clearTask = function() {
  return this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.block.Message.prototype.hasTask = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.block.TechnicalPayload.prototype.toObject = function(opt_includeInstance) {
    return proto.block.TechnicalPayload.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.block.TechnicalPayload} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.block.TechnicalPayload.toObject = function(includeInstance, msg) {
    var f, obj = {
      code: jspb.Message.getFieldWithDefault(msg, 1, 0),
      message: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.block.TechnicalPayload}
 */
proto.block.TechnicalPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.block.TechnicalPayload;
  return proto.block.TechnicalPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.block.TechnicalPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.block.TechnicalPayload}
 */
proto.block.TechnicalPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCode(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.block.TechnicalPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.block.TechnicalPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.block.TechnicalPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.block.TechnicalPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.block.TechnicalPayload.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.block.TechnicalPayload} returns this
 */
proto.block.TechnicalPayload.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.block.TechnicalPayload.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.block.TechnicalPayload} returns this
 */
proto.block.TechnicalPayload.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.block.SkillPayload.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.block.SkillPayload.PayloadCase = {
  PAYLOAD_NOT_SET: 0,
  TITLE: 3,
  DESCRIPTION: 4
};

/**
 * @return {proto.block.SkillPayload.PayloadCase}
 */
proto.block.SkillPayload.prototype.getPayloadCase = function() {
  return /** @type {proto.block.SkillPayload.PayloadCase} */(jspb.Message.computeOneofCase(this, proto.block.SkillPayload.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.block.SkillPayload.prototype.toObject = function(opt_includeInstance) {
    return proto.block.SkillPayload.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.block.SkillPayload} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.block.SkillPayload.toObject = function(includeInstance, msg) {
    var f, obj = {
      skillid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      action: jspb.Message.getFieldWithDefault(msg, 2, 0),
      title: jspb.Message.getFieldWithDefault(msg, 3, ""),
      description: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.block.SkillPayload}
 */
proto.block.SkillPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.block.SkillPayload;
  return proto.block.SkillPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.block.SkillPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.block.SkillPayload}
 */
proto.block.SkillPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSkillid(value);
        break;
      case 2:
        var value = /** @type {!proto.block.SkillPayloadAction} */ (reader.readEnum());
        msg.setAction(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTitle(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.block.SkillPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.block.SkillPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.block.SkillPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.block.SkillPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string skillId = 1;
 * @return {string}
 */
proto.block.SkillPayload.prototype.getSkillid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.block.SkillPayload} returns this
 */
proto.block.SkillPayload.prototype.setSkillid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SkillPayloadAction action = 2;
 * @return {!proto.block.SkillPayloadAction}
 */
proto.block.SkillPayload.prototype.getAction = function() {
  return /** @type {!proto.block.SkillPayloadAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.block.SkillPayloadAction} value
 * @return {!proto.block.SkillPayload} returns this
 */
proto.block.SkillPayload.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.block.SkillPayload.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.block.SkillPayload} returns this
 */
proto.block.SkillPayload.prototype.setTitle = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.block.SkillPayload.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.block.SkillPayload} returns this
 */
proto.block.SkillPayload.prototype.clearTitle = function() {
  return jspb.Message.setOneofField(this, 3, proto.block.SkillPayload.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.block.SkillPayload.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.block.SkillPayload.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.block.SkillPayload} returns this
 */
proto.block.SkillPayload.prototype.setDescription = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.block.SkillPayload.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.block.SkillPayload} returns this
 */
proto.block.SkillPayload.prototype.clearDescription = function() {
  return jspb.Message.setOneofField(this, 4, proto.block.SkillPayload.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.block.SkillPayload.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.block.TaskPayload.oneofGroups_ = [[4,5,6,7]];

/**
 * @enum {number}
 */
proto.block.TaskPayload.PayloadCase = {
  PAYLOAD_NOT_SET: 0,
  TITLE: 4,
  BLOCKACTION: 5,
  MOVEACTION: 6,
  INSERTACTION: 7
};

/**
 * @return {proto.block.TaskPayload.PayloadCase}
 */
proto.block.TaskPayload.prototype.getPayloadCase = function() {
  return /** @type {proto.block.TaskPayload.PayloadCase} */(jspb.Message.computeOneofCase(this, proto.block.TaskPayload.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.block.TaskPayload.prototype.toObject = function(opt_includeInstance) {
    return proto.block.TaskPayload.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.block.TaskPayload} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.block.TaskPayload.toObject = function(includeInstance, msg) {
    var f, obj = {
      skillid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      taskid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      action: jspb.Message.getFieldWithDefault(msg, 3, 0),
      title: jspb.Message.getFieldWithDefault(msg, 4, ""),
      blockaction: (f = msg.getBlockaction()) && proto.block.BlockAction.toObject(includeInstance, f),
      moveaction: (f = msg.getMoveaction()) && proto.block.MoveTaskAction.toObject(includeInstance, f),
      insertaction: (f = msg.getInsertaction()) && proto.block.InsertTaskAction.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.block.TaskPayload}
 */
proto.block.TaskPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.block.TaskPayload;
  return proto.block.TaskPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.block.TaskPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.block.TaskPayload}
 */
proto.block.TaskPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSkillid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTaskid(value);
        break;
      case 3:
        var value = /** @type {!proto.block.TaskPayloadAction} */ (reader.readEnum());
        msg.setAction(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTitle(value);
        break;
      case 5:
        var value = new proto.block.BlockAction;
        reader.readMessage(value,proto.block.BlockAction.deserializeBinaryFromReader);
        msg.setBlockaction(value);
        break;
      case 6:
        var value = new proto.block.MoveTaskAction;
        reader.readMessage(value,proto.block.MoveTaskAction.deserializeBinaryFromReader);
        msg.setMoveaction(value);
        break;
      case 7:
        var value = new proto.block.InsertTaskAction;
        reader.readMessage(value,proto.block.InsertTaskAction.deserializeBinaryFromReader);
        msg.setInsertaction(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.block.TaskPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.block.TaskPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.block.TaskPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.block.TaskPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBlockaction();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.block.BlockAction.serializeBinaryToWriter
    );
  }
  f = message.getMoveaction();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.block.MoveTaskAction.serializeBinaryToWriter
    );
  }
  f = message.getInsertaction();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.block.InsertTaskAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string skillId = 1;
 * @return {string}
 */
proto.block.TaskPayload.prototype.getSkillid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.block.TaskPayload} returns this
 */
proto.block.TaskPayload.prototype.setSkillid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string taskId = 2;
 * @return {string}
 */
proto.block.TaskPayload.prototype.getTaskid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.block.TaskPayload} returns this
 */
proto.block.TaskPayload.prototype.setTaskid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TaskPayloadAction action = 3;
 * @return {!proto.block.TaskPayloadAction}
 */
proto.block.TaskPayload.prototype.getAction = function() {
  return /** @type {!proto.block.TaskPayloadAction} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.block.TaskPayloadAction} value
 * @return {!proto.block.TaskPayload} returns this
 */
proto.block.TaskPayload.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.block.TaskPayload.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.block.TaskPayload} returns this
 */
proto.block.TaskPayload.prototype.setTitle = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.block.TaskPayload.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.block.TaskPayload} returns this
 */
proto.block.TaskPayload.prototype.clearTitle = function() {
  return jspb.Message.setOneofField(this, 4, proto.block.TaskPayload.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.block.TaskPayload.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional BlockAction blockAction = 5;
 * @return {?proto.block.BlockAction}
 */
proto.block.TaskPayload.prototype.getBlockaction = function() {
  return /** @type{?proto.block.BlockAction} */ (
    jspb.Message.getWrapperField(this, proto.block.BlockAction, 5));
};


/**
 * @param {?proto.block.BlockAction|undefined} value
 * @return {!proto.block.TaskPayload} returns this
 */
proto.block.TaskPayload.prototype.setBlockaction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.block.TaskPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.block.TaskPayload} returns this
 */
proto.block.TaskPayload.prototype.clearBlockaction = function() {
  return this.setBlockaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.block.TaskPayload.prototype.hasBlockaction = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MoveTaskAction moveAction = 6;
 * @return {?proto.block.MoveTaskAction}
 */
proto.block.TaskPayload.prototype.getMoveaction = function() {
  return /** @type{?proto.block.MoveTaskAction} */ (
    jspb.Message.getWrapperField(this, proto.block.MoveTaskAction, 6));
};


/**
 * @param {?proto.block.MoveTaskAction|undefined} value
 * @return {!proto.block.TaskPayload} returns this
 */
proto.block.TaskPayload.prototype.setMoveaction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.block.TaskPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.block.TaskPayload} returns this
 */
proto.block.TaskPayload.prototype.clearMoveaction = function() {
  return this.setMoveaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.block.TaskPayload.prototype.hasMoveaction = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional InsertTaskAction insertAction = 7;
 * @return {?proto.block.InsertTaskAction}
 */
proto.block.TaskPayload.prototype.getInsertaction = function() {
  return /** @type{?proto.block.InsertTaskAction} */ (
    jspb.Message.getWrapperField(this, proto.block.InsertTaskAction, 7));
};


/**
 * @param {?proto.block.InsertTaskAction|undefined} value
 * @return {!proto.block.TaskPayload} returns this
 */
proto.block.TaskPayload.prototype.setInsertaction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.block.TaskPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.block.TaskPayload} returns this
 */
proto.block.TaskPayload.prototype.clearInsertaction = function() {
  return this.setInsertaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.block.TaskPayload.prototype.hasInsertaction = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.block.MoveTaskAction.prototype.toObject = function(opt_includeInstance) {
    return proto.block.MoveTaskAction.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.block.MoveTaskAction} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.block.MoveTaskAction.toObject = function(includeInstance, msg) {
    var f, obj = {
      oldindex: jspb.Message.getFieldWithDefault(msg, 1, 0),
      newindex: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.block.MoveTaskAction}
 */
proto.block.MoveTaskAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.block.MoveTaskAction;
  return proto.block.MoveTaskAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.block.MoveTaskAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.block.MoveTaskAction}
 */
proto.block.MoveTaskAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setOldindex(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setNewindex(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.block.MoveTaskAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.block.MoveTaskAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.block.MoveTaskAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.block.MoveTaskAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldindex();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNewindex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 oldIndex = 1;
 * @return {number}
 */
proto.block.MoveTaskAction.prototype.getOldindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.block.MoveTaskAction} returns this
 */
proto.block.MoveTaskAction.prototype.setOldindex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 newIndex = 2;
 * @return {number}
 */
proto.block.MoveTaskAction.prototype.getNewindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.block.MoveTaskAction} returns this
 */
proto.block.MoveTaskAction.prototype.setNewindex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.block.InsertTaskAction.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.block.InsertTaskAction.prototype.toObject = function(opt_includeInstance) {
    return proto.block.InsertTaskAction.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.block.InsertTaskAction} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.block.InsertTaskAction.toObject = function(includeInstance, msg) {
    var f, obj = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      blocksList: jspb.Message.toObjectList(msg.getBlocksList(),
        proto.block.Block.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.block.InsertTaskAction}
 */
proto.block.InsertTaskAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.block.InsertTaskAction;
  return proto.block.InsertTaskAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.block.InsertTaskAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.block.InsertTaskAction}
 */
proto.block.InsertTaskAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTitle(value);
        break;
      case 2:
        var value = new proto.block.Block;
        reader.readMessage(value,proto.block.Block.deserializeBinaryFromReader);
        msg.addBlocks(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.block.InsertTaskAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.block.InsertTaskAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.block.InsertTaskAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.block.InsertTaskAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBlocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.block.Block.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.block.InsertTaskAction.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.block.InsertTaskAction} returns this
 */
proto.block.InsertTaskAction.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Block blocks = 2;
 * @return {!Array<!proto.block.Block>}
 */
proto.block.InsertTaskAction.prototype.getBlocksList = function() {
  return /** @type{!Array<!proto.block.Block>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.block.Block, 2));
};


/**
 * @param {!Array<!proto.block.Block>} value
 * @return {!proto.block.InsertTaskAction} returns this
 */
proto.block.InsertTaskAction.prototype.setBlocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.block.Block=} opt_value
 * @param {number=} opt_index
 * @return {!proto.block.Block}
 */
proto.block.InsertTaskAction.prototype.addBlocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.block.Block, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.block.InsertTaskAction} returns this
 */
proto.block.InsertTaskAction.prototype.clearBlocksList = function() {
  return this.setBlocksList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.block.BlockAction.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.block.BlockAction.PayloadCase = {
  PAYLOAD_NOT_SET: 0,
  BLOCK: 2,
  BLOCKMOVE: 3
};

/**
 * @return {proto.block.BlockAction.PayloadCase}
 */
proto.block.BlockAction.prototype.getPayloadCase = function() {
  return /** @type {proto.block.BlockAction.PayloadCase} */(jspb.Message.computeOneofCase(this, proto.block.BlockAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.block.BlockAction.prototype.toObject = function(opt_includeInstance) {
    return proto.block.BlockAction.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.block.BlockAction} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.block.BlockAction.toObject = function(includeInstance, msg) {
    var f, obj = {
      action: jspb.Message.getFieldWithDefault(msg, 1, 0),
      block: (f = msg.getBlock()) && proto.block.Block.toObject(includeInstance, f),
      blockmove: (f = msg.getBlockmove()) && proto.block.BlockMove.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.block.BlockAction}
 */
proto.block.BlockAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.block.BlockAction;
  return proto.block.BlockAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.block.BlockAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.block.BlockAction}
 */
proto.block.BlockAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.block.Action} */ (reader.readEnum());
        msg.setAction(value);
        break;
      case 2:
        var value = new proto.block.Block;
        reader.readMessage(value,proto.block.Block.deserializeBinaryFromReader);
        msg.setBlock(value);
        break;
      case 3:
        var value = new proto.block.BlockMove;
        reader.readMessage(value,proto.block.BlockMove.deserializeBinaryFromReader);
        msg.setBlockmove(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.block.BlockAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.block.BlockAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.block.BlockAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.block.BlockAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBlock();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.block.Block.serializeBinaryToWriter
    );
  }
  f = message.getBlockmove();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.block.BlockMove.serializeBinaryToWriter
    );
  }
};


/**
 * optional Action action = 1;
 * @return {!proto.block.Action}
 */
proto.block.BlockAction.prototype.getAction = function() {
  return /** @type {!proto.block.Action} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.block.Action} value
 * @return {!proto.block.BlockAction} returns this
 */
proto.block.BlockAction.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Block block = 2;
 * @return {?proto.block.Block}
 */
proto.block.BlockAction.prototype.getBlock = function() {
  return /** @type{?proto.block.Block} */ (
    jspb.Message.getWrapperField(this, proto.block.Block, 2));
};


/**
 * @param {?proto.block.Block|undefined} value
 * @return {!proto.block.BlockAction} returns this
 */
proto.block.BlockAction.prototype.setBlock = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.block.BlockAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.block.BlockAction} returns this
 */
proto.block.BlockAction.prototype.clearBlock = function() {
  return this.setBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.block.BlockAction.prototype.hasBlock = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BlockMove blockMove = 3;
 * @return {?proto.block.BlockMove}
 */
proto.block.BlockAction.prototype.getBlockmove = function() {
  return /** @type{?proto.block.BlockMove} */ (
    jspb.Message.getWrapperField(this, proto.block.BlockMove, 3));
};


/**
 * @param {?proto.block.BlockMove|undefined} value
 * @return {!proto.block.BlockAction} returns this
 */
proto.block.BlockAction.prototype.setBlockmove = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.block.BlockAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.block.BlockAction} returns this
 */
proto.block.BlockAction.prototype.clearBlockmove = function() {
  return this.setBlockmove(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.block.BlockAction.prototype.hasBlockmove = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.block.Block.prototype.toObject = function(opt_includeInstance) {
    return proto.block.Block.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.block.Block} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.block.Block.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      index: jspb.Message.getFieldWithDefault(msg, 2, 0),
      type: jspb.Message.getFieldWithDefault(msg, 3, ""),
      data: (f = msg.getData()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.block.Block}
 */
proto.block.Block.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.block.Block;
  return proto.block.Block.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.block.Block} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.block.Block}
 */
proto.block.Block.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setIndex(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 4:
        var value = new google_protobuf_struct_pb.Struct;
        reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
        msg.setData(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.block.Block.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.block.Block.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.block.Block} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.block.Block.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.block.Block.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.block.Block} returns this
 */
proto.block.Block.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 index = 2;
 * @return {number}
 */
proto.block.Block.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.block.Block} returns this
 */
proto.block.Block.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.block.Block.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.block.Block} returns this
 */
proto.block.Block.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Struct data = 4;
 * @return {?proto.google.protobuf.Struct}
 */
proto.block.Block.prototype.getData = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 4));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.block.Block} returns this
 */
proto.block.Block.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.block.Block} returns this
 */
proto.block.Block.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.block.Block.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.block.BlockMove.prototype.toObject = function(opt_includeInstance) {
    return proto.block.BlockMove.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.block.BlockMove} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.block.BlockMove.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      oldindex: jspb.Message.getFieldWithDefault(msg, 2, 0),
      newindex: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.block.BlockMove}
 */
proto.block.BlockMove.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.block.BlockMove;
  return proto.block.BlockMove.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.block.BlockMove} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.block.BlockMove}
 */
proto.block.BlockMove.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setOldindex(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setNewindex(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.block.BlockMove.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.block.BlockMove.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.block.BlockMove} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.block.BlockMove.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOldindex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNewindex();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.block.BlockMove.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.block.BlockMove} returns this
 */
proto.block.BlockMove.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 oldIndex = 2;
 * @return {number}
 */
proto.block.BlockMove.prototype.getOldindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.block.BlockMove} returns this
 */
proto.block.BlockMove.prototype.setOldindex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 newIndex = 3;
 * @return {number}
 */
proto.block.BlockMove.prototype.getNewindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.block.BlockMove} returns this
 */
proto.block.BlockMove.prototype.setNewindex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.block.MessageType = {
  TECHNICAL: 0,
  SKILL: 1,
  TASK: 2
};

/**
 * @enum {number}
 */
proto.block.SkillPayloadAction = {
  SKILL_UPDATE_TITLE: 0,
  SKILL_UPDATE_DESCRIPTION: 1
};

/**
 * @enum {number}
 */
proto.block.TaskPayloadAction = {
  TASK_CREATE: 0,
  TASK_DELETE: 1,
  TASK_UPDATE_TITLE: 2,
  TASK_UPDATE_DESCRIPTION: 3,
  TASK_MOVE: 4,
  TASK_INSERT: 5
};

/**
 * @enum {number}
 */
proto.block.Action = {
  BLOCK_CREATE: 0,
  BLOCK_UPDATE: 1,
  BLOCK_DELETE: 2,
  BLOCK_MOVE: 3
};

goog.object.extend(exports, proto.block);
