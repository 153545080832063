<!-- @format -->

<section *ngIf="progressDashboard">
	<app-overlay
		[appState]="toggle"
		[appClassList]="['overlay-gray']"
		(clicked)="onClose($event)"
	>
		<div
			class="relative min-w-[688px] mobile:min-w-full bg-white rounded-lg p-10 pb-8"
			data-pw="modal-completed-checkpoint"
		>
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="onClose(false)"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<ng-container [ngSwitch]="progressDashboard.user.id === user.id">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-8"
          i18n="Оценка навыка|Заголовок: Вы оценили дедлайн@@app-dashboard-progress-estimate.modal-title"
          *ngSwitchCase="true"
        >
          Вы оценили дедлайн
        </span>
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-8"
          i18n="Оценка навыка|Заголовок: Вы оценили дедлайн@@app-dashboard-progress-estimate.modal-title-teammate"
          *ngSwitchDefault
        >
          Вы оценили дедлайн тиммейта
        </span>
			</ng-container>
			<div class="flex items-center space-x-2 mb-6">
				<app-avatar class="block" [appUser]="progressDashboard.user"></app-avatar>
				<span class="block text-body-2 text-blue-grey-900">
					{{ progressDashboard.user.name }} {{ progressDashboard.user.surname }}
				</span>
			</div>
			<div
				class="flex mobile:flex-col mobile:items-center space-y-0 space-x-4 mobile:space-y-4 mobile:space-x-0 mb-8"
			>
				<div
					class="flex flex-col justify-center bg-blue-grey-25 effect-01dp rounded-md box-border w-full max-w-[296px] mobile:max-w-full p-8"
				>
					<div class="flex items-center text-primary-A400 mb-4">
						<i appSvgIcon appIcon="flag" appSquare="17" class="mr-3"></i>
						<!-- prettier-ignore -->
						<span
              class="text-headline-6 text-blue-grey-900"
              i18n="Оценка навыка|Заголовок: Дедлайн@@app-dashboard-progress-estimate.modal-deadline"
            >
              Дедлайн
            </span>
					</div>
					<div class="flex items-start">
						<span class="text-headline-2 text-blue-grey-900 mr-2">
							<ng-container *appLocale="['en']">
								{{ progressDashboard.deadline | date: "MM.dd" }}
							</ng-container>
							<ng-container *appLocale="['ru']">
								{{ progressDashboard.deadline | date: "dd.MM" }}
							</ng-container>
						</span>
						<span class="text-headline-7 leading-9 text-blue-grey-600">
							{{ progressDashboard.deadline | date: "YYYY" }}
						</span>
					</div>
				</div>
				<div
					class="flex flex-col justify-center border border-blue-grey-100 rounded-md box-border w-full max-w-[296px] mobile:max-w-full p-8"
				>
					<!-- prettier-ignore -->
					<span
            class="text-headline-6 text-blue-grey-900 mb-2"
            i18n="Оценка навыка|Заголовок: Освоено навыков@@app-dashboard-progress-estimate.modal-mastered"
          >
            Освоено навыков
          </span>
					<span class="text-headline-2 text-blue-grey-900">
						{{ progressDashboard.count_done }}/{{ progressDashboard.count_all }}
					</span>
				</div>
			</div>
			<div class="flex items-center justify-between">
				<button class="btn btn-gray outline md" (click)="onClose(false)">
					<figure class="relative">
						<i class="absolute" appSvgIcon appIcon="cross" appSquare="24"></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Оценка навыка|Кнопка: Отмена@@app-dashboard-progress-estimate.modal-cancel"
          >
            Отмена
          </span>
				</button>
				<button class="btn btn-primary inline md" (click)="onCompleteEstimate()">
					<figure class="relative">
						<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Оценка навыка|Кнопка: Подтвердить@@app-dashboard-progress-estimate.modal-confirm"
          >
						Подтвердить
					</span>
				</button>
			</div>
		</div>
	</app-overlay>
</section>
