<!-- @format -->

<div class="flex flex-col">
	<h4
		class="text-headline-4 mb-2"
		i18n="Приглашение|Заголовок: Запросите приглашение@@app-auth-invite.title"
	>
		Запросите приглашение
	</h4>
	<div class="flex space-x-2 text-body-1 mb-8">
		<span
			class="inline-block"
			i18n="Приглашение|Текст: Уже зарегистрированы?@@app-auth-invite.already-registered"
		>
			Уже зарегистрированы?
		</span>
		<a
			class="block underline"
			[routerLink]="['/auth/login']"
			i18n="Приглашение|Текст: Войдите в аккаунт@@app-auth-invite.login-to-account"
		>
			Войдите в аккаунт
		</a>
	</div>
	<!-- prettier-ignore -->
	<p
		class="text-body-1 mb-5"
		i18n="Приглашение|Параграф: Пожалуйста, заполните форму ниже@@app-auth-invite.paragraph"
	>
		Пожалуйста, заполните форму ниже – вам позвонит Артем, основатель сервиса, вместе
		решите насколько сервис подходит под ваши задачи.
	</p>
	<form [formGroup]="inviteForm" (ngSubmit)="onSubmitForm()" autocomplete="off">
		<fieldset [disabled]="inviteFormIsSubmitting">
			<fieldset class="form-control relative mb-4">
				<input
					id="name"
					type="text"
					class="peer"
					placeholder="Имя"
					i18n-placeholder="Приглашение|Поле ввода: Имя@@app-auth-invite.input-name"
					formControlName="name"
					#inputName
				/>
				<label
					for="name"
					class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
				>
					<app-error-label
						[appInputElement]="inputName"
						[appFormGroup]="inviteForm"
						[appFormControlName]="'name'"
					></app-error-label>
				</label>
			</fieldset>
			<fieldset class="form-control relative mb-4">
				<input
					id="email"
					type="email"
					class="peer"
					placeholder="E-mail"
					i18n-placeholder="Приглашение|Поле ввода: E-mail@@app-auth-invite.input-email"
					formControlName="email"
					#inputEmail
				/>
				<label
					for="email"
					class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
				>
					<app-error-label
						[appInputElement]="inputEmail"
						[appFormGroup]="inviteForm"
						[appFormControlName]="'email'"
					></app-error-label>
				</label>
			</fieldset>
			<fieldset class="form-control relative mb-4">
				<input
					id="phone"
					type="text"
					class="peer"
					placeholder="+7 (999) 999-00-00"
					i18n-placeholder="Приглашение|Поле ввода: Телефон@@app-auth-invite.input-phone"
					formControlName="phone"
					#inputPhone
				/>
				<label
					for="phone"
					class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
				>
					<app-error-label
						[appInputElement]="inputPhone"
						[appFormGroup]="inviteForm"
						[appFormControlName]="'phone'"
					></app-error-label>
				</label>
			</fieldset>
			<!-- prettier-ignore -->
			<span
				class="block text-caption text-blue-grey-400 mb-6"
				i18n="Приглашение|Параграф: Оставляя свои данные, вы соглашаетесь@@app-auth-invite.paragraph-terms"
			>
				Оставляя свои данные, вы соглашаетесь с нашей политикой обработки персональных данных.
			</span>
			<!-- prettier-ignore -->
			<button
				class="btn btn-primary inline md mb-8"
				[disabled]="inviteFormIsSubmitting"
				i18n="Приглашение|Кнопка: Оставить заявку@@app-auth-invite.submit"
			>
				Оставить заявку
			</button>
			<!-- prettier-ignore -->
			<p
				class="text-body-1"
				i18n="Приглашение|Параграф: Ваша команда уже есть в Dabster?@@app-auth-invite.paragraph-exists"
			>
				Ваша команда уже есть в Dabster? Пожалуйста, обратитесь за приглашением к своей команде или руководителю.
			</p>
		</fieldset>
	</form>
</div>
