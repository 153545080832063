/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../core';

@Component({
  selector: 'app-avatar, [appAvatar]',
  templateUrl: './avatar.component.html'
})
export class AvatarComponent implements OnInit {
  @Input()
  set appUser(user: User | undefined) {
    if (!!user) {
      this.user = user;

      this.getBackground();
    }
  }

  @Input()
  set appSize(size: string) {
    this.size = size;
  }

  @Input()
  set appTextClass(textClass: string) {
    this.textClass = textClass;
  }

  constructor() {}

  user!: User;
  userClassList: string[] = [
    'bg-primary-A400',
    'bg-blue-grey-600',
    'bg-secondary-600',
    'bg-tertiary-400'
  ];

  background!: string;
  size = '32px';
  textClass = 'text-caption font-semibold text-white';

  ngOnInit(): void {}

  getBackground(): void {
    const name: number = this.user.name.length;
    const surname: number = this.user.surname.length;
    const i: number = Math.abs((name + surname) % this.userClassList.length);

    this.background = this.userClassList[i] || 'bg-primary-A400';
  }
}
