/** @format */

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PlatformService, Skill, SkillImage, SkillImageList, SkillService } from '../../../../core';
import { map, Subscription, switchMap } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

interface SkillForm {
  description: FormControl<string | null>;
}

@Component({
  selector: 'app-skill-description, [appSkillDescription]',
  templateUrl: './description.component.html'
})
export class SkillDescriptionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('skillDescription') skillDescription!: ElementRef;

  skill!: Skill;
  skill$!: Subscription;

  skillIsEdit!: boolean;
  skillIsEdit$!: Subscription;

  skillForm: FormGroup;
  skillForm$!: Subscription;
  skillFormIsSubmitting = false;

  isShownShowMore: boolean = false;
  isShowMore: boolean = false;

  imageList: SkillImageList[] = [];
  imageListToggle!: boolean;
  imageSelected: SkillImageList | undefined;

  constructor(
    private skillService: SkillService,
    private formBuilder: FormBuilder,
    private platformService: PlatformService
  ) {
    this.skillForm = this.formBuilder.group<SkillForm>({
      description: this.formBuilder.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.skill$ = this.skillService.skill$
      .pipe(
        tap((skill: Skill) => (this.skill = skill)),
        switchMap(() => this.skillService.skillIsEdit$),
        filter((skillIsEdit: boolean) => skillIsEdit),
        tap((skillIsEdit: boolean) => (this.skillIsEdit = skillIsEdit))
      )
      .subscribe({
        next: () => {
          const abstractControl: AbstractControl | null = this.skillForm.get('description');
          const abstractControlIsEqual = (description: string): boolean => {
            return description === this.skill.description;
          };

          if (abstractControl) {
            // prettier-ignore
            !abstractControlIsEqual(abstractControl.value) && abstractControl.setValue(this.skill.description);

            this.skillForm$?.unsubscribe();
            this.skillForm$ = abstractControl.valueChanges
              .pipe(
                debounceTime(this.skillService.skillIsEditDebounce),
                filter((description: string) => !abstractControlIsEqual(description))
              )
              .subscribe({
                next: (description: string) => this.skillService.setSkillDescription(description),
                error: (error: any) => console.error(error)
              });
          }
        },
        error: (error: any) => console.error(error)
      });
  }

  ngAfterViewInit(): void {
    if (this.skillDescription) {
      const getCountLines = (htmlElement: HTMLElement): number => {
        if (this.platformService.isBrowser()) {
          const window: Window = this.platformService.getWindow();

          const offsetHeight: number = htmlElement.offsetHeight;
          const computedStyle: CSSStyleDeclaration = window.getComputedStyle(htmlElement, null);

          // prettier-ignore
          const lineHeight: string = computedStyle.getPropertyValue('line-height').replace('px', '');

          return Math.floor(offsetHeight / Number(lineHeight));
        }

        return 0;
      };

      this.isShownShowMore = getCountLines(this.skillDescription.nativeElement) >= 8;
    }
  }

  ngOnDestroy(): void {
    [this.skill$, this.skillForm$, this.skillIsEdit$].forEach($ => $?.unsubscribe());
  }

  onShowImageList(): void {
    this.skillService
      .getImageList()
      .pipe(map((data: any) => data.images))
      .subscribe({
        next: (skillImageList: SkillImageList[]) => {
          this.imageList = skillImageList;
          this.imageListToggle = true;
        },
        error: (error: any) => console.error(error)
      });
  }

  onResetImage(): void {
    this.imageSelected = undefined;
    this.imageListToggle = false;
  }

  onSubmitImage(): void {
    const formData: FormData = new FormData();

    formData.append('cover', String(this.imageSelected?.id));

    this.skillService.uploadImage(formData).subscribe({
      next: (skillImage: SkillImage) => {
        this.skillService.setImage(skillImage);

        this.onResetImage();
      },
      error: (error: any) => console.error(error)
    });
  }
}
