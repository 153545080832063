/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { PlatformService } from '../../core';
import DOMPurify, { Config } from 'dompurify';

@Pipe({
  name: 'sanitizer'
})
export class SanitizerPipe implements PipeTransform {
  optionsDefault: SanitizerPipe['options'] = {
    context: 'html',
    blankLinks: true
  };

  options?: {
    context: 'html' | 'style' | 'script' | 'url' | 'resource-url';
    blankLinks: boolean;
  };

  constructor(protected sanitizer: DomSanitizer, private platformService: PlatformService) {}

  // prettier-ignore
  transform(value: string, options: Partial<SanitizerPipe['options']> = this.optionsDefault): SafeValue | null {
    this.options = {
      ...this.optionsDefault,
      ...options
    } as SanitizerPipe['options'];

    if (this.platformService.isBrowser()) {
      const configuration: Config = {
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'target']
      };

      value = String(DOMPurify.sanitize(value, configuration));

      const res = this.bypassSecurityTrust(value) as any;

      res.changingThisBreaksApplicationSecurity.replace(/href/g, 'target="_blank" href')

      return res;
    }

    return null;
  }

  private bypassSecurityTrust(value: string): SafeValue | null {
    switch (this.options?.context) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resource-url':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error('Invalid security context specified: ' + this.options?.context);
    }
  }
}
