/** @format */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transform'
})
export class TransformPipe implements PipeTransform {
  transform(value: any, transform: any): any {
    if (!value || !transform) {
      return value;
    }

    return transform(value);
  }
}
