const Crisp = new function () {
  const instance = this;

  this.getCrisp = () => {
    return new Promise((resolve, reject) => {
      const d = document;
      const w = window;

      const init = () => {
        const crisp = window.$crisp || null;

        if (crisp) {
          resolve(crisp);
        } else {
          reject('Error: "Not found instance Crisp"');
        }
      }

      if (d.readyState === 'complete') {
        init();
      } else {
        if (w.attachEvent) {
          w.attachEvent('onload', init);
        } else {
          w.addEventListener('load', init, false);
        }
      }
    });
  }

  return function () {
    return instance;
  }
}

let crisp = new Crisp;

export default crisp;
