<!-- @format -->

<ng-container>
	<div appDropdown (toggled)="teamMemberList.length && onToggle($event)">
		<div
			class="inline-flex items-center justify-start space-x-4 group max-w-full"
			[ngClass]="{'cursor-pointer': teamMemberList.length}"
			slot="target"
			#target
		>
			<app-avatar
				*ngIf="progressUser"
				class="block"
				appSize="56px"
				[appTextClass]="'text-headline-6 font-semibold text-white'"
				[appUser]="progressUser"
			></app-avatar>
			<div class="flex items-center space-x-2 w-full">
				<!-- prettier-ignore -->
				<div
					[@routingDashboardAnimation]="false"
					class="flex text-headline-3 text-blue-grey-900"
					[ngClass]="{ 'text-blue-grey-800': toggle, 'group-hover:text-blue-grey-800': teamMemberList.length }"
				>
					<span class="block truncate w-auto">
						{{ progressUser?.name }} {{ progressUser?.surname }}
					</span>
					<span
						*ngIf="user?.id === progressUser?.id"
						class="block"
						i18n="Дропдаун тиммейтов|Своя траектория: (Вы)@@app-dropdown-track.my"
					>
						(Вы)
					</span>
				</div>
				<figure
					*ngIf="teamMemberList.length"
					class="flex items-center justify-center text-blue-grey-900 group-hover:text-blue-grey-800 w-8 h-8 mt-2"
					[ngClass]="{'text-blue-grey-800': toggle}"
					[@rotateAnimation]="toggle"
				>
					<span
						class="block -rotate-90"
						appSvgIcon
						[appIcon]="'arrow-back-2'"
						[appSquare]="16"
						[appViewBox]="'0 0 7 10'"
					></span>
				</figure>
			</div>
		</div>
		<div slot="content" *ngIf="teamMemberList.length">
			<div
				class="bg-white rounded-lg effect-00dp px-4 py-2 w-[336px] max-h-[432px] overflow-y-auto translate-x-0 translate-y-4"
				[style]="'--tw-translate-x: ' + translateX + 'px;'"
			>
				<ul class="space-y-4">
					<li *ngFor="let teamMember of teamMemberList">
						<!-- prettier-ignore -->
						<a
							class="flex items-center space-x-2 group"
							[routerLink]="['/progress/', user?.team_member_id === teamMember.id ? 'my' : teamMember.id, 'dashboard']"
						>
							<app-avatar class="block" [appUser]="teamMember.user"></app-avatar>
							<span
								class="block truncate text-body-2 text-blue-grey-900 group-hover:text-primary-A400"
							>
								{{ teamMember.user.name }} {{ teamMember.user.surname }}
							</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</ng-container>
