/** @format */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG, Configuration } from './app/core';
import { APP_VERSION } from './app/core/services/app-version.service';

function bootstrap() {
  Promise.all([
    fetch('./assets/configurations/config.json'),
    fetch('./assets/configurations/version.json')
  ])
    .then(([config, version]: [Response, Response]) => Promise.all([config.json(), version.json()]))
    .then((response: any) => {
      const [configResponse, versionResponse] = response;

      const configuration: Configuration = JSON.parse(atob(configResponse.configuration));
      const version: string = versionResponse.version;

      if (configuration.production) {
        enableProdMode();
      }

      const appConfigProvider: any = {
        provide: APP_CONFIG,
        useValue: configuration
      };

      const appVersionProvider: any = {
        provide: APP_VERSION,
        useValue: version
      };

      platformBrowserDynamic([appConfigProvider, appVersionProvider])
        .bootstrapModule(AppModule)
        .catch(err => console.error(err));
    });
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
