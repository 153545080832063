/** @format */

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { PlatformService } from '../../../core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { overlayAnimation } from '../../../app-animations';

@Component({
  selector: 'app-overlay, [appOverlay]',
  templateUrl: 'overlay.component.html',
  animations: [overlayAnimation]
})
export class OverlayComponent implements OnInit, OnDestroy {
  @Output() clicked = new EventEmitter<boolean>();

  @HostListener('document:keyup.esc')
  onEsc(keyboardEvent: KeyboardEvent): void {
    !this.disableClick && this.state$.next(false);
  }

  @Input()
  set appState(state: boolean) {
    this.state$.next(state);
  }

  @Input()
  set appClassList(classList: string[]) {
    this.classList = classList;
  }

  @Input()
  set appDisableClick(disableClick: boolean) {
    this.disableClick = disableClick;
  }

  state$ = new BehaviorSubject<boolean>(true);
  stateSubscription$!: Subscription;

  classList: string[] = [];

  disableClick!: boolean;

  constructor(private platformService: PlatformService) {}

  ngOnInit(): void {
    this.stateSubscription$ = this.state$
      .pipe(
        debounceTime(10),
        tap((state: boolean) => this.clicked.emit(state))
      )
      .subscribe({
        next: (state: boolean) => {
          this.platformService.setScrollToggle(state);
          this.platformService.isShownOverlay.next(state);
        },
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    this.stateSubscription$.unsubscribe();

    this.platformService.setScrollToggle(false);
    this.platformService.isShownOverlay.next(false);
  }

  onClick(mouseEvent: MouseEvent): void {
    !this.disableClick && this.state$.next(mouseEvent.target !== mouseEvent.currentTarget);
  }
}
