<!-- @format -->

<section>
	<app-overlay
		[appState]="state"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && onClose($event)"
	>
		<div class="relative w-[512px] bg-white rounded-lg p-10 pb-8">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="onClose(false)"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div class="mb-8">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-4"
          i18n="Просмотр траектории|Заголовок: Завершить работу над траекторией@@app-trajectory-overlay-publish.work-complete-title"
        >
					Завершить работу над траекторией
				</span>
				<!-- prettier-ignore -->
				<p
          class="text-body-1"
          i18n="Просмотр навыка|Параграф: Кажется, вы собираетесь закончить работу над этой тракеторией. Если все готово, нажмите «Готово». Если вы хотите продолжить работу над траекторией, нажмите «Отмена».@@app-trajectory-overlay-publish.work-complete-paragraph"
        >
          Кажется, вы собираетесь закончить работу над этой тракеторией. Если все готово, нажмите «Готово». Если вы хотите продолжить работу над траекторией, нажмите «Отмена».
        </p>
			</div>
			<div class="flex items-center justify-between">
				<button class="btn btn-gray outline md" (click)="onClose(false)">
					<figure class="relative -ml-1">
						<i class="absolute" appSvgIcon appIcon="cross" appSquare="24"></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Просмотр траектории|Кнопка: Отмена@@app-trajectory-overlay-publish.work-complete-button-cancel"
          >
						Отмена
					</span>
				</button>
				<button
					class="btn btn-primary inline md"
					[disabled]="trajectoryIsSubmitting"
					(click)="onPublishTrajectory()"
				>
					<figure class="relative -ml-1">
						<i class="absolute text-white" appSvgIcon appIcon="done" appSquare="24"></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Просмотр траектории|Кнопка: Готово@@app-trajectory-overlay-publish.work-complete-button-submit"
          >
						Готово
					</span>
				</button>
			</div>
		</div>
	</app-overlay>
</section>
