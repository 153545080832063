/** @format */

import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { filter, Subscription } from 'rxjs';
import { User, UserService } from '../../core';
import { tap } from 'rxjs/operators';

@Directive({
  selector: '[appRole]'
})
export class AppRoleDirective implements OnInit, OnDestroy {
  @Input()
  set appRole(role: string[]) {
    this.role = role;
  }

  user$!: Subscription;

  role: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private userService: UserService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser
      .pipe(
        tap(() => this.viewContainerRef.clear()),
        filter((user: User) => !!user)
      )
      .subscribe({
        next: (user: User) => {
          this.role.includes(user.role_key)
            ? this.viewContainerRef.createEmbeddedView(this.templateRef)
            : this.viewContainerRef.clear();
        },
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    [this.user$].filter($ => $).forEach($ => $.unsubscribe());
  }
}
