/**
 * Marker Tool for the Editor.js
 *
 * Allows to wrap inline fragment and style it somehow.
 */
class Marker {
  /**
   * Class name for term-tag
   *
   * @type {string}
   */
  static get CSS() {
    return "cdx-marker"
  }

  /**
   * @param {{api: object}}  - Editor.js API
   */
  constructor({ api }) {
    this.api = api

    /**
     * Toolbar Button
     *
     * @type {HTMLElement|null}
     */
    this.button = null

    /**
     * Tag represented the term
     *
     * @type {string}
     */
    this.tag = "MARK"

    /**
     * CSS classes
     */
    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive,
    }
  }

  /**
   * Specifies Tool as Inline Toolbar Tool
   *
   * @return {boolean}
   */
  static get isInline() {
    return true
  }

  /**
   * Create button element for Toolbar
   *
   * @return {HTMLElement}
   */
  render() {
    this.button = document.createElement("button")
    this.button.type = "button"
    this.button.classList.add(this.iconClasses.base)
    this.button.innerHTML = this.toolboxIcon

    return this.button
  }

  /**
   * Wrap/Unwrap selected fragment
   *
   * @param {Range} range - selected fragment
   */
  surround(range) {
    if (!range) {
      return
    }

    /** <KW */
    let parentNode = window.getSelection().focusNode

    while (parentNode.parentNode.nodeName !== "DIV") {
      parentNode = parentNode.parentNode
    }

    const termWrapper =
      (parentNode.querySelector &&
        parentNode.querySelector("mark." + Marker.CSS)) ||
      this.api.selection.findParentTag(this.tag, Marker.CSS)
    /** </KW */
    // let termWrapper = this.api.selection.findParentTag(this.tag, Marker.CSS)
    /**
     * If start or end of selection is in the highlighted block
     */
    if (termWrapper) {
      this.unwrap(termWrapper)
    } else {
      this.wrap(range)
    }
  }

  /**
   * Wrap selection with term-tag
   *
   * @param {Range} range - selected fragment
   */
  wrap(range) {
    /**
     * Create a wrapper for highlighting
     */
    let marker = document.createElement(this.tag)

    marker.classList.add(Marker.CSS)

    /**
     * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
     *
     * // range.surroundContents(span);
     */
    marker.appendChild(range.extractContents())
    range.insertNode(marker)

    /**
     * Expand (add) selection to highlighted block
     */
    this.api.selection.expandToTag(marker)
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrap(termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper)

    let sel = window.getSelection()
    let range = sel.getRangeAt(0)

    let unwrappedContent = range.extractContents()

    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper)

    /**
     * Insert extracted content
     */
    range.insertNode(unwrappedContent)

    /**
     * Restore selection
     */
    sel.removeAllRanges()
    sel.addRange(range)
  }

  /**
   * Check and change Term's state for current selection
   */
  checkState() {
    // const termTag = this.api.selection.findParentTag(this.tag, Marker.CSS)

    /** <KW */
    let parentNode = window.getSelection().anchorNode

    while (parentNode.parentNode.nodeName !== "DIV") {
      parentNode = parentNode.parentNode
    }

    const termTag =
      (parentNode.querySelector &&
        parentNode.querySelector("mark." + Marker.CSS)) ||
      this.api.selection.findParentTag(this.tag, Marker.CSS)
    /** </KW */

    this.button.classList.toggle(this.iconClasses.active, !!termTag)
  }

  /**
   * Get Tool icon's SVG
   * @return {string}
   */
  /** kw */
  get toolboxIcon() {
    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="1 2 13 12" width="13" height="12"><path d="M8.367 9.633L10.7 10.98l-.624 1.135-.787-.025-.78 1.35H6.94l1.193-2.066-.407-.62.642-1.121zm.436-.763l2.899-5.061a1.278 1.278 0 011.746-.472c.617.355.835 1.138.492 1.76l-2.815 5.114-2.322-1.34zM2.62 11.644H5.39a.899.899 0 110 1.798H2.619a.899.899 0 010-1.798z"/></svg>'
  }

  /**
   * Sanitizer rule
   * @return {{mark: {class: string}}}
   */
  static get sanitize() {
    return {
      mark: {
        class: Marker.CSS,
      },
    }
  }
}

module.exports = Marker
