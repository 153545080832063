/** @format */

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PlatformService } from '../../core';

@Directive({
  selector: '[appLocale]'
})
export class AppLocaleDirective implements OnInit {
  @Input()
  set appLocale(locale: string[]) {
    this.locale = locale;
  }

  locale: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private platformService: PlatformService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    if (this.locale.includes(this.platformService.getLocale())) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
