/** @format */

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { rotateAnimation, routingDashboardAnimation } from '../../../../app-animations';
import { TeamMember, User, UserService } from '../../../../core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dropdown-track',
  templateUrl: 'dropdown-track.component.html',
  animations: [rotateAnimation, routingDashboardAnimation]
})
export class DropdownTrackComponent implements OnInit, OnDestroy {
  @ViewChild('target') target?: ElementRef;

  @Input()
  set appProgressUser(progressUser: User) {
    this.progressUser = progressUser;
  }

  @Input()
  set appTeamMemberList(teamMemberList: TeamMember[]) {
    this.teamMemberList = teamMemberList.filter(
      teamMember => teamMember.user.id !== this.progressUser?.id
    );
  }

  user$?: Subscription;
  user?: User;
  progressUser?: User;

  teamMemberList: TeamMember[] = [];

  translateX?: number;

  toggle = false;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => (this.user = user),
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.user$].forEach($ => $?.unsubscribe());
  }

  onToggle(event: boolean): void {
    this.toggle = event;

    // prettier-ignore
    if (this.toggle) {
      const { top, left, height, width } = this.target?.nativeElement.getBoundingClientRect();

      /** 336 - is width of drop-down list */
      this.translateX = Math.floor(width - 336);
    }
  }
}
