/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Skill, SkillService } from '../../../../../core';
import { delay, of, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-skill-overlay-next',
  templateUrl: './next.component.html'
})
export class SkillOverlayNextComponent implements OnInit, OnDestroy {
  state!: boolean;
  state$!: Subscription;

  nextSkill?: Skill;

  constructor(private skillService: SkillService, private router: Router) {}

  ngOnInit(): void {
    this.state$ = this.skillService.skillNextModalToggle
      .pipe(tap(() => (this.nextSkill = this.skillService.skillNextModalPayload)))
      .subscribe({
        next: (state: boolean) => (this.state = state),
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    [this.state$].forEach($ => $?.unsubscribe());
  }

  onClose(toggle: boolean): void {
    this.skillService.skillNextModalPayload = undefined;
    this.skillService.skillNextModalToggle.next(toggle);
  }

  onRedirect(): void {
    const id: string = String(this.nextSkill?.id);

    this.onClose(false);

    /** Await modal close, avoid router animation glitches  */

    // prettier-ignore
    of(true)
      .pipe(first(), delay(300))
      .subscribe({
        next: () => this.router.navigate(['/library/skills/' + id]).then(() => console.debug('Route changed'))
      });
  }
}
