/** @format */

import { Component, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-hint, [appHint]',
  templateUrl: './hint.component.html'
})
export class HintComponent {
  @HostListener('document:mousemove', ['$event', '$event.target'])
  onHover(event: MouseEvent, targetElement: HTMLElement) {
    this.hintShow = targetElement && this.elementRef.nativeElement.contains(targetElement);
  }

  @Input()
  set appPosition(position: string) {
    this.hintClassList = this.getClassList(position);
  }

  hintShow = false;
  hintClassList: string | string[] = '';

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private elementRef: ElementRef
  ) {}

  getClassList(position: string): string {
    switch (position) {
      case 'top': {
        return 'left-1/2 -top-2 -translate-x-1/2 -translate-y-full';
      }
      case 'right': {
        return 'top-1/2 -right-2 translate-x-full -translate-y-1/2';
      }
      case 'bottom': {
        return 'left-1/2 -bottom-2 -translate-x-1/2 translate-y-full';
      }
      case 'left': {
        return 'top-1/2 -left-2 -translate-x-full -translate-y-1/2';
      }
      default: {
        return 'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2';
      }
    }
  }
}
