/** @format */

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SnackbarService, UserService } from '../../services';
import { first, switchMap } from 'rxjs/operators';
import { User } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class LeadGuardActivate implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private snackbarService: SnackbarService
  ) {}

  canActivate(): Observable<boolean> {
    return this.userService.currentUser.pipe(
      first(),
      switchMap((user: User) => {
        if (user.role_key === 'teammate') {
          this.router
            .navigate(['/progress'])
            .then(() =>
              this.snackbarService.info(
                $localize`:Снекбар|Сообщение - Нет доступа@@app-snackbar.no-access:Нет доступа`
              )
            );

          return of(false);
        }

        return of(true);
      })
    );
  }
}
