/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Subscription } from 'rxjs';
import { Crisp, TariffService } from '../../../../core';
import { TariffList } from '../../../../core/models/tariff.model';
import { Location } from '@angular/common';
import { Event as RouterEvent, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-overlay-tariff',
  templateUrl: './overlay-tariff.component.html'
})
export class OverlayTariffComponent implements OnInit, OnDestroy {
  routeEvents$!: Subscription;

  state!: boolean;
  state$!: Subscription;
  stateClassList: string[] = [];

  tariffList: TariffList | undefined;
  tariffList$!: Subscription;
  tariffListColor: any[] = [
    {
      border: 'border border-blue-grey-50',
      borderBottom: 'border-b border-blue-grey-50',
      background: 'bg-blue-grey-300'
    },
    {
      border: 'border border-primary-300',
      borderBottom: 'border-b border-primary-300',
      background: 'bg-primary-300'
    },
    {
      border: 'border-2 border-primary-A400',
      borderBottom: 'border-b-2 border-primary-A400',
      background: 'bg-primary-A400'
    }
  ];

  constructor(
    private tariffService: TariffService,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.state$ = this.tariffService.tariffListModalToggle.subscribe({
      next: (state: boolean) => (this.state = state),
      error: (error: any) => console.error(error)
    });

    this.tariffList$ = this.tariffService.tariffList$.subscribe({
      next: (tariffList: TariffList | undefined) => {
        this.tariffList = tariffList;

        /** Push current tariff in the begging of array */

        this.tariffList?.tariffs.unshift({
          title: this.tariffList?.current.title,
          key: this.tariffList?.current.key,
          members_limit: this.tariffList?.current.member_limit,
          skills_limit: this.tariffList?.current.skills_limit,
          space_limit: this.tariffList?.current.space_limit,
          premium_support: this.tariffList?.current.premium_support,
          month_price: this.tariffList?.current.month_price
        });
      },
      error: (error: any) => console.error(error)
    });

    this.routeEvents$ = this.router.events
      .pipe(filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd))
      .subscribe({
        next: () => {
          /** Apply overlay color depends on location */

          const pathList: string[] = [
            '/library/trajectories',
            '/library/skills',
            '/library/drafts'
          ];

          if (pathList.some((path: string) => this.location.path().endsWith(path))) {
            this.stateClassList = ['overlay-gray'];
          } else {
            this.stateClassList = [];
          }
        }
      });
  }

  ngOnDestroy(): void {
    [this.state$, this.tariffList$, this.routeEvents$].forEach($ => $?.unsubscribe());
  }

  onClose(toggle: boolean): void {
    this.tariffService.tariffListModalToggle.next(toggle);
  }

  onGotoPremium(): void {
    // prettier-ignore
    Crisp.default.getCrisp().then(($crisp: any) => {
      $crisp.push(['set', 'message:text', [$localize`:Крисп|Сообщение - Привет, хочу перейти на платный тариф!@@app-crisp.want-goto-premium:Привет, хочу перейти на платный тариф!`]]);
      $crisp.push(['do', 'chat:open']);
    });

    this.onClose(false);
  }
}
