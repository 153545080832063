<!-- @format -->

<section>
	<app-overlay
		*ngIf="!!tariffList && !!tariffList.tariffs.length"
		[appState]="state"
		[appClassList]="stateClassList"
		(clicked)="!$event && onClose($event)"
	>
		<div
			class="relative max-w-[688px] mobile:min-w-full w-full bg-white rounded-lg p-10 pb-8"
		>
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="onClose(false)"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<!-- prettier-ignore -->
			<span
        class="block text-headline-4 mb-4"
        i18n="Тарифы|Заголовок: Перейдите на платный тариф@@app-overlay-tariff.tariff-title"
      >
				Перейдите на платный тариф
			</span>
			<!-- prettier-ignore -->
			<p
        class="text-body-1 mb-4"
        i18n="Тарифы|Параграф: Перейдите на платный тариф, чтобы получить больше возможностей. Далее откроется чат, в котором можно будет пройти процедуру оплаты.@@app-overlay-tariff.tariff-paragraph"
      >
        Перейдите на платный тариф, чтобы получить больше возможностей. Далее откроется чат, в котором можно будет пройти процедуру оплаты.
      </p>
			<ul class="flex justify-between space-x-4 mb-8">
				<!-- prettier-ignore -->
				<li
					class="flex flex-col flex-1 rounded-lg justify-between overflow-hidden"
					[ngClass]="tariffListColor[i].border"
					*ngFor="let tariff of tariffList.tariffs; index as i"
				>
					<div
						class="text-headline-5 text-white text-center py-3"
						[ngClass]="tariffListColor[i].background"
					>
						{{ tariff.title }}
					</div>
					<div
						class="flex flex-col items-center py-4"
						[ngClass]="tariffListColor[i].borderBottom"
					>
						<span
							class="text-body-2 text-blue-grey-700 text-center"
							i18n="Тарифы|Текст: Члены команды@@app-overlay-tariff.tariff-members-limit"
						>
							Члены команды
						</span>
						<div class="block h-6" [ngSwitch]="!!tariff.members_limit">
							<span class="text-subtitle-1 text-blue-grey-900" *ngSwitchCase="true">
								{{ tariff.members_limit || 0 | mask: "separator.0" }}
								<!-- prettier-ignore -->
								<span i18n="Тарифы|Текст: человек@@app-overlay-tariff.tariff-members-limit-unit">
                  { tariff.members_limit, plural, =0 {человек} =1 {человек} =2 {человека} few {человека} many {человек} other {человек} }
                </span>
							</span>
							<i
								*ngSwitchDefault
								class="text-blue-grey-900"
								appSvgIcon
								appIcon="endless-2"
								appWidth="35"
								appHeight="14"
								appViewBox="0 0 98 42"
							></i>
						</div>
					</div>
					<div
						class="flex flex-col items-center py-4"
						[ngClass]="tariffListColor[i].borderBottom"
					>
						<span
              class="text-body-2 text-blue-grey-700 text-center"
              i18n="Тарифы|Текст: Количество навыков@@app-overlay-tariff.tariff-skills-limit"
            >
              Количество навыков
            </span>
						<div class="block h-6" [ngSwitch]="!!tariff.skills_limit">
							<span class="text-subtitle-1 text-blue-grey-900" *ngSwitchCase="true">
								{{ tariff.skills_limit || 0 | mask: "separator.0" }}
								<!-- prettier-ignore -->
								<span i18n="Тарифы|Текст: шт@@app-overlay-tariff.tariff-skills-limit-unit">шт</span>
							</span>
							<i
								*ngSwitchDefault
								class="text-blue-grey-900"
								appSvgIcon
								appIcon="endless-2"
								appWidth="35"
								appHeight="14"
								appViewBox="0 0 98 42"
							></i>
						</div>
					</div>
					<div
						class="flex flex-col items-center py-4"
						[ngClass]="tariffListColor[i].borderBottom"
					>
						<span
              class="text-body-2 text-blue-grey-700 text-center"
              i18n="Тарифы|Текст: Доступно для хранения файлов@@app-overlay-tariff.tariff-space-limit"
            >
							Доступно для хранения файлов
						</span>
						<div class="block h-6" [ngSwitch]="!!tariff.space_limit">
							<span class="text-subtitle-1 text-blue-grey-900" *ngSwitchCase="true">
								{{ tariff.space_limit || 0 | mask: "separator.0" }}
								<!-- prettier-ignore -->
								<span i18n="Тарифы|Текст: ГБ@@app-overlay-tariff.tariff-space-limit-unit">ГБ</span>
							</span>
							<i
								*ngSwitchDefault
								class="text-blue-grey-900"
								appSvgIcon
								appIcon="endless-2"
								appWidth="35"
								appHeight="14"
								appViewBox="0 0 98 42"
							></i>
						</div>
					</div>
					<div class="flex flex-col items-center py-4">
						<!-- prettier-ignore -->
						<span
              class="text-body-2 text-blue-grey-700 text-center"
              i18n="Тарифы|Текст: Поддержка@@app-overlay-tariff.tariff-support"
            >
              Поддержка
            </span>
						<ng-container [ngSwitch]="!!tariff.premium_support">
							<!-- prettier-ignore -->
							<span
								*ngSwitchCase="false"
								class="text-subtitle-1 text-blue-grey-900"
								i18n="Тарифы|Текст: сообществом Dabster@@app-overlay-tariff.tariff-support-false"
							>
								сообществом Dabster
							</span>
							<!-- prettier-ignore -->
							<span
								*ngSwitchCase="true"
								class="text-subtitle-1 text-blue-grey-900"
								i18n="Тарифы|Текст: тех.специалистами@@app-overlay-tariff.tariff-support-true"
							>
								тех.специалистами
							</span>
						</ng-container>
					</div>
					<div
						class="flex items-center justify-center bg-blue-grey-50 py-3"
						[ngSwitch]="!!tariff.month_price"
					>
						<ng-container *ngSwitchCase="true">
							<i
								class="text-blue-grey-900"
								appSvgIcon
								appIcon="dollar"
								appSquare="24"
							></i>
							<span class="text-headline-6 text-blue-grey-900">
								{{ tariff.month_price || 0 | mask: "separator.0" }}
								<!-- prettier-ignore -->
								<span i18n="Тарифы|Текст: N/мес.@@app-overlay-tariff.tariff-month">/мес.</span>
							</span>
						</ng-container>
						<ng-container *ngSwitchDefault>
							<i
								class="text-blue-grey-900"
								appSvgIcon
								appIcon="dollar-strikethrough"
								appWidth="24"
								appHeight="25"
								appViewBox="0 0 24 25"
							></i>
							<!-- prettier-ignore -->
							<span
                class="text-headline-6 text-blue-grey-900"
                i18n="Тарифы|Текст: бесплатно@@app-overlay-tariff.tariff-month-free"
              >
                бесплатно
              </span>
						</ng-container>
					</div>
				</li>
			</ul>
			<div class="flex items-center justify-between">
				<button class="btn btn-gray outline md" (click)="onClose(false)">
					<figure class="relative">
						<i class="absolute" appSvgIcon appIcon="cross" appSquare="24"></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Тарифы|Кнопка: Отмена@@app-overlay-tariff.tariff-cancel"
          >
            Отмена
          </span>
				</button>
				<button class="btn btn-primary inline md" (click)="onGotoPremium()">
					<figure class="relative">
						<i class="absolute" appSvgIcon appIcon="emoji-smile" appSquare="24"></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n=" Тарифы|Кнопка: Перейти на платный тариф@@app-overlay-tariff.tariff-set"
          >
						Перейти на платный тариф
					</span>
				</button>
			</div>
		</div>
	</app-overlay>
</section>
