/** @format */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService, UserService } from '../../core';
import { Title } from '@angular/platform-browser';

interface ForgotForm {
  email: FormControl<string | null>;
}

@Component({
  selector: 'app-auth-forgot',
  templateUrl: './auth-forgot.component.html'
})
export class AuthForgotComponent implements OnInit {
  forgotForm: FormGroup;
  forgotFormIsSubmitting = false;

  forgotMessage: string | null = null;

  constructor(
    private userService: UserService,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private title: Title
  ) {
    this.forgotForm = this.formBuilder.group<ForgotForm>({
      email: this.formBuilder.control('', [Validators.required, Validators.email])
    });
  }

  ngOnInit(): void {
    // prettier-ignore
    this.title.setTitle($localize`:Заголовок|Текст - Dabster | Изменить пароль@@app-title.app-auth-forgot:Dabster | Изменить пароль`);
  }

  onSubmitForm(): void {
    if (this.helperService.getFormValidation(this.forgotForm)) {
      this.forgotFormIsSubmitting = true;

      this.userService
        .postForgot({
          email: this.forgotForm.get('email')?.value
        })
        .subscribe({
          next: (response: any) => {
            this.forgotMessage = response.message;

            this.forgotFormIsSubmitting = false;
          },
          error: () => (this.forgotFormIsSubmitting = false)
        });
    }
  }
}
