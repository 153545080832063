<!-- @format -->

<ng-container *ngIf="isShownAside">
	<aside
		class="flex flex-col items-start justify-start bg-blue-grey-25 effect-00dp w-64 h-full"
		[@asideAnimation]="isShownAside"
		#asideElement
	>
		<ul class="block w-full">
			<li class="hidden tablet:block py-2 px-8">
				<app-header-logo></app-header-logo>
			</li>
			<ng-container [ngSwitch]="myTeamList.length > 1">
				<li
					*ngSwitchCase="true"
					class="block border-b border-blue-grey-50 py-6 px-8"
					appDropdown
				>
					<div
						class="flex items-center justify-between space-x-2 group cursor-pointer"
						slot="target"
					>
						<div class="flex items-center justify-start w-full">
							<app-avatar-team class="block mr-2" [appTeam]="user.team"></app-avatar-team>
							<span
								class="block text-body-2 text-blue-grey-900 group-hover:text-primary-A400 truncate max-w-[140px]"
							>
								{{ user.team?.name }}
							</span>
						</div>
						<i
							class="block text-primary-A400"
							appSvgIcon
							appIcon="ellipsis"
							appWidth="20"
							appHeight="20"
							appViewBox="0 0 20 20"
						></i>
					</div>
					<div
						class="block bg-white effect-00dp rounded-md -translate-x-4 -translate-y-10 w-[224px]"
						slot="content"
					>
						<div
							class="flex items-center justify-start border-b border-blue-grey-50 py-2 px-4"
						>
							<app-avatar-team class="block mr-2" [appTeam]="user.team"></app-avatar-team>
							<span
								class="block text-body-2 text-blue-grey-900 hover:text-primary-A400 cursor-pointer truncate"
							>
								{{ user.team?.name }}
							</span>
						</div>
						<div class="block py-1 pr-1">
							<ul class="block max-h-[420px] overflow-auto">
								<ng-container *ngFor="let myTeam of myTeamList">
									<li
										class="flex items-center justify-start group cursor-pointer py-2 px-4"
										*ngIf="myTeam.team.id !== user.team?.id"
										(click)="onTeamSelect(myTeam.team)"
									>
										<app-avatar-team
											class="block mr-2"
											[appTeam]="myTeam.team"
										></app-avatar-team>
										<span
											class="block text-body-2 text-blue-grey-900 group-hover:text-primary-A400 truncate"
										>
											{{ myTeam.team.name }}
										</span>
									</li>
								</ng-container>
							</ul>
						</div>
					</div>
				</li>
				<li *ngSwitchCase="false" class="block border-b border-blue-grey-50 py-6 px-8">
					<div class="flex items-center justify-start w-full">
						<app-avatar-team class="block mr-2" [appTeam]="user.team"></app-avatar-team>
						<span
							class="block text-body-2 text-blue-grey-900 group-hover:text-primary-A400 truncate max-w-[152px]"
						>
							{{ user.team?.name }}
						</span>
					</div>
				</li>
			</ng-container>
			<li class="block py-3 px-8">
				<!-- prettier-ignore -->
				<button
					class="flex items-center justify-start space-x-4 text-subtitle-2"
          data-cy="progress"
          data-pw="button-aside-progress"
          [ngClass]="locationPath.includes('/progress/my') ? 'text-primary-A400' : 'text-blue-grey-400 hover:text-blue-grey-600'"
					(click)="onNavigate('/progress')"
				>
					<i appSvgIcon appIcon="insights" appSquare="24"></i>
					<span class="block" i18n="Сайдбар|Ссылка: Мой прогресс@@app-aside.my-progress">
						Мой прогресс
					</span>
				</button>
			</li>
			<li class="block py-3 px-8">
				<!-- prettier-ignore -->
				<button
          class="flex items-center justify-start space-x-4 text-subtitle-2"
          data-cy="library"
          data-pw="button-aside-library"
					[ngClass]="locationPath.includes('/library')	? 'text-primary-A400'	: 'text-blue-grey-400 hover:text-blue-grey-600'"
					(click)="onNavigate('/library')"
				>
					<i appSvgIcon appIcon="grid-view" appSquare="24"></i>
					<span
						class="block"
						i18n="Сайдбар|Ссылка: Библиотека навыков@@app-aside.library"
					>
						Библиотека навыков
					</span>
				</button>
			</li>
			<ng-container *appRole="['teamlead', 'owner']">
				<li class="block py-3 px-8">
					<!-- prettier-ignore -->
					<button
						class="flex items-center justify-start space-x-4 text-subtitle-2"
            data-cy="team"
            data-pw="button-aside-team"
            [ngClass]="locationPath.includes('/team')	? 'text-primary-A400'	: 'text-blue-grey-400 hover:text-blue-grey-600'"
						(click)="onNavigate('/team')"
					>
						<i
							appSvgIcon
							appIcon="group-add"
							appViewBox="0 0 24 13"
							appWidth="24"
							appHeight="13"
						></i>
						<span class="block" i18n="Сайдбар|Ссылка: Моя команда@@app-aside.my-team">
							Моя команда
						</span>
					</button>
				</li>
			</ng-container>
		</ul>
	</aside>
	<div
		class="hidden tablet:block absolute left-full top-0 w-screen h-full bg-black opacity-10"
		(click)="setAsideIsShow()"
		[@fadeAsideAnimation]="isShownAside"
	></div>
</ng-container>
