<!-- @format -->

<ng-container>
	<app-overlay
		[appState]="markdownIsShowed"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && onClose()"
	>
		<div
			class="relative flex flex-col max-w-[688px] max-h-[688px] w-full bg-white rounded-lg p-10 pb-8"
		>
			<button class="btn btn-gray transparent absolute top-5 right-5" (click)="onClose()">
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div
				class="text-headline-4 mb-4"
				i18n="Обновления|Заголовок: Мы обновились@@app-release-notes.title"
			>
				Мы обновились!
			</div>
			<!-- prettier-ignore -->
			<div
				class="text-body-1 border-b border-blue-grey-50 pb-4 mb-4"
				i18n="Обновления|Заголовок: Рассказываем, что нового появилось на сервисе@@app-release-notes.description">
				Рассказываем, что нового появилось на сервисе
			</div>
			<div
				class="release-notes overflow-auto text-body-1 pr-4"
				[innerHtml]="markdown | sanitizer: {context: 'html'}"
			></div>
		</div>
	</app-overlay>
</ng-container>
