/** @format */

import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/** https://codereview.stackexchange.com/questions/139327/tooltip-overlay-following-the-mouse-pointer */

@Component({
  selector: 'app-hint-follow, [appHintFollow]',
  templateUrl: './hint-follow.component.html'
})
export class HintFollowComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT)
    private document: Document
  ) {}

  ngOnInit(): void {
    const styleSheet: any = this.document.styleSheets[0];

    // prettier-ignore
    const hoverIndex: number = styleSheet.insertRule('[data-hint-follow]:hover:after {}', styleSheet.cssRules.length);
    const cssHover: any = styleSheet.cssRules[hoverIndex];

    Array.from(this.document.querySelectorAll('[data-hint-follow]')).forEach((element: any) => {
      element.addEventListener('mousemove', (event: any) => {
        if (event.target.dataset['hintFollow'] === '') {
          cssHover.style.display = 'none';

          return;
        }

        cssHover.style.display = 'block';
        cssHover.style.left = event.clientX + 15 + 'px';
        cssHover.style.top = event.clientY + 15 + 'px';
      });
    });
  }
}
