<!-- @format -->

<div
	class="absolute right-0 bottom-0 flex flex-col space-y-5 items-end justify-end overflow-hidden pointer-events-none z-10"
	[ngClass]="(isShownFooter$ | async) ? 'px-8 pt-8 pb-20' : 'p-8'"
	*ngIf="!!snackbarList.length"
>
	<div
		class="bg-blue-grey-900 overflow-hidden rounded-md pointer-events-auto w-96"
		*ngFor="let snack of snackbarList"
		[@snackAnimation]
	>
		<div class="flex items-start justify-start gap-x-3 p-4">
			<ng-container *ngIf="snack.options?.icon as icon" [ngSwitch]="icon">
				<i
					class="text-secondary-600"
					*ngSwitchCase="'done'"
					appSvgIcon
					[appIcon]="icon"
					appSquare="24"
				></i>
				<i
					class="text-primary-A400"
					*ngSwitchCase="'bolt'"
					appSvgIcon
					[appIcon]="icon"
					appWidth="24"
					appHeight="24"
					appViewBox="0 0 16 16"
				></i>
				<i
					class="text-tertiary-A400 transform rotate-180"
					*ngSwitchCase="'attention'"
					appSvgIcon
					[appIcon]="icon"
					appSquare="24"
				></i>
			</ng-container>
			<div class="flex flex-wrap items-center justify-between gap-4 w-full my-0.5">
				<span class="block text-body-2 text-blue-grey-50">
					{{ snack.message }}
				</span>
				<ng-container *ngIf="snack.options?.button as button">
					<!-- prettier-ignore -->
					<button
						class="btn text-button-2 text-white p-0"
						type="button"
						(click)="onCallback(snack)"
					>
						<span class="block">{{ button.label }}</span>
					</button>
				</ng-container>
			</div>
		</div>
		<ng-container *ngIf="snack.options?.progress">
			<div class="flex items-center justify-start bg-blue-grey-600 h-1 w-full">
				<div
					class="snack-progress rounded-sm h-1 w-full"
					[ngClass]="snack.options?.progressClass || 'bg-tertiary-A400'"
					[style.--timeout]="snack.options?.timeout + 'ms'"
				></div>
			</div>
		</ng-container>
	</div>
</div>
