/** @format */

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HelperService, PlatformService } from '../../../../core';

@Component({
  selector: 'app-loader-http',
  templateUrl: './loader-http.component.html'
})
export class LoaderHttpComponent implements OnInit, OnDestroy {
  @Output() loaded = new EventEmitter<boolean>();

  router$!: Subscription;
  loader$!: Subscription;

  progress = 0;
  progressInterval$!: Subscription;
  progressBusy!: boolean;

  constructor(
    private router: Router,
    private platformService: PlatformService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.loader$ = this.platformService.isShownHttp.subscribe({
      next: (isShownHttp: boolean) => {
        switch (true) {
          case isShownHttp && !this.progressBusy:
            this.onProgressStart();
            break;
          case !isShownHttp:
            this.onProgressEnd();
            break;
        }
      },
      error: (error: any) => console.error(error)
    });

    this.router$ = this.router.events
      .pipe(filter(e => e instanceof NavigationStart || e instanceof NavigationEnd))
      .subscribe({
        next: navigation => {
          switch (true) {
            case navigation instanceof NavigationStart && !this.progressBusy:
              this.onProgressStart();
              break;
            case navigation instanceof NavigationEnd:
              this.onProgressEnd();
              break;
          }
        },
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    [this.router$, this.loader$, this.progressInterval$].forEach($ => $?.unsubscribe());
  }

  onProgressStart(): void {
    this.progressBusy = true;

    this.loaded.emit(this.progressBusy);

    this.progressInterval$ = interval(100).subscribe({
      next: () => {
        const next = this.progress + this.helperService.getRandomInt();

        if (this.progress < 100) {
          this.progress = next;
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  onProgressEnd(): void {
    this.progressInterval$?.unsubscribe();

    this.progressBusy = false;
    this.progress = 0;

    this.loaded.emit(this.progressBusy);
  }
}
