/** @format */

import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  SkillPagination,
  User,
  Trajectory,
  TrajectoryPagination,
  TrajectoryAssigned,
  TrajectoryProgressPagination,
  TrajectoryProgressDetail,
  TrajectoryResult,
  Skill
} from '../models';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class TrajectoryService {
  /** Trajectory | TrajectoryProgressDetail */

  trajectory$ = new BehaviorSubject<any>({} as any);
  trajectoryIsEdit$ = new BehaviorSubject<boolean>(false);
  trajectoryIsProgress$ = new BehaviorSubject<boolean>(false);
  trajectoryIsResult$ = new BehaviorSubject<boolean>(false);

  trajectoryAddModalToggle = new BehaviorSubject<boolean>(false);

  trajectoryPublishModalToggle = new BehaviorSubject<boolean>(false);
  trajectoryPublishModalNavigationStartUrl!: string | undefined;

  trajectoryConfirmationModalOnPublish: EventEmitter<Trajectory | void> = new EventEmitter();

  trajectorySkillStorageName: string = 'appTrajectoryStorage';

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private snackbarService: SnackbarService
  ) {}

  getTrajectoryAll(params?: any): Observable<TrajectoryPagination> {
    return this.apiService.get('/v1/trajectories', params).pipe(map((data: any) => data.data));
  }

  getTrajectoryAllSkills(id: number, params?: any): Observable<SkillPagination> {
    return this.apiService
      .get(`/v1/trajectories/${id}/skills`, params)
      .pipe(map((data: any) => data.data));
  }

  getTrajectoryById(id: number): Observable<Trajectory> {
    return this.apiService.get(`/v1/trajectories/${id}`).pipe(map((data: any) => data.data));
  }

  createTrajectory(body: any): Observable<Trajectory> {
    return this.apiService
      .post('/v1/trajectories/create', body)
      .pipe(map((data: any) => data.data));
  }

  publishTrajectory(id: number, body: any): Observable<Trajectory> {
    return this.apiService
      .post(`/v1/trajectories/update/${id}`, body)
      .pipe(map((data: any) => data.data));
  }

  removeTrajectory(id: number): Observable<Trajectory> {
    return this.apiService
      .post(`/v1/trajectories/delete/${id}`)
      .pipe(map((data: any) => data.data));
  }

  setTrajectoryToProgress(id: number, body: any): Observable<TrajectoryAssigned> {
    return this.apiService
      .post(`/v1/trajectories/member/${id}/assignToMember`, body)
      .pipe(map((data: any) => data.data));
  }

  setTrajectoryRemoveProgress(id: number): Observable<any> {
    return this.apiService
      .post(`/v1/progress/trajectories/${id}/remove`)
      .pipe(map((data: any) => data.data));
  }

  getTrajectoryProgressAll(id: number, params?: any): Observable<TrajectoryProgressPagination> {
    return this.apiService
      .get(`/v1/progress/${id}/trajectories`, params)
      .pipe(map((data: any) => data.data));
  }

  getTrajectoryProgressById(id: number): Observable<TrajectoryProgressDetail> {
    return this.apiService
      .get(`/v1/progress/trajectories/${id}/show`)
      .pipe(map((data: any) => data.data));
  }

  getTrajectoryResultAll(id: number): Observable<TrajectoryResult[]> {
    return this.apiService
      .get(`/v1/results/${id}/trajectories`)
      .pipe(map((data: any) => data.data));
  }

  getTrajectoryResultById(memberId: number, trajectoryId: string): Observable<TrajectoryResult> {
    return this.apiService
      .get(`/v1/results/${memberId}/${trajectoryId}`)
      .pipe(map((data: any) => data.data));
  }

  trajectoryValidation(trajectory: Trajectory): boolean {
    const trajectoryTitleIsValid: boolean = ((): boolean => {
      const min: boolean = trajectory.title.length < 3;
      const max: boolean = trajectory.title.length > 255;

      if (min || max) {
        this.snackbarService.info(
          $localize`:Снекбар|Сообщение - Заголовок траектории должен быть от 3 до 255 символов@@app-snackbar.trajectory-create-title-length-error:Заголовок траектории должен быть от 3 до 255 символов`,
          {
            timeout: 6000,
            icon: 'attention'
          }
        );

        return false;
      }

      return true;
    })();

    return trajectoryTitleIsValid;
  }

  getTrajectoryStorage(): Trajectory | null {
    const user: User = this.userService.currentUserSubject.getValue();

    // prettier-ignore
    const storage: string | undefined = this.localStorageService.getItem(this.trajectorySkillStorageName + user.id);

    return !!storage ? JSON.parse(storage) : null;
  }

  setTrajectoryStorage(trajectory: Trajectory): void {
    const user: User = this.userService.currentUserSubject.getValue();

    // prettier-ignore
    this.localStorageService.setItem(this.trajectorySkillStorageName + user.id, JSON.stringify(trajectory));
  }

  getNextSkillOfTrajectory(id: string): Observable<Skill> {
    return this.apiService.get(`/v1/trajectories/next/${id}`).pipe(map((data: any) => data.data));
  }
}
