/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Progress,
  ProgressPagination,
  ProgressService,
  SkillTask,
  User,
  UserService
} from '../../../../core';
import { filter, Subscription, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer-progress',
  templateUrl: './footer-progress.component.html'
})
export class FooterProgressComponent implements OnInit, OnDestroy {
  user$!: Subscription;
  user!: User;

  progress!: Progress;
  progress$!: Subscription;

  progressList: Progress[] = [];
  progressListTotal!: number;
  progressActive!: Progress;
  progressActiveIndex!: number;

  estimatedDeadlineToggle: boolean = false;

  constructor(
    private progressService: ProgressService,
    private location: Location,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => (this.user = user),
      error: (error: any) => console.error(error)
    });

    this.progress$ = this.progressService.progress$
      .pipe(
        filter((progress: Progress) => !!Object.keys(progress).length),
        tap((progress: Progress) => (this.progress = progress)),
        switchMap(() => this.progressService.progressList$),
        // prettier-ignore
        filter((progressPagination: ProgressPagination) => !!Object.keys(progressPagination).length),
        tap((progressPagination: ProgressPagination) => {
          this.progressList = progressPagination.items;
          this.progressListTotal = progressPagination.total;
        })
      )
      .subscribe({
        // prettier-ignore
        next: () => {
          // @ts-ignore
          this.progressActive = this.progressList.find((progress: Progress) => progress.id === this.progress.id);

          this.progressActiveIndex = this.progressList.findIndex((progress: Progress) => progress.id === this.progress.id);
        },
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    [this.user$, this.progress$].forEach($ => $?.unsubscribe());
  }

  onNavigation(direction: string): void {
    const progressMap: any = {
      previous: this.progressList[this.progressActiveIndex - 1],
      next: this.progressList[this.progressActiveIndex + 1]
    };

    const progress: Progress = progressMap[direction];
    const path: string = this.location.path().replace(this.progress.id, progress.id);

    // prettier-ignore
    const skillTask: SkillTask | undefined = this.progress.skill.taskList.find((skillTask: SkillTask) => {
      return path.includes(skillTask.id);
    });

    /** Proper navigation to next progress/task */

    this.router
      .navigate([skillTask ? path.replace(skillTask.id, '') : path])
      .then(() => console.debug('Route changed'));
  }
}
