/** @format */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Subscription } from 'rxjs';
import { UserService } from '../../core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-auth-error',
  templateUrl: './auth-error.component.html'
})
export class AuthErrorComponent implements OnInit {
  routeParams$!: Subscription;

  statusCode!: number;
  statusCodeMap: number[] = [401, 403];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private title: Title
  ) {}

  ngOnInit(): void {
    // prettier-ignore
    this.title.setTitle($localize`:Заголовок|Текст - Dabster | У вас нет доступа@@app-title.app-auth-error:Dabster | У вас нет доступа`);

    this.routeParams$ = this.route.params.pipe(map((data: any) => data.status)).subscribe({
      next: (status: string) => {
        const statusCode = Number(status);

        if (!statusCode || !this.statusCodeMap.includes(statusCode)) {
          this.router.navigate(['/error/404']).then(() => console.debug('Route changed'));
        }

        this.statusCode = statusCode;
      },
      error: (error: any) => console.error(error)
    });
  }

  onLogout(): void {
    this.userService.postLogout({ flag: 'this' }).subscribe({
      next: () => this.router.navigate(['/auth/login']).then(() => console.debug('Route changed')),
      error: () => this.router.navigate(['/auth/login']).then(() => console.debug('Route changed'))
    });
  }
}
