/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import {
  MyTeam,
  Team,
  TeamInfo,
  TeamInvitation,
  TeamInvitationPagination,
  TeamMemberPagination
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  currentTeamSubject = new BehaviorSubject<MyTeam[]>([]);
  currentTeam = this.currentTeamSubject.asObservable().pipe(distinctUntilChanged());

  currentTeamMembersSubject = new BehaviorSubject<TeamMemberPagination>({} as TeamMemberPagination);
  currentTeamMembers = this.currentTeamMembersSubject.asObservable().pipe(distinctUntilChanged());

  // prettier-ignore
  currentTeamInvitationsSubject = new BehaviorSubject<TeamInvitationPagination>({} as TeamInvitationPagination);
  // prettier-ignore
  currentTeamInvitations = this.currentTeamInvitationsSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private apiService: ApiService) {}

  getTeamMembers(params?: any): Observable<TeamMemberPagination> {
    return this.apiService.get('/v1/teams/members', params).pipe(map((data: any) => data.data));
  }

  postCreateTeam(body: any): Observable<Team> {
    return this.apiService.post('/v1/teams/create', body).pipe(map((data: any) => data.data));
  }

  postChangeRole(id: number, body: any) {
    return this.apiService
      .post(`/v1/teams/members/${id}/role`, body)
      .pipe(map((data: any) => data.data));
  }

  postRemoveTeamMember(id: number) {
    return this.apiService
      .post(`/v1/teams/members/${id}/delete`)
      .pipe(map((data: any) => data.data));
  }

  getTeamInvitations(params?: any): Observable<TeamInvitationPagination> {
    return this.apiService.get('/v1/teams/invitations', params).pipe(map((data: any) => data.data));
  }

  postTeamInvitationCreate(body: any): Observable<TeamInvitation> {
    return this.apiService
      .post('/v1/teams/invitations/create', body)
      .pipe(map((data: any) => data.data));
  }

  postTeamInvitationEditRole(id: number, body: any): Observable<TeamInvitation> {
    return this.apiService
      .post(`/v1/invitations/${id}/editRole`, body)
      .pipe(map((data: any) => data.data));
  }

  postTeamInvitationDelete(id: number): Observable<{ message: string }> {
    return this.apiService.post(`/v1/invitations/${id}/delete`).pipe(map((data: any) => data.data));
  }

  postTeamInvitationsSend(): Observable<{ message: string }> {
    return this.apiService.post('/v1/teams/invitations/send').pipe(map((data: any) => data.data));
  }

  getInvitationInfo(token: string): Observable<{ onboarding: string; email: string }> {
    return this.apiService.get(`/v1/invitations/info/${token}`).pipe(map((data: any) => data.data));
  }

  getInvitationTeamInfo(token: string): Observable<TeamInfo> {
    return this.apiService
      .get(`/v1/invitations/teamInfo/${token}`)
      .pipe(map((data: any) => data.data));
  }

  getMyTeams(): Observable<MyTeam[]> {
    return this.apiService.get('/v1/teams/my').pipe(
      map((data: any) => data.data),
      tap((myTeam: MyTeam[]) => this.currentTeamSubject.next(myTeam))
    );
  }
}
