/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { TariffLimits, TariffList } from '../models/tariff.model';
import { ApiService } from './api.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TariffService {
  tariffList$ = new BehaviorSubject<TariffList | undefined>(undefined);
  tariffLimits$ = new BehaviorSubject<TariffLimits | undefined>(undefined);

  /** Tariff modal */

  tariffListModalToggle = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService) {}

  getTeamLimits(params?: any): Observable<TariffLimits> {
    return this.apiService.get('/v1/teams/limits', params).pipe(
      map((data: any) => data.data),
      tap((tariffLimits: TariffLimits) => this.tariffLimits$.next(tariffLimits))
    );
  }

  getTeamTariffs(params?: any): Observable<TariffList> {
    return this.apiService.get('/v1/teams/tariffs', params).pipe(
      map((data: any) => data.data),
      tap((tariffList: TariffList) => this.tariffList$.next(tariffList))
    );
  }
}
