<!-- @format -->

<div class="flex items-center justify-start">
	<button
		class="tablet:block hidden my-2 mr-5"
		*ngIf="isInTeam && isInTeamInProfile"
		(click)="setAsideIsShow()"
	>
		<i
			class="block text-primary-A400"
			[class.rotate-45]="isShownAside"
			appSvgIcon
			[appIcon]="isShownAside ? 'plus' : 'menu'"
			appSquare="24"
		></i>
	</button>
	<a class="flex items-center justify-start space-x-2 my-2.5" href="/profile">
		<img class="block" src="assets/logo.svg" alt="Dabster" />
		<img class="block" src="assets/logo-alpha.svg" alt="Alpha" *ngIf="isCanary" />
	</a>
</div>
