/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SkillService, SkillTemplate, SnackbarService } from '../../../../../core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-skill-overlay-templates',
  templateUrl: './templates.component.html'
})
export class SkillOverlayTemplatesComponent implements OnInit, OnDestroy {
  state!: boolean;
  state$!: Subscription;

  templateSelected!: SkillTemplate | undefined;
  templateList: SkillTemplate[] = [
    {
      id: uuidv4(),
      title: $localize`:Шаблон навыка|Заголовок - [Развивающее действие]@@app-app-skill-overlay-templates.template-1-title:[Развивающее действие]`,
      description: {
        time: Date.now(),
        blocks: [
          {
            id: '2tFxreSSZn',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Пример базового развивающего действия.@@app-app-skill-overlay-templates.template-1-block-1:Пример базового развивающего действия.`
            }
          },
          {
            id: 'h0pwtQqb92',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Отступ@@app-app-skill-overlay-templates.template-1-block-2:`
            }
          },
          {
            id: '1663066926',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - <b>Цель действия [зачем оно тебе]</b>@@app-app-skill-overlay-templates.template-1-block-3:<b>Цель действия [зачем оно тебе]</b>`
            }
          },
          {
            id: 'zGY-vVH32Q',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - [Чтобы стать сильней]@@app-app-skill-overlay-templates.template-1-block-4:[Чтобы стать сильней]`
            }
          },
          {
            id: 'Ant3LIGQ30',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Отступ@@app-app-skill-overlay-templates.template-1-block-5:`
            }
          },
          {
            id: 'XZAyMlfg7E',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - <b>Что понадобится</b>@@app-app-skill-overlay-templates.template-1-block-6:<b>Что понадобится</b>`
            }
          },
          {
            id: 'j-ySsbgF4v',
            type: 'list',
            data: {
              items: [
                {
                  content: $localize`:Шаблон навыка|Список - Ссылка на что-то@@app-app-skill-overlay-templates.template-1-block-7-list-1:Ссылка на что-то`,
                  items: []
                },
                {
                  content: $localize`:Шаблон навыка|Список - Контакт кого-то@@app-app-skill-overlay-templates.template-1-block-7-list-2:Контакт кого-то`,
                  items: []
                }
              ],
              style: 'ordered'
            }
          },
          {
            id: 'u5F89jk6K1',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Отступ@@app-app-skill-overlay-templates.template-1-block-8:`
            }
          },
          {
            id: 'XXBSK8zh1h',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - В результат тиммейт должен добавить [артефакт, полученный в ходе действия] или [собственную оценку выполнения действия].@@app-app-skill-overlay-templates.template-1-block-9:В результат тиммейт должен добавить [артефакт,&nbsp; полученный в ходе действия] или [собственную оценку выполнения действия].`
            }
          }
        ],
        version: '2.24.3'
      },
      created_at: new Date().toDateString(),
      updated_at: new Date().toDateString()
    },
    {
      id: uuidv4(),
      title: $localize`:Шаблон навыка|Заголовок - [Meetup]@@app-app-skill-overlay-templates.template-2-title:[Meetup]`,
      description: {
        time: Date.now(),
        blocks: [
          {
            id: 'KQ3V6r6fav',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Формат действия, в котором тиммейт делится и закрепляет полученный опыт с командой.@@app-app-skill-overlay-templates.template-2-block-1:Формат действия, в котором тиммейт делится и закрепляет полученный опыт с командой.`
            }
          },
          {
            id: '7YEeoIUFY1',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Отступ@@app-app-skill-overlay-templates.template-2-block-2:`
            }
          },
          {
            id: 'O52BALaMMF',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - <b>На [какую тему] будет митап</b>@@app-app-skill-overlay-templates.template-2-block-3:<b>На [какую тему] будет митап</b>`
            }
          },
          {
            id: 'xjYypiBHO-',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - [Очень классная технология], которую тиммейт освоил.@@app-app-skill-overlay-templates.template-2-block-4:[Очень классная технология], которую тиммейт освоил.`
            }
          },
          {
            id: '1FCDF8t9Tj',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Отступ@@app-app-skill-overlay-templates.template-2-block-5:`
            }
          },
          {
            id: 'gSCVlcbXEj',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - <b>Когда он будет [ссылка на встречу]</b>@@app-app-skill-overlay-templates.template-2-block-6:<b>Когда он будет [ссылка на встречу]</b>`
            }
          },
          {
            id: 'xe4MUwVUOF',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - [Тут можно добавить ссылку] или [просто информацию о дате-времени].@@app-app-skill-overlay-templates.template-2-block-7:[Тут можно добавить ссылку] или [просто информацию о дате-времени].`
            }
          },
          {
            id: 'd2q78SsGOy',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Отступ@@app-app-skill-overlay-templates.template-2-block-8:`
            }
          },
          {
            id: 'e7geNWTTN1',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - В результат тиммейт [должен добавить ссылку на запись митапа] или [материалы к нему], или [обратную связь участников].@@app-app-skill-overlay-templates.template-2-block-9:В результат тиммейт [должен добавить ссылку на запись митапа] или [материалы к нему], или [обратную связь участников].`
            }
          }
        ],
        version: '2.24.3'
      },
      created_at: new Date().toDateString(),
      updated_at: new Date().toDateString()
    },
    {
      id: uuidv4(),
      title: $localize`:Шаблон навыка|Заголовок - [Цель]@@app-app-skill-overlay-templates.template-3-title:[Цель]`,
      description: {
        time: Date.now(),
        blocks: [
          {
            id: '883f1222',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - <b>Описание цели (зачем оно нужно?)</b>@@app-app-skill-overlay-templates.template-3-block-1:<b>Описание цели (зачем оно нужно?)</b>`
            }
          },
          {
            id: 'cNghDEUWFx',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Достичь [чего-то], чтобы развить@@app-app-skill-overlay-templates.template-3-block-2:Достичь [чего-то], чтобы развить`
            }
          },
          {
            id: 'ShsHX02bq7',
            type: 'list',
            data: {
              items: [
                {
                  content: $localize`:Шаблон навыка|Список - [что-то]@@app-app-skill-overlay-templates.template-3-block-3-list-1:[что-то]`,
                  items: []
                },
                {
                  content: $localize`:Шаблон навыка|Список - [и ещё что-то]@@app-app-skill-overlay-templates.template-3-block-3-list-2:[и ещё что-то]`,
                  items: []
                }
              ],
              style: 'unordered'
            }
          },
          {
            id: 'rpDTjHJsFM',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Отступ@@app-app-skill-overlay-templates.template-3-block-4:`
            }
          },
          {
            id: 'E9Gb7tmlZ3',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - <b>Шаги достижения цели [первые три]</b>@@app-app-skill-overlay-templates.template-3-block-5:<b>Шаги достижения цели [первые три]</b>`
            }
          },
          {
            id: 'dmdufdIQHo',
            type: 'list',
            data: {
              items: [
                {
                  content: $localize`:Шаблон навыка|Список - Засучить рукава.@@app-app-skill-overlay-templates.template-3-block-6-list-1:Засучить рукава.`,
                  items: []
                },
                {
                  content: $localize`:Шаблон навыка|Список - Задуматься.@@app-app-skill-overlay-templates.template-3-block-6-list-2:Задуматься.`,
                  items: []
                },
                {
                  content: $localize`:Шаблон навыка|Список - И третий шаг.@@app-app-skill-overlay-templates.template-3-block-6-list-3:И третий шаг.`,
                  items: []
                }
              ],
              style: 'ordered'
            }
          },
          {
            id: 'LB11-T0Xs5',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Отступ@@app-app-skill-overlay-templates.template-3-block-7:`
            }
          },
          {
            id: 'nBeIswC9jO',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - <b>В результаты тиммейт добавляет</b>@@app-app-skill-overlay-templates.template-3-block-8:<b>В результаты тиммейт добавляет</b>`
            }
          },
          {
            id: 'HXzhEElB70',
            type: 'list',
            data: {
              items: [
                {
                  content: $localize`:Шаблон навыка|Список - Собственную оценку достижения цели.@@app-app-skill-overlay-templates.template-3-block-9-list-1:Собственную оценку достижения цели.`,
                  items: []
                },
                {
                  content: $localize`:Шаблон навыка|Список - [Что] получилось развить по ходу.@@app-app-skill-overlay-templates.template-3-block-9-list-2:[Что] получилось развить по ходу.`,
                  items: []
                },
                {
                  content: $localize`:Шаблон навыка|Список - [Артефакт], подтверждающий достижение цели.@@app-app-skill-overlay-templates.template-3-block-9-list-3:[Артефакт], подтверждающий достижение цели.`,
                  items: []
                }
              ],
              style: 'unordered'
            }
          }
        ],
        version: '2.24.3'
      },
      created_at: new Date().toDateString(),
      updated_at: new Date().toDateString()
    },
    {
      id: uuidv4(),
      title: $localize`:Шаблон навыка|Заголовок - [Алгоритм]@@app-app-skill-overlay-templates.template-4-title:[Алгоритм]`,
      description: {
        time: Date.now(),
        blocks: [
          {
            id: 'Dp7sYMLtiv',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - <b>Алгоритм</b>@@app-app-skill-overlay-templates.template-4-block-1:<b>Алгоритм</b>`
            }
          },
          {
            id: 'xnSII37cfc',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Подходит, когда в команде есть устоявшаяся практика.@@app-app-skill-overlay-templates.template-4-block-2:Подходит, когда в команде есть устоявшаяся практика.`
            }
          },
          {
            id: 'fufI63dCYD',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Действие, где мы описываем последовательность выполнения. По которой можно пройти <b>от</b> и <b>до</b>.@@app-app-skill-overlay-templates.template-4-block-3:Действие, где мы описываем последовательность выполнения. По которой можно пройти <b>от</b> и <b>до</b>.`
            }
          },
          {
            id: 'YtJ7LkCHyD',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Отступ@@app-app-skill-overlay-templates.template-4-block-4:`
            }
          },
          {
            id: 'uhmsoZMdX4',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - <b>Что нужно знать до</b>@@app-app-skill-overlay-templates.template-4-block-5:<b>Что нужно знать до</b>`
            }
          },
          {
            id: 'RXcGdZyFPz',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Здесь могут быть ссылки@@app-app-skill-overlay-templates.template-4-block-6:- Здесь могут быть ссылки`
            }
          },
          {
            id: '339HCvQLmZ',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Описания@@app-app-skill-overlay-templates.template-4-block-7:- Описания`
            }
          },
          {
            id: '_sPGrQcN_A',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Видео@@app-app-skill-overlay-templates.template-4-block-8:- Видео`
            }
          },
          {
            id: 'KpmU3RfIGM',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Отступ@@app-app-skill-overlay-templates.template-4-block-9:`
            }
          },
          {
            id: 'WMuKJlJz-p',
            type: 'list',
            data: {
              items: [
                {
                  content: $localize`:Шаблон навыка|Список - <b>Шаг 1</b>@@app-app-skill-overlay-templates.template-4-block-10-list-1:<b>Шаг 1</b>`,
                  items: [
                    {
                      content: $localize`:Шаблон навыка|Список - ...@@app-app-skill-overlay-templates.template-4-block-10-list-1-sublist-1:...`,
                      items: []
                    }
                  ]
                },
                {
                  content: $localize`:Шаблон навыка|Список - <b>Шаг 2</b>@@app-app-skill-overlay-templates.template-4-block-10-list-2:<b>Шаг 2</b>`,
                  items: [
                    {
                      content: $localize`:Шаблон навыка|Список - ...@@app-app-skill-overlay-templates.template-4-block-10-list-2-sublist-1:...`,
                      items: []
                    }
                  ]
                },
                {
                  content: $localize`:Шаблон навыка|Список - <b>Шаг 3</b>@@app-app-skill-overlay-templates.template-4-block-10-list-3:<b>Шаг 3</b>`,
                  items: []
                }
              ],
              style: 'unordered'
            }
          },
          {
            id: 'mU6tN8Nqcp',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - Отступ@@app-app-skill-overlay-templates.template-4-block-11:`
            }
          },
          {
            id: 'fdXsVEFbL0',
            type: 'paragraph',
            data: {
              alignment: 'left',
              text: $localize`:Шаблон навыка|Блок - В результаты тиммейт добавляет@@app-app-skill-overlay-templates.template-4-block-12:В результаты тиммейт добавляет`
            }
          },
          {
            id: '_q9bdaTaHu',
            type: 'list',
            data: {
              items: [
                {
                  content: $localize`:Шаблон навыка|Список - [Артефакт]@@app-app-skill-overlay-templates.template-4-block-13-list-1:[Артефакт]`,
                  items: []
                },
                {
                  content: $localize`:Шаблон навыка|Список - [Мысли на тему улучшения алгоритма]@@app-app-skill-overlay-templates.template-4-block-13-list-2:[Мысли на тему улучшения алгоритма]`,
                  items: []
                },
                {
                  content: $localize`:Шаблон навыка|Список - [Собранную оценку выполнения практики]@@app-app-skill-overlay-templates.template-4-block-13-list-3:[Собранную оценку выполнения практики]`,
                  items: []
                }
              ],
              style: 'unordered'
            }
          }
        ],
        version: '2.24.3'
      },
      created_at: new Date().toDateString(),
      updated_at: new Date().toDateString()
    }
  ];

  constructor(private skillService: SkillService, private snackbarService: SnackbarService) {}

  ngOnInit(): void {
    this.state$ = this.skillService.skillTemplateModalToggle.subscribe({
      next: (state: boolean) => (this.state = state),
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.state$].forEach($ => $?.unsubscribe());
  }

  onApplyNewTask(): void {
    if (!!this.templateSelected) {
      this.skillService.skillTemplateModalOnApplyNewTask.emit(this.templateSelected);

      this.onClose(false);
    } else {
      this.snackbarService.info(
        $localize`:Снекбар|Сообщение - Выберите шаблон@@app-snackbar.no-template:Выберите шаблон`
      );
    }
  }

  onApply(): void {
    if (!!this.templateSelected) {
      this.skillService.skillTemplateModalOnApply.emit(this.templateSelected);

      this.onClose(false);
    } else {
      this.snackbarService.info(
        $localize`:Снекбар|Сообщение - Выберите шаблон@@app-snackbar.no-template:Выберите шаблон`
      );
    }
  }

  onClose(toggle: boolean): void {
    this.templateSelected = undefined;

    this.skillService.skillTemplateModalToggle.next(toggle);
  }
}
