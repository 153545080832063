<!-- @format -->

<section class="h-full">
	<div
		class="grid grid-cols-1 xl:grid-cols-2 place-items-center w-screen h-full min-h-screen overflow-y-auto"
		*ngIf="invitationErrorToggle"
	>
		<div class="flex flex-col w-full h-full px-12">
			<div class="flex flex-shrink-0 items-center w-full h-14 mb-12">
				<a [routerLink]="['/']">
					<img class="block" src="assets/logo.svg" alt="Dabster" />
				</a>
			</div>
			<div class="flex w-full max-w-[960px] mb-12">
				<div class="flex flex-col items-start">
					<h4
						class="text-headline-4 mb-2"
						i18n="Инвайт|Заголовок: Ваше приглашение недействительно@@app-invite.title"
					>
						Ваше приглашение недействительно
					</h4>
					<!-- prettier-ignore -->
					<p
            class="text-body-1 mt-6"
            i18n="Инвайт|Параграф: Где-то произошла ошибка. Пожалуйста обратитесь за новым приглашением.@@app-invite.error-paragraph"
          >
            Где-то произошла ошибка. Пожалуйста обратитесь за новым приглашением.
          </p>
				</div>
			</div>
			<footer class="flex flex-col items-end text-body-2 mb-6 mt-auto">
				<!-- prettier-ignore -->
				<span
          class="block"
          i18n="Инвайт|Текст: Есть вопрос? Свяжитесь с нами@@app-invite.text-question"
        >
					Есть вопрос? Свяжитесь с нами:
				</span>
				<a href="mailto:artem@dabster.im">artem@dabster.im</a>
			</footer>
		</div>
		<div
			class="flex items-center justify-center relative bg-primary-A700 w-full h-full min-h-[530px]"
		>
			<ng-lottie
				class="absolute block object-scale-down w-full h-full"
				[options]="{path: '/assets/lottie/2.json'}"
				[styles]="{maxWidth: '768px', margin: '0 auto'}"
				[@authImageAnimation]
			></ng-lottie>
		</div>
	</div>
</section>
