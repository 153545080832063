<!-- @format -->

<footer class="flex items-center bg-white effect-02dp pl-64 tablet:pl-0">
	<div class="flex items-center justify-between w-full py-2 px-8" *ngIf="!!progress">
		<div class="flex items-center justify-start space-x-2">
			<app-avatar class="block" [appUser]="progress.user"></app-avatar>
			<span class="block text-body-2 text-blue-grey-900">
				{{ progress.user.name }} {{ progress.user.surname }}
			</span>
		</div>
		<div class="flex items-center justify-end space-x-10">
			<!-- prettier-ignore -->
			<button
        class="flex items-center justify-start space-x-2 text-primary-A400"
        [ngClass]="{'opacity-25 cursor-not-allowed': progressActiveIndex === 0}"
        [disabled]="progressActiveIndex === 0"
        (click)="onNavigation('previous')"
        *ngIf="!!progressList.length"
      >
        <i appSvgIcon appIcon="arrow-back" appSquare="20"></i>
      </button>
			<div class="flex items-center justify-center">
				<span class="block text-headline-7 w-4">
					{{ progressActiveIndex + 1 }}
				</span>
				<span class="block text-body-2 text-blue-grey-600 w-4">
					/{{ progressList.length }}
				</span>
			</div>
			<!-- prettier-ignore -->
			<button
        class="flex items-center justify-start space-x-2 text-primary-A400 rotate-180"
        [ngClass]="{'opacity-25 cursor-not-allowed':progressActiveIndex === progressList.length - 1}"
        [disabled]="progressActiveIndex === progressList.length - 1"
        (click)="onNavigation('next')"
        *ngIf="!!progressList.length"
      >
        <i appSvgIcon appIcon="arrow-back" appSquare="20"></i>
      </button>
		</div>
		<button
			class="btn btn-primary inline md"
			i18n="Оценка навыка|Ссылка: Вернуться к оценке@@app-footer-progress.complete"
			(click)="estimatedDeadlineToggle = true"
		>
			Завершить оценку
		</button>
	</div>
	<app-overlay-estimate-complete
		[appToggle]="estimatedDeadlineToggle"
		[appProgressDashboardId]="progress.user.team_member_id"
		(clicked)="estimatedDeadlineToggle = $event"
	></app-overlay-estimate-complete>
</footer>
