/** @format */

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { AuthComponent } from './auth.component';
import { AuthErrorComponent } from './error/auth-error.component';
import { AuthForgotComponent } from './forgot/auth-forgot.component';
import { AuthInviteComponent } from './invite/auth-invite.component';
import { AuthLoginComponent } from './login/auth-login.component';
import { AuthRegistrationComponent } from './registration/auth-registration.component';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthResetComponent } from './reset/auth-reset.component';

@NgModule({
  imports: [SharedModule, AuthRoutingModule],
  declarations: [
    AuthComponent,
    AuthErrorComponent,
    AuthForgotComponent,
    AuthInviteComponent,
    AuthLoginComponent,
    AuthRegistrationComponent,
    AuthResetComponent
  ]
})
export class AuthModule {}
