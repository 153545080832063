/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import EditorJS, { API } from '@editorjs/editorjs';
import { OutputBlockData, OutputData } from '@editorjs/editorjs/types/data-formats';
import {
  EventService,
  PlatformService,
  Skill,
  SkillService,
  SkillTask,
  SkillTemplate,
  SnackbarService,
  SnackOptions
} from '../../../../../../core';
import { map, Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import Embed from './plugins/embed/dabster-embed';
import Codeflask from './plugins/code/dabser-code';
import Header from '@editorjs/header';
import NestedList from './plugins/nested-list/dabster-nested-list';
import Marker from './plugins/marker/dabster-marker';
import Quote from './plugins/quote/dabster-quote';
import Table from '@editorjs/table';
import Warning from './plugins/warning/dabster-warning';
import Paragraph from './plugins/paragraph-with-alignment/dabster-paragraph-with-alignment';
import Underline from './plugins/underline/dabster-underline';
import { AttachesTool } from './plugins/attaches/dabster-attaches';
import ImageTool from './plugins/image/dabster-image';
import { DabsterLinkTool } from './plugins/link-tool/dabster-link-tool';
import { DabsterI18n } from './i18n';
import { v4 as uuidv4 } from 'uuid';
import DragDrop from 'editorjs-drag-drop';
import DeleteButtonHandler from './plugins/kw/delete-button/dabster-delete-button';
import LinkInlineTool from './plugins/inline-link/dabster-inline-link';
import { Location } from '@angular/common';

@Component({
  selector: 'app-skill-tasks-description-editor',
  templateUrl: './editor.component.html'
})
export class SkillTaskDescriptionEditorComponent implements OnInit, OnDestroy {
  activatedRouteParams$!: Subscription;

  skill!: Skill;
  skill$!: Subscription;

  skillTask!: SkillTask;
  skillTask$!: Subscription;

  editorJS!: EditorJS;
  editorJSReady!: boolean;
  editorJSOutputData!: OutputData;

  skillTemplateModalOnApplyNewTask$!: Subscription;
  skillTemplateModalOnApply$!: Subscription;

  skillIsEditIndividual!: boolean;

  constructor(
    private skillService: SkillService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private platformService: PlatformService,
    private snackbarService: SnackbarService,
    private location: Location,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    /**
     * https://github.com/codex-team/editor.js/blob/next/types/configs/editor-config.d.ts
     * https://github.com/editor-js/awesome-editorjs
     * https://github.com/editor-js/image
     * https://github.com/editor-js/attaches
     * https://github.com/editor-js/link
     **/

    const that = this;

    this.editorJS = new EditorJS({
      holder: 'editorJS',
      placeholder: $localize`:Редактор навыка|Текст - Введите описание задачи@@app-skill-tasks-description-editor.placeholder:Введите описание задачи`,
      // @ts-ignore
      logLevel: 'ERROR',
      readOnly: false,
      i18n: DabsterI18n,
      inlineToolbar: ['bold', 'italic', 'underline', 'marker', 'link'],
      minHeight: 160,
      tools: {
        // @ts-ignore
        embed: Embed,
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: 'Placeholder',
            levels: [1, 2],
            defaultLevel: 1
          },
          shortcut: 'CMD+SHIFT+H'
        },
        list: {
          // @ts-ignore
          class: NestedList,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+L'
        },
        marker: {
          class: Marker,
          shortcut: 'CMD+M'
        },
        quote: {
          // @ts-ignore
          class: Quote,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+O'
        },
        image: {
          // @ts-ignore
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile(file: File) {
                const formData: FormData = new FormData();

                formData.append('file', file);
                formData.append('task', that.skillTask.id as string);

                return that.skillService.uploadByFile(formData).toPromise();
              },
              uploadByUrl(url: string) {
                const formData: FormData = new FormData();

                formData.append('url', url);
                formData.append('task', that.skillTask.id as string);

                return that.skillService.uploadByUrl(formData).toPromise();
              }
            },
            snackbarInfo: (message: string) => this.snackbarService.info(message)
          },
          shortcut: 'CMD+SHIFT+I'
        },
        linkTool: {
          class: DabsterLinkTool,
          config: {
            endpoint: this.skillService.getMetadataByUrl(),
            snackbarInfo: (message: string) => this.snackbarService.info(message)
          },
          shortcut: 'CMD+SHIFT+K'
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3
          },
          shortcut: 'CMD+SHIFT+Y'
        },
        warning: {
          // @ts-ignore
          class: Warning,
          inlineToolbar: ['italic', 'underline'],
          shortcut: 'CMD+SHIFT+E'
        },
        underline: {
          class: Underline,
          shortcut: 'CMD+U'
        },
        link: {
          class: LinkInlineTool,
          shortcut: 'CMD+K'
        },
        attaches: {
          class: AttachesTool,
          config: {
            // types: ['image/png', 'image/jpg', 'image/bmp'].join(','),
            uploader: {
              uploadByFile(file: File) {
                const formData: FormData = new FormData();

                formData.append('file', file);
                formData.append('task', that.skillTask?.id as string);

                return that.skillService.uploadByFileAttach(formData).toPromise();
              }
            },
            // prettier-ignore
            snackbarInfo: (message: string, options: SnackOptions) => this.snackbarService.info(message, options)
          },
          shortcut: 'CMD+SHIFT+F'
        },
        paragraph: {
          // @ts-ignore
          class: class extends Paragraph {
            constructor(data: any) {
              super(data);
            }

            // @ts-ignore
            override validate(savedData) {
              return true;
            }
          },
          inlineToolbar: true
        },
        code: {
          // @ts-ignore
          class: Codeflask,
          shortcut: 'CMD+SHIFT+C'
        }
      },
      defaultBlock: 'paragraph',
      onReady: () => {
        new DragDrop(this.editorJS);
        new DeleteButtonHandler(this.editorJS);

        /**
         * INITIALIZATION
         * Call render when change route (without any original skill object changes)
         **/

        this.activatedRouteParams$ = this.activatedRoute.params
          .pipe(
            map((data: any) => data.taskId),
            tap((taskId: string) => {
              const skill: Skill = this.skillService.skill$.getValue();

              /** Set active skillTask from original skill object */

              // @ts-ignore
              this.skillTask = skill.taskList.find((skillTask: SkillTask) => {
                return skillTask.id === taskId;
              });

              this.editorJSOutputData = this.skillTask.description;
            })
          )
          .subscribe({
            next: () => {
              this.onRender();

              setTimeout(() => (this.editorJSReady = true), 100);
            },
            error: (error: any) => console.error(error)
          });

        /**
         * IN PROCESS
         * We are calling render only if "description" changed
         * Avoiding task "title" changes ant other things (in skill object) which can trigger unnecessary render
         **/

        this.skill$ = this.skillService.skill$
          .pipe(
            debounceTime(10),
            filter(() => {
              const left: string = JSON.stringify(this.skillTask.description.blocks);
              const right: string = JSON.stringify(this.editorJSOutputData.blocks);

              return left !== right;
            })
          )
          .subscribe({
            next: () => this.onRender(),
            error: (error: any) => console.error(error)
          });
      },
      onChange: (api: API, customEvent: CustomEvent) => {
        const changeTimeout: any = setTimeout(() => {
          this.editorJS.save().then((outputData: OutputData) => {
            this.editorJSOutputData = outputData;

            /**
             * Вставляем не сохраненные блоки
             *
             * При вставке нескольких параграфов или параграфы + заголовки:
             *    - редактор удаляет блок в который вставляем.
             *    - не сохраняет вставленные блоки.
             * Для исправления этого поведения следующий код:
             *    - Ищем не сохраненные блоки.
             *    - Вставляем те которые не сохранены.
             * */
            if (customEvent.type === 'block-removed') {
              /** Индекс блока вставки контента */
              const startIndex: number = customEvent.detail.index;

              /** Список Id блоков которые сохранены */
              const skillTaskBlockIdList: (string | undefined)[] =
                this.skillTask.description.blocks.map((block: OutputBlockData) => block.id);

              if (skillTaskBlockIdList.length) {
                /** Список не сохраненных блоков */
                const notChangedBlockList: Array<OutputBlockData<string, any>> =
                  outputData.blocks.filter(taskBlock => {
                    return !skillTaskBlockIdList.includes(taskBlock.id);
                  });

                switch (true) {
                  /** Если не сохраненных блоков больше одного. */
                  case notChangedBlockList.length > 1: {
                    notChangedBlockList.forEach((block, index) => {
                      const insetTimeout: number = setTimeout(() => {
                        /** вставляем блок */
                        api.blocks.insert(block.type, block.data, undefined, startIndex + index);
                        /** удаляем не сохраненный блок */
                        api.blocks.delete(startIndex + index + 1);

                        clearTimeout(insetTimeout);
                      }, 10);
                    });

                    break;
                  }
                  /** Если не сохраненный блок один (пока только для таблицы) */
                  case notChangedBlockList.length === 1: {
                    /** Если вставляем таблицу */
                    if (notChangedBlockList[0].type === 'table') {
                      const blockTable = notChangedBlockList[0];
                      /** вставляем блок с таблицей */
                      api.blocks.insert(blockTable.type, blockTable.data, undefined, startIndex);
                      /** удаляем не сохраненный блок с таблицей */
                      api.blocks.delete(startIndex + 1);
                    }

                    break;
                  }
                  default:
                    break;
                }
              }
            }

            // prettier-ignore
            this.skillService.setSkillTaskDescriptionById(this.skillTask.id, this.editorJSOutputData, customEvent, api);
          });

          clearTimeout(changeTimeout);
        }, 10);
      }
    });

    // prettier-ignore
    this.skillTemplateModalOnApplyNewTask$ = this.skillService.skillTemplateModalOnApplyNewTask.subscribe({
        next: (skillTemplate: SkillTemplate) => {
          this.skillService.onAddSkillTask().subscribe({
            next: (skillTask: SkillTask) => {
              this.router
                .navigateByUrl(this.location.path().replace(this.skillTask.id, skillTask.id))
                .then(() => this.onTemplate(skillTemplate, true));
            },
            error: (error: any) => console.error(error)
          });
        },
        error: (error: any) => console.error(error)
      });

    this.skillTemplateModalOnApply$ = this.skillService.skillTemplateModalOnApply.subscribe({
      next: (skillTemplate: SkillTemplate) => this.onTemplate(skillTemplate, false),
      error: (error: any) => console.error(error)
    });

    /** Individual skill edition setup */

    // prettier-ignore
    this.skillIsEditIndividual = !!String(this.activatedRoute.snapshot.queryParamMap.get('individual') || '');
  }

  ngOnDestroy(): void {
    [
      this.activatedRouteParams$,
      this.skill$,
      this.skillTemplateModalOnApplyNewTask$,
      this.skillTemplateModalOnApply$
    ].forEach($ => $?.unsubscribe());

    this.editorJS.destroy();
  }

  onRender(): void {
    /** Avoid undefined holder error */

    if (!this.skillTask.description.blocks.length) {
      this.skillTask.description.blocks.push({
        id: uuidv4().split('-').shift(),
        type: 'paragraph',
        data: {
          text: ''
        }
      });
    }

    this.editorJS?.render(this.skillTask.description).then(() => {
      console.debug('EditorJS rendered');
    });
  }

  onTemplate(skillTemplate: SkillTemplate, isNewTask: boolean, isCancelable = true): void {
    const skillTask: SkillTask = { ...this.skillTask };

    const templateTimeout: number = setTimeout(() => {
      this.skillService.setSkillTaskTitleById(this.skillTask.id, skillTemplate.title);

      /** Remove block's sequentially */

      this.editorJSOutputData.blocks.forEach((block: OutputBlockData) => {
        this.editorJS.blocks.delete(this.editorJS.blocks.getBlockIndex(block.id || ''));
      });

      /** Insert block's sequentially */

      skillTemplate.description.blocks.forEach((block: OutputBlockData, i: number) => {
        this.editorJS.blocks.insert(block.type, block.data, {}, i, false, false);
      });

      clearTimeout(templateTimeout);
    }, 10);

    if (isCancelable) {
      const snackbarMessage: string = isNewTask
        ? $localize`:Снекбар|Сообщение - Действие по шаблону создано@@app-snackbar.applying-template-new-task:Действие по шаблону создано`
        : $localize`:Снекбар|Сообщение - Шаблон применен@@app-snackbar.applying-template:Шаблон применен`;

      this.snackbarService.info(snackbarMessage, {
        timeout: 6000,
        progress: true,
        progressClass: 'bg-secondary-600',
        button: {
          label: $localize`:Снекбар|Кнопка - Отмена@@app-snackbar.applying-template-button:Отмена`,
          callback: () => this.onTemplate(skillTask, isNewTask, false)
        }
      });

      this.eventService.postDispatch('applying_template').subscribe({
        next: () => console.debug('dispatch event'),
        error: (error: any) => console.error(error)
      });
    }
  }
}
