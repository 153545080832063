/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { delay, of, Subscription } from 'rxjs';
import { HelperService, Trajectory, TrajectoryService } from '../../../../../core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-trajectory-overlay-add',
  templateUrl: './add.component.html'
})
export class TrajectoryOverlayAddComponent implements OnInit, OnDestroy {
  createForm: FormGroup;
  createFormIsSubmitting = false;

  state!: boolean;
  state$!: Subscription;

  constructor(
    private trajectoryService: TrajectoryService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private router: Router
  ) {
    this.createForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(3)]]
    });
  }

  ngOnInit(): void {
    this.state$ = this.trajectoryService.trajectoryAddModalToggle.subscribe({
      next: (state: boolean) => {
        this.state = state;

        this.createForm.reset();
      }
    });
  }

  ngOnDestroy(): void {
    [this.state$].forEach($ => $?.unsubscribe());
  }

  onSubmitForm(): void {
    if (this.helperService.getFormValidation(this.createForm)) {
      this.createFormIsSubmitting = true;

      this.trajectoryService.createTrajectory(this.createForm.value).subscribe({
        next: (trajectory: Trajectory) => {
          this.onClose(false);

          /** Await modal close, avoid router animation glitches  */

          of(true)
            .pipe(first(), delay(300))
            .subscribe({
              next: () => {
                this.router
                  .navigate(['/library/trajectories', trajectory.id])
                  .then(() => console.debug('Route changed'));
              }
            });
        },
        error: () => (this.createFormIsSubmitting = false)
      });
    }
  }

  onClose(toggle: boolean): void {
    this.trajectoryService.trajectoryAddModalToggle.next(toggle);
  }
}
