/** @format */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as dayjs from 'dayjs';
import { ManipulateType } from 'dayjs';
import {
  ProgressDashboard,
  ProgressService,
  SnackbarService,
  User,
  UserService
} from '../../../../core';
import { BehaviorSubject, delay, of, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-overlay-checkpoint',
  templateUrl: './overlay-checkpoint.component.html'
})
export class OverlayCheckpointComponent {
  @Output() applied = new EventEmitter<boolean>();

  @Output() closed = new EventEmitter<boolean>();

  @Input()
  set appState(state: boolean) {
    this.state$.next(state);

    /** Set deadline date */

    if (!!this.progressDashboard?.deadline) {
      /** Avoid deadlineSelected change glitch */

      if (state) {
        const dayDeadline: dayjs.Dayjs = dayjs(this.progressDashboard.deadline);
        const dayNow: dayjs.Dayjs = dayjs();

        this.deadlineSelected = dayNow.isBefore(dayDeadline) ? dayDeadline : dayNow;
      }
    } else {
      this.deadlineSelected = null;
    }
  }

  @Input()
  set appProgressDashboard(progressDashboard: ProgressDashboard) {
    this.progressDashboard = progressDashboard;
  }

  state$ = new BehaviorSubject<boolean>(true);

  user!: User;
  user$!: Subscription;

  progressDashboard!: ProgressDashboard;

  deadlineIsSubmit: boolean = false;
  deadlineSelected!: dayjs.Dayjs | null;

  constructor(
    private userService: UserService,
    private progressService: ProgressService,
    private snackbarService: SnackbarService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser.pipe(first()).subscribe({
      next: (user: User) => (this.user = user),
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.user$].forEach($ => $?.unsubscribe());
  }

  onDeadlineClose(): void {
    this.state$.next(false);
    this.closed.emit(false);
  }

  onDeadlineAdd(manipulateType: string): void {
    if (manipulateType !== 'endless') {
      // prettier-ignore
      this.deadlineSelected = dayjs(this.deadlineSelected || dayjs()).add(1, manipulateType as ManipulateType);
    } else {
      this.deadlineSelected = null;
    }
  }

  onDeadlineApply(): void {
    this.deadlineIsSubmit = true;

    this.progressService
      .setDeadline(this.progressDashboard.user.team_member_id, {
        deadline: this.deadlineSelected?.format('YYYY-MM-DD') || null
      })
      .subscribe({
        next: (response: any) => {
          this.onDeadlineClose();
          this.deadlineIsSubmit = false;

          if (this.progressDashboard.user.id === this.user.id) {
            this.snackbarService.info(
              $localize`:Снекбар|Сообщение - Дедлайн был изменён@@app-snackbar.deadline-has-been-changed:Дедлайн был изменён`,
              {
                icon: 'done'
              }
            );
          } else {
            this.snackbarService.info(
              $localize`:Снекбар|Сообщение - Дедлайн тиммейта был изменён@@app-snackbar.deadline-has-been-changed-teammate:Дедлайн тиммейта был изменён`,
              {
                icon: 'done'
              }
            );
          }

          this.applied.emit(true);

          /**
           * Update parent resolver data
           *
           * https://kostylworks.atlassian.net/browse/DAB-962
           */

          of(true)
            .pipe(first(), delay(300))
            .subscribe({
              next: () => {
                // prettier-ignore
                const resolverParent: BehaviorSubject<any> = this.activatedRoute.parent?.data as BehaviorSubject<any>;
                const resolverParentPreviousData: any = this.activatedRoute.parent?.snapshot.data;
                const resolverParentNextData: any = resolverParentPreviousData;

                // prettier-ignore
                resolverParentNextData.data[0].deadline = !!response.deadline ? dayjs(response.deadline).format('YYYY-MM-DD') : null;
                resolverParent.next(resolverParentNextData);
              }
            });
        },
        error: () => (this.deadlineIsSubmit = false)
      });
  }
}
