/** @format */

import { Component, OnInit } from '@angular/core';
import { routingMainAnimation } from './app-animations';
import { TeamService, UserService, LogRocketService, TariffService, User } from './core';
import { filter, Subscription, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routingMainAnimation]
})
export class AppComponent implements OnInit {
  isAuthenticated$!: Subscription;

  constructor(
    private userService: UserService,
    private teamService: TeamService,
    private logRocketService: LogRocketService,
    private tariffService: TariffService
  ) {}

  ngOnInit(): void {
    this.userService.getAuthorization();

    this.isAuthenticated$ = this.userService.isAuthenticated
      .pipe(
        filter((isAuthenticated: boolean) => isAuthenticated),
        tap(() => this.logRocketService.onInitLogRocket()),
        switchMap(() => this.teamService.getMyTeams()),
        filter(() => {
          const user: User = this.userService.currentUserSubject.getValue();

          return !!user.team && !!user.team_member_id;
        }),
        switchMap(() => this.tariffService.getTeamTariffs())
      )
      .subscribe({
        next: () => console.debug('Additional information received'),
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    // prettier-ignore
    [this.isAuthenticated$, this.logRocketService.logRocketUser$].forEach($ => $?.unsubscribe());
  }
}
