<!-- @format -->

<div class="flex flex-col w-full">
	<!-- prettier-ignore -->
	<h4
		class="text-headline-4 mb-2"
		i18n="Логин|Заголовок: Войдите в аккаунт@@app-auth-login.title"
	>
		{ invitationError, select, true {Ваше приглашение недействительно} false {Войдите в аккаунт} }
	</h4>
	<ng-container [ngSwitch]="invitationError">
		<ng-container *ngSwitchCase="true">
			<!-- prettier-ignore -->
			<p
        class="text-body-1 mt-6"
        i18n="Логин|Текст: Где-то произошла ошибка. Пожалуйста, обратитесь за новым приглашением.@@app-auth-login.error-paragraph"
      >
        Где-то произошла ошибка. Пожалуйста, обратитесь за новым приглашением.
      </p>
		</ng-container>
		<ng-container *ngSwitchCase="false">
			<div class="flex space-x-2 text-body-1 mb-8">
				<!-- prettier-ignore -->
				<span
          class="inline-block mr-1"
          i18n="Логин|Текст: Нет аккаунта?@@app-auth-login.no-account"
        >
          Нет аккаунта?
        </span>
				<a
					class="block underline"
					[routerLink]="['/auth/registration']"
					[queryParamsHandling]="'merge'"
					i18n="Логин|Ссылка: Зарегистрируйтесь@@app-auth-login.to-registration"
				>
					Зарегистрируйтесь
				</a>
			</div>
			<form [formGroup]="loginForm" (ngSubmit)="onSubmitForm()" autocomplete="off">
				<fieldset [disabled]="loginFormIsSubmitting">
					<fieldset
						class="form-control relative mb-4"
						*ngIf="loginForm.get('email') as email"
					>
						<input
							id="email"
							type="email"
							class="peer"
							placeholder="E-mail"
							i18n-placeholder="Логин|Поле ввода: E-mail@@app-auth-login.input-email"
							formControlName="email"
							#inputEmail
						/>
						<label
							for="email"
							class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
						>
							<app-error-label
								[appInputElement]="inputEmail"
								[appFormGroup]="loginForm"
								[appFormControlName]="'email'"
							></app-error-label>
						</label>
					</fieldset>
					<fieldset
						class="form-control relative mb-4"
						*ngIf="{value: false} as isVisible"
					>
						<figure
							class="absolute password i-right"
							(click)="isVisible.value = !isVisible.value"
						>
							<i
								class="absolute text-blue-grey-400"
								appSvgIcon
								[appIcon]="isVisible.value ? 'eye' : 'eye-strikethrough'"
								appSquare="24"
							></i>
						</figure>
						<!-- prettier-ignore -->
						<input
              id="password"
              [type]="isVisible.value ? 'text' : 'password'"
              class="peer"
              placeholder="Введите пароль"
              i18n-placeholder="Логин|Поле ввода: Введите пароль@@app-auth-login.input-password"
              formControlName="password"
              #inputPassword
            />
						<label
							for="password"
							class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
						>
							<app-error-label
								[appInputElement]="inputPassword"
								[appFormGroup]="loginForm"
								[appFormControlName]="'password'"
							></app-error-label>
						</label>
					</fieldset>
					<div class="block text-caption text-blue-grey-400 mb-6">
						<span
							class="inline-block"
							i18n="Логин|Текст: Не помните пароль?@@app-auth-login.password-forgot"
						>
							Не помните пароль?
						</span>
						<a
							[routerLink]="['/auth/forgot']"
							i18n="Логин|Ссылка: Изменить пароль@@app-auth-login.password-restore"
						>
							Изменить пароль
						</a>
					</div>
					<button
						class="btn btn-primary inline md"
						data-cy="nextStage"
						data-pw="button-submit"
						[disabled]="loginFormIsSubmitting"
						i18n="Логин|Кнопка: Войти в Dabster@@app-auth-login.submit"
					>
						Войти в Dabster
					</button>
				</fieldset>
			</form>
		</ng-container>
	</ng-container>
</div>
