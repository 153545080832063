/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService, TeamInvitationRegistration, TeamService, UserService } from '../../core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

interface RegistrationForm {
  name: FormControl<string | null>;
  surname: FormControl<string | null>;
  email: FormControl<string | null>;
  password: FormControl<string | null>;
  password_confirmation: FormControl<string | null>;
}

/**
 *
 *  demandToken
 *
 *  ИНВАЙТ ИЗ АДМИНКИ
 *
 *  отправляем письмо, переходим по ссылке
 *
 *  регистрация (почта заблокирована)
 *    .../auth/registration?demandToken=QWERTY
 *
 *  после регистрации попадаем в профиль -> онбординг
 *    .../profile/on-boarding?type=dabster&token=QWERTY
 *
 *  ЕСЛИ УДАЛЯЮ ТОКЕН ОСТАЮСЬ В ПРОФИЛЕ БЕЗ ВОЗМОЖНОСТИ СДЕЛАТЬ ХОТЬ ЧТО НИБУДЬ
 *    это поведение будет исправлено с решением этой задачи https://kostylworks.atlassian.net/browse/DAB-981
 *
 * */

/**
 *
 *  referralToken
 *
 *  ПРИГЛАСИТЬ В ДАБСТЕР
 *
 *  копируем ссылку с страницы профиля
 *    .../invite/QWERTY
 *
 *  переходим по ссылке и получаем редирект на регистрацию (регистрация с любой почтой)
 *    .../auth/registration?referralToken=QWERTY
 *
 *  после регистрации попадаем в профиль -> онбординг
 *    .../profile/on-boarding?type=dabster&token=QWERTY
 *
 *  ЕСЛИ УДАЛЯЮ ТОКЕН ОСТАЮСЬ В ПРОФИЛЕ БЕЗ ВОЗМОЖНОСТИ СДЕЛАТЬ ХОТЬ ЧТО НИБУДЬ
 *    это поведение будет исправлено с решением этой задачи https://kostylworks.atlassian.net/browse/DAB-981
 *
 * */

/**
 *
 *  token
 *
 *  ПРИГЛАСИТЬ В КОМАНДУ
 *
 *  отправляем письмо с страницы команды, переходим по ссылке
 *
 *  регистрация (почта заблокирована)
 *    .../auth/registration?token=QWERTY
 *
 *  после регистрации попадаем в профиль -> онбординг
 *
 *  можем принять инвайт сразу (этот урл только для модалки, для получения информации о инвайте)
 *    .../profile/on-boarding?type=team&token=QWERTY
 *
 *  можем не принимать инвайт (принять позже)
 *    .../profile
 *
 * */

@Component({
  selector: 'app-auth-registration',
  templateUrl: './auth-registration.component.html'
})
export class AuthRegistrationComponent implements OnInit, OnDestroy {
  routerQueryParams$!: Subscription;

  registrationForm: FormGroup;
  registrationFormIsSubmitting = false;

  invitationMap: any = {
    token: 'invitation_token',
    demandToken: 'demand_token',
    referralToken: 'referral_token'
  };
  invitationPayload!: any;
  invitationToken!: string;
  invitationOnBoardingType!: string;
  invitationError = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private teamService: TeamService,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private router: Router,
    private title: Title
  ) {
    this.registrationForm = this.formBuilder.group<RegistrationForm>(
      {
        name: this.formBuilder.control('', [Validators.required]),
        surname: this.formBuilder.control('', [Validators.required]),
        email: this.formBuilder.control('', [Validators.required, Validators.email]),
        password: this.formBuilder.control('', [Validators.required, Validators.minLength(6)]),
        password_confirmation: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(6)
        ])
      },
      {
        validators: [
          this.helperService.getCustomValidation('isSame', {
            left: 'password',
            right: 'password_confirmation'
          })
        ]
      }
    );
  }

  ngOnInit(): void {
    // prettier-ignore
    this.title.setTitle($localize`:Заголовок|Текст - Dabster | Присоединяйтесь к Dabster@@app-title.app-auth-registration:Dabster | Присоединяйтесь к Dabster`);

    this.routerQueryParams$ = this.activatedRoute.queryParams.subscribe({
      next: (queryParams: Params) => {
        Object.keys(this.invitationMap).forEach((invitation: string) => {
          if (invitation in queryParams) {
            const token: string = queryParams[invitation];

            /** Saving token and token type */

            this.invitationPayload = {
              [this.invitationMap[invitation]]: token
            };

            /** Check token */

            this.getTokenInfo(token);
          }
        });
      },
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.routerQueryParams$].forEach($ => $?.unsubscribe());
  }

  getTokenInfo(token: string): void {
    this.teamService.getInvitationInfo(token).subscribe({
      next: (teamInvitationRegistration: TeamInvitationRegistration) => {
        this.invitationToken = token;
        this.invitationOnBoardingType = teamInvitationRegistration.onboarding;

        if (!!teamInvitationRegistration.email) {
          this.registrationForm.get('email')?.setValue(teamInvitationRegistration.email);
          this.registrationForm.get('email')?.disable();
        }
      },
      error: () => (this.invitationError = true)
    });
  }

  onSubmitForm(): void {
    if (this.helperService.getFormValidation(this.registrationForm)) {
      this.registrationFormIsSubmitting = true;

      const body: any = {
        ...this.registrationForm.getRawValue(),
        ...this.invitationPayload
      };

      this.userService.postRegistration(body).subscribe({
        next: () => {
          this.registrationFormIsSubmitting = false;

          this.router
            .navigate(['/profile/on-boarding'], {
              queryParams: {
                type: this.invitationOnBoardingType || 'dabster',
                token: this.invitationToken
              }
            })
            .then(() => console.debug('Route changed'));
        },
        error: () => (this.registrationFormIsSubmitting = false)
      });
    }
  }
}
