<!-- @format -->

<section>
	<app-overlay
		*ngIf="nextSkill"
		[appState]="state"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && onClose($event)"
	>
		<div class="relative w-full max-w-[688px] bg-white rounded-lg p-10">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="onClose(false)"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div class="flex flex-col items-start">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-4"
          i18n="Прогресс навыка|Заголовок: Вы освоили навык из траектории! Хотите посмотреть следующий?@@app-skill-overlay-next.next-skill-title"
        >
					Вы освоили навык из траектории! Хотите посмотреть следующий?
				</span>
				<!-- prettier-ignore -->
				<p
          class="text-body-1 mb-4"
          i18n="Прогресс навыка|Параграф: Поздравляем, вы только что освоили один из навыков вашей траектории! Не останавливайтесь на достигнутом, продолжите свое обучение со следующим навыком:@@app-skill-overlay-next.next-skill-paragraph"
        >
          Поздравляем, вы только что освоили один из навыков вашей траектории! Не останавливайтесь на достигнутом, продолжите свое обучение со следующим навыком:
        </p>
				<div class="flex w-full rounded-lg bg-blue-grey-25 effect-01dp gap-4 mb-8">
					<img
						class="block object-cover rounded-l-lg w-20 h-20"
						[srcset]="nextSkill.image.url['320']"
						[alt]="nextSkill.title"
					/>
					<div class="flex items-center w-full gap-4 p-4">
						<span class="flex w-full text-subtitle-1 line-clamp-2">
							{{ nextSkill.title }}
						</span>
						<div class="flex items-center">
							<i
								appSvgIcon
								appIcon="bookmarks"
								appSquare="20"
								appViewBox="0 0 24 24"
								class="text-blue-grey-600 mr-1.5"
							></i>
							<!-- prettier-ignore -->
							<span
                class="text-body-2 text-blue-grey-600 mr-1"
                i18n="Прогресс навыка|Текст: Задачи@@app-skill-overlay-next.next-skill-tasks"
              >
                Задачи:
              </span>
							<span class="text-subtitle-2 text-blue-grey-900">
								{{ nextSkill.task_count }}
							</span>
						</div>
					</div>
				</div>
				<div class="flex items-center justify-between w-full">
					<button class="btn btn-gray outline md" (click)="onClose(false)">
						<figure class="relative">
							<i
								class="absolute text-blue-grey-400"
								appSvgIcon
								appIcon="cross"
								appSquare="24"
							></i>
						</figure>
						<!-- prettier-ignore -->
						<span
              class="block"
              i18n="Прогресс навыка|Кнопка: Отмена@@app-skill-overlay-next.next-skill-button-cancel"
            >
              Отмена
            </span>
					</button>
					<button class="btn btn-primary inline md" (click)="onRedirect()">
						<!-- prettier-ignore -->
						<span
              class="block"
              i18n="Прогресс навыка|Кнопка: Перейти к навыку@@app-skill-overlay-next.next-skill-button-next"
            >
							Перейти к навыку
						</span>
						<figure class="relative rotate-180">
							<i
								class="absolute text-white"
								appSvgIcon
								appIcon="arrow-back"
								appSquare="24"
							></i>
						</figure>
					</button>
				</div>
			</div>
		</div>
	</app-overlay>
</section>
