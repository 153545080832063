/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable, Subscription } from 'rxjs';
import {
  PlatformService,
  ProgressService,
  SkillService,
  Snack,
  SnackbarService,
  TrajectoryService
} from '../../../core';
import { snackAnimation } from '../../../app-animations';
import { Location } from '@angular/common';
import { Event as RouterEvent, NavigationEnd, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  animations: [snackAnimation]
})
export class SnackbarComponent implements OnInit, OnDestroy {
  routerEvents$!: Subscription;

  snackbar$!: Subscription;
  snackbarList: Snack[] = [];

  isShownFooter$!: Observable<boolean>;

  constructor(
    private snackbarService: SnackbarService,
    private skillService: SkillService,
    private progressService: ProgressService,
    private platformService: PlatformService,
    private location: Location,
    private router: Router,
    private trajectoryService: TrajectoryService
  ) {}

  ngOnInit(): void {
    this.snackbar$ = this.snackbarService.snackbar$
      .pipe(filter(() => !this.platformService.isShownOverlay.getValue()))
      .subscribe({
        next: (snack: Snack) => {
          this.onPush({
            id: Date.now(),
            ...snack
          });
        }
      });

    this.routerEvents$ = this.router.events
      .pipe(
        filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd),
        debounceTime(10)
      )
      .subscribe({
        next: () => {
          const skillIsEdit: boolean = this.skillService.skillIsEdit$.getValue();
          const trajectoryIsEdit: boolean = this.trajectoryService.trajectoryIsEdit$.getValue();
          const progressIsEstimate: boolean = this.progressService.progressIsEstimate$.getValue();
          const isFullscreen: boolean = this.location.path().includes('isFullscreen=1');

          // prettier-ignore
          this.snackbarService.isShownFooter$.next(isFullscreen || skillIsEdit || trajectoryIsEdit || progressIsEstimate);
        }
      });

    this.isShownFooter$ = this.snackbarService.isShownFooter$.asObservable();
  }

  ngOnDestroy(): void {
    [this.snackbar$, this.routerEvents$].forEach($ => $?.unsubscribe());
  }

  onPush(snack: Snack): void {
    this.snackbarList.push(snack);

    if (snack.options?.timeout !== 0) {
      const timeout = setTimeout(() => {
        this.onClose(snack?.id as number);

        clearTimeout(timeout);
      }, snack.options?.timeout);
    }
  }

  onCallback(snack: Snack): void {
    snack.options?.button?.callback();

    this.onClose(snack?.id as number);
  }

  onClose(snackId: number): void {
    this.snackbarList = this.snackbarList.filter((snack: Snack) => snack?.id !== snackId);
  }
}
