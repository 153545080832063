<!-- @format -->

<section>
	<app-overlay
		[appState]="state$.getValue()"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && onDeadlineClose()"
	>
		<div
			data-pw="change-checkpoint"
			class="relative min-w-[776px] mobile:min-w-full w-full bg-white rounded-lg p-10 pb-8"
		>
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="onDeadlineClose()"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<!-- prettier-ignore -->
			<span
        class="block text-headline-4 mb-4"
        i18n="Дашборд|Заголовок: Изменение дедлайна@@app-dashboard-progress-dashboard.deadline-change-title"
      >
				Изменение дедлайна
			</span>
			<!-- prettier-ignore -->
			<p
        class="text-body-1 mb-4"
        i18n="Дашборд|Параграф: Выберите новую дату@@app-dashboard-progress-dashboard.deadline-change-paragraph"
      >
        Выберите новую дату дедлайна для тиммейта
      </p>
			<div
				class="flex mobile:flex-col mobile:items-center space-y-0 space-x-4 mobile:space-y-4 mobile:space-x-0 mb-8"
			>
				<div
					data-pw="checkpoint"
					class="flex flex-col justify-center items-start bg-blue-grey-25 effect-01dp rounded-md box-border w-full max-w-[312px] mobile:max-w-full p-8"
				>
					<div class="flex items-center text-blue-grey-600 mb-4">
						<!-- prettier-ignore -->
						<span
              class="text-body-1 text-blue-grey-600 mr-2"
              i18n="Дашборд|Текст: Было@@app-dashboard-progress-dashboard.deadline-change-was"
            >
							Было:
						</span>
						<i appSvgIcon appIcon="flag" appSquare="17" class="mr-2"></i>
						<ng-container [ngSwitch]="!!progressDashboard.deadline">
							<i
								*ngSwitchCase="false"
								appSvgIcon
								appIcon="endless-2"
								appWidth="48"
								appHeight="17"
								appViewBox="0 0 98 42"
								class="text-blue-grey-600 mr-3"
							></i>
							<span *ngSwitchCase="true" class="text-headline-6 text-blue-grey-600 mr-3">
								<ng-container *appLocale="['en']">
									{{ progressDashboard.deadline | date: "MM.dd.YY" }}
								</ng-container>
								<ng-container *appLocale="['ru']">
									{{ progressDashboard.deadline | date: "dd.MM.YY" }}
								</ng-container>
							</span>
						</ng-container>
						<i
							appSvgIcon
							appIcon="arrow-back"
							appWidth="20"
							appHeight="20"
							appViewBox="0 0 16 16"
							class="rotate-180 translate-y-0.5"
						></i>
					</div>
					<ng-container [ngSwitch]="!!deadlineSelected">
						<i
							*ngSwitchCase="false"
							appSvgIcon
							appIcon="endless-2"
							appWidth="98"
							appHeight="42"
							appViewBox="0 0 98 42"
							class="text-primary-A400 mt-1.5 mb-8"
						></i>
						<span *ngSwitchCase="true" class="text-headline-3 text-primary-A400 mb-6">
							<ng-container *appLocale="['en']">
								{{ deadlineSelected?.format() | date: "MM.dd.YY" }}
							</ng-container>
							<ng-container *appLocale="['ru']">
								{{ deadlineSelected?.format() | date: "dd.MM.YY" }}
							</ng-container>
						</span>
					</ng-container>
					<div class="flex flex-wrap items-center gap-4">
						<button
							class="btn btn-primary transparent text-button-2"
							(click)="onDeadlineAdd('week')"
						>
							<span class="pb-1">+</span>
							<!-- prettier-ignore -->
							<span
                class="border-b border-primary-A400 border-dashed pb-1"
                i18n="Дашборд|Кнопка: 1 неделя@@app-dashboard-progress-dashboard.deadline-change-week"
              >
								1 неделя
							</span>
						</button>
						<button
							class="btn btn-primary transparent text-button-2"
							(click)="onDeadlineAdd('month')"
						>
							<span class="pb-1">+</span>
							<!-- prettier-ignore -->
							<span
                class="border-b border-primary-A400 border-dashed pb-1"
                i18n="Дашборд|Кнопка: 1 месяц@@app-dashboard-progress-dashboard.deadline-change-month"
              >
                1 месяц
              </span>
						</button>
						<button
							class="btn btn-primary transparent text-button-2"
							(click)="onDeadlineAdd('year')"
						>
							<span class="pb-1">+</span>
							<!-- prettier-ignore -->
							<span
                class="border-b border-primary-A400 border-dashed pb-1"
                i18n="Дашборд|Кнопка: 1 год@@app-dashboard-progress-dashboard.deadline-change-year"
              >
                1 год
              </span>
						</button>
						<button
							class="btn btn-primary transparent text-button-2"
							(click)="onDeadlineAdd('endless')"
						>
							<span class="pb-1">+</span>
							<!-- prettier-ignore -->
							<span
                class="border-b border-primary-A400 border-dashed pb-1"
                i18n="Дашборд|Кнопка: бесконечность@@app-dashboard-progress-dashboard.deadline-change-endless"
              >
                бесконечность
              </span>
						</button>
					</div>
				</div>
				<app-calendar
					data-pw="checkpoint-calendar"
					class="block w-full"
					[appSelectedDayjs]="deadlineSelected"
					[appDisablePrevious]="true"
					(selected)="deadlineSelected = $event"
				></app-calendar>
			</div>
			<div class="flex items-center justify-between">
				<button
					data-pw="checkpoint-cancel"
					class="btn btn-gray outline md"
					(click)="onDeadlineClose()"
				>
					<figure class="relative">
						<i class="absolute" appSvgIcon appIcon="cross" appSquare="24"></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Дашборд|Кнопка: Отмена@@app-dashboard-progress-dashboard.deadline-change-cancel"
          >
            Отмена
          </span>
				</button>
				<button
					data-pw="checkpoint-set"
					class="btn btn-primary inline md"
					(click)="onDeadlineApply()"
					[disabled]="deadlineIsSubmit"
				>
					<figure class="relative">
						<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n=" Дашборд|Кнопка: Назначить дедлайн@@app-dashboard-progress-dashboard.deadline-change-set"
          >
						Назначить дедлайн
					</span>
				</button>
			</div>
		</div>
	</app-overlay>
</section>
