/** @format */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  PlatformService,
  Skill,
  SkillErrors,
  SkillService,
  SkillTask
} from '../../../../../../core';
import { EMPTY, fromEvent, map, merge, startWith, Subscription, switchMap } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

interface SkillForm {
  title: FormControl<string | null>;
}

@Component({
  selector: 'app-skill-tasks-description-wrapper',
  templateUrl: './wrapper.component.html'
})
export class SkillTaskDescriptionWrapperComponent implements OnInit {
  @ViewChild('skillTaskDescriptionFloat') skillTaskDescriptionFloat!: ElementRef;

  activatedRouteParams$!: Subscription;
  activatedRouteQueryParams$!: Subscription;

  skill$!: Subscription;
  skillTask!: SkillTask;

  skillIsEdit!: boolean;
  skillIsEdit$!: Subscription;
  skillIsEditIndividual!: boolean;

  skillForm: FormGroup;
  skillForm$!: Subscription;
  skillFormIsSubmitting = false;

  floatSubscribe$!: Subscription;
  floatIsAnimated: boolean = false;
  floatDuration: number = 300;
  floatDelay: number = 100;
  floatY: number = 0;
  floatX: number = 0;
  floatHeight: number = 0;
  floatWidth: number = 0;

  isFullscreen!: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private skillService: SkillService,
    private platformService: PlatformService,
    private formBuilder: FormBuilder
  ) {
    this.skillForm = this.formBuilder.group<SkillForm>({
      title: this.formBuilder.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    /** Skill task title handler */

    this.activatedRouteParams$ = this.activatedRoute.params
      .pipe(map((data: any) => data.taskId))
      .subscribe({
        next: (taskId: string) => {
          this.skill$?.unsubscribe();
          this.skill$ = this.skillService.skill$
            .pipe(
              filter((skill: Skill) => !!Object.keys(skill).length),
              tap((skill: Skill) => {
                // @ts-ignore
                // prettier-ignore
                this.skillTask = skill.taskList.find((skillTask: SkillTask) => skillTask.id === taskId);
              }),
              switchMap(() => this.skillService.skillIsEdit$),
              tap((skillIsEdit: boolean) => (this.skillIsEdit = skillIsEdit)),
              filter(() => this.skillIsEdit)
            )
            .subscribe({
              next: () => {
                const abstractControl: AbstractControl | null = this.skillForm.get('title');
                const abstractControlIsEqual = (title: string): boolean => {
                  return title === this.skillTask.title;
                };

                if (abstractControl) {
                  // prettier-ignore
                  !abstractControlIsEqual(abstractControl.value) && abstractControl.setValue(this.skillTask.title);

                  this.skillForm$?.unsubscribe();

                  // prettier-ignore
                  this.skillForm$ = abstractControl.valueChanges
                    .pipe(
                      debounceTime(this.skillService.skillIsEditDebounce),
                      filter((title: string) => !abstractControlIsEqual(title))
                    )
                    .subscribe({
                      next: (title: string) => {
                        this.skillService.setSkillTaskTitleById(this.skillTask.id, title);

                        /** touched, then remove from errors */
                        const skillErrors: SkillErrors = this.skillService.skillErrors$.getValue();

                        this.skillService.skillErrors$.next({
                          ...skillErrors,
                          taskListErrors: skillErrors.taskListErrors?.filter((skillTask: SkillTask) => skillTask.id !== taskId)
                        });
                      },
                      error: (error: any) => console.error(error)
                    });
                }
              },
              error: (error: any) => console.error(error)
            });
        },
        error: (error: any) => console.error(error)
      });

    /** Float animation */

    this.setFloatUpdate();

    this.activatedRouteQueryParams$ = this.activatedRoute.queryParams
      .pipe(
        map((params: Params, index: number) => {
          this.floatIsAnimated = !!index;

          return params;
        }),
        tap((params: Params) => (this.isFullscreen = !!Number(params['isFullscreen']))),
        filter(() => this.floatIsAnimated),
        debounceTime(this.floatDelay + this.floatDuration),
        tap(() => (this.floatIsAnimated = false))
      )
      .subscribe({
        next: () => console.debug('Query params changed'),
        error: (error: any) => console.error(error)
      });

    /** Individual skill edition setup */

    // prettier-ignore
    this.skillIsEditIndividual = !!String(this.activatedRoute.snapshot.queryParamMap.get('individual') || '');
  }

  ngOnDestroy(): void {
    // prettier-ignore
    [this.activatedRouteParams$, this.activatedRouteQueryParams$, this.skill$, this.floatSubscribe$].forEach($ => $?.unsubscribe());
  }

  setFloatUpdate(): void {
    if (this.platformService.isBrowser()) {
      const window: Window = this.platformService.getWindow();
      const setFloat = (): void => {
        const htmlElement: HTMLElement = this.skillTaskDescriptionFloat.nativeElement;
        const { x, y, width, height } = htmlElement.getBoundingClientRect();

        this.floatY = y;
        this.floatX = x;
        this.floatHeight = height;
        this.floatWidth = width;
      };

      this.floatSubscribe$ = merge(fromEvent(window, 'resize'), this.platformService.getScroll(0))
        .pipe(startWith(EMPTY), debounceTime(!this.floatIsAnimated ? 600 : 0))
        .subscribe({
          next: () => setFloat(),
          error: (error: any) => console.error(error)
        });
    }
  }

  onTemplate(): void {
    this.skillService.skillTemplateModalToggle.next(true);
  }
}
