<!-- @format -->

<ul
	class="flex items-center justify-start space-x-4 h-8"
	[class.invisible]="backPathIsInvisible"
>
	<li class="flex text-button-2">
		<button
			class="flex items-center justify-start space-x-2 text-button-2 text-blue-grey-400"
			type="button"
			data-pw="button-go-back"
			(click)="onBack()"
		>
			<i appSvgIcon appIcon="arrow-back" appSquare="20"></i>
			<span class="block" i18n="Хлебные крошки|Ссылка: Назад@@app-breadcrumbs.back">
				Назад
			</span>
		</button>
	</li>
	<li
		class="flex text-subtitle-2 text-primary-A400 line-clamp-1"
		[ngSwitch]="progressIsEstimate"
	>
		<div class="block" *ngSwitchDefault>{{ backPathAdditionalTitle }}</div>
		<div class="block" *ngSwitchCase="true">
			<!-- prettier-ignore -->
			<span
        class="inline-block"
        i18n="Оценка навыка|Заголовок: Оценка дедлайна@@app-dashboard-progress-estimate.title"
      >
        Оценка дедлайна
      </span>
			<ng-container *appLocale="['en']">
				{{ progress.user.deadline | date: "MM.dd.YYYY" }}
			</ng-container>
			<ng-container *appLocale="['ru']">
				{{ progress.user.deadline | date: "dd.MM.YYYY" }}
			</ng-container>
		</div>
	</li>
</ul>
