/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, of, ReplaySubject } from 'rxjs';
import { Snack, SnackOptions } from '../models';

@Injectable()
export class SnackbarService {
  snackbar$ = new ReplaySubject<Snack>();

  isShownFooter$ = new BehaviorSubject<boolean>(false);

  info(message: string, snackOptions?: SnackOptions): void {
    const delay$ = of(true)
      .pipe(delay(200))
      .subscribe({
        next: () => {
          this.snackbar$.next({
            message,
            options: {
              timeout: 3000,
              ...snackOptions
            }
          });

          delay$.unsubscribe();
        },
        error: (error: any) => console.error(error)
      });
  }
}
