/** @format */

import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { InviteComponent } from './invite.component';

const routes: Routes = [
  {
    path: ':token',
    component: InviteComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/error/404'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InviteRoutingModule {}
