<!-- @format -->

<div
	class="fixed inset-0 flex flex-col space-y-2 items-center overflow-hidden pointer-events-none pt-4 z-40"
	*ngIf="!!snackList.length"
>
	<div
		class="flex items-center flex-shrink-0 bg-tertiary-50 overflow-hidden pointer-events-auto rounded-md w-[512px] py-3 px-4"
		*ngFor="let snack of snackList"
		[@snackModalAnimation]
	>
		<div class="flex items-start justify-between gap-x-3 w-full">
			<ng-container *ngIf="snack.options?.icon as icon" [ngSwitch]="icon">
				<i
					class="text-tertiary-A400"
					*ngSwitchCase="'cross'"
					appSvgIcon
					[appIcon]="icon"
					appSquare="24"
				></i>
			</ng-container>
			<div class="flex flex-wrap items-center justify-between gap-4 w-full my-0.5">
				<span class="block text-body-2 text-tertiary-A400">
					{{ snack.message }}
				</span>
				<ng-container *ngIf="snack.options?.button as button">
					<button
						class="btn text-button-2 text-tertiary-A400 p-0"
						type="button"
						(click)="onCallback(snack)"
					>
						<span class="block">{{ button.label }}</span>
					</button>
				</ng-container>
			</div>
		</div>
	</div>
</div>
