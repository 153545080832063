/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  Progress,
  ProgressDashboard,
  ProgressPagination,
  ProgressResultMap,
  TrajectoryAssignedLabel
} from '../models';
import { SkillService } from './skill.service';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  progress$ = new BehaviorSubject<Progress>({} as Progress);
  progressList$ = new BehaviorSubject<ProgressPagination>({} as ProgressPagination);

  progressIsEdit$ = new BehaviorSubject<boolean>(false);
  progressIsEstimate$ = new BehaviorSubject<boolean>(false);
  progressIsResult$ = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService, private skillService: SkillService) {}

  transformProgress(progress: Progress): Progress {
    progress = {
      ...progress,
      skill: {
        ...progress.skill,
        // @ts-ignore
        taskList: progress.skill.tasks_list
      }
    };

    // @ts-ignore
    delete progress.skill.tasks_list;

    return progress;
  }

  /** PROGRESS EDIT */

  onAddProgress(progress: Progress): Observable<Progress> {
    this.progress$.next(progress);
    this.progress$.pipe(first()).subscribe({
      next: (progress: Progress) => this.skillService.onAddSkill(progress.skill),
      error: (error: any) => console.error(error)
    });

    return of(this.progress$.getValue());
  }

  onAddProgressList(progressPagination: ProgressPagination): Observable<ProgressPagination> {
    this.progressList$.next(progressPagination);

    return of(this.progressList$.getValue());
  }

  onRemoveProgress(): Observable<Progress> {
    this.progress$.next({} as Progress);
    this.progress$.pipe(first()).subscribe({
      next: () => this.skillService.onRemoveSkill(),
      error: (error: any) => console.error(error)
    });

    return of(this.progress$.getValue());
  }

  onRemoveProgressList(): Observable<ProgressPagination> {
    this.progressList$.next({} as ProgressPagination);

    return of(this.progressList$.getValue());
  }

  /** API */

  getProgressDashboard(id: number): Observable<ProgressDashboard> {
    return this.apiService
      .get('/v1/progress/' + id + '/dashboard')
      .pipe(map((data: any) => data.data));
  }

  getProgressAll(id: number, params?: any): Observable<ProgressPagination> {
    return this.apiService
      .get('/v1/progress/' + id + '/progress', params)
      .pipe(map((data: any) => data.data));
  }

  getProgressById(memberId: number, progressId: string): Observable<Progress> {
    return this.apiService.get('/v1/progress/' + memberId + '/' + progressId).pipe(
      map((data: any) => data.data),
      map((progress: Progress) => this.transformProgress(progress))
    );
  }

  getProgressByIdIndividual(skillId: string, params?: any): Observable<Progress> {
    return this.apiService.get('/v1/progress/skill/' + skillId + '/getTasksStatuses', params).pipe(
      map((data: any) => data.data),
      map((progress: Progress) => this.transformProgress(progress))
    );
  }

  // prettier-ignore
  getProgressByIdTrajectories(skillId: string, params?: any): Observable<TrajectoryAssignedLabel[]> {
    return this.apiService
      .get('/v1/skill/' + skillId + '/getTrajectories', params)
      .pipe(map((data: any) => data.data.items));
  }

  setSkillStatus(id: string, body: any): Observable<Progress> {
    return this.apiService.post('/v1/progress/setSkillStatus/' + id, body).pipe(
      map((data: any) => data.data),
      map((progress: Progress) => this.transformProgress(progress))
    );
  }

  getProgressResults(memberId: number): Observable<ProgressResultMap[]> {
    return this.apiService.get('/v1/results/' + memberId).pipe(map((data: any) => data.data));
  }

  // prettier-ignore
  getProgressResultsById(memberId: number, progressId: string, resultId: string): Observable<Progress> {
    return this.apiService.get('/v1/results/' + memberId + '/' + resultId + '/' + progressId).pipe(
      map((data: any) => data.data),
      map((progress: Progress) => this.transformProgress(progress))
    );
  }

  setSkillToProgress(id: number, body: any): Observable<Progress> {
    return this.apiService.post('/v1/progress/' + id + '/addSkill', body).pipe(
      map((data: any) => data.data),
      map((progress: Progress) => this.transformProgress(progress))
    );
  }

  setSkillTaskProgress(id: string, body: any): Observable<Progress> {
    return this.apiService.post('/v1/progress/addProgressResult/' + id, body).pipe(
      map((data: any) => data.data),
      map((progress: Progress) => this.transformProgress(progress))
    );
  }

  setSkillTaskStatus(id: string, body: any): Observable<Progress> {
    return this.apiService.post('/v1/progress/setTaskStatus/' + id, body).pipe(
      map((data: any) => data.data),
      map((progress: Progress) => this.transformProgress(progress))
    );
  }

  setDeadline(id: number, body: any): Observable<any> {
    return this.apiService
      .post('/v1/progress/' + id + '/setDeadline', body)
      .pipe(map((data: any) => data.data));
  }

  setProgressRemove(id: string): Observable<any> {
    return this.apiService.post('/v1/progress/remove/' + id).pipe(map((data: any) => data.data));
  }

  setProgressComplete(memberId: number): Observable<any> {
    return this.apiService
      .post('/v1/results/' + memberId + '/finalScore')
      .pipe(map((data: any) => data.data));
  }
}
