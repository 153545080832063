<!-- @format -->

<ng-container>
	<div #target>
		<ng-content select="[slot=target]"></ng-content>
	</div>
	<div
		class="absolute z-30"
		*ngIf="state$ | async"
		#content
		[@dropdownAnimation]="{value: state$.getValue()}"
	>
		<ng-content select="[slot=content]"></ng-content>
	</div>
</ng-container>
