<!-- @format -->

<footer class="flex items-center bg-white effect-02dp pl-64 tablet:pl-0">
	<div class="flex items-center justify-between w-full py-2 px-8">
		<div class="flex items-center justify-start invisible min-w-[15rem]"></div>
		<div
			class="flex items-center justify-end space-x-10"
			[ngSwitch]="trajectoryIsAddSkills"
		>
			<div *ngSwitchCase="true" class="flex items-center justify-center space-x-1">
				<!-- prettier-ignore -->
				<span
          class="block text-button-1 text-blue-grey-400"
          i18n="Редактирование траектории|Текст: Выбрано@@app-footer-trajectory.add-skills-selected"
        >
          Выбрано
        </span>
				<span class="block text-button-1 text-blue-grey-400">
					({{ trajectory.skills.length || 0 }})
				</span>
			</div>
			<button
				*ngSwitchDefault
				class="btn btn-on-primary inline md"
				(click)="onGotoAddSkills()"
			>
				<figure class="relative">
					<i class="absolute" appSvgIcon appIcon="plus" appSquare="24"></i>
				</figure>
				<!-- prettier-ignore -->
				<span
					class="text-button-1 text-primary-A400"
					i18n="Редактирование траектории|Ссылка: Добавить@@app-footer-trajectory.goto-add-skills"
				>
					Добавить
				</span>
			</button>
		</div>
		<div
			class="flex items-center justify-end space-x-4"
			[ngSwitch]="trajectoryIsAddSkills"
		>
			<ng-container *ngSwitchCase="true">
				<div class="hidden md:block">
					<button
						class="btn btn-primary inline md"
						data-pw="button-skill-submit"
						(click)="onAddSkills()"
					>
						<figure class="relative">
							<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
						</figure>
						<!-- prettier-ignore -->
						<span
              class="block"
              i18n="Редактирование траектории|Кнопка: Добавить@@app-footer-trajectory.add-skills"
            >
              Добавить
            </span>
					</button>
				</div>
				<div class="block md:hidden">
					<button
						class="btn btn-primary inline md"
						data-pw="button-skill-submit"
						(click)="onAddSkills()"
					>
						<figure class="relative">
							<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
						</figure>
					</button>
				</div>
			</ng-container>
			<ng-container *ngSwitchDefault>
				<div class="hidden md:block">
					<button
						class="btn btn-primary inline md"
						data-pw="button-skill-submit"
						(click)="onFinish()"
					>
						<figure class="relative">
							<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
						</figure>
						<!-- prettier-ignore -->
						<span
              class="block"
              i18n="Редактирование траектории|Кнопка: Завершить@@app-footer-trajectory.complete"
            >
              Завершить
            </span>
					</button>
				</div>
				<div class="block md:hidden">
					<button
						class="btn btn-primary inline md"
						data-pw="button-skill-submit"
						(click)="onFinish()"
					>
						<figure class="relative">
							<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
						</figure>
					</button>
				</div>
			</ng-container>
		</div>
	</div>
</footer>
