<!-- @format -->

<div>
	<app-loader-http (loaded)="isLoading = $event"></app-loader-http>
	<div class="relative">
		<ng-content></ng-content>
		<div
			*ngIf="isLoading"
			class="absolute top-0 left-0 w-full h-full bg-white opacity-50 cursor-not-allowed"
		></div>
	</div>
</div>
