<!-- @format -->

<section>
	<ul
		class="tabs relative flex tab-group-head w-[fit-content] mb-8"
		[style.--tabs-count]="skill.taskList.length ? 2 : 1"
		*ngIf="skill$ | async as skill"
	>
		<li class="block" [class.active]="descriptionRla.isActive">
			<!-- prettier-ignore -->
			<a
				class="tab flex items-center justify-center text-button-2 border-b-2 border-b-blue-grey-200 hover:border-b-blue-grey-400 rounded-t-lg w-44 h-12"
        data-pw="button-skill-description"
				[ngClass]="descriptionRla.isActive ? 'text-primary-A400 pointer-events-none' : 'text-blue-grey-200 hover:text-blue-grey-400'"
				[routerLink]="['description']"
				[routerLinkActive]="['active']"
        [queryParamsHandling]="'merge'"
				#descriptionRla="routerLinkActive"
				i18n="Просмотр навыка|Ссылка: Описание@@app-skill-tabs.description"
			>
				Описание
			</a>
		</li>
		<li
			class="block"
			[class.active]="taskListRla.isActive"
			*ngIf="skill.taskList?.length"
		>
			<!-- prettier-ignore -->
			<a
				class="tab flex items-center justify-center text-button-2 border-b-2 border-b-blue-grey-200 hover:border-b-blue-grey-400 rounded-t-lg w-44 h-12"
        data-pw="button-skill-tasks"
        [ngClass]="taskListRla.isActive ? 'text-primary-A400 pointer-events-none' : 'text-blue-grey-200 hover:text-blue-grey-400'"
				[routerLink]="['tasks']"
				[routerLinkActive]="['active']"
        [queryParamsHandling]="'merge'"
				#taskListRla="routerLinkActive"
				i18n="Просмотр навыка|Ссылка: Задачи@@app-skill-tabs.tasks"
			>
				Задачи ({{ skill.taskList.length }})
			</a>
		</li>
		<li class="underline"></li>
	</ul>
</section>
