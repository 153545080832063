/** @format */

import { EventEmitter, Inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, delay, EMPTY, filter, map, Observable, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import {
  Configuration,
  Skill,
  SkillEdit,
  SkillErrors,
  SkillImage,
  SkillPagination,
  SkillTask,
  SkillTemplate,
  WebSocketStatus
} from '../models';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SkillProto } from '../proto';
import { OutputBlockData, OutputData, SavedData } from '@editorjs/editorjs/types/data-formats';
import EditorJS, { API } from '@editorjs/editorjs';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, SnackbarService } from '../services';
import * as google_protobuf_struct_pb from 'google-protobuf/google/protobuf/struct_pb';

@Injectable({
  providedIn: 'root'
})
export class SkillService {
  webSocketOrigin!: string;
  webSocketToken!: string;
  webSocketConnect!: WebSocket;
  webSocketProto!: any;
  webSocketStatus$ = new BehaviorSubject<WebSocketStatus>({ uuid: 0, status: 3 });
  webSocketInterval!: number;
  webSocketIntervalIteration: number = 0;

  skill$ = new BehaviorSubject<Skill>({} as Skill);
  skillIsEdit$ = new BehaviorSubject<boolean>(false);
  skillIsEditDebounce: number = 100;

  skillErrors$ = new BehaviorSubject<SkillErrors>({} as SkillErrors);

  /** Confirmation modal */

  skillConfirmationModalToggle = new BehaviorSubject<boolean>(false);
  skillConfirmationModalNavigationStartUrl!: string | undefined;
  skillConfirmationModalOnSaveDraft: EventEmitter<void> = new EventEmitter();
  skillConfirmationModalOnPublishSkill: EventEmitter<Skill | void> = new EventEmitter();
  skillConfirmationModalOnPublishSkillIndividual: EventEmitter<Skill | void> = new EventEmitter();

  /** Template modal */

  skillTemplateModalToggle = new BehaviorSubject<boolean>(false);
  skillTemplateModalOnApplyNewTask: EventEmitter<SkillTemplate> = new EventEmitter();
  skillTemplateModalOnApply: EventEmitter<SkillTemplate> = new EventEmitter();

  /** Next skill modal */

  skillNextModalToggle = new BehaviorSubject<boolean>(false);
  skillNextModalPayload!: Skill | undefined;

  constructor(
    @Inject(APP_CONFIG)
    private configuration: Configuration,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private http: HttpClient,
    private snackbarService: SnackbarService
  ) {
    this.webSocketOrigin = this.configuration.webSocketOrigin;
  }

  transformSkill(skill: Skill): Skill {
    skill = {
      ...skill,
      // @ts-ignore
      taskList: skill.tasks_list
    };

    // @ts-ignore
    delete skill.tasks_list;

    return skill;
  }

  /** WEBSOCKET */

  openWebSocketConnect(token: string): void {
    /** https://gitlab.com/kostylworks/dabster-golang/-/tree/master/frontend */

    this.webSocketProto = SkillProto;
    this.webSocketToken = token;

    /** OPEN WEBSOCKET CONNECTION */

    // prettier-ignore
    this.webSocketConnect = new WebSocket(this.webSocketOrigin + '/ws?token=' + this.webSocketToken);
    this.webSocketConnect.binaryType = 'arraybuffer';

    /** WEBSOCKET onmessage */

    this.webSocketConnect.onmessage = (messageEvent: MessageEvent) => {
      const message: any = this.webSocketProto.Message.deserializeBinary(messageEvent.data);

      /** If it's a technical message */

      if (message.getType() === 0) {
        const webSocketStatus: WebSocketStatus = {
          uuid: message.getUuid(),
          status: message.getTechnical().getCode()
        };

        of(webSocketStatus)
          .pipe(
            first(),
            delay(500),
            // prettier-ignore
            filter((webSocketStatus: WebSocketStatus) => this.webSocketStatus$.getValue().uuid === webSocketStatus.uuid),
            tap((webSocketStatus: WebSocketStatus) => this.webSocketStatus$.next(webSocketStatus)),
            delay(2000)
          )
          .subscribe({
            next: (status: WebSocketStatus) => this.webSocketStatus$.next({ ...status, status: 3 }),
            error: (error: any) => console.error(error)
          });
      }
    };

    /** SET skillIsEdit$ */

    this.skillIsEdit$.next(true);

    /** WEBSOCKET ping */

    this.webSocketIntervalIteration++;
    this.webSocketInterval = setInterval(() => {
      if ([2, 3].includes(this.webSocketConnect.readyState)) {
        clearInterval(this.webSocketInterval);

        this.openWebSocketConnect(token);
      }
    }, this.webSocketIntervalIteration * 500);
  }

  closeWebSocketConnect(): void {
    this.webSocketProto = undefined;
    this.webSocketToken = '';

    /** OPEN WEBSOCKET CONNECTION */

    clearInterval(this.webSocketInterval);

    this.webSocketConnect.close();

    /** SET skillIsEdit$ */

    this.skillIsEdit$.next(false);
  }

  getWebSocketOrigin(path?: string): string {
    const urlSearchParams: URLSearchParams = new URLSearchParams({ token: this.webSocketToken });

    const webSocketOrigin = (): string => {
      if (this.configuration.production) {
        return this.webSocketOrigin.replace('wss://', 'https://');
      } else {
        return this.webSocketOrigin.replace('ws://', 'http://');
      }
    };

    return webSocketOrigin() + (path || '') + '?' + urlSearchParams.toString();
  }

  /** SKILL EDIT */

  onAddSkill(skill: Skill): Observable<Skill> {
    /** Avoid error when editor trying to initialize with empty blocks */

    skill.taskList = skill.taskList.map((skillTask: SkillTask) => {
      if (!skillTask.description.blocks.length) {
        return {
          ...skillTask,
          description: {
            ...skillTask.description,
            blocks: [
              {
                id: uuidv4().split('-').shift(),
                type: 'paragraph',
                data: {
                  text: ''
                }
              }
            ]
          }
        };
      }

      return skillTask;
    });

    this.skill$.next(skill);

    return of(this.skill$.getValue());
  }

  onRemoveSkill(): Observable<Skill> {
    this.skill$.next({} as Skill);

    return of(this.skill$.getValue());
  }

  setSkillTitle(title: string): Observable<never> {
    const skill: Skill = this.skill$.getValue();

    if (skill.title !== title) {
      /** Make Web Socket payload */

      const payload: any = new this.webSocketProto.SkillPayload();

      payload.setSkillid(skill.id);
      payload.setAction(this.webSocketProto.SkillPayloadAction.SKILL_UPDATE_TITLE);
      payload.setTitle(title);

      const message: any = new this.webSocketProto.Message();

      message.setUuid(String(Date.now()));
      message.setType(this.webSocketProto.MessageType.SKILL);
      message.setSkill(payload);

      /** Update backend */
      this.webSocketSend(message);

      /** Update behaviour subject */

      this.skill$.next({
        ...skill,
        title
      });
    }

    return EMPTY;
  }

  setSkillDescription(description: string): Observable<never> {
    const skill: Skill = this.skill$.getValue();

    if (skill.description !== description) {
      /** Make Web Socket payload */

      const payload: any = new this.webSocketProto.SkillPayload();

      payload.setSkillid(skill.id);
      payload.setAction(this.webSocketProto.SkillPayloadAction.SKILL_UPDATE_DESCRIPTION);
      payload.setDescription(description);

      const message: any = new this.webSocketProto.Message();

      message.setUuid(String(Date.now()));
      message.setType(this.webSocketProto.MessageType.SKILL);
      message.setSkill(payload);

      /** Update backend */
      this.webSocketSend(message);

      /** Update behaviour subject */

      this.skill$.next({
        ...skill,
        description
      });
    }

    return EMPTY;
  }

  setImage(skillImage: SkillImage): Observable<never> {
    const skill: Skill = this.skill$.getValue();

    /** Update behaviour subject */

    this.skill$.next({
      ...skill,
      image: skillImage
    });

    return EMPTY;
  }

  uploadImage(body: any): Observable<any> {
    return this.http.post(this.getWebSocketOrigin('/covers/set'), body);
  }

  getImageList(): Observable<any> {
    return this.http.get(this.getWebSocketOrigin('/covers/list'));
  }

  skillValidation(skill: Skill): boolean {
    const skillTitleIsValid: boolean = ((): boolean => {
      const min: boolean = skill.title.length < 3;
      const max: boolean = skill.title.length > 255;

      if (min || max) {
        this.snackbarService.info(
          $localize`:Снекбар|Сообщение - Заголовок навыка должен быть от 3 до 255 символов@@app-snackbar.skill-create-title-length-error:Заголовок навыка должен быть от 3 до 255 символов`,
          {
            timeout: 6000,
            icon: 'attention'
          }
        );

        return false;
      }

      return true;
    })();

    /** set title error */
    this.skillErrors$.next({ ...this.skillErrors$.getValue(), title: !skillTitleIsValid });

    const skillDescriptionIsValid: boolean = ((): boolean => {
      const min: boolean = skill.description.length < 3;

      if (min) {
        this.skillErrors$.next({ ...this.skillErrors$.getValue(), description: true });

        this.snackbarService.info(
          $localize`:Снекбар|Сообщение - Описание навыка должно быть от 3 символов@@app-snackbar.skill-create-description-length-error:Описание навыка должно быть от 3 символов`,
          {
            timeout: 6000,
            icon: 'attention'
          }
        );

        return false;
      }

      return true;
    })();

    /** set description error */
    // prettier-ignore
    this.skillErrors$.next({...this.skillErrors$.getValue(), description: !skillDescriptionIsValid});

    const taskListWithError: SkillTask[] = [];

    const skillTaskTitleIsValid: boolean = ((): boolean => {
      return skill.taskList
        .map((skillTask: SkillTask) => {
          const min: boolean = skillTask.title.length < 1;
          const max: boolean = skillTask.title.length > 255;

          if (min || max) {
            taskListWithError.push(skillTask);

            return false;
          }

          return true;
        })
        .every((state: boolean) => state);
    })();

    /** set taskListErrors error */
    if (!skillTaskTitleIsValid) {
      // prettier-ignore
      this.skillErrors$.next({...this.skillErrors$.getValue(), taskListErrors: taskListWithError});

      this.snackbarService.info(
        $localize`:Снекбар|Сообщение - Заголовок задачи должен быть от 1 до 255 символов@@app-snackbar.skill-create-task-title-length-error:Заголовок задачи должен быть от 1 до 255 символов`,
        {
          timeout: 6000,
          icon: 'attention'
        }
      );
    }

    return skillTitleIsValid && skillDescriptionIsValid && skillTaskTitleIsValid;
  }

  /** SKILL TASK EDIT */

  onAddSkillTask(skillTask?: SkillTask): Observable<SkillTask> {
    const skill: Skill = this.skill$.getValue();
    const skillTaskList: SkillTask[] = skill.taskList || [];

    /**
     * If "skillTask" not provided we create absolutely new one
     * Avoid unnecessary changes of BehaviorSubject which call many subscriptions
     **/

    if (!skillTask) {
      skillTask = {
        id: uuidv4(),
        title: '',
        description: {
          time: Date.now(),
          blocks: [
            // Avoid undefined holder error
            {
              id: uuidv4().split('-').shift(),
              type: 'paragraph',
              data: {
                text: ''
              }
            }
          ],
          version: EditorJS.version
        },
        created_at: new Date().toDateString(),
        updated_at: new Date().toDateString()
      };
    }

    /**
     * Only if we sure that task not exist
     **/

    // prettier-ignore
    const skillTaskExist: SkillTask | undefined = skillTaskList.find((skillTaskExist: SkillTask) => skillTaskExist.id === skillTask?.id);

    if (!skillTaskExist) {
      /** Make Web Socket payload */

      const payload = new this.webSocketProto.TaskPayload();

      payload.setSkillid(skill.id);
      payload.setTaskid(skillTask.id);
      payload.setAction(this.webSocketProto.TaskPayloadAction.TASK_CREATE);

      const message = new this.webSocketProto.Message();

      message.setUuid(String(Date.now()));
      message.setType(this.webSocketProto.MessageType.TASK);
      message.setTask(payload);

      /** Update backend */
      this.webSocketSend(message);

      /** Update behaviour subject */

      this.skill$.next({
        ...skill,
        taskList: [...skillTaskList, skillTask as SkillTask]
      });
    }

    return of(skillTask as SkillTask);
  }

  onRestoreSkillTask(skillTask: SkillTask): Observable<SkillTask> {
    const skill: Skill = this.skill$.getValue();
    const skillTaskList: SkillTask[] = skill.taskList || [];

    /** Make Web Socket payload */

    const insertTaskAction = new this.webSocketProto.InsertTaskAction();

    insertTaskAction.setTitle(skillTask.title);

    skillTask.description.blocks.forEach((taskBlock: OutputBlockData) => {
      const block = new this.webSocketProto.Block();

      block.setId(taskBlock?.id);
      block.setType(taskBlock?.type);
      block.setData(google_protobuf_struct_pb.Struct.fromJavaScript(taskBlock?.data));

      insertTaskAction.addBlocks(block);
    });

    const payload = new this.webSocketProto.TaskPayload();

    payload.setSkillid(skill.id);
    payload.setTaskid(skillTask.id);
    payload.setAction(this.webSocketProto.TaskPayloadAction.TASK_INSERT);
    payload.setInsertaction(insertTaskAction);

    const message = new this.webSocketProto.Message();

    message.setUuid(String(Date.now()));
    message.setType(this.webSocketProto.MessageType.TASK);
    message.setTask(payload);

    /** Update backend */
    this.webSocketSend(message);

    /** Update behaviour subject */

    this.skill$.next({
      ...skill,
      taskList: [...skillTaskList, skillTask as SkillTask]
    });

    return of(skillTask as SkillTask);
  }

  onRemoveSkillTaskById(id: string): Observable<SkillTask | undefined> {
    const skill: Skill = this.skill$.getValue();
    const skillTaskList: SkillTask[] = skill.taskList;
    const skillTask: SkillTask | undefined = skillTaskList.find((skillTask: SkillTask) => {
      return skillTask.id === id;
    });

    /**
     * Only if we sure about element exist we do changes
     * Avoid unnecessary changes of BehaviorSubject which call many subscriptions
     **/

    if (skillTask) {
      /** Make Web Socket payload */

      const payload = new this.webSocketProto.TaskPayload();

      payload.setSkillid(skill.id);
      payload.setTaskid(skillTask.id);
      payload.setAction(this.webSocketProto.TaskPayloadAction.TASK_DELETE);

      const message = new this.webSocketProto.Message();

      message.setUuid(String(Date.now()));
      message.setType(this.webSocketProto.MessageType.TASK);
      message.setTask(payload);

      /** Update backend */
      this.webSocketSend(message);

      /** Update behaviour subject */

      this.skill$.next({
        ...skill,
        taskList: skillTaskList.filter((skillTask: SkillTask) => {
          return skillTask.id !== id;
        })
      });
    }

    return of(skillTask);
  }

  setSkillTaskTitleById(id: string, title: string): Observable<never> {
    const skill: Skill = this.skill$.getValue();
    const skillTask: SkillTask | undefined = skill.taskList.find((skillTask: SkillTask) => {
      return skillTask.id === id;
    });

    /**
     * Only if we sure that title changed
     * Avoid unnecessary changes of BehaviorSubject which call many subscriptions
     **/

    if (!!skillTask && skillTask.title !== title) {
      /** Make Web Socket payload */

      const payload = new this.webSocketProto.TaskPayload();

      payload.setSkillid(skill.id);
      payload.setTaskid(id);
      payload.setAction(this.webSocketProto.TaskPayloadAction.TASK_UPDATE_TITLE);
      payload.setTitle(title);

      const message = new this.webSocketProto.Message();

      message.setUuid(String(Date.now()));
      message.setType(this.webSocketProto.MessageType.TASK);
      message.setTask(payload);

      /** Update backend */
      this.webSocketSend(message);

      /** Update behaviour subject */

      skill.taskList.map((skillTask: SkillTask) => {
        return skillTask.id === id && (skillTask.title = title);
      });

      this.skill$.next({
        ...skill,
        taskList: skill.taskList
      });
    }

    return EMPTY;
  }

  setSkillTaskPosition(id: string, oldIndex: number, newIndex: number): Observable<never> {
    const skill: Skill = this.skill$.getValue();
    const skillTask: SkillTask | undefined = skill.taskList.find((skillTask: SkillTask) => {
      return skillTask.id === id;
    });

    if (!!skillTask && oldIndex !== newIndex) {
      /** Make Task action */

      const moveAction = new this.webSocketProto.MoveTaskAction();

      moveAction.setOldindex(oldIndex);
      moveAction.setNewindex(newIndex);

      /** Make Web Socket payload */

      const payload = new this.webSocketProto.TaskPayload();

      payload.setSkillid(skill.id);
      payload.setTaskid(id);
      payload.setAction(this.webSocketProto.TaskPayloadAction.TASK_MOVE);
      payload.setMoveaction(moveAction);

      const message = new this.webSocketProto.Message();

      message.setUuid(String(Date.now()));
      message.setType(this.webSocketProto.MessageType.TASK);
      message.setTask(payload);

      /** Update backend */
      this.webSocketSend(message);

      /** Update behaviour subject */

      const skillTaskOldIndex = skill.taskList.splice(oldIndex, 1).shift();

      if (skillTaskOldIndex) {
        skill.taskList.splice(newIndex, 0, skillTaskOldIndex);

        this.skill$.next({
          ...skill,
          taskList: skill.taskList
        });
      }
    }

    return EMPTY;
  }

  // prettier-ignore
  setSkillTaskDescriptionById(id: string, outputData: OutputData, customEvent: CustomEvent, api: API): Observable<never> {
    const skill: Skill = this.skill$.getValue();
    const skillTask: SkillTask | undefined = skill.taskList.find((skillTask: SkillTask) => {
      return skillTask.id === id;
    });

    /**
     * Only if we sure that description changed
     * Avoid unnecessary changes of BehaviorSubject which call many subscriptions
     **/

    if (!!skillTask) {
      /** Make Web Socket payload */

      customEvent.detail.target.save().then((savedData: SavedData) => {
        if (['attaches'].includes(savedData.tool)) {
          // @ts-ignore
          if (Object.values(savedData.data.file).every((value: string | undefined) => !value)) {
            savedData.data.file = {
              extension: '',
              name: '',
              size: '',
              url: '',
            }
          }
        }

        if (['image'].includes(savedData.tool)) {
          if (!Object.keys(savedData.data.file).length) {
            savedData.data.file = [];
          }
        }

        const eventTypeMap: any = {
          ['block-added']: this.webSocketProto.Action.BLOCK_CREATE,
          ['block-changed']: this.webSocketProto.Action.BLOCK_UPDATE,
          ['block-moved']: this.webSocketProto.Action.BLOCK_MOVE,
          ['block-removed']: this.webSocketProto.Action.BLOCK_DELETE
        };

        const block = new this.webSocketProto.Block();

        block.setId(savedData?.id);
        block.setType(savedData?.tool);
        block.setData(google_protobuf_struct_pb.Struct.fromJavaScript(savedData?.data));

        const action = new this.webSocketProto.BlockAction();

        action.setAction(eventTypeMap[customEvent.type]);

        if (['block-removed'].includes(customEvent.type)) {
          action.setBlock(block);
        }

        /** If (changed|added) then adding index (upsert crutch) */

        if (['block-changed', 'block-added'].includes(customEvent.type)) {
          block.setIndex(customEvent.detail.index);

          action.setBlock(block);
        }

        /** If moved then adding both indexes */

        if (['block-moved'].includes(customEvent.type)) {
          const detail: any = customEvent.detail;

          if ('fromIndex' in detail && 'toIndex' in detail) {
            const moveAction = new this.webSocketProto.BlockMove();

            moveAction.setOldindex(detail.fromIndex);
            moveAction.setNewindex(detail.toIndex);
            moveAction.setId(detail.target.id);

            action.setBlockmove(moveAction);
          }
        }

        const payload = new this.webSocketProto.TaskPayload();

        payload.setSkillid(skill.id);
        payload.setTaskid(skillTask?.id);
        payload.setAction(this.webSocketProto.TaskPayloadAction.TASK_UPDATE_DESCRIPTION);
        payload.setBlockaction(action);

        const message = new this.webSocketProto.Message();

        message.setUuid(String(Date.now()));
        message.setType(this.webSocketProto.MessageType.TASK);
        message.setTask(payload);

        this.webSocketSend(message);
      });

      /** Update behaviour subject */

      skill.taskList.map((skillTask: SkillTask) => {
        return skillTask.id === id && (skillTask.description = outputData);
      });

      this.skill$.next({
        ...skill,
        taskList: skill.taskList
      });
    }

    return EMPTY;
  }

  uploadByFileAttach(body: any): Observable<any> {
    return this.http.post(this.getWebSocketOrigin('/static/upload/fromAttachment'), body);
  }

  uploadByFile(body: any): Observable<any> {
    return this.http.post(this.getWebSocketOrigin('/static/upload/fromFile'), body);
  }

  uploadByUrl(body: any): Observable<any> {
    return this.http.post(this.getWebSocketOrigin('/static/upload/fromUrl'), body);
  }

  getMetadataByUrl(): string {
    return this.getWebSocketOrigin('/helper/url/metadata');
  }

  webSocketSend(message: any): void {
    this.webSocketStatus$.next({ uuid: message.getUuid(), status: 2 });

    this.webSocketConnect.send(message.serializeBinary());
  }

  /** API */

  createSkill(): Observable<SkillEdit> {
    return this.apiService.post('/v1/skill/create').pipe(
      map((data: any) => data.data),
      map((skillEdit: SkillEdit) => {
        skillEdit = {
          ...skillEdit,
          skill: this.transformSkill(skillEdit.skill)
        };

        return skillEdit;
      })
    );
  }

  upsertSkillIndividual(skillId: string, body?: any): Observable<SkillEdit> {
    return this.apiService.post('/v1/skill/' + skillId + '/individual', body).pipe(
      map((data: any) => data.data),
      map((skillEdit: SkillEdit) => {
        skillEdit = {
          ...skillEdit,
          skill: this.transformSkill(skillEdit.skill)
        };

        return skillEdit;
      })
    );
  }

  editSkill(id: string): Observable<SkillEdit> {
    return this.apiService.post('/v1/skill/' + id).pipe(
      map((data: any) => data.data),
      map((skillEdit: SkillEdit) => {
        skillEdit = {
          ...skillEdit,
          skill: this.transformSkill(skillEdit.skill)
        };

        return skillEdit;
      })
    );
  }

  publishSkill(id: string, body?: any): Observable<Skill> {
    return this.apiService.post('/v1/skill/' + id + '/publish', body).pipe(
      map((data: any) => data.data),
      map((skill: Skill) => this.transformSkill(skill))
    );
  }

  publishSkillIndividual(id: string, memberId: number): Observable<Skill> {
    return this.apiService
      .post('/v1/skill/' + id + '/member/' + memberId + '/publishIndividual')
      .pipe(
        map((data: any) => data.data),
        map((skill: Skill) => this.transformSkill(skill))
      );
  }

  getLibraryAll(params?: any): Observable<SkillPagination> {
    return this.apiService.get('/v1/lib/list', params).pipe(map((data: any) => data.data));
  }

  getLibraryById(id: string): Observable<Skill> {
    return this.apiService.get('/v1/lib/' + id + '/show').pipe(
      map((data: any) => data.data),
      map((skill: Skill) => this.transformSkill(skill))
    );
  }

  getDraftAll(params?: any): Observable<SkillPagination> {
    return this.apiService.get('/v1/skill/drafts', params).pipe(map((data: any) => data.data));
  }

  getDraftById(id: string): Observable<Skill> {
    return this.apiService.get('/v1/skill/' + id + '/show').pipe(
      map((data: any) => data.data),
      map((skill: Skill) => this.transformSkill(skill))
    );
  }

  postRemoveSkill(id: string): Observable<Skill> {
    return this.apiService.post('/v1/lib/' + id + '/delete').pipe(
      map((data: any) => data.data),
      map((skill: Skill) => this.transformSkill(skill))
    );
  }

  postRemoveDraft(id: string): Observable<Skill> {
    return this.apiService.post('/v1/skill/' + id + '/delete').pipe(
      map((data: any) => data.data),
      map((skill: Skill) => this.transformSkill(skill))
    );
  }
}
