/** @format */

import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  APP_CONFIG,
  Configuration,
  EditGuardDeactivate,
  HelperService,
  PlatformService,
  Progress,
  ProgressService,
  Skill,
  SkillService,
  SkillTask,
  SnackbarService,
  WebSocketStatus
} from '../../../../core';
import { ActivatedRoute, Event as RouterEvent, NavigationStart, Router } from '@angular/router';
import { filter, map, Subscription } from 'rxjs';
import { DOCUMENT, Location } from '@angular/common';

@Component({
  selector: 'app-footer-skill',
  templateUrl: './footer-skill.component.html'
})
export class FooterSkillComponent implements OnInit, OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  beforeunload($event: BeforeUnloadEvent): void {
    if (this.configuration.production) {
      $event.preventDefault();
      $event.returnValue = true; // без returnValue не работает
    }
  }

  routeEvents$!: Subscription;
  routeQueryParams$!: Subscription;

  skill!: Skill;
  skill$!: Subscription;

  progress!: Progress;
  progress$!: Subscription;

  webSocketStatus!: WebSocketStatus; // status: 0 - 'Сохранено', 1 - 'Ошибка', 2 - 'Сохраняем...', 3 - 'Нет изменений'
  webSocketStatus$!: Subscription;

  isFullscreen!: boolean;

  skillConfirmationModalOnSaveDraft$!: Subscription;
  skillConfirmationModalOnPublishSkill$!: Subscription;
  skillConfirmationModalOnPublishSkillIndividual$!: Subscription;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(APP_CONFIG)
    private configuration: Configuration,
    private skillService: SkillService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackbarService,
    private location: Location,
    private editGuardDeactivate: EditGuardDeactivate,
    private platformService: PlatformService,
    private helperService: HelperService,
    private progressService: ProgressService
  ) {}

  ngOnInit(): void {
    /** Disable exit */

    this.editGuardDeactivate.allowed = false;

    this.skill$ = this.skillService.skill$.subscribe({
      next: (skill: Skill) => (this.skill = skill)
    });

    // prettier-ignore
    this.webSocketStatus$ = this.skillService.webSocketStatus$.subscribe({
      next: (webSocketStatus: WebSocketStatus) => {
        this.webSocketStatus = webSocketStatus;
      }
    });

    this.routeEvents$ = this.router.events
      .pipe(
        filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationStart),
        // @ts-ignore
        // prettier-ignore
        filter((routerEvent: RouterEvent) => !['library/edit', 'library/create'].some((path: string) => routerEvent.url.includes(path))),
        filter(() => !this.editGuardDeactivate.allowed)
      )
      .subscribe({
        next: (routerEvent: RouterEvent) => {
          // @ts-ignore
          this.skillService.skillConfirmationModalNavigationStartUrl = routerEvent.url;

          if (this.platformService.isBrowser()) {
            const window = this.platformService.getWindow();

            /** Check app-aside component => isShownAsideBreakpoint */

            this.platformService.isShownAside.next(!(window.innerWidth <= 1160));
          }

          this.skillService.skillConfirmationModalToggle.next(true);
        }
      });

    this.routeQueryParams$ = this.activatedRoute.queryParams
      .pipe(map((data: any) => data.isFullscreen))
      .subscribe({
        next: (isFullscreen: number | undefined) => (this.isFullscreen = !!isFullscreen)
      });

    // prettier-ignore
    this.skillConfirmationModalOnSaveDraft$ = this.skillService.skillConfirmationModalOnSaveDraft.subscribe({
      next: () => {
        // prettier-ignore
        const path: string = this.skillService.skillConfirmationModalNavigationStartUrl || this.location.path().replace(/create|edit/gi, 'drafts');

        this.onClose(false);

        /** Enable exit */

        this.editGuardDeactivate.allowed = true;

        this.skillService.skillConfirmationModalToggle.next(false);

        /** Close fullscreen first, then redirect (avoid broken animation) */

        this.router
          .navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: null
          })
          .then(() => {
            // @ts-ignore
            const redirectTimeout: number = setTimeout(() => {
              this.router
                .navigateByUrl(path)
                .then(() => console.debug('Route changed'));

              clearTimeout(redirectTimeout);
            }, 300);
          });
      }
    });

    // prettier-ignore
    this.skillConfirmationModalOnPublishSkill$ = this.skillService.skillConfirmationModalOnPublishSkill.subscribe({
      next: (skill: Skill) => {
        // prettier-ignore
        const path: string = this.skillService.skillConfirmationModalNavigationStartUrl || this.location.path().replace(/create|edit/gi, 'skills').replace(this.skill.id, skill.id).replace(this.skill.source_id as string, skill.id);

        this.onClose(false);

        /** Enable exit */

        this.editGuardDeactivate.allowed = true;

        /** Close fullscreen first, then redirect (avoid broken animation) */

        this.router
          .navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: null
          })
          .then(() => {
            const redirectTimeout: number = setTimeout(() => {
              this.router
                .navigateByUrl(path)
                .then(() => {
                  this.snackbarService.info($localize`:Снекбар|Сообщение - Навык успешно создан@@app-snackbar.skill-created:Навык успешно создан`, {
                    icon: 'done',
                  });
                });

              clearTimeout(redirectTimeout);
            }, 300);
          });
      }
    });

    // prettier-ignore
    this.skillConfirmationModalOnPublishSkillIndividual$ = this.skillService.skillConfirmationModalOnPublishSkillIndividual.subscribe({
      next: (skill: Skill) => {
        this.onClose(false);

        /** Enable exit */

        this.editGuardDeactivate.allowed = true;

        /** Do redirect */

        const progress: Progress = this.progressService.progress$.getValue();

        // @ts-ignore
        const path: string = this.location.path().split('?').shift();
        const pathNew: string = path
          .replace('library/edit', 'progress/' + progress.user.team_member_id + '/progress')
          .replace(progress.skill_id, progress.id);

        this.router.navigateByUrl(pathNew).then(() => console.debug('Route changed'));
      }
    });
  }

  ngOnDestroy(): void {
    [
      this.skill$,
      this.webSocketStatus$,
      this.routeEvents$,
      this.routeQueryParams$,
      this.skillConfirmationModalOnSaveDraft$,
      this.skillConfirmationModalOnPublishSkill$,
      this.skillConfirmationModalOnPublishSkillIndividual$
    ].forEach($ => $?.unsubscribe());
  }

  onAddSkillTask(): void {
    if (this.location.path().includes('/description')) {
      this.router
        .navigateByUrl(this.location.path().replace('/description', '/tasks'))
        .then(() => console.debug('Route changed'));
    } else {
      this.skillService.onAddSkillTask().subscribe({
        next: (skillTask: SkillTask) => {
          // prettier-ignore
          const { taskId } = Object.values(this.helperService.getRouteParams(this.activatedRoute.snapshot)).find((object: any) => !!object.taskId);

          this.router.navigateByUrl(this.location.path().replace(taskId, skillTask.id)).then(() => {
            /** Autoscroll through focus */

            const navigateTimeout: number = setTimeout(() => {
              const inputElement: HTMLElement | null = this.document.getElementById(skillTask.id);

              if (!!inputElement) {
                inputElement.focus();
              }

              clearTimeout(navigateTimeout);
            });
          });
        }
      });
    }
  }

  onFinish(): void {
    this.skillService.skillConfirmationModalToggle.next(true);
  }

  onClose(toggle: boolean): void {
    this.skillService.skillConfirmationModalToggle.next(toggle);
    this.skillService.skillConfirmationModalNavigationStartUrl = undefined;
  }
}
