<!-- @format -->

<section>
	<div class="app-error w-full h-screen">
		<div
			class="flex items-center justify-center relative w-full bg-white effect-00dp py-2 px-2"
		>
			<a class="my-2.5" [routerLink]="['/']">
				<img src="assets/logo.svg" alt="Dabster" class="block" />
			</a>
		</div>
		<div class="grid bg-blue-grey-25 overflow-auto w-full h-full px-8 py-12">
			<div
				class="flex items-center justify-center space-x-16 mobile:flex-col mobile:space-x-0 mobile:space-y-8"
			>
				<div class="flex relative flex-col justify-center shrink-0 w-[400px] h-full">
					<ng-container [ngSwitch]="message">
						<div *ngSwitchCase="messageMap[3]" class="flex flex-col justify-center">
							<!-- prettier-ignore -->
							<span
								class="text-headline-3 mb-4"
								i18n="Ошибка|Заголовок: Упс! Такой страницы не существует :-(@@app-error.error-title-4xx"
							>
								Упс! Такой страницы не существует :-(
							</span>
							<span class="text-subtitle-1 text-tertiary-A400 mb-8">
								{{ message }} {{ statusCode }}
							</span>
							<div class="block">
								<!-- prettier-ignore -->
								<span
                  class="text-body-1"
                  i18n="Ошибка|Параграф: К сожалению, запрашиваемая страница не найдена@@app-error.error-paragraph-4xx"
                >
                  К сожалению, запрашиваемая страница не найдена. Возможно, вы перешли по
                  ссылке, в которой была допущена ошибка, или ресурс был удалён. Попробуйте
                  перейти на страницу
                </span>
								<!-- prettier-ignore -->
								<button
                  (click)="onGoToMyOffice()"
                  class="btn btn-primary transparent inline-block underline"
                  i18n="Ошибка|Ссылка: «Мой кабинет»@@app-error.error-link-4xx"
                >
                  «Мой кабинет»
                </button>
							</div>
						</div>
						<div *ngSwitchCase="messageMap[4]" class="flex flex-col justify-center">
							<!-- prettier-ignore -->
							<span
								class="text-headline-3 mb-4"
								i18n="Ошибка|Заголовок: Упс! Сервер не отвечает :-(@@app-error.error-title-5xx"
							>
								Упс! Сервер не отвечает :-(
							</span>
							<span class="text-subtitle-1 text-tertiary-A400 mb-8">
								{{ message }} {{ statusCode }}
							</span>
							<!-- prettier-ignore -->
							<p
								class="text-body-1"
								i18n="Ошибка|Параграф: HTTP-шлюз не отвечает@@app-error.error-paragraph-5xx"
							>
								HTTP-шлюз не отвечает, попробуйте повторить через 30 секунд.
							</p>
						</div>
					</ng-container>
				</div>
				<img
					class="block object-cover w-[400px]"
					src="assets/images/error/error.svg"
					alt="Error"
				/>
			</div>
		</div>
	</div>
</section>
