<!-- @format -->

<section>
	<app-overlay
		*ngIf="!skillIsEditIndividual"
		[appState]="state"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && onClose($event)"
	>
		<div class="relative w-full max-w-[512px] bg-white rounded-lg p-10 pb-8">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="onClose(false)"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div class="mb-8">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-4"
          i18n="Просмотр навыка|Заголовок: Завершить работу над навыком@@app-skill-overlay-publish.work-complete-title"
        >
					Завершить работу над навыком
				</span>
				<!-- prettier-ignore -->
				<p
          class="text-body-1"
          i18n="Просмотр навыка|Параграф: Кажется, вы собираетесь закончить работу над этим навыком@@app-skill-overlay-publish.work-complete-paragraph"
        >
          Кажется, вы собираетесь закончить работу над этим навыком. Если все готово, нажмите «Опубликовать».
          Если вы хотите продолжить работу над навыком позже, нажмите «Сохранить черновик».
        </p>
			</div>
			<form
				class="flex items-center mb-8"
				[formGroup]="autoAssignForm"
				autocomplete="off"
			>
				<fieldset class="checkbox mr-1">
					<input id="isAuto" type="checkbox" formControlName="isAuto" />
					<!-- prettier-ignore -->
					<label
            for="isAuto"
            i18n="Просмотр навыка|Чекбокс: Сделать стартовым навыком@@app-skill-overlay-publish.auto-assign-checkbox"
          >
            Сделать стартовым навыком
          </label>
				</fieldset>
				<div class="flex" appHint appPosition="top">
					<i
						slot="trigger"
						class="transition-colors text-blue-grey-100 hover:text-blue-grey-400 cursor-pointer"
						appSvgIcon
						appIcon="question-inline"
						appSquare="20"
					></i>
					<!-- prettier-ignore -->
					<p
            slot="hint"
            class="rounded-md border border-primary-A100 effect-00dp text-blue-grey-600 bg-white text-caption w-[228px] px-2 py-1"
            i18n="Просмотр навыка|Подсказка: Навык будет автоматически добавляться@@app-skill-overlay-publish.auto-assign-hint"
          >
            Навык будет автоматически добавляться в первый чекпоинт всем новичкам в команде
          </p>
				</div>
			</form>
			<div class="flex items-center justify-between">
				<button
					*ngIf="isShowDraftButton"
					class="btn btn-gray outline md"
					data-pw="button-save-draft"
					[disabled]="autoAssignFormIsSubmitting || !!autoAssignForm.get('isAuto')?.value"
					(click)="onSaveDraft()"
				>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Просмотр навыка|Кнопка: Сохранить черновик@@app-skill-overlay-publish.work-complete-button-cancel"
          >
						Сохранить черновик
					</span>
				</button>
				<button
					class="btn btn-primary inline md"
					data-pw="button-publish-skill"
					[disabled]="autoAssignFormIsSubmitting"
					(click)="onPublishSkill()"
				>
					<figure class="relative">
						<i class="absolute text-white" appSvgIcon appIcon="done" appSquare="24"></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Просмотр навыка|Кнопка: Опубликовать@@app-skill-overlay-publish.work-complete-button-submit"
          >
						Опубликовать
					</span>
				</button>
			</div>
		</div>
	</app-overlay>
	<app-overlay
		*ngIf="!!skillIsEditIndividual"
		[appState]="state"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && onClose($event)"
	>
		<div class="relative w-full max-w-[688px] bg-white rounded-lg p-10 pb-8">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="onClose(false)"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div class="mb-4">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-4"
          i18n="Просмотр навыка|Заголовок: Завершить работу над навыком тиммейта@@app-skill-overlay-publish.work-individual-complete-title"
        >
					Завершить работу над навыком тиммейта
				</span>
				<!-- prettier-ignore -->
				<p
          class="text-body-1"
          i18n="Просмотр навыка|Параграф: Эти изменения сохранятся только в навыке тиммета. Изменения не будут опубликованы в навыке в библиотеке.@@app-skill-overlay-publish.work-individual-complete-paragraph"
        >
          Эти изменения сохранятся только в навыке тиммета. Изменения не будут опубликованы в навыке в библиотеке.
        </p>
			</div>
			<div
				class="flex flex-col justify-center bg-blue-grey-25 effect-01dp rounded-md w-full mb-8 p-4"
			>
				<div class="flex items-center justify-between space-x-4 mb-1">
					<span class="flex text-body-2 text-blue-grey-600 line-through line-clamp-1">
						{{ progress.skillSnapshot?.title }}
					</span>
					<div class="flex items-center justify-end space-x-1">
						<i
							class="text-blue-grey-600"
							appSvgIcon
							appIcon="bookmarks"
							appSquare="20"
							appViewBox="0 0 24 24"
						></i>
						<!-- prettier-ignore -->
						<span
              class="text-body-2 text-blue-grey-600"
              i18n="Просмотр навыка|Текст: Задачи:@@app-skill-overlay-publish.work-complete-checkpoint-tasks-individual"
            >
              Задачи:
            </span>
						<span class="text-body-2 text-blue-grey-500 line-through">
							{{ progress.skillSnapshot?.taskList?.length }}
						</span>
						<i
							class="text-blue-grey-600 rotate-180"
							appSvgIcon
							appIcon="arrow-back"
							appWidth="16"
							appHeight="16"
							appViewBox="0 0 20 20"
						></i>
						<span class="text-body-2 text-blue-grey-900">
							{{ progress.skill.taskList.length }}
						</span>
					</div>
				</div>
				<div class="flex items-start justify-start space-x-1 mb-4">
					<i
						class="text-gray-900 rotate-180"
						appSvgIcon
						appIcon="arrow-back"
						appWidth="18"
						appHeight="18"
						appViewBox="0 0 20 20"
					></i>
					<span class="flex text-subtitle-2 text-gray-900 line-clamp-1">
						{{ progress.skill.title }}
					</span>
				</div>
				<div class="flex items-center justify-between space-x-4 bg-white rounded-3xl p-1">
					<div class="flex items-center justify-start space-x-2">
						<app-avatar [appUser]="progress.user"></app-avatar>
						<span class="block text-body-2 text-blue-grey-900 line-clamp-1">
							{{ progress.user.name }} {{ progress.user.surname }}
						</span>
					</div>
					<div class="flex items-center justify-end space-x-1 !mr-3">
						<i class="text-primary-A400" appSvgIcon appIcon="flag" appSquare="17"></i>
						<!-- prettier-ignore -->
						<span
							class="text-body-2 text-blue-grey-600"
							i18n="Просмотр навыка|Текст: Чекпоинт:@@app-skill-overlay-publish.work-complete-checkpoint-individual"
						>
							Чекпоинт:
						</span>
						<ng-container [ngSwitch]="!!progress.user.deadline">
							<i
								*ngSwitchCase="false"
								appSvgIcon
								appIcon="endless-2"
								appWidth="48"
								appHeight="17"
								appViewBox="0 0 98 42"
								class="text-primary-A400"
							></i>
							<span class="text-subtitle-2 text-blue-grey-900" *ngSwitchCase="true">
								<ng-container *appLocale="['en']">
									{{ progress.user.deadline | date: "MM.dd.YYYY" }}
								</ng-container>
								<ng-container *appLocale="['ru']">
									{{ progress.user.deadline | date: "dd.MM.YYYY" }}
								</ng-container>
							</span>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="flex items-center justify-between">
				<button
					class="btn btn-gray outline md"
					[disabled]="skillIsEditIndividualIsSubmitting"
					(click)="onClose(false)"
				>
					<figure class="relative -ml-1">
						<i class="absolute" appSvgIcon appIcon="cross" appSquare="24"></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Просмотр навыка|Кнопка: Отмена@@app-skill-overlay-publish.work-complete-individual-button-cancel"
          >
						Отмена
					</span>
				</button>
				<button
					class="btn btn-primary inline md"
					[disabled]="skillIsEditIndividualIsSubmitting"
					(click)="onPublishSkillIndividual()"
				>
					<figure class="relative -ml-1">
						<i class="absolute text-white" appSvgIcon appIcon="done" appSquare="24"></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Просмотр навыка|Кнопка: Сохранить@@app-skill-overlay-publish.work-complete-individual-button-submit"
          >
						Сохранить
					</span>
				</button>
			</div>
		</div>
	</app-overlay>
</section>
