/** @format */

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  HelperService,
  SnackbarService,
  TariffService,
  TeamInvitation,
  TeamInvitationPagination,
  TeamMember,
  TeamMemberPagination,
  TeamService,
  User,
  UserService
} from '../../../../core';
import { Subscription } from 'rxjs';
import { rotateAnimation } from '../../../../app-animations';
import { tap } from 'rxjs/operators';

interface AddForm {
  email: FormControl<string | null>;
  role: FormControl<string | null>;
}

@Component({
  selector: 'app-overlay-team-add-members',
  templateUrl: './overlay-team-add-members.component.html',
  animations: [rotateAnimation]
})
export class OverlayTeamAddMembersComponent implements OnInit, OnDestroy {
  @ViewChild('inputEmail') inputEmail!: ElementRef;

  @Output() finished = new EventEmitter<boolean>();

  @Input()
  set appResetForm(resetFrom: boolean) {
    if (resetFrom) {
      this.setResetForm();
      this.getTeamInvitations();

      this.showTariffNotification = true;
    }
  }

  @Input()
  set appShowSkip(showSkip: boolean) {
    this.showSkip = showSkip;
  }

  @Input()
  set appShowFinishSnackbar(showFinishSnackbar: boolean) {
    this.showFinishSnackbar = showFinishSnackbar;
  }

  user$!: Subscription;
  user!: User;

  teamLimit!: number | null;

  teamMembersSubscription$!: Subscription;
  teamMembersList: TeamMember[] = [];
  teamMembersTotal: number = 0;

  teamInvitationSentSubscription$!: Subscription;
  teamInvitationSentList: TeamInvitation[] = [];
  teamInvitationSentTotal: number = 0;

  teamInvitationList: TeamInvitation[] = [];
  teamInvitationTotal!: number;

  addForm: FormGroup;
  addFormIsLoading: boolean = true;

  onSubmitAddForm: boolean = false;
  onSubmitTeamInvitation: boolean = false;

  showSkip: boolean = false;
  showFinishSnackbar: boolean = true;
  showTariffNotification: boolean = true;

  constructor(
    private userService: UserService,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private teamService: TeamService,
    private snackbarService: SnackbarService,
    private tariffService: TariffService
  ) {
    this.addForm = this.formBuilder.group<AddForm>({
      email: this.formBuilder.control('', [
        Validators.required,
        Validators.email,
        this.helperService.getCustomValidation('isUnique', () => ({
          list: this.teamInvitationList,
          key: 'email'
        }))
      ]),
      role: this.formBuilder.control('teammate', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => {
        this.user = user;

        this.teamLimit = user.team?.tariff.member_limit || null;
      },
      error: (error: any) => console.error(error)
    });

    this.teamMembersSubscription$ = this.teamService.currentTeamMembersSubject.subscribe({
      next: (teamMemberPagination: TeamMemberPagination) => {
        this.teamMembersList = teamMemberPagination.items || [];
        this.teamMembersTotal = teamMemberPagination.total || 0;
      },
      error: (error: any) => console.error(error)
    });

    this.teamInvitationSentSubscription$ = this.teamService.currentTeamInvitationsSubject.subscribe(
      {
        next: (teamInvitationPagination: TeamInvitationPagination) => {
          this.teamInvitationSentList = teamInvitationPagination.invitations || [];
          this.teamInvitationSentTotal = teamInvitationPagination.total || 0;
        },
        error: (error: any) => console.error(error)
      }
    );
  }

  ngOnDestroy(): void {
    // prettier-ignore
    [this.user$, this.teamMembersSubscription$, this.teamInvitationSentSubscription$].forEach($ => $?.unsubscribe());
  }

  setResetForm(): void {
    this.addForm.reset({
      email: '',
      role: 'teammate'
    });

    this.addForm.markAsUntouched();
  }

  onAdd(): void {
    if (this.helperService.getFormValidation(this.addForm)) {
      this.onSubmitAddForm = true;

      this.teamService
        .postTeamInvitationCreate(this.addForm.value)
        .pipe(
          tap(() => this.setResetForm()),
          tap(() => this.getTeamInvitations())
        )
        .subscribe({
          next: () => this.inputEmail.nativeElement.blur(),
          error: () => (this.onSubmitAddForm = false)
        });
    }
  }

  onRemove(teamInvitation: TeamInvitation): void {
    this.onSubmitTeamInvitation = true;

    this.teamService.postTeamInvitationDelete(teamInvitation.id).subscribe({
      next: () => this.getTeamInvitations(),
      error: () => (this.onSubmitTeamInvitation = false)
    });
  }

  onChangeRole(teamInvitation: TeamInvitation, event: any): void {
    this.onSubmitTeamInvitation = true;

    this.teamService
      .postTeamInvitationEditRole(teamInvitation.id, { role: event.roleKey })
      .subscribe({
        next: () => this.getTeamInvitations(),
        error: () => {
          this.onSubmitTeamInvitation = false;

          event.rollback();
        }
      });
  }

  onSubmit(): void {
    if (!!this.teamInvitationList.length) {
      this.onSubmitTeamInvitation = true;

      this.teamService.postTeamInvitationsSend().subscribe({
        next: () => {
          this.onSubmitTeamInvitation = false;

          this.finished.emit(true);

          /** Update for limits */

          this.teamInvitationSentList = this.teamInvitationSentList.concat(this.teamInvitationList);
          this.teamInvitationSentTotal = this.teamInvitationSentTotal + this.teamInvitationTotal;

          if (this.showFinishSnackbar) {
            this.snackbarService.info(
              $localize`:Снекбар|Сообщение - Приглашения отправлены@@app-snackbar.invitations-sent:Приглашения отправлены`,
              {
                icon: 'done'
              }
            );
          }
        },
        error: () => (this.onSubmitTeamInvitation = false)
      });
    }
  }

  getTeamInvitations(): void {
    this.onSubmitAddForm = true;
    this.onSubmitTeamInvitation = true;

    this.teamService.getTeamInvitations().subscribe({
      next: (teamInvitationPagination: TeamInvitationPagination) => {
        this.teamInvitationList = teamInvitationPagination.invitations;
        this.teamInvitationTotal = teamInvitationPagination.total;

        this.onSubmitAddForm = false;
        this.onSubmitTeamInvitation = false;
      },
      error: () => {
        this.onSubmitAddForm = false;
        this.onSubmitTeamInvitation = false;
      },
      complete: () => (this.addFormIsLoading = false)
    });
  }

  onGoToTariff(): void {
    this.tariffService.tariffListModalToggle.next(true);
  }
}
