/** @format */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { authImageAnimation } from '../app-animations';
import { Title } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { TeamService, UserService } from '../core';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  animations: [authImageAnimation]
})
export class InviteComponent implements OnInit {
  routerParams$!: Subscription;

  invitationErrorToggle = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private teamService: TeamService,
    private title: Title
  ) {}

  ngOnInit(): void {
    // prettier-ignore
    this.title.setTitle($localize`:Заголовок|Текст - Dabster | Присоединяйтесь к Dabster@@app-title.app-auth-registration:Dabster | Присоединяйтесь к Dabster`);

    this.routerParams$ = this.route.params.subscribe({
      next: (params: Params) => {
        if ('token' in params) {
          this.userService.isAuthenticated.pipe(first()).subscribe({
            next: (isAuthenticated: boolean) => {
              if (isAuthenticated) {
                this.checkToken(params['token']);
              } else {
                this.router
                  .navigate(['/auth/registration'], {
                    queryParams: {
                      referralToken: params['token']
                    }
                  })
                  .then(() => console.debug('Route changed'));
              }
            },
            error: (error: any) => console.error(error)
          });
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.routerParams$].forEach($ => $?.unsubscribe());
  }

  checkToken(token: string): void {
    this.teamService.getInvitationInfo(token).subscribe({
      next: (invitation: any) => {
        this.router
          .navigate(['/profile/on-boarding'], {
            queryParams: {
              type: invitation.onboarding,
              token
            }
          })
          .then(() => console.debug('Route changed'));
      },
      error: () => (this.invitationErrorToggle = true)
    });
  }
}
