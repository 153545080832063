/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import {
  AsideComponent,
  AvatarComponent,
  AvatarTeamComponent,
  BreadcrumbsComponent,
  CalendarComponent,
  CardProgressComponent,
  CardSkillComponent,
  CardTrajectoryComponent,
  CardTrajectoryProgressComponent,
  DropdownComponent,
  DropdownRoleComponent,
  DropdownTrackComponent,
  ErrorLabelComponent,
  FooterProgressComponent,
  FooterSkillComponent,
  FooterTrajectoryComponent,
  HeaderComponent,
  HeaderLogoComponent,
  HintComponent,
  HintFollowComponent,
  LoaderComponent,
  LoaderHttpComponent,
  OverlayComponent,
  OverlayCheckpointComponent,
  OverlayEstimateCompleteComponent,
  OverlayImageComponent,
  OverlayReleaseNotesComponent,
  OverlayTariffComponent,
  OverlayTeamAddMembersComponent,
  SkillDescriptionComponent,
  SkillOverlayNextComponent,
  SkillOverlayPublishComponent,
  SkillOverlayTemplatesComponent,
  SkillTabsComponent,
  SkillTaskDescriptionEditorComponent,
  SkillTaskDescriptionEditorFooterComponent,
  SkillTaskDescriptionHTMLComponent,
  SkillTaskDescriptionWrapperComponent,
  SkillTaskListComponent,
  SkillTitleComponent,
  SnackbarComponent,
  SnackbarModalComponent,
  SortingComponent,
  SvgIconComponent,
  SvgProgressComponent,
  TrajectoryDescriptionComponent,
  TrajectoryOverlayAddComponent,
  TrajectoryOverlayPublishComponent,
  TrajectoryTitleComponent
} from './components';
import {
  AppRoleDirective,
  AppLocaleDirective,
  AppTextareaDirective,
  AppCrispDirective
} from './directives';
import { DayjsPipe, PluralPipe, SanitizerPipe, TransformPipe } from './pipes';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

/** https://github.com/ngx-lottie/ngx-lottie */

export function lottiePlayerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    LottieModule.forRoot({
      player: lottiePlayerFactory
    })
  ],
  declarations: [
    AppRoleDirective,
    AppCrispDirective,
    AppLocaleDirective,
    AppTextareaDirective,
    AsideComponent,
    AvatarComponent,
    AvatarTeamComponent,
    BreadcrumbsComponent,
    CalendarComponent,
    CardProgressComponent,
    CardSkillComponent,
    CardTrajectoryComponent,
    CardTrajectoryProgressComponent,
    DropdownComponent,
    DropdownRoleComponent,
    DropdownTrackComponent,
    ErrorLabelComponent,
    FooterProgressComponent,
    FooterSkillComponent,
    FooterTrajectoryComponent,
    HeaderComponent,
    HeaderLogoComponent,
    HintComponent,
    HintFollowComponent,
    LoaderComponent,
    LoaderHttpComponent,
    OverlayComponent,
    OverlayCheckpointComponent,
    OverlayEstimateCompleteComponent,
    OverlayImageComponent,
    OverlayReleaseNotesComponent,
    OverlayTariffComponent,
    OverlayTeamAddMembersComponent,
    SkillDescriptionComponent,
    SkillOverlayNextComponent,
    SkillOverlayPublishComponent,
    SkillOverlayTemplatesComponent,
    SkillTabsComponent,
    SkillTaskDescriptionEditorComponent,
    SkillTaskDescriptionEditorFooterComponent,
    SkillTaskDescriptionHTMLComponent,
    SkillTaskDescriptionWrapperComponent,
    SkillTaskListComponent,
    SkillTitleComponent,
    SnackbarComponent,
    SnackbarModalComponent,
    SortingComponent,
    SvgIconComponent,
    SvgProgressComponent,
    TrajectoryDescriptionComponent,
    TrajectoryOverlayAddComponent,
    TrajectoryOverlayPublishComponent,
    TrajectoryTitleComponent,
    DayjsPipe,
    PluralPipe,
    SanitizerPipe,
    TransformPipe
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoleDirective,
    AppCrispDirective,
    AppLocaleDirective,
    AppTextareaDirective,
    AsideComponent,
    AvatarComponent,
    AvatarTeamComponent,
    BreadcrumbsComponent,
    CalendarComponent,
    CardProgressComponent,
    CardSkillComponent,
    CardTrajectoryComponent,
    CardTrajectoryProgressComponent,
    DropdownComponent,
    DropdownRoleComponent,
    DropdownTrackComponent,
    ErrorLabelComponent,
    FooterProgressComponent,
    FooterSkillComponent,
    FooterTrajectoryComponent,
    HeaderComponent,
    HeaderLogoComponent,
    HintComponent,
    HintFollowComponent,
    LoaderComponent,
    LoaderHttpComponent,
    NgxMaskModule,
    LottieModule,
    OverlayComponent,
    OverlayCheckpointComponent,
    OverlayEstimateCompleteComponent,
    OverlayImageComponent,
    OverlayReleaseNotesComponent,
    OverlayTariffComponent,
    OverlayTeamAddMembersComponent,
    SkillDescriptionComponent,
    SkillOverlayNextComponent,
    SkillOverlayPublishComponent,
    SkillOverlayTemplatesComponent,
    SkillTabsComponent,
    SkillTaskDescriptionEditorComponent,
    SkillTaskDescriptionEditorFooterComponent,
    SkillTaskDescriptionHTMLComponent,
    SkillTaskDescriptionWrapperComponent,
    SkillTaskListComponent,
    SkillTitleComponent,
    SnackbarComponent,
    SnackbarModalComponent,
    SortingComponent,
    SvgIconComponent,
    SvgProgressComponent,
    TrajectoryDescriptionComponent,
    TrajectoryOverlayAddComponent,
    TrajectoryOverlayPublishComponent,
    TrajectoryTitleComponent,
    DayjsPipe,
    PluralPipe,
    SanitizerPipe,
    TransformPipe
  ]
})
export class SharedModule {}
