/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { User, UserService } from '../../../../core';
import { map, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import MarkdownIt from 'markdown-it';
import MarkdownItAttrs from 'markdown-it-attrs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-overlay-release-notes',
  templateUrl: 'overlay-release-notes.component.html'
})
export class OverlayReleaseNotesComponent implements OnInit, OnDestroy {
  user$!: Subscription;

  /** https://github.com/markdown-it/markdown-it */
  /** https://github.com/arve0/markdown-it-attrs */

  markdownIt = new MarkdownIt({
    linkify: true,
    breaks: true
  }).use(MarkdownItAttrs, {
    allowedAttributes: []
  });

  markdown!: string;
  markdownIsShowed!: boolean;

  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser
      .pipe(
        filter((user: User) => user.show_updates),
        filter(() => !this.router.routerState.snapshot.url.includes('/on-boarding?')),
        switchMap(() => this.userService.getReleaseNotes()),
        map((data: any) => data.content)
      )
      .subscribe({
        next: (markdown: string) => {
          this.markdown = this.markdownIt.render(markdown);
          this.markdownIsShowed = true;
        },
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    [this.user$].forEach($ => $?.unsubscribe());
  }

  onClose(): void {
    this.markdownIsShowed = false;
  }
}
