<!-- @format -->

<section>
	<app-overlay
		[appState]="state"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && onClose($event)"
	>
		<div class="relative w-full max-w-[600px] bg-blue-grey-25 rounded-lg p-10 pb-8">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="onClose(false)"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div class="flex flex-col mb-4">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-4"
          i18n="Создание навыка|Заголовок: Выберите шаблон действия@@app-skill-overlay-templates.select-template-title"
        >
					Выберите шаблон действия
				</span>
				<!-- prettier-ignore -->
				<p
          class="text-body-1"
          i18n="Создание навыка|Параграф: Шаблоны действий — это готовая структура, которую можно будет отредактировать под свои нужды.@@app-skill-overlay-templates.select-template-paragraph"
        >
          Шаблоны действий — это готовая структура, которую можно будет отредактировать под свои нужды.
        </p>
			</div>
			<div class="bg-white rounded px-2 py-1 mb-4">
				<ul class="list-none flex flex-col space-y-2 max-h-[330px] overflow-auto">
					<li
						*ngFor="let template of templateList"
						class="flex items-center justify-between space-x-4 text-body-2 text-blue-grey-900 py-1 px-2"
					>
						<span
							class="block hover:font-semibold cursor-pointer"
							(click)="templateSelected = template"
						>
							{{ template.title }}
						</span>
						<i
							*ngIf="templateSelected?.id === template.id"
							class="text-secondary-500"
							appSvgIcon
							appIcon="done"
							appSquare="16"
							appViewBox="0 0 24 24"
						></i>
					</li>
				</ul>
			</div>
			<div class="flex items-center justify-between">
				<button class="btn btn-primary outline md" (click)="onApplyNewTask()">
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Создание навыка|Кнопка: Создать новое действие по шаблону@@app-skill-overlay-templates.template-apply-new-task"
          >
						Создать новое действие по шаблону
					</span>
				</button>
				<button class="btn btn-primary inline md" (click)="onApply()">
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Создание навыка|Кнопка: Применить@@app-skill-overlay-templates.template-apply"
          >
						Применить
					</span>
				</button>
			</div>
		</div>
	</app-overlay>
</section>
