/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, startWith, Subscription, switchMap } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { Skill, SkillService, SkillTask } from '../../../../../../core';
import { ActivatedRoute, Event as RouterEvent, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

interface SkillForm {
  title: FormControl<string | null>;
}

@Component({
  selector: 'app-skill-tasks-description-editor-footer',
  templateUrl: './footer.component.html'
})
export class SkillTaskDescriptionEditorFooterComponent implements OnInit, OnDestroy {
  routerEvents$!: Subscription;

  skill$!: Subscription;
  skill!: Skill;
  skillTask!: SkillTask;
  skillTaskIndex!: number;

  skillIsEdit!: boolean;
  skillIsEdit$!: Subscription;

  skillForm: FormGroup;
  skillForm$!: Subscription;
  skillFormIsSubmitting = false;

  constructor(
    private skillService: SkillService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    this.skillForm = this.formBuilder.group<SkillForm>({
      title: this.formBuilder.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.routerEvents$ = this.router.events
      .pipe(
        filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd),
        startWith(EMPTY),
        filter(() => !!this.activatedRoute.snapshot.paramMap.get('taskId'))
      )
      .subscribe({
        next: () => {
          this.skill$?.unsubscribe();
          this.skill$ = this.skillService.skill$
            .pipe(
              tap((skill: Skill) => {
                this.skill = skill;

                // prettier-ignore
                // @ts-ignore
                const taskId: string = this.activatedRoute.snapshot.paramMap.get('taskId');

                // @ts-ignore
                this.skillTask = this.skill.taskList.find((skillTask: SkillTask) => {
                  return skillTask.id === taskId;
                });

                this.skillTaskIndex = this.skill.taskList.findIndex((skillTask: SkillTask) => {
                  return skillTask.id === this.skillTask.id;
                });
              }),
              switchMap(() => this.skillService.skillIsEdit$),
              filter((skillIsEdit: boolean) => skillIsEdit),
              tap((skillIsEdit: boolean) => (this.skillIsEdit = skillIsEdit))
            )
            .subscribe({
              next: () => {
                const abstractControl: AbstractControl | null = this.skillForm.get('title');
                const abstractControlIsEqual = (title: string): boolean => {
                  return title === this.skillTask.title;
                };

                if (abstractControl) {
                  // prettier-ignore
                  !abstractControlIsEqual(abstractControl.value) && abstractControl.setValue(this.skillTask.title);

                  this.skillForm$?.unsubscribe();
                  this.skillForm$ = abstractControl.valueChanges
                    .pipe(
                      debounceTime(this.skillService.skillIsEditDebounce),
                      filter((title: string) => !abstractControlIsEqual(title))
                    )
                    .subscribe({
                      // prettier-ignore
                      next: (title: string) => this.skillService.setSkillTaskTitleById(this.skillTask.id, title),
                      error: (error: any) => console.error(error)
                    });
                }
              },
              error: (error: any) => console.error(error)
            });
        },
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    [this.skill$, this.skillForm$, this.routerEvents$].forEach($ => $?.unsubscribe());
  }

  onAddSkillTask(): void {
    this.skillService.onAddSkillTask().subscribe({
      next: (skillTask: SkillTask) => {
        this.router
          .navigateByUrl(this.location.path().replace(this.skillTask.id, skillTask.id))
          .then(() => console.debug('Route changed'));
      },
      error: (error: any) => console.error(error)
    });
  }
}
