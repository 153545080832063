/** @format */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from '../services';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headersConfig: any = {
      ['Accept']: 'application/json'
    };

    const officeToken = this.cookieService.getItem('tokenDabsterUser');

    if (officeToken) {
      headersConfig = {
        ...headersConfig,
        ['Authorization']: 'Bearer '.concat(officeToken)
      };
    }

    const request = req.clone({ setHeaders: headersConfig });

    return next.handle(request);
  }
}
