/** @format */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { TeamInvitationPagination, TeamReferral, User } from '../models';
import { CookieService } from './cookie.service';

@Injectable()
export class UserService {
  currentUserSubject = new BehaviorSubject<User>({} as User);
  currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) {}

  getAuthorization(): void {
    if (this.cookieService.getItem('tokenDabsterUser')) {
      this.apiService
        .get('/v1/user/profile')
        .pipe(
          map((data: any) => data.data),
          catchError((error: any) => {
            this.removeAuthorization();

            return throwError(() => new Error(error));
          })
        )
        .subscribe({
          next: (user: User) => this.setAuthorization(user),
          error: (error: any) => console.error(error)
        });
    } else {
      this.removeAuthorization();
    }
  }

  setAuthorization(user: User): void {
    if (user.token) {
      this.cookieService.setItem('tokenDabsterUser', user.token, {
        ['max-age']: 2592000
      });
    }

    /** SET currentUser */

    this.currentUserSubject.next(user);
    this.isAuthenticatedSubject.next(true);
  }

  removeAuthorization(): void {
    this.cookieService.removeItem('tokenDabsterUser');

    /** SET currentUser */

    this.currentUserSubject.next({} as User);
    this.isAuthenticatedSubject.next(false);
  }

  postRegistration(body: any): Observable<User> {
    return this.apiService.post('/v1/signUp', body).pipe(
      map((data: any) => data.data),
      tap((user: User) => this.setAuthorization(user))
    );
  }

  postLogin(body: any): Observable<User> {
    return this.apiService.post('/v1/login', body).pipe(
      map((data: any) => data.data),
      tap((user: User) => this.setAuthorization(user))
    );
  }

  postLogout(body: any): Observable<any> {
    return this.apiService.post('/v1/logout', body).pipe(
      tap(() => this.removeAuthorization()),
      catchError((error: any) => {
        this.removeAuthorization();

        return throwError(() => new Error(error));
      })
    );
  }

  postChangeName(body: any): Observable<User> {
    return this.apiService.post('/v1/user/changeName', body).pipe(map((data: any) => data.data));
  }

  postChangeEmail(body: any): Observable<User> {
    return this.apiService.post('/v1/user/changeEmail', body).pipe(map((data: any) => data.data));
  }

  postChangePassword(body: any): Observable<User> {
    return this.apiService
      .post('/v1/user/changePassword', body)
      .pipe(map((data: any) => data.data));
  }

  postChangeLocale(body: any): Observable<User> {
    return this.apiService.post('/v1/user/changeLocale', body).pipe(map((data: any) => data.data));
  }

  postForgot(body: object): Observable<{ message: string }> {
    return this.apiService.post('/v1/forgotPassword', body).pipe(map((data: any) => data.data));
  }

  postReset(body: any): Observable<{ user: User; message: string }> {
    return this.apiService.post('/v1/resetPassword', body).pipe(map((data: any) => data.data));
  }

  getInvitationsOfTeams(): Observable<TeamInvitationPagination> {
    return this.apiService.get('/v1/user/profile/invitations').pipe(map((data: any) => data.data));
  }

  getReferralLink(): Observable<TeamReferral> {
    return this.apiService.get('/v1/user/getReferralLink').pipe(map((data: any) => data.data));
  }

  postDeclineInvitation(token: string): Observable<{ message: string }> {
    return this.apiService
      .post(`/v1/user/invitations/${token}/decline`, {})
      .pipe(map((data: any) => data.data));
  }

  postAcceptInvitation(token: string): Observable<any> {
    return this.apiService
      .post(`/v1/user/invitations/${token}/accept`, {})
      .pipe(map((data: any) => data.data));
  }

  getReleaseNotes(): Observable<any> {
    return this.apiService.get('/v1/updates').pipe(map((data: any) => data.data));
  }

  postLeaveTeam(teamMemberId: number): Observable<any> {
    return this.apiService
      .post(`/v1/user/leaveTeam/${teamMemberId}`, {})
      .pipe(map((data: any) => data.data));
  }

  postAlpha(): Observable<any> {
    return this.apiService.post('/v1/user/addMeToAlpha').pipe(map((data: any) => data.data));
  }
}
