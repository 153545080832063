<!-- @format -->

<section class="h-full w-full">
	<div
		class="bg-white flex flex-col p-4 rounded-lg h-full box-border group effect-01dp"
		[ngClass]="['done', 'ready'].includes(skill.status) ? 'bg-secondary-100' : 'bg-white'"
		[attr.data-pw-skill]="skill.title"
	>
		<div class="flex items-center justify-between -mt-2 mb-2" *ngIf="draggable">
			<button class="btn btn-gray btn-drag transparent sm cursor-move !p-0 -ml-1.5">
				<i appSvgIcon appIcon="drag-indicator" appSquare="20"></i>
			</button>
			<div class="flex opacity-0 group-hover:opacity-100" appHint appPosition="top">
				<button
					slot="trigger"
					class="btn btn-gray btn-drag transparent sm !p-0 -mr-1.5"
					(click)="removedFromTrajectory.emit(skill)"
				>
					<i appSvgIcon appIcon="cross" appSquare="24"></i>
				</button>
				<!-- prettier-ignore -->
				<p
          slot="hint"
          class="rounded-md border border-primary-A100 effect-00dp text-blue-grey-600 bg-white text-caption whitespace-nowrap px-2 py-1"
          i18n="Карточка навыка|Подсказка: Убрать из траектории@@app-skill-card.skill-tasks-remove-from-trajectory"
        >
          Убрать из траектории
        </p>
			</div>
		</div>
		<picture class="relative block w-full">
			<ul
				class="absolute top-1 left-1 flex flex-col flex-wrap items-center justify-center gap-1 max-h-[124px]"
				*ngIf="!!skill.is_auto || !!skill.is_skill_in_trajectory"
			>
				<li
					class="rounded bg-white p-1.5 text-blue-grey-900"
					appSvgIcon
					appIcon="rocket"
					appWidth="16"
					appHeight="16"
					appViewBox="0 0 14 14"
					*ngIf="!!skill.is_auto"
				></li>
				<li
					class="rounded bg-white p-1.5 text-blue-grey-900"
					appSvgIcon
					appIcon="insights"
					appWidth="16"
					appHeight="16"
					appViewBox="0 0 24 24"
					*ngIf="!!skill.is_skill_in_trajectory"
				></li>
			</ul>
			<source [srcset]="skill.image.url['960']" media="(max-width: 768px)" />
			<img
				class="block object-cover rounded-lg w-full max-h-[9.5rem]"
				[srcset]="skill.image.url['640']"
				[alt]="skill.title"
			/>
		</picture>
		<div class="flex flex-col items-start mt-4 h-full overflow-hidden">
			<ng-container *ngIf="!!skill.task_progress" [ngSwitch]="true">
				<label
					*ngSwitchCase="skill.status === 'done'"
					class="label label-green inline md mb-4"
				>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Карточка навыка|Текст: Подтвержден@@app-card-skill.skill-confirmed"
          >
						Подтвержден
					</span>
					<figure class="relative">
						<i
							class="absolute"
							appSvgIcon
							appIcon="done-all"
							appSquare="16"
							appViewBox="0 0 16 16"
						></i>
					</figure>
				</label>
				<label
					*ngSwitchCase="skill.status === 'ready'"
					class="label label-green inline md mb-4"
				>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Карточка навыка|Текст: Освоен@@app-card-skill.skill-mastered"
          >
						Освоен
					</span>
					<figure class="relative">
						<i
							class="absolute"
							appSvgIcon
							appIcon="done"
							appSquare="16"
							appViewBox="0 0 24 24"
						></i>
					</figure>
				</label>
				<label
					*ngSwitchCase="skill.status === 'in_progress'"
					class="label label-primary inline md mb-4"
				>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Карточка навыка|Текст: В работе@@app-card-skill.skill-in-progress"
          >
						В работе
					</span>
					<figure class="relative">
						<i class="absolute" appSvgIcon appIcon="bolt" appSquare="16"></i>
					</figure>
				</label>
			</ng-container>
			<span class="text-headline-7 mb-4 line-clamp-2" *ngIf="skill.title">
				{{ skill.title }}
			</span>
			<ng-container [ngSwitch]="!!skill.task_progress">
				<div class="flex items-center w-full mt-auto" *ngSwitchDefault>
					<i
						appSvgIcon
						appIcon="bookmarks"
						appSquare="20"
						appViewBox="0 0 24 24"
						class="text-blue-grey-600 mr-1.5"
					></i>
					<!-- prettier-ignore -->
					<span
            class="text-body-2 text-blue-grey-600 mr-1"
            i18n="Карточка навыка|Текст: Задачи@@app-skill-card.skill-tasks"
          >
            Задачи:
          </span>
					<span class="text-subtitle-2 text-blue-grey-900" data-pw="number-of-actions">
						{{ skill.task_count }}
					</span>
				</div>
				<div
					class="flex justify-between items-center space-x-4 w-full"
					*ngSwitchCase="true"
				>
					<div class="flex justify-start items-center">
						<span class="text-headline-7 mr-1">{{ skill.task_progress?.done }}</span>
						<span class="text-body-2 text-blue-grey-600">
							/{{ skill.task_progress?.all }}
						</span>
					</div>
					<div class="bg-blue-grey-50 rounded-sm w-full h-1">
						<div
							class="bg-secondary-500 rounded-sm h-1"
							[style.width]="skill.task_progress?.percent + '%'"
						></div>
					</div>
					<span class="text-headline-7">{{ skill.task_progress?.percent }}%</span>
				</div>
			</ng-container>
		</div>
	</div>
</section>
