/** @format */

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  Progress,
  ProgressService,
  Skill,
  SkillService,
  SnackbarService,
  TeamMember,
  TeamMemberPagination,
  TeamService,
  TrajectoryAssignedLabel,
  User,
  UserService
} from '../../../../core';
import { delay, forkJoin, of, Subscription, switchMap } from 'rxjs';
import { catchError, debounceTime, filter, first, tap } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { routingDashboardAnimation } from '../../../../app-animations';

interface SkillForm {
  title: FormControl<string | null>;
}

@Component({
  selector: 'app-skill-title',
  templateUrl: './title.component.html',
  animations: [routingDashboardAnimation]
})
export class SkillTitleComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('textAreaElement') textAreaElement!: ElementRef;

  user$!: Subscription;
  user!: User;

  skill!: Skill;
  skill$!: Subscription;

  skillIsEdit!: boolean;
  skillIsEdit$!: Subscription;
  skillIsEditIndividual!: boolean;

  skillForm: FormGroup;
  skillForm$!: Subscription;
  skillFormIsSubmitting = false;
  skillRemoveToggle!: boolean;

  skillSetTeammateState: string = 'select';
  skillSetTeammateToggle!: boolean;
  skillSetAlreadyAssignedToggle!: boolean;

  progress!: Progress;
  progress$!: Subscription;
  progressRemoveToggle!: boolean;

  progressIsEdit!: boolean;
  progressIsEstimate!: boolean;
  progressIsResult!: boolean;

  teamMemberSelected: TeamMember | undefined;
  teamMemberList: TeamMember[] = [];

  draftIsShown!: boolean;
  draftRemoveToggle!: boolean;

  skillConfirmationModalOnSaveDraft$!: Subscription;
  skillConfirmationModalOnPublishSkill$!: Subscription;

  individualSkill!: Progress;
  individualSkill$!: Subscription;
  individualSkillToggle!: boolean;

  trajectoryAssignedList: TrajectoryAssignedLabel[] = [];
  trajectoryAssignedListLimit: number = 3;

  constructor(
    private skillService: SkillService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackbarService,
    private location: Location,
    private progressService: ProgressService,
    private userService: UserService,
    private teamService: TeamService
  ) {
    this.skillForm = this.formBuilder.group<SkillForm>({
      title: this.formBuilder.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => (this.user = user)
    });

    this.skill$ = this.skillService.skill$
      .pipe(
        tap((skill: Skill) => (this.skill = skill)),
        switchMap(() => this.skillService.skillIsEdit$),
        filter((skillIsEdit: boolean) => skillIsEdit),
        tap((skillIsEdit: boolean) => (this.skillIsEdit = skillIsEdit))
      )
      .subscribe({
        next: () => {
          const abstractControl: AbstractControl | null = this.skillForm.get('title');
          const abstractControlIsEqual = (title: string): boolean => {
            return title === this.skill.title;
          };

          if (abstractControl) {
            // prettier-ignore
            !abstractControlIsEqual(abstractControl.value) && abstractControl.setValue(this.skill.title);

            this.skillForm$?.unsubscribe();
            this.skillForm$ = abstractControl.valueChanges
              .pipe(
                debounceTime(this.skillService.skillIsEditDebounce),
                filter((title: string) => !abstractControlIsEqual(title))
              )
              .subscribe({
                next: (title: string) => this.skillService.setSkillTitle(title)
              });
          }
        }
      });

    this.progress$ = this.progressService.progress$
      .pipe(
        tap((progress: Progress) => (this.progress = progress)),
        switchMap(() => {
          return forkJoin([
            this.progressService.progressIsEdit$.pipe(first()),
            this.progressService.progressIsEstimate$.pipe(first()),
            this.progressService.progressIsResult$.pipe(first())
          ]);
        })
      )
      .subscribe({
        next: ([progressIsEdit, progressIsEstimate, progressIsResult]: [
          boolean,
          boolean,
          boolean
        ]) => {
          this.progressIsEdit = progressIsEdit;
          this.progressIsEstimate = progressIsEstimate;
          this.progressIsResult = progressIsResult;
        }
      });

    this.draftIsShown = this.location.path().includes('library/drafts');

    // prettier-ignore
    this.skillConfirmationModalOnSaveDraft$ = this.skillService.skillConfirmationModalOnSaveDraft.subscribe({
      next: () => {}
    });

    // prettier-ignore
    this.skillConfirmationModalOnPublishSkill$ = this.skillService.skillConfirmationModalOnPublishSkill.subscribe({
      next: () => {
        this.skillService.skillConfirmationModalToggle.next(false);

        this.router.navigateByUrl('/library/skills')
          .then(() => {
            console.debug('Route changed from SkillTitleComponent')
          });
      }
    });

    /** Individual skill edition setup */

    // prettier-ignore
    this.skillIsEditIndividual = !!String(this.activatedRoute.snapshot.queryParamMap.get('individual') || '');
  }

  ngAfterViewInit(): void {
    if (this.skillIsEdit) {
      const focusTimeout: number = setTimeout(() => {
        this.textAreaElement.nativeElement.focus();

        clearTimeout(focusTimeout);
      });
    } else {
      if (!!this.skill.id) {
        // prettier-ignore
        const isDraftDetail: boolean = this.location.path().includes('/library/drafts/' + this.skill.id);

        /** Do not ask for trajectories in drafts detail */

        if (!isDraftDetail) {
          // prettier-ignore
          const params: any = this.progressIsEdit ? { team_member_id: this.user.team_member_id } : {};

          // prettier-ignore
          this.progressService
            .getProgressByIdTrajectories(this.skill.id, params)
            .subscribe({
              next: (trajectoryAssignedList: TrajectoryAssignedLabel[]) => this.trajectoryAssignedList = trajectoryAssignedList
            });
        }
      }
    }
  }

  ngOnDestroy(): void {
    [
      this.user$,
      this.skill$,
      this.skillForm$,
      this.skillIsEdit$,
      this.progress$,
      this.skillConfirmationModalOnSaveDraft$,
      this.skillConfirmationModalOnPublishSkill$
    ].forEach($ => $?.unsubscribe());
  }

  onToggleSetTeammateSkill(toggle: boolean): void {
    const setState = (): void => {
      this.skillSetTeammateToggle = toggle;

      const stateTimeout: number = setTimeout(() => {
        this.skillSetTeammateState = 'select';
        this.teamMemberSelected = undefined;

        clearTimeout(stateTimeout);
      }, 300);
    };

    if (toggle) {
      this.teamService
        .getTeamMembers({ skillId: this.skill.id })
        .pipe(
          tap((teamMemberPagination: TeamMemberPagination) => {
            this.teamMemberList = teamMemberPagination.items.filter((teamMember: TeamMember) => {
              return teamMember.id !== this.user.team_member_id;
            });
          })
        )
        .subscribe({
          next: () => setState()
        });
    } else {
      setState();
    }
  }

  onSetTeammateSkill(teamMemberId: number | undefined): void {
    const setSkill = (callback: any): void => {
      this.progressService
        .setSkillToProgress(Number(teamMemberId), { skill: this.skill.id })
        .pipe(
          switchMap((progress: Progress) => of({ ...progress, assigned: true })),
          catchError((error: any) => of({ ...error.data, assigned_already: true }))
        )
        .subscribe({
          next: (progress: Progress) => callback(progress)
        });
    };

    /** If skill already assigned to authed user show modal https://kostylworks.atlassian.net/browse/DAB-92 */

    if (teamMemberId !== this.user.team_member_id) {
      setSkill(() => (this.skillSetTeammateState = 'done'));
    } else {
      setSkill((progress: Progress) => {
        /** Assign skill and redirect */

        if (!!progress.assigned) {
          let path: string = this.location.path();

          path = path.replace('library/skills', 'progress/my/progress');
          path = path.replace(this.skill.id, progress.id);

          this.router.navigate([path]).then(() => console.debug('Route changed'));
        }

        /** If skill already assigned */

        if (!!progress.assigned_already) {
          this.progress = progress;

          this.skillSetAlreadyAssignedToggle = true;
        }
      });
    }
  }

  onEditSkill(isProgress: boolean = false): void {
    let path: string = this.location.path();

    /** Навык может быть вызван к редактированию через просмотр прогресса тиммейта || через просмотр навыка в библиотеке */

    const navigate = (path: string): void => {
      this.router.navigateByUrl(path).then(() => console.debug('Route changed'));
    };

    if (isProgress) {
      this.individualSkillToggle = false;

      /** Await modal close, avoid router animation glitches  */

      of(true)
        .pipe(first(), delay(300))
        .subscribe({
          next: () => {
            const pathNew = path
              .replace(/progress\/.*\/progress/, 'library/edit')
              .replace(this.progress.id, String(this.progress.skill.general_source_id));

            navigate(pathNew);
          }
        });
    } else {
      const pathNew: string = path
        .replace(this.draftIsShown ? 'drafts' : 'skills', 'edit')
        .replace(this.skill.id, String(this.draftIsShown ? this.skill.id : this.skill.source_id));

      navigate(pathNew);
    }
  }

  onEditSkillIndividual(): void {
    this.individualSkillToggle = false;

    /** Await modal close, avoid router animation glitches  */

    of(true)
      .pipe(first(), delay(300))
      .subscribe({
        next: () => {
          this.router
            .navigate(['/library/edit', this.progress.skill.id, 'description'], {
              queryParams: {
                individual: this.progress.user.team_member_id
              }
            })
            .then(() => console.debug('Route changed'));
        }
      });
  }

  onToggleRemoveSkill(): void {
    this.draftRemoveToggle = this.draftIsShown;
    this.skillRemoveToggle = !this.draftIsShown;
  }

  onRemoveSkill(): void {
    this.skillRemoveToggle = false;

    this.skillService.postRemoveSkill(this.skill.id).subscribe({
      next: () => {
        this.router.navigate(['/library/skills']).then(() => {
          this.snackbarService.info(
            $localize`:Снекбар|Сообщение - Навык был удалён@@app-snackbar.skill-deleted:Навык был удалён`,
            {
              timeout: 3000,
              icon: 'done',
              progress: true,
              progressClass: 'bg-secondary-600'
            }
          );
        });
      }
    });
  }

  onRemoveDraft(): void {
    this.draftRemoveToggle = false;

    this.skillService.postRemoveDraft(this.skill.id).subscribe({
      next: () => {
        this.router.navigate(['/library/drafts']).then(() => {
          this.snackbarService.info(
            $localize`:Снекбар|Сообщение - Черновик навыка был удалён@@app-snackbar.draft-deleted:Черновик навыка был удалён`,
            {
              timeout: 3000,
              icon: 'done',
              progress: true,
              progressClass: 'bg-secondary-600'
            }
          );
        });
      }
    });
  }

  onSetSkillStatus(): void {
    this.progressService
      .setSkillStatus(this.progress.id, {
        status: 'done'
      })
      .pipe(switchMap((progress: Progress) => this.progressService.onAddProgress(progress)))
      .subscribe({
        next: (progress: Progress) => {
          this.snackbarService.info(
            $localize`:Снекбар|Сообщение - Навык освоен@@app-snackbar.skill-mastered:Навык освоен`,
            {
              icon: 'done'
            }
          );

          // prettier-ignore
          const userId: string | number = this.user.id === progress.user.id ? 'my' : progress.user.team_member_id;

          this.router
            .navigate(['/progress', userId, 'estimate', this.progress.id])
            .then(() => console.debug('Route changed'));
        }
      });
  }

  onRemoveProgress(): void {
    this.progressRemoveToggle = false;

    this.progressService.setProgressRemove(this.progress.id).subscribe({
      next: () => {
        // prettier-ignore
        const userId: string | number = this.user.id === this.progress.user.id ? 'my' : this.progress.user.team_member_id;

        this.router.navigate(['/progress', userId, 'dashboard']).then(() => {
          this.progressService.onRemoveProgress();

          this.snackbarService.info(
            $localize`:Снекбар|Сообщение - Навык был убран из прогресса@@app-snackbar.progress-removed:Навык был убран из прогресса`,
            {
              timeout: 3000,
              icon: 'done',
              progress: true,
              progressClass: 'bg-secondary-600'
            }
          );
        });
      }
    });
  }

  onPublishSkill(): void {
    this.skillService.skillConfirmationModalToggle.next(true);
  }

  onGotoTeam(): void {
    this.skillSetTeammateToggle = false;

    /** Await modal close, avoid router animation glitches  */

    of(true)
      .pipe(first(), delay(300))
      .subscribe({
        next: () => {
          this.router
            .navigate(['/team'], { queryParams: { addToTeam: 1 } })
            .then(() => console.debug('Route changed'));
        }
      });
  }

  onGotoMyProgress(): void {
    this.skillSetAlreadyAssignedToggle = false;

    /** Await modal close, avoid router animation glitches  */

    of(true)
      .pipe(first(), delay(300))
      .subscribe({
        next: () => {
          let path: string = this.location.path();

          path = path.replace('library/skills', 'progress/my/progress');
          path = path.replace(this.skill.id, this.progress.id);

          this.router.navigate([path]).then(() => console.debug('Route changed'));
        }
      });
  }
}
