<!-- @format -->

<section class="h-full">
	<div
		class="grid grid-cols-1 xl:grid-cols-2 place-items-center w-screen h-full min-h-screen overflow-y-auto"
	>
		<div class="flex flex-col w-full h-full px-12">
			<div class="flex flex-shrink-0 items-center w-full h-14 mb-12">
				<a href="https://dabster.im/">
					<img class="block" src="assets/logo.svg" alt="Dabster" />
				</a>
			</div>
			<div class="flex w-full max-w-[960px] mb-12">
				<router-outlet
					(activate)="onActivate($event)"
					(deactivate)="onDeactivate($event)"
				></router-outlet>
			</div>
			<footer class="flex flex-col items-end text-body-2 mb-6 mt-auto">
				<!-- prettier-ignore -->
				<span
					class="block"
					i18n="Авторизация|Текст: Есть вопрос? Свяжитесь с нами@@app-auth.text-question"
				>
					Есть вопрос? Свяжитесь с нами:
				</span>
				<a href="mailto:artem@dabster.im">artem@dabster.im</a>
			</footer>
		</div>
		<div
			class="flex items-center justify-center relative bg-primary-A700 w-full h-full min-h-[530px]"
			[ngSwitch]="backgroundImage"
		>
			<ng-lottie
				class="absolute block object-scale-down w-full h-full"
				[options]="lottieAnimationOptions"
				[styles]="lottieStyles"
				[@authImageAnimation]
				*ngSwitchDefault
			></ng-lottie>
			<img
				class="absolute block object-scale-down w-full h-full"
				[src]="'assets/images/auth/forgot.svg'"
				[@authImageAnimation]
				*ngSwitchCase="'forgot'"
				alt="Forgot password"
			/>
		</div>
	</div>
</section>
