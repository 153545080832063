/** @format */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { rotateAnimation } from '../../../../app-animations';
import { Role, RoleKey, InvitationRoleMap } from '../../../../core';

const { owner, ...roleMap } = InvitationRoleMap;

@Component({
  selector: 'app-dropdown-role',
  templateUrl: './dropdown-role.component.html',
  animations: [rotateAnimation]
})
export class DropdownRoleComponent {
  @Output() selected = new EventEmitter<{ roleKey: RoleKey; rollback: () => void }>();

  @Input()
  set appRoleActive(roleActive: RoleKey) {
    this.roleActive = roleActive === 'owner' ? 'teamlead' : roleActive;
  }

  roleMap: Role = roleMap as Role;

  roleActive: RoleKey = 'owner';

  constructor() {}

  onSelect(roleKey: string): void {
    const rolePrevious = this.roleActive;
    const rollback = (): any => (this.roleActive = rolePrevious);

    this.roleActive = roleKey as RoleKey;

    this.selected.emit({ roleKey: this.roleActive, rollback });
  }
}
