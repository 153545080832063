<!-- @format -->

<ng-container *ngIf="state$.getValue()">
	<div
		class="overlay overflow-auto grid fixed inset-0 z-30 p-8 h-screen"
		[ngClass]="classList"
		(click)="onClick($event)"
		[@overlayAnimation]="state$.getValue()"
	>
		<div class="self-center justify-self-center">
			<ng-content></ng-content>
		</div>
	</div>
</ng-container>
