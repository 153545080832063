<!-- @format -->

<section class="flex flex-col">
	<ul
		class="flex items-center justify-start flex-wrap gap-2 mb-4"
		*ngIf="!!skill.is_auto || !!trajectoryAssignedList.length"
	>
		<li class="flex" appHint appPosition="right" *ngIf="!!skill.is_auto">
			<div
				slot="trigger"
				class="flex items-center justify-start space-x-1 bg-white rounded-md p-1.5"
			>
				<i class="text-blue-grey-900" appSvgIcon appIcon="rocket" appSquare="14"></i>
				<!-- prettier-ignore -->
				<span
          class="text-body-2 text-blue-grey-900 whitespace-nowrap"
          i18n="Просмотр навыка|стартовый навык: Стартовый навык@@app-skill-title.auto-assign"
        >
          Стартовый навык
        </span>
			</div>
			<!-- prettier-ignore -->
			<p
        slot="hint"
        class="rounded-md border border-primary-A100 effect-00dp text-blue-grey-600 bg-white text-caption w-[228px]  px-2 py-1"
        i18n="Просмотр навыка|стартовый навык подсказка: Навык будет автоматически добавляться@@app-skill-title.auto-assign-hint"
      >
        Навык будет автоматически добавляться в первый чекпоинт всем новичкам в команде
      </p>
		</li>
		<ng-container *ngIf="!!trajectoryAssignedList.length">
			<!-- prettier-ignore -->
			<li
        class="block"
        *ngFor="let trajectoryAssigned of trajectoryAssignedList.slice(0, trajectoryAssignedListLimit)"
      >
        <a
          class="flex items-center justify-start space-x-1 bg-white rounded-md p-1.5 text-body-2 text-blue-grey-900 hover:text-primary-A400"
          target="_blank"
          [routerLink]="['/library/trajectories', trajectoryAssigned.id]">
          <i
            appSvgIcon
            appIcon="insights"
            appSquare="16"
            appViewBox="0 0 24 24"
          ></i>
          <span
            class=" whitespace-nowrap truncate max-w-[250px]">
            {{ trajectoryAssigned.title }}
          </span>
        </a>
      </li>
			<li
				class="block text-button-2 text-blue-grey-400 cursor-pointer"
				*ngIf="!!trajectoryAssignedList.slice(trajectoryAssignedListLimit).length"
				(click)="trajectoryAssignedListLimit = trajectoryAssignedList.length"
			>
				+ {{ trajectoryAssignedList.slice(trajectoryAssignedListLimit).length }}
			</li>
		</ng-container>
	</ul>
	<!-- NAVIGATION: Если редактируем скилл (подразумевает и создание скилла) -->
	<ng-container [ngSwitch]="skillIsEdit">
		<!-- NAVIGATION: Если редактируем скилл тогда отображаем textarea -->
		<ng-container *ngSwitchCase="true">
			<form class="w-full" [formGroup]="skillForm" autocomplete="off">
				<fieldset [disabled]="skillFormIsSubmitting">
					<fieldset class="form-control-unset">
						<!-- prettier-ignore -->
						<textarea
              appTextarea
              class="text-headline-3 h-auto overflow-y-hidden w-full p-0 mb-6"
              data-pw="skill-title"
              placeholder="Введите название навыка"
              i18n-placeholder="Просмотр навыка|Поле ввода: Введите название навыка@@app-skill-title.input-title"
              name="title"
              id="title"
              [rows]="1"
              formControlName="title"
              #textAreaElement
            ></textarea>
						<label for="title" class="hidden"></label>
					</fieldset>
				</fieldset>
			</form>
			<!-- NAVIGATION: Если редактируем скилл как индивидуальный навык -->
			<div
				*ngIf="!!skillIsEditIndividual && !!progress"
				class="flex flex-col lg:flex-row justify-start lg:items-center space-y-4 lg:space-y-0 lg:space-x-5 mb-8"
			>
				<ng-container
					[ngTemplateOutlet]="progressDisplay"
					[ngTemplateOutletContext]="{user: user, progress: progress}"
				></ng-container>
			</div>
		</ng-container>
		<!-- NAVIGATION: Если НЕ редактируем скилл тогда отображаем информацию скилла -->
		<ng-container *ngSwitchDefault>
			<h3
				class="text-headline-3 text-blue-grey-900 line-clamp-3 mb-6"
				data-pw="skill-title"
			>
				{{ skill.title }}
			</h3>
			<div
				class="flex items-center justify-start space-x-4 mb-8"
				[@routingDashboardAnimation]="false"
			>
				<!-- NAVIGATION: Блок с кнопками -->
				<ng-container [ngSwitch]="progressIsEdit">
					<!-- NAVIGATION: Если мы ЗАШЛИ В СКИЛЛ ПРОСТО ПОСМОТРЕТЬ (например из библиотеки) -->
					<ng-container *ngSwitchDefault>
						<div class="flex items-center justify-start space-x-2">
							<ng-container [ngSwitch]="draftIsShown">
								<!-- NAVIGATION: Если мы смотрим НАВЫК -->
								<ng-container *ngSwitchDefault>
									<!-- NAVIGATION: Если мы ТИМЛИД тогда показываем "Назначить тиммейту" -->
									<ng-container *appRole="['teamlead', 'owner']">
										<button
											class="btn btn-primary inline md"
											(click)="onToggleSetTeammateSkill(true)"
										>
											<!-- prettier-ignore -->
											<span
                        class="block"
                        i18n="Просмотр навыка|Кнопка: Назначить тиммейту@@app-skill-title.teammate-set"
                      >
                        Назначить тиммейту
                      </span>
										</button>
									</ng-container>
									<!-- NAVIGATION: Если мы НЕВАЖНО кто тогда показываем "Освоить навык" (все пользователи могут сами себе набирать навыки) -->
									<button
										data-pw="skill-learn"
										class="btn btn-primary outline md"
										(click)="onSetTeammateSkill(user.team_member_id)"
									>
										<!-- prettier-ignore -->
										<span
                      class="block"
                      i18n="Просмотр навыка|Кнопка: Освоить навык@@app-skill-title.master-skill"
                    >
                      Освоить навык
                    </span>
									</button>
								</ng-container>
								<!-- NAVIGATION: Если мы смотрим ЧЕРНОВИК -->
								<ng-container *ngSwitchCase="true">
									<!-- Здесь нужно ограничить по ролям?  -->
									<button
										class="btn btn-primary inline md"
										data-pw="button-publish-skill"
										(click)="onPublishSkill()"
									>
										<figure class="relative">
											<i
												class="absolute text-white"
												appSvgIcon
												appIcon="done"
												appSquare="24"
											></i>
										</figure>
										<!-- prettier-ignore -->
										<span
                      class="block"
                      i18n="Просмотр навыка|Кнопка: Опубликовать@@app-skill-title.publish"
                    >
                      Опубликовать
                    </span>
									</button>
								</ng-container>
							</ng-container>
						</div>
						<!-- NAVIGATION: Если мы ТИМЛИД тогда показываем кнопки редактирования и удаления навыка -->
						<ng-container *appRole="['teamlead', 'owner']">
							<div class="flex items-center justify-start space-x-2">
								<button
									class="btn btn-gray outline md"
									data-pw="button-edit-skill"
									(click)="onEditSkill()"
								>
									<figure class="relative">
										<i
											class="absolute"
											appSvgIcon
											appIcon="pencil"
											appHeight="24"
											appWidth="24"
											appViewBox="0 0 20 20"
										></i>
									</figure>
								</button>
								<button
									class="btn btn-gray outline md"
									data-pw="button-remove-skill"
									(click)="onToggleRemoveSkill()"
								>
									<figure class="relative">
										<i class="absolute" appSvgIcon appIcon="cross" appSquare="24"></i>
									</figure>
								</button>
							</div>
						</ng-container>
					</ng-container>
					<!-- NAVIGATION: Если мы ЗАШЛИ В ПРОГРЕСС (например из страницы списка прогрессов) -->
					<ng-container *ngSwitchCase="true">
						<!-- NAVIGATION: Блок с прогрессом -->
						<div
							class="flex flex-col lg:flex-row justify-start lg:items-center space-y-4 lg:space-y-0 lg:space-x-5"
						>
							<!-- NAVIGATION: Если мы находимся в ЧУЖОМ ПРОГРЕССЕ тогда отображаем аватар пользователя этого прогресса -->
							<ng-container
								[ngTemplateOutlet]="progressDisplay"
								[ngTemplateOutletContext]="{user: user, progress: progress}"
							></ng-container>
							<div
								class="flex items-center justify-start space-x-2"
								*ngIf="!progressIsEstimate && !progressIsResult"
							>
								<!-- NAVIGATION: Если мы находимся в ЛЮБОМ ПРОГРЕССЕ тогда отображаем кнопку редактирования прогресса-навыка (тимлид может редактировать навык = индивидуальное действие) -->
								<ng-container *appRole="['teamlead', 'owner']">
									<button
										class="btn btn-gray outline md"
										(click)="individualSkillToggle = true"
									>
										<figure class="relative">
											<i
												class="absolute"
												appSvgIcon
												appIcon="pencil"
												appHeight="24"
												appWidth="24"
												appViewBox="0 0 20 20"
											></i>
										</figure>
									</button>
								</ng-container>
								<!-- NAVIGATION: Если мы находимся в ЛЮБОМ ПРОГРЕССЕ тогда отображаем кнопку удаления прогресса (тиммейт может сам перестать проходить навык, так же тимлид может убрать навык из дедлайна тиммейта) -->
								<button
									class="btn btn-gray outline md"
									(click)="progressRemoveToggle = true"
								>
									<figure class="relative">
										<i class="absolute" appSvgIcon appIcon="cross" appSquare="24"></i>
									</figure>
								</button>
							</div>
						</div>
					</ng-container>
				</ng-container>
				<!-- NAVIGATION: Если мы ЗАШЛИ В ОЦЕНКУ (так может сделать ТОЛЬКО ТИМЛИД и ТОЛЬКО ЧЕРЕЗ СЦЕНАРИЙ оценки, по факту это обычный просмотр прогресса (с доп. кнопками) -->
				<ng-container *ngIf="progressIsEstimate">
					<button
						class="btn btn-primary inline md"
						data-pw="button-skill-pretend"
						*ngIf="progress.status !== 'done'"
						(click)="onSetSkillStatus()"
					>
						<figure class="relative">
							<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
						</figure>
						<!-- prettier-ignore -->
						<span
              class="block"
              i18n="Просмотр навыка|Кнопка: Считать освоенным@@app-skill-title.master-skill-pretend"
            >
              Считать освоенным
            </span>
					</button>
				</ng-container>
			</div>
		</ng-container>
	</ng-container>
	<!-- NAVIGATION: Модалка удаления прогресса из дедлайна -->
	<app-overlay
		*ngIf="progressIsEdit"
		[appState]="progressRemoveToggle"
		[appClassList]="['overlay-gray']"
		(clicked)="progressRemoveToggle = $event"
	>
		<div class="relative w-full max-w-[512px] bg-white rounded-lg p-10">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="progressRemoveToggle = false"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<ng-container [ngSwitch]="user.id === progress.user.id">
				<div class="flex flex-col items-start" *ngSwitchCase="true">
					<!-- prettier-ignore -->
					<span
            class="block text-headline-4 mb-4"
            i18n="Просмотр навыка|Заголовок: Перестать проходить навык@@app-skill-title.skill-progress-my-title"
          >
            Перестать проходить навык
          </span>
					<!-- prettier-ignore -->
					<p
            class="text-body-1 mb-8"
            i18n="Просмотр навыка|Параграф: Навык останется в общей библиотеке@@app-skill-title.skill-progress-my-paragraph"
          >
            Навык останется в общей библиотеке, но пропадет из вашего прогресса.
          </p>
					<div class="flex items-center justify-between w-full">
						<button
							class="btn btn-gray outline md"
							(click)="progressRemoveToggle = false"
						>
							<figure class="relative">
								<i
									class="absolute text-blue-grey-400"
									appSvgIcon
									appIcon="cross"
									appSquare="24"
								></i>
							</figure>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Просмотр навыка|Кнопка: Отмена@@app-skill-title.skill-progress-my-cancel"
              >
                Отмена
              </span>
						</button>
						<button class="btn btn-tertiary inline md" (click)="onRemoveProgress()">
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Просмотр навыка|Кнопка: Перестать проходить@@app-skill-title.skill-progress-my-submit"
              >
                Перестать проходить
              </span>
						</button>
					</div>
				</div>
				<div class="flex flex-col items-start" *ngSwitchCase="false">
					<!-- prettier-ignore -->
					<span
            class="block text-headline-4 mb-4"
            i18n="Просмотр навыка|Заголовок: Убрать навык из дедлайна тиммейта@@app-skill-title.skill-progress-teammate-title"
          >
            Убрать навык из дедлайна тиммейта
          </span>
					<!-- prettier-ignore -->
					<p
            class="text-body-1 mb-8"
            i18n="Просмотр навыка|Параграф: Навык не удалится из библиотеки@@app-skill-title.skill-progress-teammate-paragraph"
          >
            Навык не удалится из библиотеки, но пропадет из прогресса пользователя.
          </p>
					<div class="flex items-center justify-between w-full">
						<button
							class="btn btn-gray outline md"
							(click)="progressRemoveToggle = false"
						>
							<figure class="relative">
								<i
									class="absolute text-blue-grey-400"
									appSvgIcon
									appIcon="cross"
									appSquare="24"
								></i>
							</figure>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Просмотр навыка|Кнопка: Отмена@@app-skill-title.skill-progress-teammate-cancel"
              >
                Отмена
              </span>
						</button>
						<button class="btn btn-tertiary inline md" (click)="onRemoveProgress()">
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Просмотр навыка|Кнопка: Убрать из дедлайна@@app-skill-title.skill-progress-teammate-submit"
              >
                Убрать из дедлайна
              </span>
						</button>
					</div>
				</div>
			</ng-container>
		</div>
	</app-overlay>
	<!-- NAVIGATION: Модалка удаления скилла из библиотеки -->
	<app-overlay
		[appState]="skillRemoveToggle"
		[appClassList]="['overlay-gray']"
		(clicked)="skillRemoveToggle = $event"
	>
		<div class="relative w-full max-w-[512px] bg-white rounded-lg p-10">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="skillRemoveToggle = false"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div class="flex flex-col items-start">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-4"
          i18n="Просмотр навыка|Заголовок: Вы собираетесь удалить навык из библиотеки@@app-skill-title.skill-delete-title"
        >
					Вы собираетесь удалить навык из библиотеки
				</span>
				<!-- prettier-ignore -->
				<p
          class="text-body-1 mb-8"
          i18n="Просмотр навыка|Параграф: Навык не будет удалён полностью@@app-skill-title.skill-delete-paragraph"
        >
          Навык не будет удалён полностью, а будет снят с публикации и станет недоступен для просмотра в библиотеке.
          Вы сможете продолжить работу над навыком в разделе «Черновики» и вновь опубликовать его, когда посчитаете
          нужным.
          Если пользователи уже проходят этот навык, он не будет удалён из их прогресса.
        </p>
				<div class="flex items-center justify-between w-full">
					<button
						class="btn btn-gray outline md"
						data-pw="button-skill-delete-cancel"
						(click)="skillRemoveToggle = false"
					>
						<figure class="relative">
							<i
								class="absolute text-blue-grey-400"
								appSvgIcon
								appIcon="cross"
								appSquare="24"
							></i>
						</figure>
						<!-- prettier-ignore -->
						<span
              class="block"
              i18n="Просмотр навыка|Кнопка: Отмена@@app-skill-title.skill-delete-cancel"
            >
							Отмена
						</span>
					</button>
					<button
						class="btn btn-tertiary inline md"
						data-pw="button-skill-delete"
						(click)="onRemoveSkill()"
					>
						<!-- prettier-ignore -->
						<span
              class="block"
              i18n="Просмотр навыка|Кнопка: Удалить навык из библиотеки@@app-skill-title.skill-delete-submit"
            >
							Удалить навык из библиотеки
						</span>
					</button>
				</div>
			</div>
		</div>
	</app-overlay>
	<!-- NAVIGATION: Модалка удаления черновика -->
	<app-overlay
		[appState]="draftRemoveToggle"
		[appClassList]="['overlay-gray']"
		(clicked)="draftRemoveToggle = $event"
	>
		<div class="relative w-full max-w-lg bg-white rounded-lg p-10 pb-8">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="draftRemoveToggle = false"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div class="mb-8">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-4"
          i18n="Просмотр навыка|Заголовок: Вы собираетесь удалить черновик навыка@@app-skill-title.draft-delete-title"
        >
          { !!skill.is_published, select, true {Вы собираетесь удалить черновик навыка} false {Вы собираетесь удалить неопубликованный навык} }
				</span>
				<!-- prettier-ignore -->
				<p
          class="text-body-1"
          i18n="Просмотр навыка|Параграф: Черновик навыка навсегда исчезнет из библиотеки@@app-skill-title.draft-delete-paragraph"
        >
          { !!skill.is_published, select, true { Черновик навыка навсегда исчезнет из библиотеки, восстановить его будет невозможно. Опубликованный навык останется в библиотеке. } false { Этот навык не был опубликован в библиотеке, и удаление его черновика приведёт к полному удалению этого навыка. } }
        </p>
			</div>
			<div class="flex items-center justify-between">
				<button
					class="btn btn-gray outline md"
					data-pw="button-skill-delete-cancel"
					(click)="draftRemoveToggle = false"
				>
					<figure class="relative">
						<i
							class="absolute text-blue-grey-400"
							appSvgIcon
							appIcon="cross"
							appSquare="24"
						></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Просмотр навыка|Кнопка: Отмена@@app-skill-title.draft-delete-cancel"
          >
						Отмена
					</span>
				</button>
				<button
					class="btn btn-tertiary inline md"
					data-pw="button-skill-delete"
					(click)="onRemoveDraft()"
				>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Просмотр навыка|Кнопка: Удалить черновик@@app-skill-title.draft-delete-submit"
          >
						{ !!skill.is_published, select, true {Удалить черновик} false {Удалить навык} }
					</span>
				</button>
			</div>
		</div>
	</app-overlay>
	<!-- NAVIGATION: Модалка назначить навык тиммейту -->
	<app-overlay
		*ngIf="!!skill"
		[appState]="skillSetTeammateToggle"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && onToggleSetTeammateSkill(false)"
	>
		<div class="relative w-full md:w-[688px] bg-white rounded-lg p-10">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="onToggleSetTeammateSkill(false)"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<ng-container [ngSwitch]="skillSetTeammateState">
				<div class="flex flex-col items-start" *ngSwitchCase="'select'">
					<!-- prettier-ignore -->
					<span
            class="block text-headline-4 mb-4"
            i18n="Назначить навык|Заголовок: Назначение навыка@@app-skill-title.skill-set-title"
          >
            Назначение навыка
          </span>
					<div
						class="flex justify-between items-start space-x-2 bg-blue-grey-25 w-full effect-01dp rounded-md p-3 mb-8"
					>
						<div class="block text-subtitle-1 line-clamp-2">
							{{ skill.title }}
						</div>
						<div class="flex items-center">
							<i
								appSvgIcon
								appIcon="bookmarks"
								appSquare="20"
								appViewBox="0 0 24 24"
								class="text-blue-grey-600 mr-1.5"
							></i>
							<!-- prettier-ignore -->
							<span
                class="text-body-2 text-blue-grey-600 mr-1"
                i18n="Назначить навык|Текст: Задачи@@app-skill-title.skill-set-tasks"
              >
                Задачи:
              </span>
							<span class="text-subtitle-2 text-blue-grey-900">
								{{ skill.taskList.length }}
							</span>
						</div>
					</div>
					<!-- prettier-ignore -->
					<p
            class="text-subtitle-1 mb-6"
            i18n="Назначить навык|Параграф: Выберите тиммейта@@app-skill-title.skill-set-paragraph"
          >
            Выберите тиммейта, которому хотите назначить навык:
          </p>
					<ul
						class="overflow-auto max-h-[285px] w-full mb-8"
						*ngIf="!!teamMemberList.length; else emptyTeamMember"
					>
						<!-- prettier-ignore -->
						<li
              class="flex items-center justify-between border-b border-blue-grey-50 px-2 py-3 mr-1"
              [ngClass]="teamMember.skill_assigned ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-blue-grey-25'"
              [class.bg-secondary-25]="teamMemberSelected?.id === teamMember.id"
              *ngFor="let teamMember of teamMemberList"
              (click)="!teamMember.skill_assigned && (teamMemberSelected = teamMember)"
            >
              <div
                class="flex items-center justify-start space-x-2"
                [ngClass]="{ 'select-none opacity-25': teamMember.skill_assigned }"
              >
                <app-avatar class="block" [appUser]="teamMember.user"></app-avatar>
                <span class="block text-body-2">
									{{ teamMember.user.name }} {{ teamMember.user.surname }}
								</span>
              </div>
              <!-- prettier-ignore -->
              <span
                class="block text-caption text-blue-grey-900"
                i18n="Назначить навык|Текст: Навык уже назначен@@app-skill-title.skill-set-alredy"
                *ngIf="teamMember.skill_assigned"
                [ngClass]="{ 'select-none opacity-25': teamMember.skill_assigned }"
              >
								Навык уже назначен
							</span>
            </li>
					</ul>
					<ng-template #emptyTeamMember>
						<div class="flex items-start p-4 bg-tertiary-25 rounded-lg mb-8">
							<i
								appSvgIcon
								appIcon="emoji-sad"
								appSquare="24"
								class="text-tertiary-A400"
							></i>
							<!-- prettier-ignore -->
							<p
                class="text-body-2 ml-2.5"
                i18n="Назначить навык|Параграф: К сожалению, в вашей команде пока нет ни одного тиммейта@@app-skill-title.skill-set-empty-teamMember"
              >
                К сожалению, в вашей команде пока нет ни одного тиммейта. Добавьте
                тиммейтов в команду прежде, чем приступить к назначению навыков.
              </p>
						</div>
					</ng-template>
					<div class="flex items-center justify-between w-full">
						<button
							class="btn btn-primary inline md"
							[disabled]="!teamMemberSelected"
							(click)="onSetTeammateSkill(teamMemberSelected?.id)"
							*ngIf="!!teamMemberList.length"
						>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Назначить навык|Кнопка: Назначить тиммейту@@app-skill-title.skill-set-submit"
              >
                Назначить тиммейту
              </span>
						</button>
						<button
							class="btn btn-primary inline md"
							(click)="onGotoTeam()"
							*ngIf="!teamMemberList.length"
						>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Назначить навык|Кнопка: Пригласить в команду@@app-skill-title.invite-to-team"
              >
                Пригласить в команду
              </span>
						</button>
					</div>
				</div>
				<div class="flex flex-col items-start" *ngSwitchCase="'done'">
					<!-- prettier-ignore -->
					<span
            class="block text-headline-4 mb-4"
            i18n="Назначить навык|Заголовок: Отлично!@@app-skill-title.skill-done-title"
          >
            Отлично!
          </span>
					<!-- prettier-ignore -->
					<p
            class="text-body-1 mb-4"
            i18n="Назначить навык|Параграф: Вы успешно внесли этот навык@@app-skill-title.skill-done-paragraph"
          >
            Вы успешно внесли этот навык в дедлайн выбранного тиммейта.
          </p>
					<div class="bg-blue-grey-25 w-full effect-01dp rounded-md p-3 mb-8">
						<div class="flex justify-between items-start space-x-2 mb-4">
							<div class="block text-subtitle-1 line-clamp-2">
								{{ skill.title }}
							</div>
							<div class="flex items-center">
								<i
									appSvgIcon
									appIcon="bookmarks"
									appSquare="20"
									appViewBox="0 0 24 24"
									class="text-blue-grey-600 mr-1.5"
								></i>
								<!-- prettier-ignore -->
								<span
                  class="text-body-2 text-blue-grey-600 mr-1"
                  i18n="Назначить навык|Текст: Задачи@@app-skill-title.skill-set-tasks"
                >
                  Задачи:
                </span>
								<span class="text-subtitle-2 text-blue-grey-900">
									{{ skill.taskList.length }}
								</span>
							</div>
						</div>
						<div class="flex justify-between items-center bg-white p-1 rounded-full">
							<div
								class="flex items-center justify-start space-x-2"
								*ngIf="teamMemberSelected"
							>
								<app-avatar
									class="block"
									[appUser]="teamMemberSelected.user"
								></app-avatar>
								<span class="block text-body-2">
									{{ teamMemberSelected.user.name }} {{ teamMemberSelected.user.surname }}
								</span>
							</div>
							<div class="flex justify-end space-x-2 mr-3">
								<i appSvgIcon appIcon="flag" appSquare="17" class="text-primary-A400"></i>
								<!-- prettier-ignore -->
								<span
                  class="block text-body-2 text-blue-grey-600"
                  i18n="Назначить навык|Текст: Дедлайн!@@app-skill-title.skill-done-deadline"
                >
									Дедлайн:
								</span>
								<time class="block text-subtitle-2">
									{{ teamMemberSelected?.deadline | date: "longDate" }}
								</time>
							</div>
						</div>
					</div>
					<div class="flex items-center justify-between w-full">
						<button
							class="btn btn-primary outline md"
							(click)="onToggleSetTeammateSkill(true)"
						>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Назначить навык|Кнопка: Назначить ещё одному тиммейту@@app-skill-title.skill-done-repeat"
              >
                Назначить ещё одному тиммейту
              </span>
						</button>
						<button
							class="btn btn-primary inline md"
							(click)="onToggleSetTeammateSkill(false)"
						>
							<figure class="relative">
								<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
							</figure>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Назначить навык|Кнопка: Готово@@app-skill-title.skill-done-submit"
              >
                Готово
              </span>
						</button>
					</div>
				</div>
			</ng-container>
		</div>
	</app-overlay>
	<!-- NAVIGATION: Модалка Навык уже в вашем прогрессе -->
	<app-overlay
		*ngIf="!!progress"
		[appState]="skillSetAlreadyAssignedToggle"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && (skillSetAlreadyAssignedToggle = false)"
	>
		<div class="relative w-full md:w-[688px] bg-white rounded-lg p-10">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="skillSetAlreadyAssignedToggle = false"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div class="flex flex-col items-start">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-4"
          i18n="Назначить навык|Заголовок: Вы уже изучаете этот навык@@app-skill-title.skill-modal-title-already-assigned"
        >
          Вы уже изучаете этот навык
        </span>
				<!-- prettier-ignore -->
				<p
          class="text-body-1 mb-4"
          i18n="Назначить навык|Параграф: Навык уже есть в вашем текущем дедлайне@@app-skill-title.skill-modal-paragraph-already-assigned"
        >
          Навык уже есть в вашем текущем дедлайне. Пожалуйста, перейдите в него для дальнейшей работы над навыком или
          выберите другой навык для изучения
        </p>
				<div class="bg-blue-grey-25 w-full effect-01dp rounded-md p-3 mb-8">
					<div class="flex justify-between items-start space-x-2 mb-4">
						<div class="block text-subtitle-1 line-clamp-2" *ngIf="!!progress.skill">
							{{ progress.skill.title }}
						</div>
						<div class="flex items-center">
							<i
								appSvgIcon
								appIcon="bookmarks"
								appSquare="20"
								appViewBox="0 0 24 24"
								class="text-blue-grey-600 mr-1.5"
							></i>
							<!-- prettier-ignore -->
							<span
                class="text-body-2 text-blue-grey-600 mr-1"
                i18n="Назначить навык|Текст: Задачи@@app-skill-title.skill-set-tasks"
              >
                Задачи:
              </span>
							<div class="flex items-center justify-end text-body-2 text-blue-grey-900">
								<span class="block text-subtitle-2">
									{{ progress.count_done }}
								</span>
								/
								<span class="block">
									{{ progress.count_all }}
								</span>
							</div>
						</div>
					</div>
					<div class="flex justify-start items-center bg-white p-1 rounded-full">
						<div class="flex justify-end space-x-2 mr-3 p-1">
							<i appSvgIcon appIcon="flag" appSquare="17" class="text-primary-A400"></i>
							<!-- prettier-ignore -->
							<span
                class="block text-body-2 text-blue-grey-600"
                i18n="Назначить навык|Текст: Дедлайн!@@app-skill-title.skill-done-deadline"
              >
                Дедлайн:
              </span>
							<ng-container [ngSwitch]="!!user.deadline">
								<i
									*ngSwitchCase="false"
									appSvgIcon
									appIcon="endless-2"
									appWidth="48"
									appHeight="17"
									appViewBox="0 0 98 42"
									class="text-primary-A400"
								></i>
								<time *ngSwitchCase="true" class="block text-subtitle-2">
									{{ user.deadline | date: "longDate" }}
								</time>
							</ng-container>
						</div>
					</div>
				</div>
				<div class="flex items-center justify-between w-full">
					<button
						class="btn btn-gray outline md"
						(click)="skillSetAlreadyAssignedToggle = false"
					>
						<figure class="relative">
							<i class="absolute" appSvgIcon appIcon="cross" appSquare="24"></i>
						</figure>
						<!-- prettier-ignore -->
						<span
              class="block"
              i18n="Назначить навык|Кнопка: Отмена@@app-skill-title.skill-modal-cancel-already-assigned"
            >
              Отмена
            </span>
					</button>
					<button class="btn btn-primary inline md" (click)="onGotoMyProgress()">
						<!-- prettier-ignore -->
						<span
              class="block"
              i18n="Назначить навык|Кнопка: Открыть в моём прогрессе@@app-skill-title.skill-modal-redirect-already-assigned"
            >
              Открыть в моём прогрессе
            </span>
						<figure class="relative">
							<i
								class="absolute transform rotate-180"
								appSvgIcon
								appIcon="arrow-back"
								appSquare="24"
								appViewBox="0 0 20 20"
							></i>
						</figure>
					</button>
				</div>
			</div>
		</div>
	</app-overlay>
	<!-- NAVIGATION: Модалка редактирования навыка = индвивидуальные действия -->
	<app-overlay
		[appState]="individualSkillToggle"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && (individualSkillToggle = false)"
	>
		<div class="relative w-full md:w-[688px] bg-white rounded-lg p-10">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="individualSkillToggle = false"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div class="flex flex-col items-start">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-4"
          i18n="Редактировать навык|Заголовок: Вы хотите редактировать навык тиммейта@@app-skill-title.skill-individual-title"
        >
          Вы хотите редактировать навык тиммейта
        </span>
				<!-- prettier-ignore -->
				<p
          class="text-body-1 mb-4"
          i18n="Редактировать навык|Параграф: Эти изменения сохранятся только в навыке тиммета. Изменения не будут опубликованы в навыке в библиотеке.@@app-skill-title.skill-individual-paragraph"
        >
          Эти изменения сохранятся только в навыке тиммета. Изменения не будут опубликованы в навыке в библиотеке.
        </p>
				<div class="flex items-center justify-between w-full">
					<button class="btn btn-gray outline md" (click)="onEditSkill(true)">
						<figure class="relative">
							<i class="absolute" appSvgIcon appIcon="arrow-back" appSquare="20"></i>
						</figure>
						<!-- prettier-ignore -->
						<span
              class="block"
              i18n="Редактировать навык|Кнопка: Редактировать в библиотеке@@app-skill-title.skill-individual-repeat"
            >
              Редактировать в библиотеке
            </span>
					</button>
					<button class="btn btn-primary inline md" (click)="onEditSkillIndividual()">
						<!-- prettier-ignore -->
						<span
              class="block"
              i18n="Редактировать навык|Кнопка: Готово@@app-skill-title.skill-individual-submit"
            >
              Продолжить
            </span>
						<figure class="relative">
							<i
								class="absolute rotate-180"
								appSvgIcon
								appIcon="arrow-back"
								appSquare="20"
							></i>
						</figure>
					</button>
				</div>
			</div>
		</div>
	</app-overlay>
	<!-- NAVIGATION: Переиспользование элемента отображения прогресса -->
	<ng-template #progressDisplay let-user="user" let-progress="progress">
		<a
			*ngIf="user.id !== progress.user.id"
			class="flex items-center justify-start space-x-2 my-1"
			[routerLink]="['/progress/', progress.user.team_member_id, 'dashboard']"
		>
			<app-avatar class="block" [appUser]="progress.user"></app-avatar>
			<span
				class="block text-body-2 text-blue-grey-900 line-clamp-1 hover:text-primary-A400"
			>
				{{ progress.user.name }} {{ progress.user.surname }}
			</span>
		</a>
		<div
			class="flex justify-between items-center space-x-3 bg-white rounded-full w-[fit-content] effect-01dp p-1"
		>
			<div class="flex items-center justify-start" [ngSwitch]="true">
				<!-- prettier-ignore -->
				<label *ngSwitchCase="progress.status === 'done' || progressIsResult" class="label label-green inline md">
          <!-- prettier-ignore -->
          <span
            class="block"
            i18n="Прогресс навыка|Текст: Подтвержден@@app-skill-title.skill-confirmed"
          >
            Подтвержден
          </span>
          <figure class="relative">
            <i
              class="absolute"
              appSvgIcon
              appIcon="done-all"
              appSquare="16"
              appViewBox="0 0 16 16"
            ></i>
          </figure>
        </label>
				<!-- prettier-ignore -->
				<label *ngSwitchCase="progress.status === 'ready'" class="label label-green inline md">
          <!-- prettier-ignore -->
          <span
            class="block"
            i18n="Прогресс навыка|Текст: Освоен@@app-skill-title.skill-mastered"
          >
            Освоен
          </span>
          <figure class="relative">
            <i
              class="absolute"
              appSvgIcon
              appIcon="done"
              appSquare="16"
              appViewBox="0 0 24 24"
            ></i>
          </figure>
        </label>
				<!-- prettier-ignore -->
				<label *ngSwitchCase="progress.status === 'in_progress'" class="label label-primary inline md">
          <!-- prettier-ignore -->
          <span
            class="block"
            i18n="Прогресс навыка|Текст: В работе@@app-skill-title.skill-in-progress"
          >
            В работе
          </span>
          <figure class="relative">
            <i class="absolute" appSvgIcon appIcon="bolt" appSquare="16"></i>
          </figure>
        </label>
			</div>
			<div class="flex items-center justify-start">
				<!-- prettier-ignore -->
				<span
          class="block text-body-2 whitespace-nowrap mr-3"
          i18n="Прогресс навыка|Текст: Выполнено задач@@app-skill-title.skill-progress-percent"
        >
          Выполнено задач:
        </span>
				<div class="flex justify-start items-center space-x-4 mr-2">
					<div data-pw="tesks-progress" class="flex justify-start items-center">
						<span class="text-headline-7 mr-1">{{ progress.count_done }}</span>
						<span class="text-body-2 text-blue-grey-600">/{{ progress.count_all }}</span>
					</div>
					<div class="bg-blue-grey-50 rounded-sm w-36 h-1">
						<div
							class="bg-secondary-500 rounded-sm h-1"
							[style.width]="progress.progress_percent + '%'"
						></div>
					</div>
					<span data-pw="skill-percent" class="text-headline-7">
						{{ progress.progress_percent }}%
					</span>
				</div>
			</div>
		</div>
	</ng-template>
</section>
