/** @format */

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { UserService, SnackbarService } from '../../../core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardActivate implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private snackbarService: SnackbarService
  ) {}

  canActivate(): Observable<boolean> {
    return this.userService.isAuthenticated.pipe(
      first(),
      switchMap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this.router.navigate(['/auth/error/401']).then(() =>
            this.snackbarService.info(
              $localize`:Снекбар|Сообщение - Нет доступа@@app-snackbar.no-access:Нет доступа`,
              {
                icon: 'attention'
              }
            )
          );

          return of(false);
        }

        return of(true);
      })
    );
  }
}
