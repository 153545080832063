/** @format */

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent, of, startWith, Subscription, switchMap } from 'rxjs';
import { MyTeam, PlatformService, Team, TeamService, User, UserService } from '../../../core';
import { Event as RouterEvent, NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { asideAnimation, fadeAsideAnimation } from '../../../app-animations';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  animations: [asideAnimation, fadeAsideAnimation]
})
export class AsideComponent implements OnInit, OnDestroy {
  @ViewChild('asideElement') asideElement!: ElementRef;

  routeEvents$!: Subscription;
  resize$!: Subscription;

  user!: User;
  user$!: Subscription;

  isShownAside$!: Subscription;
  isShownAside!: boolean;
  isShownAsideBreakpoint = 1160;

  myTeamList: MyTeam[] = [];
  myTeam$!: Subscription;

  locationPath!: string;

  constructor(
    private platformService: PlatformService,
    private router: Router,
    private userService: UserService,
    private teamService: TeamService,
    private location: Location
  ) {}

  ngOnInit(): void {
    /** Watch for URL changes */

    this.locationPath = this.location.path();
    this.location.onUrlChange((path: string) => (this.locationPath = path));

    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => (this.user = user),
      error: (error: any) => console.error(error)
    });

    this.myTeam$ = this.teamService.currentTeam.subscribe({
      next: (myTeam: MyTeam[]) => (this.myTeamList = myTeam),
      error: (error: any) => console.error(error)
    });

    this.isShownAside$ = this.platformService.isShownAside.subscribe({
      next: (isShownAside: boolean) => (this.isShownAside = isShownAside),
      error: (error: any) => console.error(error)
    });

    if (this.platformService.isBrowser()) {
      const window = this.platformService.getWindow();

      this.resize$ = fromEvent(window, 'resize')
        .pipe(
          switchMap(() => of(window.innerWidth)),
          startWith(window.innerWidth)
        )
        .subscribe({
          next: (width: number) => {
            this.platformService.isShownAside.next(width > this.isShownAsideBreakpoint);

            this.platformService.isMobileAside.next(width <= this.isShownAsideBreakpoint);
          },
          error: (error: any) => console.error(error)
        });

      this.routeEvents$ = this.router.events
        .pipe(
          filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd),
          switchMap(() => of(window.innerWidth)),
          tap((width: number) =>
            this.platformService.isMobileAside.next(width <= this.isShownAsideBreakpoint)
          ),
          filter((width: number) => width <= this.isShownAsideBreakpoint)
        )
        .subscribe({
          next: () => this.platformService.isShownAside.next(false),
          error: (error: any) => console.error(error)
        });
    }
  }

  ngOnDestroy(): void {
    [this.resize$, this.isShownAside$, this.routeEvents$, this.user$, this.myTeam$].forEach($ =>
      $?.unsubscribe()
    );
  }

  setAsideIsShow(): void {
    this.platformService.isShownAside.next(!this.platformService.isShownAside.getValue());
  }

  onTeamSelect(team: Team): void {
    if (this.platformService.isBrowser()) {
      const window: Window = this.platformService.getWindow();
      const userTeam: Team = this.user.team as Team;

      const hostname: string = window.location.hostname.replace(userTeam.subdomain, team.subdomain);
      const origin: string = window.location.origin.replace(window.location.hostname, hostname);

      window.location.assign(origin + '/');
    }
  }

  onNavigate(path: string): void {
    /** Save current state */
    const shouldReuseRoute: any = this.router.routeReuseStrategy.shouldReuseRoute;
    const onSameUrlNavigation = this.router.onSameUrlNavigation;

    /** Apply new config */

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    this.router.navigate([path]).then(() => console.debug('Route changed'));

    const timeout: number = setTimeout(() => {
      /** Restore previous state */

      this.router.routeReuseStrategy.shouldReuseRoute = shouldReuseRoute;
      this.router.onSameUrlNavigation = onSameUrlNavigation;

      clearTimeout(timeout);
    });
  }
}
