/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Trajectory, TrajectoryService } from '../../../../core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-trajectory-description, [appTrajectoryDescription]',
  templateUrl: './description.component.html'
})
export class TrajectoryDescriptionComponent implements OnInit, OnDestroy {
  trajectory!: Trajectory;
  trajectory$!: Subscription;

  constructor(private trajectoryService: TrajectoryService) {}

  ngOnInit(): void {
    this.trajectory$ = this.trajectoryService.trajectory$.subscribe({
      next: (trajectory: Trajectory) => (this.trajectory = trajectory)
    });
  }

  ngOnDestroy(): void {
    [this.trajectory$].forEach($ => $?.unsubscribe());
  }
}
