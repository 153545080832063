/** @format */

import { Component } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html'
})
export class LoaderComponent {
  isLoading = false;

  constructor() {}
}
