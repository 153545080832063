/** @format */

import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  Progress,
  ProgressService,
  Skill,
  SkillErrors,
  SkillService,
  SkillTask,
  SnackbarService
} from '../../../../../core';
import { ActivatedRoute, Event as RouterEvent, NavigationEnd, Router } from '@angular/router';
import { EMPTY, startWith, Subscription, switchMap } from 'rxjs';
import { debounceTime, filter, first, tap } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import { DOCUMENT, Location } from '@angular/common';

interface SkillForm {
  [key: string]: FormControl<any>;
}

@Component({
  selector: 'app-skill-tasks-list',
  templateUrl: './list.component.html'
})
export class SkillTaskListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sortableElement') sortableElement!: ElementRef;

  routerEvents$!: Subscription;

  progress!: Progress;
  progress$!: Subscription;
  progressIsEstimate!: boolean;
  progressIsEstimate$!: Subscription;

  skill!: Skill;
  skill$!: Subscription;

  skillIsEdit!: boolean;
  skillIsEdit$!: Subscription;
  skillIsEditIndividual!: boolean;

  skillErrors$!: Subscription;
  skillErrors!: SkillErrors;

  skillTask!: SkillTask;

  skillForm: FormGroup;
  skillForm$!: Subscription;
  skillFormIsSubmitting = false;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private skillService: SkillService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private location: Location,
    private progressService: ProgressService
  ) {
    this.skillForm = this.formBuilder.group<SkillForm>({});
  }

  ngOnInit(): void {
    this.routerEvents$ = this.router.events
      .pipe(
        filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd),
        startWith(EMPTY),
        filter(() => !!this.activatedRoute.firstChild?.snapshot.paramMap.get('taskId'))
      )
      .subscribe({
        next: () => {
          this.skill$?.unsubscribe();
          this.skill$ = this.skillService.skill$
            .pipe(
              tap((skill: Skill) => {
                this.skill = skill;

                // prettier-ignore
                // @ts-ignore
                const taskId: string = this.activatedRoute.firstChild?.snapshot.paramMap.get('taskId');

                // @ts-ignore
                this.skillTask = this.skill.taskList.find((skillTask: SkillTask) => {
                  return skillTask.id === taskId;
                });
              }),
              switchMap(() => this.skillService.skillIsEdit$),
              filter((skillIsEdit: boolean) => skillIsEdit),
              tap((skillIsEdit: boolean) => (this.skillIsEdit = skillIsEdit))
            )
            .subscribe({
              next: () => {
                this.setSkillForm(this.skill.taskList);

                const taskId = String(this.skillTask.id);

                const abstractControl: AbstractControl | null = this.skillForm.get(taskId);
                const abstractControlIsEqual = (title: string): boolean => {
                  return title === this.skillTask.title;
                };

                if (abstractControl) {
                  // prettier-ignore
                  !abstractControlIsEqual(abstractControl.value) && abstractControl.setValue(this.skillTask.title);

                  this.skillForm$?.unsubscribe();
                  this.skillForm$ = abstractControl.valueChanges
                    .pipe(
                      debounceTime(200),
                      filter((title: string) => !abstractControlIsEqual(title))
                    )
                    .subscribe({
                      next: (title: string) => {
                        this.skillService.setSkillTaskTitleById(taskId, title);

                        /** touched, then remove from errors */
                        const skillErrors: SkillErrors = this.skillService.skillErrors$.getValue();

                        // prettier-ignore
                        this.skillService.skillErrors$.next({
                          ...skillErrors,
                          taskListErrors: skillErrors.taskListErrors?.filter((skillTask: SkillTask) => skillTask.id !== taskId)
                        });
                      },
                      error: (error: any) => console.error(error)
                    });
                }
              },
              error: (error: any) => console.error(error)
            });

          /** subscribing to errors */

          this.skillErrors$?.unsubscribe();

          this.skillErrors$ = this.skillService.skillErrors$.subscribe({
            next: (skillErrors: SkillErrors) => (this.skillErrors = skillErrors),
            error: (error: any) => console.error(error)
          });
        },
        error: (error: any) => console.error(error)
      });

    /** Individual skill edition setup */

    // prettier-ignore
    this.skillIsEditIndividual = !!String(this.activatedRoute.snapshot.queryParamMap.get('individual') || '');

    /**
     * https://kostylworks.atlassian.net/browse/DAB-897
     *
     * Watch for task list statuses to auto confirm progress
     *
     **/

    this.progressIsEstimate$ = this.progressService.progressIsEstimate$
      .pipe(
        first(),
        filter((progressIsEstimate: boolean) => progressIsEstimate)
      )
      .subscribe({
        next: (progressIsEstimate: boolean) => {
          this.progressIsEstimate = progressIsEstimate;

          this.progress$ = this.progressService.progress$
            .pipe(filter((progress: Progress) => progress.status !== 'done'))
            .subscribe({
              next: (progress: Progress) => {
                this.progress = progress;

                // prettier-ignore
                const skillTaskConfirmed: boolean = this.progress.skill.taskList.every((skillTask: SkillTask) => {
                  return skillTask.status === 'done';
                });

                if (skillTaskConfirmed) {
                  this.progressService
                    .setSkillStatus(this.progress.id, { status: 'done' })
                    .subscribe({
                      next: (progress: Progress) => this.progressService.onAddProgress(progress),
                      error: (error: any) => console.error(error)
                    });
                }
              },
              error: (error: any) => console.error(error)
            });
        },
        error: (error: any) => console.error(error)
      });
  }

  ngAfterViewInit(): void {
    this.skillIsEdit$ = this.skillService.skillIsEdit$
      .pipe(
        debounceTime(200),
        filter((skillIsEdit: boolean) => skillIsEdit)
      )
      .subscribe({
        next: () => {
          new Sortable(this.sortableElement.nativeElement, {
            animation: 150,
            handle: '.btn-drag',
            onEnd: (event: any) => {
              const skillTask: SkillTask = this.skill.taskList[event.oldIndex];

              this.skillService.setSkillTaskPosition(skillTask.id, event.oldIndex, event.newIndex);
            }
          });
        },
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    // prettier-ignore
    [
      this.skill$,
      this.skillForm$,
      this.skillIsEdit$,
      this.routerEvents$,
      this.progress$,
      this.progressIsEstimate$
    ].forEach($ =>$?.unsubscribe());
  }

  setSkillForm(skillTaskList: SkillTask[]): void {
    skillTaskList.forEach((skillTask: SkillTask) => {
      const abstractControl: AbstractControl | null = this.skillForm.get(String(skillTask.id));

      if (!abstractControl) {
        const formControl: any = this.formBuilder.control(skillTask.title || '', [
          Validators.required
        ]);

        this.skillForm.addControl(String(skillTask.id), formControl);
      }
    });

    Object.keys(this.skillForm.controls).forEach((key: string) => {
      const skillTask: SkillTask | undefined = this.skill.taskList.find((skillTask: SkillTask) => {
        return skillTask.id === String(key);
      });

      !skillTask && this.skillForm.removeControl(key);
    });
  }

  onRemoveSkillTaskById(id: string): void {
    if (this.skill.taskList.length > 1) {
      const skillTaskList: SkillTask[] = this.skill.taskList;
      const skillTaskIndex: number = skillTaskList.findIndex((skillTask: SkillTask) => {
        return skillTask.id === id;
      });

      /**
       * Taking "skillTaskIndex" for route next change (go to next or previous)
       * Avoid move to not existed task (route)
       * */

      const getTaskId = (): string => {
        const previousSkillTask: SkillTask = skillTaskList[skillTaskIndex - 1];
        const nextSkillTask: SkillTask = skillTaskList[skillTaskIndex + 1];

        /** If we're deleting not current task, staying on same route */

        if (this.skillTask.id !== id) {
          return this.skillTask.id;
        }

        /** If we're deleting current task
         *
         * Have a next task? Go to next route
         * If not have a next task go to previous route
         * */

        return nextSkillTask ? nextSkillTask.id : previousSkillTask.id;
      };

      this.router
        .navigateByUrl(this.location.path().replace(this.skillTask.id, getTaskId()))
        .then(() => {
          const skillTask: SkillTask | undefined = skillTaskList.find((skillTask: SkillTask) => {
            return skillTask.id === id;
          });

          this.skillService.onRemoveSkillTaskById(id).subscribe({
            next: () => {
              this.snackbarService.info(
                $localize`:Снекбар|Сообщение - Задача была удалена@@app-snackbar.task-deleted:Задача была удалена`,
                {
                  timeout: 8000,
                  icon: 'done',
                  progress: true,
                  progressClass: 'bg-secondary-600',
                  button: {
                    label: $localize`:Снекбар|Кнопка - Отмена@@app-snackbar.task-deleted-cancel:Отмена`,
                    callback: () => skillTask && this.skillService.onRestoreSkillTask(skillTask)
                  }
                }
              );
            },
            error: (error: any) => console.error(error)
          });
        });
    } else {
      this.snackbarService.info(
        $localize`:Снекбар|Сообщение - Вы не можете удалить единственную задачу@@app-snackbar.task-cannot-be-deleted:Вы не можете удалить единственную задачу`,
        {
          icon: 'attention'
        }
      );
    }
  }

  onAddSkillTask(event: any): void {
    event.preventDefault();

    const valueChangesTimeout: any = setTimeout(() => {
      this.skillService.onAddSkillTask().subscribe({
        next: (skillTask: SkillTask) => {
          this.router
            .navigateByUrl(this.location.path().replace(this.skillTask.id, skillTask.id))
            .then(() => {
              const navigateTimeout: number = setTimeout(() => {
                const inputElement: HTMLElement | null = this.document.getElementById(skillTask.id);

                if (!!inputElement) {
                  inputElement.focus();
                }

                clearTimeout(navigateTimeout);
              });
            });
        },
        error: (error: any) => console.error(error)
      });

      clearTimeout(valueChangesTimeout);
    }, 200);
  }

  onGotoSkillTask(skillTask: SkillTask): void {
    this.router
      .navigate(['.', skillTask.id], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge'
      })
      .then(() => console.debug('Route changed'));
  }

  onFocus(skillTask: SkillTask): void {
    // prettier-ignore
    const taskId: string | null | undefined = this.activatedRoute.firstChild?.snapshot.paramMap.get('taskId');

    if (!!taskId && taskId !== skillTask.id) {
      this.router
        .navigateByUrl(this.location.path().replace(taskId, skillTask.id))
        .then(() => console.debug('Route changed'));
    }
  }
}
