<!-- @format -->

<!-- prettier-ignore -->
<main appCrisp class="absolute w-full h-full" [@routingMainAnimation]="outlet && outlet.activatedRouteData">
	<app-loader-http></app-loader-http>
	<router-outlet #outlet="outlet"></router-outlet>
	<app-snackbar></app-snackbar>
	<app-snackbar-modal></app-snackbar-modal>
	<app-overlay-image></app-overlay-image>
  <app-overlay-tariff></app-overlay-tariff>
</main>
