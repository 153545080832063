/** @format */

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PlatformService } from '../../../core';

@Component({
  selector: 'app-sorting',
  templateUrl: './sorting.component.html'
})
export class SortingComponent implements OnInit {
  sortingType$!: Observable<string>;
  sortingReverse$!: Observable<boolean>;

  constructor(private platformService: PlatformService) {}

  ngOnInit(): void {
    this.sortingType$ = this.platformService.sortingType$.asObservable();
    this.sortingReverse$ = this.platformService.sortingReverse$.asObservable();
  }

  onSorting(type: string): void {
    const sortingType: string = this.platformService.sortingType$.getValue();

    if (sortingType === type) {
      this.platformService.sortingReverse$.next(!this.platformService.sortingReverse$.getValue());
    }

    this.platformService.sortingType$.next(type);
  }
}
