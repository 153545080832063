/** @format */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardLoad, AuthGuardActivate, PublicGuardLoad, PublicGuardActivate } from './core';

const routes: Routes = [
  {
    path: 'alpha',
    loadChildren: () => import('./alpha/alpha.module').then(m => m.AlphaModule),
    canLoad: [AuthGuardLoad],
    canActivate: [AuthGuardActivate]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canLoad: [PublicGuardLoad],
    canActivate: [PublicGuardActivate]
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  },
  {
    path: 'invite',
    loadChildren: () => import('./invite/invite.module').then(m => m.InviteModule)
  },
  {
    path: 'ui',
    loadChildren: () => import('./ui/ui.module').then(m => m.UiModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canLoad: [AuthGuardLoad],
    canActivate: [AuthGuardActivate]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/error/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
