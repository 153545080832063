/** @format */

import { User } from './user.model';
import { TariffCurrent } from './tariff.model';

export interface Team {
  id: number;
  name: string;
  subdomain: string;
  tariff: TariffCurrent;
  created_at: string;
  updated_at: string;
}

export interface TeamInfo {
  team: Team;
  invited_by: TeamInfoInvitedBy;
}

export interface TeamInfoInvitedBy {
  name: string;
  surname: string;
}

export interface MyTeam {
  id: number;
  team: Team;
  role_key: RoleKey;
  role_title: string;
  user_id: number;
  created_at: string;
  updated_at: string;
}

export interface TeamMember {
  id: number;
  user: User;
  deadline?: string;
  skill_assigned?: boolean;
  trajectory_assigned?: boolean;
  skill_count?: number;
  role_key: RoleKey;
  role_title: string;
  created_at: string;
  updated_at: string;
}

export interface TeamMemberPagination {
  items: TeamMember[];
  total: number;
}

export interface TeamInvitation {
  id: number;
  token: string;
  email: string;
  role_key: RoleKey;
  role_title: string;
  send_status: string;
  team: Team;
  created_at: string;
  updated_at: string;
}

export interface TeamInvitationPagination {
  invitations: TeamInvitation[];
  total: number;
}

export interface TeamReferral {
  url: string;
}

export interface TeamInvitationRegistration {
  email: string | null;
  onboarding: string;
}

export type RoleKey = 'teamlead' | 'owner' | 'teammate';

export type Role = { [key in RoleKey]: string };

export const InvitationRoleMap: Role = {
  owner: 'Владелец',
  teamlead: 'Тимлид',
  teammate: 'Тиммейт'
};
