/** @format */

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { InviteComponent } from './invite.component';
import { InviteRoutingModule } from './invite-routing.module';

@NgModule({
  imports: [SharedModule, InviteRoutingModule],
  declarations: [InviteComponent]
})
export class InviteModule {}
