/** @format */

import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PlatformService } from '../../../core';

@Injectable({
  providedIn: 'root'
})
export class TeamGuardLoad implements CanLoad {
  constructor(private router: Router, private platformService: PlatformService) {}

  canLoad(): Observable<boolean> {
    if (!this.platformService.isInTeam()) {
      this.router.navigate(['/profile']).then(() => console.debug('Route changed'));

      return of(false);
    }
    return of(true);
  }
}
