/** @format */

import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { UserService } from '../../../core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardLoad implements CanLoad {
  constructor(private router: Router, private userService: UserService) {}

  canLoad(): Observable<boolean> {
    return this.userService.isAuthenticated.pipe(
      first(),
      switchMap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this.router.navigate(['/auth/error/401']).then(() => console.debug('Route changed'));

          return of(false);
        }

        return of(true);
      })
    );
  }
}
