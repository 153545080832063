<!-- @format -->

<section *ngIf="skill">
	<ng-container
		[ngSwitch]="skillIsEdit"
		*ngIf="{
			ul: 'flex mobile:flex-row flex-col items-start justify-start mobile:overflow-auto space-y-2 mobile:space-y-0 mobile:space-x-2 mobile:px-16 mobile:pb-[4px] mobile:-mx-16',
			li: 'flex items-center justify-start mobile:justify-center bg-white border-2 rounded-lg effect-01dp text-left w-full mobile:w-14 mobile:h-14',
			error: 'border-0 border-l-2 py-0.5 pr-0.5 border-tertiary-100 effect-02dp'
		} as classList"
	>
		<ng-container *ngSwitchCase="true">
			<form
				class="block w-full"
				[formGroup]="skillForm"
				autocomplete="off"
				*ngIf="skillTask as skillTaskActive"
			>
				<fieldset class="grid w-full" [disabled]="skillFormIsSubmitting">
					<ul [ngClass]="classList.ul" #sortableElement>
						<!-- @ts-ignore -->
						<!-- prettier-ignore -->
						<li
							[ngClass]="[classList.li, skillTaskActive.id === skillTask.id ? 'border-blue-grey-200' : !$any(skillErrors)?.taskListErrors?.includes(skillTask) ? 'border-transparent' : '', $any(skillErrors)?.taskListErrors?.includes(skillTask) ? classList.error : '']"
							*ngFor="let skillTask of skill.taskList; let i = index"
						>
							<div class="flex items-center justify-start group w-full mobile:hidden">
                <div class="flex items-center justify-start">
                  <button
                    *ngIf="!['done', 'ready'].includes(skillTask.status || '')"
                    class="btn btn-gray btn-drag transparent sm cursor-move"
                  >
                    <i appSvgIcon appIcon="drag-indicator" appSquare="20"></i>
                  </button>
                </div>
								<div class="block w-full" (click)="onGotoSkillTask(skillTask)">
									<fieldset class="form-control-unset flex w-full">
										<!-- prettier-ignore -->
										<textarea
                      appTextarea
											class="text-subtitle-2 h-auto overflow-y-hidden w-full py-4 px-0"
											[ngClass]="{'cursor-pointer pl-4':!!skillIsEditIndividual && ['done', 'ready'].includes(skillTask.status || '')}"
                      [attr.data-pw]="'action-title-' + i"
											placeholder="Введите название задачи"
                      i18n-placeholder="Просмотр навыка|Поле ввода: Введите название задачи@@app-skill-tasks-list.input-title"
                      [readOnly]="!!skillIsEditIndividual && ['done', 'ready'].includes(skillTask.status || '')"
                      [rows]="1"
											[name]="skillTask.id"
											[id]="skillTask.id"
											[formControlName]="skillTask.id"
                      (focus)="onFocus(skillTask)"
                      (keydown.enter)="onAddSkillTask($event)"
										></textarea>
										<label [for]="skillTask.id" class="hidden"></label>
									</fieldset>
								</div>
                <div class="flex items-center justify-end cursor-pointer">
                  <!-- prettier-ignore -->
                  <button
                    *ngIf="!['done', 'ready'].includes(skillTask.status || '')"
                    class="btn btn-gray transparent sm invisible opacity-0"
                    [ngClass]="{ 'group-hover:visible group-hover:opacity-100': skill.taskList.length > 1 }"
                    (click)="onRemoveSkillTaskById(skillTask.id)"
                  >
                    <i
                      appSvgIcon
                      appIcon="cross"
                      appHeight="20"
                      appWidth="20"
                      appViewBox="0 0 24 24"
                    ></i>
                  </button>
                  <ng-container *ngIf="!!skillIsEditIndividual">
                    <div
                      class="label label-icon-green inline md mr-3"
                      *ngIf="skillTask.status === 'done'"
                    >
                      <i
                        class="w-4"
                        appSvgIcon
                        appIcon="done-all"
                        appSquare="16"
                      ></i>
                    </div>
                    <div
                      class="label label-icon-green inline md mr-3"
                      *ngIf="skillTask.status === 'ready'"
                    >
                      <i
                        class="w-4"
                        appSvgIcon
                        appIcon="done"
                        appSquare="24"
                      ></i>
                    </div>
                  </ng-container>
                </div>
							</div>
							<div class="hidden mobile:block">
								<div
									class="block text-subtitle-2 group py-4.5 px-6"
									(click)="onGotoSkillTask(skillTask)"
								>
									<!-- prettier-ignore -->
									<span
										class="block group-hover:text-blue-grey-900"
										[ngClass]="skillTaskActive.id === skillTask.id ? 'text-blue-grey-900' : 'text-blue-grey-600'"
                  >
										{{ i + 1 }}
									</span>
								</div>
							</div>
						</li>
					</ul>
				</fieldset>
			</form>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<div class="block w-full">
				<ul [ngClass]="classList.ul">
					<li
						data-pw="skill-action"
						[ngClass]="[
							classList.li,
							taskListRla.isActive ? 'border-blue-grey-200' : 'border-transparent'
						]"
						*ngFor="let skillTask of skill.taskList; let i = index"
					>
						<a
							class="block text-subtitle-2 group w-full min-h-[56px] mobile:w-auto py-4.5 px-6"
							[attr.data-pw]="'action-title-' + i"
							[routerLink]="['.', skillTask.id]"
							[routerLinkActive]="['active']"
							[queryParamsHandling]="'merge'"
							#taskListRla="routerLinkActive"
						>
							<!-- prettier-ignore -->
							<div
								class="flex items-center justify-between space-x-2 group-hover:text-blue-grey-900 mobile:hidden"
								[ngClass]="taskListRla.isActive ? 'text-blue-grey-900' : 'text-blue-grey-600'"
							>
								<span class="block truncate">{{ skillTask.title }}</span>
								<ng-container *ngIf="skillTask.status">
									<div
										class="label label-icon-green inline md"
										*ngIf="skillTask.status === 'done'"
									>
										<i class="w-4" appSvgIcon appIcon="done-all" appSquare="16"></i>
									</div>
                  <div
                    class="label label-icon-green inline md"
                    *ngIf="skillTask.status === 'ready'"
                  >
                    <i class="w-4" appSvgIcon appIcon="done" appSquare="24"></i>
                  </div>
								</ng-container>
							</div>
							<div
								class="hidden group-hover:text-blue-grey-900 mobile:block"
								[ngClass]="
									taskListRla.isActive ? 'text-blue-grey-900' : 'text-blue-grey-600'
								"
							>
								<span>{{ i + 1 }}</span>
							</div>
						</a>
					</li>
				</ul>
			</div>
		</ng-container>
	</ng-container>
</section>
