/** @format */

import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { authImageAnimation } from '../app-animations';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  animations: [authImageAnimation]
})
export class AuthComponent implements OnInit {
  backgroundImage: string | undefined;
  backgroundImageList: string[] = ['error', 'forgot', 'invite', 'login', 'registration', 'reset'];

  lottieAnimationOptions: AnimationOptions = {
    path: '/assets/lottie/2.json'
  };

  lottieStyles: Partial<CSSStyleDeclaration> = {
    maxWidth: '768px',
    margin: '0 auto'
  };

  constructor(public router: Router, public activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}

  getNameImg(name: string | undefined): string | undefined {
    switch (name) {
      case 'reset':
      case 'forgot':
      case '401':
      case '403':
        return 'forgot';
      default:
        return undefined;
    }
  }

  onActivate(event: EventEmitter<any>): void {
    this.backgroundImage = this.getNameImg(this.router.url.split('/').pop()?.split('?').shift());
  }

  onDeactivate(event: EventEmitter<any>): void {
    this.backgroundImage = undefined;
  }
}
