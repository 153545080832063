<!-- @format -->

<section class="flex flex-col">
	<ng-container [ngSwitch]="trajectoryIsEdit">
		<!-- NAVIGATION: Если редактируем траекторию тогда отображаем textarea -->
		<ng-container *ngSwitchCase="true">
			<form class="w-full" [formGroup]="trajectoryForm" autocomplete="off">
				<fieldset [disabled]="trajectoryFormIsSubmitting">
					<fieldset class="form-control-unset">
						<!-- prettier-ignore -->
						<textarea
              appTextarea
							class="text-headline-3 h-auto overflow-y-hidden w-full p-0 mb-12"
							placeholder="Введите название траектории"
							i18n-placeholder="Просмотр траектории|Поле ввода: Введите название траектории@@app-trajectory-title.input-title"
							name="title"
							id="title"
							[rows]="1"
							formControlName="title"
              #textAreaElement
						></textarea>
						<label for="title" class="hidden"></label>
					</fieldset>
				</fieldset>
			</form>
		</ng-container>
		<!-- NAVIGATION: Если НЕ редактируем траекторию тогда отображаем информацию траектории -->
		<ng-container *ngSwitchDefault>
			<h3 class="text-headline-3 text-blue-grey-900 line-clamp-3 mb-6">
				{{ trajectory.title }}
			</h3>
			<div class="flex items-center justify-start space-x-4 mb-12">
				<!-- NAVIGATION: Если смотрим прогресс -->
				<ng-container [ngSwitch]="trajectoryIsProgress">
					<!-- NAVIGATION: Если мы ЗАШЛИ В ТРАЕКТОРИЮ ПРОСТО ПОСМОТРЕТЬ (например из библиотеки) -->
					<ng-container *ngSwitchDefault>
						<div class="flex items-center justify-start space-x-2">
							<!-- NAVIGATION: Если мы ТИМЛИД тогда показываем "Назначить тиммейту" -->
							<ng-container *appRole="['teamlead', 'owner']">
								<button
									class="btn btn-primary inline md"
									(click)="onToggleSetTeammateTrajectory(true)"
								>
									<!-- prettier-ignore -->
									<span
                    class="block"
                    i18n="Просмотр навыка|Кнопка: Назначить тиммейту@@app-trajectory-title.teammate-set"
                  >
                    Назначить тиммейту
                  </span>
								</button>
							</ng-container>
							<!-- NAVIGATION: Если мы НЕВАЖНО кто тогда показываем "Освоить навык" (все пользователи могут сами себе набирать траектории) -->
							<button
								data-pw="skill-learn"
								class="btn btn-primary outline md"
								(click)="onSetTeammateTrajectory(user.team_member_id)"
							>
								<!-- prettier-ignore -->
								<span
                  class="block"
                  i18n="Просмотр навыка|Кнопка: Взять траекторию@@app-trajectory-title.master-trajectory"
                >
                  Взять траекторию
                </span>
							</button>
						</div>
						<!-- NAVIGATION: Если мы ТИМЛИД тогда показываем кнопки редактирования и удаления траектории -->
						<ng-container *appRole="['teamlead', 'owner']">
							<div class="flex items-center justify-start space-x-2">
								<button class="btn btn-gray outline md" (click)="onGotoEdit()">
									<figure class="relative">
										<i
											class="absolute"
											appSvgIcon
											appIcon="pencil"
											appHeight="24"
											appWidth="24"
											appViewBox="0 0 20 20"
										></i>
									</figure>
								</button>
								<button
									class="btn btn-gray outline md"
									(click)="onToggleRemoveTrajectory()"
								>
									<figure class="relative">
										<i class="absolute" appSvgIcon appIcon="cross" appSquare="24"></i>
									</figure>
								</button>
							</div>
						</ng-container>
					</ng-container>
					<!-- NAVIGATION: Если мы ЗАШЛИ В ПРОГРЕСС (например из страницы списка прогрессов) -->
					<ng-container *ngSwitchCase="true">
						<ng-container [ngSwitch]="trajectory.progress?.status">
							<!-- NAVIGATION: Блок без прогресса -->
							<ng-container *ngSwitchCase="'not_in_progress'">
								<div
									*ngIf="user.id !== trajectory.progress?.user?.id"
									class="flex items-center justify-start space-x-2 my-1"
								>
									<app-avatar
										class="block"
										[appUser]="trajectory.progress?.user"
									></app-avatar>
									<span class="block text-body-2 text-blue-grey-900 line-clamp-1">
										{{ trajectory.progress?.user?.name }}
										{{ trajectory.progress?.user?.surname }}
									</span>
								</div>
								<div
									class="flex items-center bg-white effect-01dp rounded-full py-2 pl-3 pr-4"
								>
									<i
										appSvgIcon
										appIcon="grid-view"
										appSquare="24"
										class="text-blue-grey-600 mr-1.5"
									></i>
									<!-- prettier-ignore -->
									<span
                    class="text-body-2 text-blue-grey-600 mr-1"
                    i18n="Прогресс траектории|Текст: Навыки@@app-trajectory-title.trajectory-skills"
                  >
                    Навыки:
                  </span>
									<span class="text-subtitle-2 text-blue-grey-900">
										{{ trajectory.progress?.count_all }}
									</span>
								</div>
							</ng-container>
							<!-- NAVIGATION: Блок с прогрессом -->
							<div
								class="flex flex-col lg:flex-row justify-start lg:items-center space-y-4 lg:space-y-0 lg:space-x-5"
								*ngSwitchDefault
							>
								<!-- NAVIGATION: Если мы находимся в ЧУЖОМ ПРОГРЕССЕ тогда отображаем аватар пользователя этого прогресса -->
								<ng-container
									[ngTemplateOutlet]="progressDisplay"
									[ngTemplateOutletContext]="{user: user, progress: trajectory.progress}"
								></ng-container>
							</div>
						</ng-container>
						<button
							class="btn btn-gray outline md"
							(click)="trajectoryProgressRemoveToggle = true"
							*ngIf="!trajectoryIsResult"
						>
							<figure class="relative">
								<i class="absolute" appSvgIcon appIcon="cross" appSquare="24"></i>
							</figure>
						</button>
					</ng-container>
				</ng-container>
			</div>
		</ng-container>
	</ng-container>
	<!-- NAVIGATION: Модалка удаления прогресса из дедлайна -->
	<app-overlay
		[appState]="trajectoryProgressRemoveToggle"
		[appClassList]="['overlay-gray']"
		(clicked)="trajectoryProgressRemoveToggle = $event"
		*ngIf="trajectoryIsProgress"
	>
		<div class="relative w-full max-w-[512px] bg-white rounded-lg p-10">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="trajectoryProgressRemoveToggle = false"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<ng-container [ngSwitch]="user.id === trajectory.progress?.user?.id">
				<div class="flex flex-col items-start" *ngSwitchCase="true">
					<!-- prettier-ignore -->
					<span
            class="block text-headline-4 mb-4"
            i18n="Просмотр прогресса траектории|Заголовок: Убрать траекторию @@app-trajectory-title.trajectory-progress-my-title"
          >
            Убрать траекторию
          </span>
					<!-- prettier-ignore -->
					<p
            *ngIf="trajectory.progress?.status === 'not_in_progress'"
            class="text-body-1 mb-8"
            i18n="Просмотр прогресса траектории|Параграф: Вы больше не будете видеть эту траекторию в своём дашборде.@@app-trajectory-title.trajectory-progress-my-paragraph-not-in-progress"
          >
            Вы больше не будете видеть эту траекторию в своём дашборде.
          </p>
					<!-- prettier-ignore -->
					<p
            *ngIf="trajectory.progress?.status === 'in_progress'"
            class="text-body-1 mb-8"
            i18n="Просмотр прогресса траектории|Параграф: Вы больше не будете видеть эту траекторию в своём дашборде. Все освоенные и начатые навыки останутся в чекпоинте и в ваших результатах.@@app-trajectory-title.trajectory-progress-my-paragraph-in-progress"
          >
            Вы больше не будете видеть эту траекторию в своём дашборде. Все освоенные и начатые навыки останутся в чекпоинте и в ваших результатах.
          </p>
					<div class="flex items-center justify-between w-full">
						<button
							class="btn btn-gray outline md"
							(click)="trajectoryProgressRemoveToggle = false"
						>
							<figure class="relative">
								<i
									class="absolute text-blue-grey-400"
									appSvgIcon
									appIcon="cross"
									appSquare="24"
								></i>
							</figure>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Просмотр прогресса траектории|Кнопка: Отмена@@app-trajectory-title.trajectory-progress-my-cancel"
              >
                Отмена
              </span>
						</button>
						<button
							class="btn btn-tertiary inline md"
							(click)="onRemoveTrajectoryProgress()"
						>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Просмотр прогресса траектории|Кнопка: Убрать траекторию@@app-trajectory-title.trajectory-progress-my-submit"
              >
                Убрать траекторию
              </span>
						</button>
					</div>
				</div>
				<div class="flex flex-col items-start" *ngSwitchCase="false">
					<!-- prettier-ignore -->
					<span
            class="block text-headline-4 mb-4"
            i18n="Просмотр прогресса траектории|Заголовок: Убрать траекторию тиммейта @@app-trajectory-title.trajectory-progress-teammate-title"
          >
            Убрать траекторию тиммейта
          </span>
					<!-- prettier-ignore -->
					<p
            *ngIf="trajectory.progress?.status === 'not_in_progress'"
            class="text-body-1 mb-8"
            i18n="Просмотр прогресса траектории|Параграф: Траектория не удалится из библиотеки, но больше не будет назначена этому тиммейту для прохождения.@@app-trajectory-title.trajectory-progress-teammate-paragraph-not-in-progress"
          >
            Траектория не удалится из библиотеки, но больше не будет назначена этому тиммейту для прохождения.
          </p>
					<!-- prettier-ignore -->
					<p
            *ngIf="trajectory.progress?.status === 'in_progress'"
            class="text-body-1 mb-8"
            i18n="Просмотр прогресса траектории|Параграф: Траектория не удалится из библиотеки, но больше не будет назначена этому тиммейту для прохождения. Все освоенные и начатые навыки останутся в чекпоинте и результатах тиммейта.@@app-trajectory-title.trajectory-progress-teammate-paragraph-in-progress"
          >
            Траектория не удалится из библиотеки, но больше не будет назначена этому тиммейту для прохождения. Все освоенные и начатые навыки останутся в чекпоинте и результатах тиммейта.
          </p>
					<div class="flex items-center justify-between w-full">
						<button
							class="btn btn-gray outline md"
							(click)="trajectoryProgressRemoveToggle = false"
						>
							<figure class="relative">
								<i
									class="absolute text-blue-grey-400"
									appSvgIcon
									appIcon="cross"
									appSquare="24"
								></i>
							</figure>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Просмотр прогресса траектории|Кнопка: Отмена@@app-trajectory-title.trajectory-progress-teammate-cancel"
              >
                Отмена
              </span>
						</button>
						<button
							class="btn btn-tertiary inline md"
							(click)="onRemoveTrajectoryProgress()"
						>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Просмотр прогресса траектории|Кнопка: Убрать траекторию@@app-trajectory-title.trajectory-progress-teammate-submit"
              >
                Убрать траекторию
              </span>
						</button>
					</div>
				</div>
			</ng-container>
		</div>
	</app-overlay>
	<!-- NAVIGATION: Модалка назначить траекторию тиммейту -->
	<app-overlay
		[appState]="trajectorySetTeammateToggle"
		[appClassList]="['overlay-gray']"
		(clicked)="!$event && onToggleSetTeammateTrajectory(false)"
	>
		<div class="relative w-full md:w-[688px] bg-white rounded-lg p-10">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="onToggleSetTeammateTrajectory(false)"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<ng-container [ngSwitch]="trajectorySetTeammateState">
				<div class="flex flex-col items-start" *ngSwitchCase="'select'">
					<!-- prettier-ignore -->
					<span
            class="block text-headline-4 mb-4"
            i18n="Назначение траектории|Заголовок: Назначение траектории@@app-trajectory-title.trajectory-set-title"
          >
            Назначение траектории
          </span>
					<div
						class="flex justify-between items-start space-x-2 bg-blue-grey-25 w-full effect-01dp rounded-md p-3 mb-8"
					>
						<div class="block text-subtitle-1 line-clamp-2">{{ trajectory.title }}</div>
						<div class="flex items-center">
							<i
								appSvgIcon
								appIcon="grid-view"
								appSquare="24"
								class="text-blue-grey-600 mr-1.5"
							></i>
							<!-- prettier-ignore -->
							<span
                class="text-body-2 text-blue-grey-600 mr-1"
                i18n="Назначение траектории|Текст: Навыки@@app-trajectory-title.trajectory-set-skills"
              >
                Навыки:
              </span>
							<span class="text-subtitle-2 text-blue-grey-900">
								{{ trajectory.skill_count }}
							</span>
						</div>
					</div>
					<!-- prettier-ignore -->
					<p
            class="text-subtitle-1 mb-6"
            i18n="Назначение траектории|Параграф: Выберите тиммейта@@app-trajectory-title.trajectory-set-paragraph"
          >
            Выберите тиммейта, которому хотите назначить траекторию:
          </p>
					<ul
						class="overflow-auto max-h-[285px] w-full mb-8"
						*ngIf="!!teamMemberList.length; else emptyTeamMember"
					>
						<!-- prettier-ignore -->
						<li
							class="flex items-center justify-between border-b border-blue-grey-50 px-2 py-3 mr-1"
							[ngClass]="teamMember.trajectory_assigned ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-blue-grey-25'"
							[class.bg-secondary-25]="teamMemberSelected?.id === teamMember.id"
							*ngFor="let teamMember of teamMemberList"
							(click)="!teamMember.trajectory_assigned && (teamMemberSelected = teamMember)"
						>
							<div
                class="flex items-center justify-start space-x-2"
                [ngClass]="{ 'select-none opacity-25': teamMember.trajectory_assigned }"
              >
								<app-avatar class="block" [appUser]="teamMember.user"></app-avatar>
								<span class="block text-body-2">
									{{ teamMember.user.name }} {{ teamMember.user.surname }}
								</span>
							</div>
							<!-- prettier-ignore -->
							<span
                class="block text-caption text-blue-grey-900"
                i18n="Назначение траектории|Текст: Траектория уже назначена@@app-trajectory-title.trajectory-set-alredy"
                *ngIf="teamMember.trajectory_assigned"
                [ngClass]="{ 'select-none opacity-25': teamMember.trajectory_assigned }"
              >
								Траектория уже назначена
							</span>
						</li>
					</ul>
					<ng-template #emptyTeamMember>
						<div class="flex items-start p-4 bg-tertiary-25 rounded-lg mb-8">
							<i
								appSvgIcon
								appIcon="emoji-sad"
								appSquare="24"
								class="text-tertiary-A400"
							></i>
							<!-- prettier-ignore -->
							<p
                class="text-body-2 ml-2.5"
                i18n="Назначение траектории|Параграф: К сожалению, в вашей команде пока нет ни одного тиммейта@@app-trajectory-title.trajectory-set-empty-teamMember"
              >
                К сожалению, в вашей команде пока нет ни одного тиммейта. Добавьте
                тиммейтов в команду прежде, чем приступить к назначению траекторий.
              </p>
						</div>
					</ng-template>
					<div class="flex items-center justify-between w-full">
						<button
							class="btn btn-primary inline md"
							[disabled]="!teamMemberSelected"
							(click)="onSetTeammateTrajectory(teamMemberSelected?.id)"
							*ngIf="!!teamMemberList.length"
						>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Назначение траектории|Кнопка: Назначить тиммейту@@app-trajectory-title.trajectory-set-submit"
              >
                Назначить тиммейту
              </span>
						</button>
						<button
							class="btn btn-primary inline md"
							(click)="onGotoTeam()"
							*ngIf="!teamMemberList.length"
						>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Назначение траектории|Кнопка: Пригласить в команду@@app-trajectory-title.invite-to-team"
              >
                Пригласить в команду
              </span>
						</button>
					</div>
				</div>
				<div class="flex flex-col items-start" *ngSwitchCase="'done'">
					<!-- prettier-ignore -->
					<span
            class="block text-headline-4 mb-4"
            i18n="Назначение траектории|Заголовок: Отлично!@@app-trajectory-title.trajectory-done-title"
          >
            Отлично!
          </span>
					<!-- prettier-ignore -->
					<p
            class="text-body-1 mb-4"
            i18n="Назначение траектории|Параграф: Вы успешно внесли этот навык@@app-trajectory-title.trajectory-done-paragraph"
          >
            Вы успешно назначили траекторию выбранному тиммейту.
          </p>
					<div class="bg-blue-grey-25 w-full effect-01dp rounded-md p-3 mb-8">
						<div class="flex justify-between items-start space-x-2 mb-4">
							<div class="block text-subtitle-1 line-clamp-2">{{ trajectory.title }}</div>
							<div class="flex items-center">
								<i
									appSvgIcon
									appIcon="bookmarks"
									appSquare="20"
									appViewBox="0 0 24 24"
									class="text-blue-grey-600 mr-1.5"
								></i>
								<!-- prettier-ignore -->
								<span
                  class="text-body-2 text-blue-grey-600 mr-1"
                  i18n="Назначение траектории|Текст: Навыки@@app-trajectory-title.trajectory-set-skills"
                >
                  Навыки:
                </span>
								<span class="text-subtitle-2 text-blue-grey-900">
									{{ trajectory.skill_count }}
								</span>
							</div>
						</div>
						<div class="flex justify-between items-center bg-white p-1 rounded-full">
							<div
								class="flex items-center justify-start space-x-2"
								*ngIf="teamMemberSelected"
							>
								<app-avatar
									class="block"
									[appUser]="teamMemberSelected.user"
								></app-avatar>
								<span class="block text-body-2">
									{{ teamMemberSelected.user.name }} {{ teamMemberSelected.user.surname }}
								</span>
							</div>
							<div class="flex justify-end space-x-2 mr-3">
								<i appSvgIcon appIcon="flag" appSquare="17" class="text-primary-A400"></i>
								<!-- prettier-ignore -->
								<span
                  class="block text-body-2 text-blue-grey-600"
                  i18n="Назначение траектории|Текст: Дедлайн!@@app-trajectory-title.trajectory-done-deadline"
                >
									Дедлайн:
								</span>
								<time class="block text-subtitle-2">
									{{ teamMemberSelected?.deadline | date: "longDate" }}
								</time>
							</div>
						</div>
					</div>
					<div class="flex items-center justify-between w-full">
						<button
							class="btn btn-primary outline md"
							(click)="onToggleSetTeammateTrajectory(true)"
						>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Назначение траектории|Кнопка: Назначить ещё одному тиммейту@@app-trajectory-title.trajectory-done-repeat"
              >
                Назначить ещё одному тиммейту
              </span>
						</button>
						<button
							class="btn btn-primary inline md"
							(click)="onToggleSetTeammateTrajectory(false)"
						>
							<figure class="relative">
								<i class="absolute" appSvgIcon appIcon="done" appSquare="24"></i>
							</figure>
							<!-- prettier-ignore -->
							<span
                class="block"
                i18n="Назначение траектории|Кнопка: Готово@@app-trajectory-title.trajectory-done-submit"
              >
                Готово
              </span>
						</button>
					</div>
				</div>
			</ng-container>
		</div>
	</app-overlay>
	<!-- NAVIGATION: Модалка удаления траектории -->
	<app-overlay
		[appState]="trajectoryRemoveToggle"
		[appClassList]="['overlay-gray']"
		(clicked)="trajectoryRemoveToggle = $event"
	>
		<div class="relative w-[512px] bg-white rounded-lg p-10 pb-8">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="trajectoryRemoveToggle = false"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div class="mb-8">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-4"
          i18n="Просмотр траектории|Заголовок: Вы собираетесь удалить траекторию@@app-trajectory-title.trajectory-delete-title"
        >
          Вы собираетесь удалить траекторию
				</span>
				<!-- prettier-ignore -->
				<p
          class="text-body-1"
          i18n="Просмотр траектории|Параграф: Траектория навсегда исчезнет из библиотеки, восстановить её будет невозможно. Все навыки останутся в библиотеке.@@app-trajectory-title.trajectory-delete-paragraph"
        >
          Траектория навсегда исчезнет из библиотеки, восстановить её будет невозможно. Все навыки останутся в библиотеке.
        </p>
			</div>
			<div class="flex items-center justify-between">
				<button class="btn btn-gray outline md" (click)="trajectoryRemoveToggle = false">
					<figure class="relative">
						<i
							class="absolute text-blue-grey-400"
							appSvgIcon
							appIcon="cross"
							appSquare="24"
						></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Просмотр траектории|Кнопка: Отмена@@app-trajectory-title.trajectory-delete-cancel"
          >
						Отмена
					</span>
				</button>
				<button class="btn btn-tertiary inline md" (click)="onRemoveTrajectory()">
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Просмотр траектории|Кнопка: Удалить траекторию@@app-trajectory-title.trajectory-delete-submit"
          >
            Удалить траекторию
					</span>
				</button>
			</div>
		</div>
	</app-overlay>
	<!-- NAVIGATION: Модалка траектория уже назначена -->
	<app-overlay
		[appState]="trajectoryAssignedToggle"
		[appClassList]="['overlay-gray']"
		(clicked)="trajectoryAssignedToggle = $event"
	>
		<div class="relative w-[688px] bg-white rounded-lg p-10 pb-8">
			<button
				class="btn btn-gray transparent absolute top-5 right-5"
				(click)="trajectoryAssignedToggle = false"
			>
				<i appSvgIcon appIcon="cross" appSquare="24"></i>
			</button>
			<div class="mb-4">
				<!-- prettier-ignore -->
				<span
          class="block text-headline-4 mb-4"
          i18n="Просмотр траектории|Заголовок: Вы уже взяли эту траекторию@@app-trajectory-title.trajectory-assigned-title"
        >
          Вы уже взяли эту траекторию
				</span>
				<!-- prettier-ignore -->
				<p
          class="text-body-1"
          i18n="Просмотр траектории|Параграф: Траектория уже добавлена в ваш прогресс. Пожалуйста, перейдите в неё для дальнейшей работы или выберите другую траекторию@@app-trajectory-title.trajectory-assigned-paragraph"
        >
          Траектория уже добавлена в ваш прогресс. Пожалуйста, перейдите в неё для дальнейшей работы или выберите другую траекторию
        </p>
			</div>
			<div
				class="flex justify-between items-start space-x-2 bg-blue-grey-25 w-full effect-01dp rounded-md p-3 mb-8"
			>
				<div class="block text-subtitle-1 line-clamp-2">
					{{ trajectoryAssigned?.title }}
				</div>
				<div class="flex items-center">
					<i
						appSvgIcon
						appIcon="grid-view"
						appSquare="20"
						appViewBox="0 0 24 24"
						class="text-blue-grey-600 mr-1.5"
					></i>
					<!-- prettier-ignore -->
					<span
            class="text-body-2 text-blue-grey-600 mr-1"
            i18n="Просмотр траектории|Текст: Навыки:@@app-trajectory-title.trajectory-assigned-skills"
          >
            Навыки:
          </span>
					<div class="flex justify-end items-center">
						<span class="text-subtitle-2 text-blue-grey-900">
							{{ trajectoryAssigned?.count_done }}
						</span>
						<span class="text-body-2 text-blue-grey-600">
							/{{ trajectoryAssigned?.count_all }}
						</span>
					</div>
				</div>
			</div>
			<div class="flex items-center justify-between">
				<button
					class="btn btn-gray outline md"
					(click)="trajectoryAssignedToggle = false"
				>
					<figure class="relative">
						<i
							class="absolute text-blue-grey-400"
							appSvgIcon
							appIcon="cross"
							appSquare="24"
						></i>
					</figure>
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Просмотр траектории|Кнопка: Отмена@@app-trajectory-title.trajectory-assigned-cancel"
          >
						Отмена
					</span>
				</button>
				<button class="btn btn-primary inline md" (click)="onGotoTrajectory()">
					<!-- prettier-ignore -->
					<span
            class="block"
            i18n="Просмотр траектории|Кнопка: Перейти в траекторию@@app-trajectory-title.trajectory-assigned-redirect"
          >
            Перейти в траекторию
					</span>
					<figure class="relative">
						<i
							class="absolute rotate-180"
							appSvgIcon
							appIcon="arrow-back"
							appSquare="20"
						></i>
					</figure>
				</button>
			</div>
		</div>
	</app-overlay>
	<!-- NAVIGATION: Переиспользование элемента отображения прогресса -->
	<ng-template #progressDisplay let-user="user" let-progress="progress">
		<a
			*ngIf="user.id !== progress.user.id"
			class="flex items-center justify-start space-x-2 my-1"
			[routerLink]="['/progress/', progress.user.team_member_id, 'dashboard']"
		>
			<app-avatar class="block" [appUser]="progress.user"></app-avatar>
			<span
				class="block text-body-2 text-blue-grey-900 line-clamp-1 hover:text-primary-A400"
			>
				{{ progress.user.name }} {{ progress.user.surname }}
			</span>
		</a>
		<div
			class="flex justify-between items-center space-x-3 bg-white rounded-full w-[fit-content] effect-01dp p-1"
		>
			<div class="flex items-center justify-start" [ngSwitch]="true">
				<!-- prettier-ignore -->
				<label *ngSwitchCase="progress.status === 'done'" class="label label-green inline md">
          <!-- prettier-ignore -->
          <span
            class="block"
            i18n="Прогресс траектории|Текст: Пройдена@@app-trajectory-title.trajectory-finished"
          >
            Пройдена
          </span>
          <figure class="relative">
            <i
              class="absolute"
              appSvgIcon
              appIcon="done-all"
              appSquare="16"
              appViewBox="0 0 16 16"
            ></i>
          </figure>
        </label>
				<!-- prettier-ignore -->
				<label *ngSwitchCase="progress.status === 'in_progress'" class="label label-primary inline md">
          <!-- prettier-ignore -->
          <span
            class="block"
            i18n="Прогресс траектории|Текст: В работе@@app-trajectory-title.trajectory-in-progress"
          >
            В работе
          </span>
          <figure class="relative">
            <i class="absolute" appSvgIcon appIcon="bolt" appSquare="16"></i>
          </figure>
        </label>
			</div>
			<div class="flex items-center justify-start">
				<!-- prettier-ignore -->
				<span
          class="block text-body-2 whitespace-nowrap mr-3"
          i18n="Прогресс траектории|Текст: Освоено навыков@@app-trajectory-title.trajectory-progress-percent"
        >
          Освоено навыков:
        </span>
				<div class="flex justify-start items-center space-x-4 mr-2">
					<div data-pw="tesks-progress" class="flex justify-start items-center">
						<span class="text-headline-7 mr-1">{{ progress.count_done }}</span>
						<span class="text-body-2 text-blue-grey-600">/{{ progress.count_all }}</span>
					</div>
					<div class="bg-blue-grey-50 rounded-sm w-36 h-1">
						<div
							class="bg-secondary-500 rounded-sm h-1"
							[style.width]="progress.percent + '%'"
						></div>
					</div>
					<span data-pw="skill-percent" class="text-headline-7">
						{{ progress.percent }}%
					</span>
				</div>
			</div>
		</div>
	</ng-template>
</section>
