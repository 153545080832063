/** @format */

import { Inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map, Observable } from 'rxjs';
import { Configuration, User } from '../models';
import { UserService } from './user.service';
import { APP_CONFIG } from './configuration.service';

@Injectable()
export class EventService {
  constructor(
    @Inject(APP_CONFIG)
    private configuration: Configuration,
    private userService: UserService,
    private apiService: ApiService
  ) {}

  postDispatch(eventName: string): Observable<any> {
    const user: User = this.userService.currentUserSubject.getValue();

    const body: any = {
      event_data: { email: user.email },
      event_name: eventName,
      token: this.configuration.eventsToken
    };

    return this.apiService.post('/v1/dispatchEvent', body).pipe(map((data: any) => data.data));
  }
}
