/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  HttpAppVersionInterceptor,
  HttpLoaderInterceptor,
  HttpTokenInterceptor
} from './interceptors';
import {
  ApiService,
  CookieService,
  EventService,
  HelperService,
  LocalStorageService,
  LogRocketService,
  PlatformService,
  ProgressService,
  SkillService,
  SnackbarService,
  TariffService,
  TeamService,
  TrajectoryService,
  UserService
} from './services';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAppVersionInterceptor, multi: true },
    ApiService,
    CookieService,
    EventService,
    HelperService,
    LocalStorageService,
    LogRocketService,
    PlatformService,
    ProgressService,
    SkillService,
    SnackbarService,
    TariffService,
    TeamService,
    TrajectoryService,
    UserService
  ],
  declarations: []
})
export class CoreModule {}
