/** @format */

import { Inject, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from './user.service';
import { Configuration, User } from '../models';
import { PlatformService } from './platform.service';
import { APP_CONFIG } from './configuration.service';

@Injectable()
export class LogRocketService {
  logRocketUser$!: Subscription;
  logRocket!: any;

  constructor(
    @Inject(APP_CONFIG)
    private configuration: Configuration,
    private userService: UserService,
    private platformService: PlatformService
  ) {}

  onInitLogRocket(): void {
    if (this.configuration.production) {
      this.logRocketUser$ = this.userService.currentUser.subscribe({
        next: (user: User) => {
          if (this.platformService.isBrowser()) {
            const window: Window = this.platformService.getWindow();

            // @ts-ignore
            this.logRocket = window['LogRocket'];

            this.logRocket.init(this.configuration.logRocketAppId);

            this.logRocket.identify(String(user.id), {
              name: [user.name, user.surname].join(' '),
              email: user.email
            });
          }
        },
        error: (error: any) => console.error(error)
      });
    }
  }
}
