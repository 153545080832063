/** @format */

import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService, PlatformService, SnackbarService } from '../services';
import { Location } from '@angular/common';
import { APP_VERSION } from '../services/app-version.service';

@Injectable()
export class HttpAppVersionInterceptor implements HttpInterceptor {
  appVersionUrl: string = './assets/configurations/version.json';

  constructor(
    @Inject(APP_VERSION)
    private version: string,
    private httpClient: HttpClient,
    private location: Location,
    private platformService: PlatformService,
    private localStorageService: LocalStorageService,
    private snackbarService: SnackbarService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.localStorageService.getItem('appReload')) {
      this.localStorageService.removeItem('appReload');

      this.snackbarService.info(
        $localize`:Снекбар|Сообщение - Приложение было обновлено@@app-snackbar.app-reload:Приложение было обновлено`,
        {
          icon: 'done'
        }
      );
    }

    const isNotAllowedRequest: boolean = ((): boolean => {
      return [
        this.appVersionUrl,
        new RegExp('^/api/v1/skill/\\w*/publish'),
        new RegExp('^/api/v1/lib/\\w*/show'),
        new RegExp('^/api/v1/skill/\\w*/show'),
        new RegExp('^/api/v1/invitations/\\w*/editRole'),
        new RegExp('^/api/v1/invitations/\\w*/delete'),
        '/api/v1/invitations/info',
        '/api/v1/teams/invitations',
        '/api/v1/login',
        '/api/v1/logout',
        '/api/v1/signUp',
        '/api/v1/forgotPassword',
        '/api/v1/resetPassword'
      ].some((url: string | RegExp) => request.url.match(url));
    })();

    if (!isNotAllowedRequest && Boolean(this.version)) {
      this.httpClient.get(this.appVersionUrl).subscribe({
        next: (response: any) => {
          if (this.version !== response.version) {
            if (this.platformService.isBrowser()) {
              this.localStorageService.setItem('appReload', 'true');

              const window = this.platformService.getWindow();

              window.location.reload();
            }
          }
        },
        error: (error: any) => console.error(error)
      });
    }

    return next.handle(request);
  }
}
