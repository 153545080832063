<!-- @format -->

<div class="flex flex-col items-start w-full">
	<!-- prettier-ignore -->
	<h1
		class="text-headline-4 mb-2"
		i18n="Сброс пароля|Заголовок: Изменить пароль/Пароль изменён!@@app-auth-reset.title"
	>
    { resetState === "start", select, true {Изменить пароль} false {Пароль изменён!} }
  </h1>
	<div class="flex space-x-2 text-body-1 mb-8">
		<span
			class="inline-block"
			i18n="Сброс пароля|Текст: Вспомнили пароль?@@app-auth-reset.remember-password"
		>
			Вспомнили пароль?
		</span>
		<a
			class="block underline"
			[routerLink]="['/auth/login']"
			i18n="Сброс пароля|Текст: Войдите в аккаунт@@app-auth-reset.login-to-account"
		>
			Войдите в аккаунт
		</a>
	</div>
	<ng-container [ngSwitch]="resetState">
		<ng-container *ngSwitchCase="'start'">
			<!-- prettier-ignore -->
			<span
				class="block text-body-1 mb-6"
				i18n="Сброс пароля|Параграф: Пожалуйста, придумайте новый пароль для вашего аккаунта@@app-auth-reset.create-new-password"
			>
				Пожалуйста, придумайте новый пароль для вашего аккаунта
			</span>
			<form
				[formGroup]="resetForm"
				(ngSubmit)="onSubmitForm()"
				autocomplete="off"
				class="w-full"
			>
				<fieldset [disabled]="resetFormIsSubmitting">
					<fieldset
						class="form-control relative mb-4"
						*ngIf="{value: false} as isVisible"
					>
						<figure
							class="absolute password i-right"
							(click)="isVisible.value = !isVisible.value"
						>
							<i
								class="absolute text-blue-grey-400"
								appSvgIcon
								[appIcon]="isVisible.value ? 'eye' : 'eye-strikethrough'"
								appSquare="24"
							></i>
						</figure>
						<!-- prettier-ignore -->
						<input
							id="password"
							[type]="isVisible.value ? 'text' : 'password'"
							class="peer"
							placeholder="Придумайте пароль"
							i18n-placeholder="Сброс пароля|Поле ввода: Придумайте пароль@@app-auth-reset.create-new-password-input"
							formControlName="password"
							#inputPassword
						/>
						<label
							for="password"
							class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
						>
							<app-error-label
								[appInputElement]="inputPassword"
								[appFormGroup]="resetForm"
								[appFormControlName]="'password'"
								[appLinkedControlNames]="['password_confirmation']"
							></app-error-label>
						</label>
					</fieldset>
					<fieldset
						class="form-control relative mb-4"
						*ngIf="{value: false} as isVisible"
					>
						<figure
							class="absolute password i-right"
							(click)="isVisible.value = !isVisible.value"
						>
							<i
								class="absolute text-blue-grey-400"
								appSvgIcon
								[appIcon]="isVisible.value ? 'eye' : 'eye-strikethrough'"
								appSquare="24"
							></i>
						</figure>
						<!-- prettier-ignore -->
						<input
							id="password_confirmation"
							[type]="isVisible.value ? 'text' : 'password'"
							class="peer"
							placeholder="Придумайте пароль"
              i18n-placeholder="Сброс пароля|Поле ввода: Повторите пароль@@app-auth-reset.repeat-new-password-input"
							formControlName="password_confirmation"
							#inputPasswordConfirmation
						/>
						<label
							for="password_confirmation"
							class="label peer-focus:label-focus peer-placeholder-shown:label-placeholder-shown"
						>
							<app-error-label
								[appInputElement]="inputPasswordConfirmation"
								[appFormGroup]="resetForm"
								[appFormControlName]="'password_confirmation'"
							></app-error-label>
						</label>
					</fieldset>
					<button
						class="btn btn-primary inline md mb-8"
						data-pw="button-submit"
						[disabled]="resetFormIsSubmitting"
						i18n="Сброс пароля|Кнопка: Отправить@@app-auth-reset.submit"
					>
						Отправить
					</button>
				</fieldset>
			</form>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<!-- prettier-ignore -->
			<span
				class="block text-body-1 mb-8"
				i18n="Сброс пароля|Текст: Теперь вы можете войти в сервис с новым	паролем@@app-auth-reset.change-success"
			>
				Теперь вы можете войти в сервис с новым паролем
			</span>
			<button
				class="btn btn-primary inline md"
				data-pw="button-to-profile"
				(click)="onLogin()"
				i18n="Сброс пароля|Кнопка: Войти в Dabster@@app-auth-reset.change-login"
			>
				Войти в Dabster
			</button>
		</ng-container>
	</ng-container>
</div>
