/** @format */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService, TeamInvitationRegistration, TeamService, UserService } from '../../core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

interface LoginForm {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
}

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html'
})
export class AuthLoginComponent implements OnInit {
  routerQueryParams$!: Subscription;

  loginForm: FormGroup;
  loginFormIsSubmitting = false;

  invitationMap: any = {
    token: 'invitation_token',
    demandToken: 'demand_token',
    referralToken: 'referral_token'
  };
  invitationPayload!: any;
  invitationToken!: string;
  invitationOnBoardingType!: string;
  invitationError = false;
  invitationNavigate: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private teamService: TeamService,
    private router: Router,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private title: Title
  ) {
    this.loginForm = this.formBuilder.group<LoginForm>({
      email: this.formBuilder.control('', [Validators.required, Validators.email]),
      password: this.formBuilder.control('', [Validators.required, Validators.minLength(6)])
    });
  }

  ngOnInit(): void {
    // prettier-ignore
    this.title.setTitle($localize`:Заголовок|Текст - Dabster | Войдите в аккаунт@@app-title.app-auth-login:Dabster | Войдите в аккаунт`);

    this.routerQueryParams$ = this.route.queryParams.subscribe({
      next: (queryParams: Params) => {
        Object.keys(this.invitationMap).forEach((invitation: string) => {
          if (invitation in queryParams) {
            const token: string = queryParams[invitation];

            /** Saving token and token type */

            this.invitationPayload = {
              [this.invitationMap[invitation]]: token
            };

            /** Check token */

            this.getTokenInfo(token);
          }
        });
      },
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.routerQueryParams$].forEach($ => $?.unsubscribe());
  }

  getTokenInfo(token: string): void {
    this.teamService.getInvitationInfo(token).subscribe({
      next: (teamInvitationRegistration: TeamInvitationRegistration) => {
        this.invitationToken = token;
        this.invitationOnBoardingType = teamInvitationRegistration.onboarding;

        if (!!teamInvitationRegistration.email) {
          this.loginForm.get('email')?.setValue(teamInvitationRegistration.email);
        }

        this.invitationNavigate = true;
      },
      error: () => (this.invitationError = true)
    });
  }

  onSubmitForm(): void {
    if (this.helperService.getFormValidation(this.loginForm)) {
      this.loginFormIsSubmitting = true;

      const body: any = {
        ...this.loginForm.value,
        ...this.invitationPayload
      };

      this.userService.postLogin(body).subscribe({
        next: () => {
          if (this.invitationNavigate) {
            this.router
              .navigate(['/profile/on-boarding'], {
                queryParams: {
                  type: this.invitationOnBoardingType || 'dabster',
                  token: this.invitationToken
                }
              })
              .then(() => console.debug('Route changed'));
          } else {
            this.router.navigate(['/profile']).then(() => console.debug('Route changed'));
          }
        },
        error: () => (this.loginFormIsSubmitting = false)
      });
    }
  }
}
