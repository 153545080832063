<!-- @format -->

<header class="flex items-center justify-between bg-white effect-00dp py-2 px-8">
	<app-header-logo></app-header-logo>
	<a class="flex items-center justify-start space-x-2 my-1" [routerLink]="['/profile']">
		<app-avatar class="block" [appUser]="user"></app-avatar>
		<span
			data-pw="header-username"
			class="block text-body-2 text-blue-grey-900 hover:text-primary-A400"
		>
			{{ user.name }} {{ user.surname }}
		</span>
	</a>
</header>
