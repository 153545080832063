/** @format */

import { Directive, ElementRef, HostListener, AfterViewInit, Input } from '@angular/core';
import autosize from 'autosize';

@Directive({
  selector: '[appTextarea]'
})
export class AppTextareaDirective implements AfterViewInit {
  @HostListener('ngModelChange') onNgModelChange() {
    autosize.update(this.elementRef.nativeElement);
  }

  @Input()
  set appUpdate(trigger: any) {
    const autosizeTimeout: number = setTimeout(() => {
      autosize.update(this.elementRef.nativeElement);

      clearTimeout(autosizeTimeout);
    }, trigger.delay);
  }

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    /** http://www.jacklmoore.com/autosize/ */

    const autosizeTimeout: number = setTimeout(() => {
      autosize(this.elementRef.nativeElement);

      clearTimeout(autosizeTimeout);
    }, 10);
  }
}
