<!-- @format -->
<div #elParentError class="error-text w-full overflow-hidden">
	<span
		#elError
		class="relative inline-block"
		[class.run-line]="shift < 0"
		[style]="
			'&#45;&#45;shift: ' + shift + 'px; &#45;&#45;duration: ' + durationBoost + 's;'
		"
	>
		{{ label }}
	</span>
</div>
