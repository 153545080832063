/** @format */

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { APP_CONFIG } from './configuration.service';
import { Configuration } from '../models';

@Injectable()
export class CookieService {
  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(APP_CONFIG)
    private configuration: Configuration
  ) {}

  getItem(key: string): any {
    // prettier-ignore
    const matches = document.cookie.match(new RegExp('(?:^|; )' + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));

    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  setItem(key: string, value: string, options?: any): void {
    options = {
      ...options,
      path: '/',
      domain: this.configuration.rootDomain
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(key) + '=' + encodeURIComponent(value);

    // tslint:disable-next-line:forin
    for (const optionKey in options) {
      updatedCookie += '; ' + optionKey;

      const optionValue = options[optionKey];

      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    this.document.cookie = updatedCookie;
  }

  removeItem(item: string): void {
    this.setItem(item, '', {
      ['max-age']: -1
    });
  }
}
