/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event as RouterEvent, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { EMPTY, startWith, Subscription } from 'rxjs';
import { EditGuardDeactivate, Trajectory, TrajectoryService } from '../../../../core';

@Component({
  selector: 'app-footer-trajectory',
  templateUrl: './footer-trajectory.component.html'
})
export class FooterTrajectoryComponent implements OnInit, OnDestroy {
  routerEventsNavigationEnd$!: Subscription;
  routerEventsNavigationStart$!: Subscription;

  trajectoryIsAddSkills!: boolean;

  trajectoryConfirmationModalOnPublish$!: Subscription;

  trajectory!: Trajectory;
  trajectory$!: Subscription;

  constructor(
    private router: Router,
    private location: Location,
    private trajectoryService: TrajectoryService,
    private editGuardDeactivate: EditGuardDeactivate
  ) {}

  ngOnInit(): void {
    this.routerEventsNavigationEnd$ = this.router.events
      .pipe(
        filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd),
        startWith(EMPTY)
      )
      .subscribe({
        next: () => {
          this.trajectoryIsAddSkills = this.location.path().includes('edit/add');

          /** Disable exit */

          this.editGuardDeactivate.allowed = false;
        }
      });

    this.routerEventsNavigationStart$ = this.router.events
      .pipe(filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationStart))
      .subscribe({
        next: (routerEvent: RouterEvent) => {
          // @ts-ignore
          const url: string = routerEvent.url;

          if (['/edit', '/edit/add'].some((path: string) => url.endsWith(path))) {
            this.editGuardDeactivate.allowed = true;
          } else {
            this.trajectoryService.trajectoryPublishModalNavigationStartUrl = url;
            this.trajectoryService.trajectoryPublishModalToggle.next(true);
          }
        }
      });

    // prettier-ignore
    this.trajectoryConfirmationModalOnPublish$ = this.trajectoryService.trajectoryConfirmationModalOnPublish.subscribe({
      next: (trajectory: Trajectory) => {
        // prettier-ignore
        const path: string = this.trajectoryService.trajectoryPublishModalNavigationStartUrl || '/library/trajectories/' + trajectory.id;

        this.onClose(false);

        /** Enable exit */

        this.editGuardDeactivate.allowed = true;

        const redirectTimeout: any = setTimeout(() => {
          this.routerEventsNavigationStart$.unsubscribe();

          this.router
            .navigateByUrl(path)
            .then(() => console.debug('Route changed'));

          clearTimeout(redirectTimeout);
        }, 300);
      }
    });

    this.trajectory$ = this.trajectoryService.trajectory$.subscribe({
      next: (trajectory: Trajectory) => {
        this.trajectory = trajectory;
        this.trajectoryService.setTrajectoryStorage(trajectory);
      }
    });
  }

  ngOnDestroy(): void {
    [
      this.routerEventsNavigationEnd$,
      this.routerEventsNavigationStart$,
      this.trajectoryConfirmationModalOnPublish$
    ].forEach($ => $?.unsubscribe());
  }

  onGotoAddSkills(): void {
    this.router
      .navigateByUrl(this.location.path() + '/add')
      .then(() => console.debug('Route changed'));
  }

  onAddSkills(): void {
    const path: string = this.location.path().replace('/add', '');

    this.router.navigateByUrl(path).then(() => console.debug('Route changed'));
  }

  onFinish(): void {
    this.trajectoryService.trajectoryPublishModalToggle.next(true);
  }

  onClose(toggle: boolean): void {
    this.trajectoryService.trajectoryPublishModalToggle.next(toggle);
    this.trajectoryService.trajectoryPublishModalNavigationStartUrl = undefined;
  }
}
