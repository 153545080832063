<!-- @format -->

<section>
	<ng-container *ngIf="!trajectory.skills.length">
		<!-- prettier-ignore -->
		<p
			class="text-headline-7 text-blue-grey-600"
			i18n="Просмотр траектории|Параграф: В этой траектории пока пусто@@app-trajectory-description.trajectory-empty-paragraph"
		>
			В этой траектории пока пусто...
		</p>
	</ng-container>
	<ng-container *ngIf="!!trajectory.skills.length">
		<ul class="grid grid-cols-4 tablet:grid-cols-3 mobile:grid-cols-2 gap-4">
			<li
				*ngFor="let skill of trajectory.skills; let i = index"
				[attr.data-cy]="'skill-' + skill.id"
			>
				<a
					class="block h-full"
					target="_blank"
					[href]="'/library/skills/' + skill.id"
					appCardSkill
					[appSkill]="skill"
				></a>
			</li>
		</ul>
	</ng-container>
</section>
