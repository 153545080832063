/** @format */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '../../core';
import { Title } from '@angular/platform-browser';

interface InviteForm {
  name: FormControl<string | null>;
  email: FormControl<string | null>;
  phone: FormControl<string | null>;
}

@Component({
  selector: 'app-auth-invite',
  templateUrl: './auth-invite.component.html'
})
export class AuthInviteComponent implements OnInit {
  inviteForm: FormGroup;
  inviteFormIsSubmitting = false;

  constructor(
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private title: Title
  ) {
    this.inviteForm = this.formBuilder.group<InviteForm>({
      name: this.formBuilder.control('', [Validators.required]),
      email: this.formBuilder.control('', [Validators.required, Validators.email]),
      phone: this.formBuilder.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    // prettier-ignore
    this.title.setTitle($localize`:Заголовок|Текст - Dabster | Запросите приглашение@@app-title.app-auth-invite:Dabster | Запросите приглашение`);
  }

  onSubmitForm(): void {
    if (this.helperService.getFormValidation(this.inviteForm)) {
      this.inviteFormIsSubmitting = true;
    }
  }
}
