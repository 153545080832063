/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Progress, ProgressService, Skill, SkillService } from '../../../../../core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

interface AutoAssignForm {
  isAuto: FormControl<boolean | null>;
}

@Component({
  selector: 'app-skill-overlay-publish',
  templateUrl: './publish.component.html'
})
export class SkillOverlayPublishComponent implements OnInit, OnDestroy {
  state!: boolean;
  state$!: Subscription;

  skill!: Skill;
  skill$!: Subscription;

  skillIsEdit!: boolean;
  skillIsEditIndividual!: boolean;
  skillIsEditIndividualIsSubmitting!: boolean;

  progress!: Progress;
  progress$!: Subscription;

  autoAssignForm!: FormGroup;
  autoAssignFormIsSubmitting = false;

  isShowDraftButton!: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private skillService: SkillService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private progressService: ProgressService
  ) {
    this.autoAssignForm = this.formBuilder.group<AutoAssignForm>({
      isAuto: this.formBuilder.control(false)
    });
  }

  ngOnInit(): void {
    this.isShowDraftButton = ['library/create', 'library/edit'].some((path: string) => {
      return this.location.path().includes(path);
    });

    this.state$ = this.skillService.skillConfirmationModalToggle.subscribe({
      next: (state: boolean) => (this.state = state),
      error: (error: any) => console.error(error)
    });

    this.skill$ = this.skillService.skill$.subscribe({
      next: (skill: Skill) => {
        this.skill = skill;

        if (!!this.skill.is_auto) {
          this.autoAssignForm.setValue({ isAuto: true });
        }
      },
      error: (error: any) => console.error(error)
    });

    this.progress$ = this.progressService.progress$.subscribe({
      next: (progress: Progress) => (this.progress = progress),
      error: (error: any) => console.error(error)
    });

    /** Individual skill edition setup */

    // prettier-ignore
    this.skillIsEditIndividual = !!String(this.activatedRoute.snapshot.queryParamMap.get('individual') || '');
  }

  ngOnDestroy(): void {
    [this.state$, this.skill$, this.progress$].forEach($ => $?.unsubscribe());
  }

  onClose(toggle: boolean): void {
    this.skillService.skillConfirmationModalToggle.next(toggle);
  }

  onSaveDraft(): void {
    this.skillService.skillConfirmationModalOnSaveDraft.emit();
  }

  onPublishSkill(): void {
    if (this.skillService.skillValidation(this.skill)) {
      this.autoAssignFormIsSubmitting = true;

      const body: any = {
        is_auto: +this.autoAssignForm.get('isAuto')?.value
      };

      this.skillService.publishSkill(this.skill.id, body).subscribe({
        next: (skill: Skill) => this.skillService.skillConfirmationModalOnPublishSkill.emit(skill),
        error: () => (this.autoAssignFormIsSubmitting = false)
      });
    }
  }

  onPublishSkillIndividual(): void {
    if (this.skillService.skillValidation(this.skill)) {
      this.skillIsEditIndividualIsSubmitting = true;

      // prettier-ignore
      this.skillService
        .publishSkillIndividual(this.progress.skill.id, this.progress.user.team_member_id)
        .subscribe({
          next: (skill: Skill) => this.skillService.skillConfirmationModalOnPublishSkillIndividual.emit(skill),
          error: () => (this.skillIsEditIndividualIsSubmitting = false)
        });
    }
  }
}
