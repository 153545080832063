<!-- @format -->

<div class="flex items-center gap-2 md:gap-4">
	<button
		class="flex gap-1 items-center justify-center"
		(click)="onSorting('created_at')"
	>
		<span
			class="text-button-2 text-blue-grey-400"
			i18n="Библиотека|Сортировка: По дате@@app-dashboard-library.sort-created-at"
		>
			По дате
		</span>
		<!-- prettier-ignore -->
		<i
      class="text-blue-grey-400"
      [ngClass]="{ 'invisible': (sortingType$ | async) !== 'created_at', '-rotate-90': !(sortingReverse$ | async), 'rotate-90': (sortingReverse$ | async) }"
      appSvgIcon
      appIcon="arrow-back"
      appSquare="20"
    ></i>
	</button>
	<button class="flex gap-1 items-center justify-center" (click)="onSorting('title')">
		<span
			class="text-button-2 text-blue-grey-400"
			i18n="Библиотека|Сортировка: По алфавиту@@app-dashboard-library.sort-title"
		>
			По алфавиту
		</span>
		<!-- prettier-ignore -->
		<i
      class="text-blue-grey-400"
      [ngClass]="{ 'invisible': (sortingType$ | async) !== 'title', '-rotate-90': !(sortingReverse$ | async), 'rotate-90': (sortingReverse$ | async) }"
      appSvgIcon
      appIcon="arrow-back"
      appSquare="20"
    ></i>
	</button>
</div>
