/** @format */

import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

/* http://easings.net/ */
/* https://github.com/fivedice/ng-router-animation/blob/master/src/app/route-transition-animations.ts */
/* https://medium.com/ngconf/animating-angular-route-transitions-ef02b871cc30 */

export const asideAnimation = trigger('asideAnimation', [
  transition(
    ':enter',
    [
      style({ transform: 'translateX(-100%)' }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ transform: 'translateX(0)' })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  ),
  transition(
    ':leave',
    [
      style({ transform: 'translateX(0)' }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ transform: 'translateX(-100%)' })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0.1
      }
    }
  )
]);

export const fadeAsideAnimation = trigger('fadeAsideAnimation', [
  transition(
    ':enter',
    [
      style({ opacity: 0 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ opacity: 0.1 })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0.1
      }
    }
  ),
  transition(
    ':leave',
    [
      style({ opacity: 0.1 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ opacity: 0 })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  )
]);

export const overlayAnimation = trigger('overlayAnimation', [
  transition(
    ':enter',
    [
      style({ opacity: 0 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ opacity: 1 })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  ),
  transition(
    ':leave',
    [
      style({ opacity: 1 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ opacity: 0 })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  )
]);

export const dropdownAnimation = trigger('dropdownAnimation', [
  transition(
    ':enter',
    [
      style({ opacity: 0 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ opacity: 1 })
      )
    ],
    {
      params: {
        duration: 0,
        delay: 0
      }
    }
  ),
  transition(
    ':leave',
    [
      style({ opacity: 1 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ opacity: 0 })
      )
    ],
    {
      params: {
        duration: 0,
        delay: 0
      }
    }
  )
]);

export const snackAnimation = trigger('snackAnimation', [
  transition(
    ':enter',
    [
      style({ transform: 'translateX(100%)', opacity: 0 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ transform: 'translateX(0)', opacity: 1 })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  ),
  transition(
    ':leave',
    [
      style({ transform: 'translateX(0)', opacity: 1 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ transform: 'translateX(100%)', opacity: 0 })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  )
]);

export const snackModalAnimation = trigger('snackModalAnimation', [
  transition(
    ':enter',
    [
      style({ transform: 'translateY(-100%)', opacity: 0 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ transform: 'translateY(0)', opacity: 1 })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  ),
  transition(
    ':leave',
    [
      style({ transform: 'translateY(0)', opacity: 1 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ transform: 'translateY(-100%)', opacity: 0 })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  )
]);

export const authImageAnimation = trigger('authImageAnimation', [
  transition('void => *', [
    style({ opacity: 0, transform: 'scale(0.5)' }),
    animate(
      '500ms cubic-bezier(0.785, 0.135, 0.15, 0.86)',
      style({ opacity: 1, transform: 'scale(1)' })
    )
  ]),
  transition('* => void', [
    style({ opacity: 1, transform: 'scale(1)' }),
    animate(
      '500ms cubic-bezier(0.785, 0.135, 0.15, 0.86)',
      style({ opacity: 0, transform: 'scale(0.5)' })
    )
  ])
]);

export const rotateAnimation = trigger('rotateAnimation', [
  state('true', style({ transform: 'rotate({{to}}deg)' }), { params: { to: 180 } }),
  state('false', style({ transform: 'rotate({{from}}deg)' }), { params: { from: 0 } }),
  transition('true => false', animate('{{duration}}s {{delay}}s ease-out'), {
    params: {
      delay: 0,
      duration: 0.2
    }
  }),
  transition('false => true', animate('{{duration}}s {{delay}}s ease-in'), {
    params: {
      delay: 0,
      duration: 0.2
    }
  })
]);

export const routingTabAnimation = trigger('routingTabAnimation', [
  transition(':increment', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ right: '-105%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [
        group([
          animate('0.25s cubic-bezier(0.65, 0, 0.35, 1)', style({ opacity: 0 })),
          animate('0.5s cubic-bezier(0.65, 0, 0.35, 1)', style({ right: '105%' }))
        ])
      ]),
      query(':enter', [
        group([
          animate('0.5s cubic-bezier(0.65, 0, 0.35, 1)', style({ opacity: 1 })),
          animate('0.5s cubic-bezier(0.65, 0, 0.35, 1)', style({ right: '0%' }))
        ])
      ])
    ]),
    query(':enter', animateChild())
  ]),
  transition(':decrement', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '-105%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [
        group([
          animate('0.25s cubic-bezier(0.65, 0, 0.35, 1)', style({ opacity: 0 })),
          animate('0.5s cubic-bezier(0.65, 0, 0.35, 1)', style({ left: '105%' }))
        ])
      ]),
      query(':enter', [
        group([
          animate('0.5s cubic-bezier(0.65, 0, 0.35, 1)', style({ opacity: 1 })),
          animate('0.5s cubic-bezier(0.65, 0, 0.35, 1)', style({ left: '0%' }))
        ])
      ])
    ]),
    query(':enter', animateChild())
  ]),
  transition(':leave', [
    query(':leave', animateChild(), { optional: true }),
    group([
      query(
        ':leave',
        [
          group([
            animate('0.4s cubic-bezier(0.65, 0, 0.35, 1)', style({ opacity: 0 })),
            animate('0.2s cubic-bezier(0.65, 0, 0.35, 1)', style({ transform: 'scale(0.95)' }))
          ])
        ],
        { optional: true }
      )
    ])
  ])
]);

export const routingMainAnimation = trigger('routingMainAnimation', [
  transition('* <=> *', [
    style({ position: 'absolute', overflow: 'hidden' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%'
        })
      ],
      { optional: true }
    ),
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('500ms cubic-bezier(0.25, 1, 0.5, 1)', style({ opacity: 0 }))], {
        optional: true
      }),
      query(':enter', [animate('500ms cubic-bezier(0.25, 1, 0.5, 1)', style({ opacity: 1 }))], {
        optional: true
      })
    ]),
    query(':enter', animateChild(), { optional: true })
  ])
]);

export const routingDashboardAnimation = trigger('routingDashboardAnimation', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%'
        })
      ],
      { optional: true }
    ),
    query(':enter', [style({ opacity: 0, transform: 'scale(1.05)' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(
        ':leave',
        [
          group([
            animate('0.4s cubic-bezier(0.65, 0, 0.35, 1)', style({ opacity: 0 })),
            animate('0.2s cubic-bezier(0.65, 0, 0.35, 1)', style({ transform: 'scale(0.95)' }))
          ])
        ],
        { optional: true }
      ),
      query(
        ':enter',
        [
          group(
            [
              animate('0.4s cubic-bezier(0.65, 0, 0.35, 1)', style({ opacity: 1 })),
              animate('0.2s cubic-bezier(0.65, 0, 0.35, 1)', style({ transform: 'scale(1)' }))
            ],
            {
              delay: '0.3s'
            }
          )
        ],
        { optional: true }
      )
    ]),
    query(':enter', animateChild(), { optional: true })
  ])
]);
