/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Subscription } from 'rxjs';
import { SnackbarService, Snack, PlatformService } from '../../../../core';
import { snackModalAnimation } from '../../../../app-animations';

@Component({
  selector: 'app-snackbar-modal',
  templateUrl: './snackbar-modal.component.html',
  animations: [snackModalAnimation]
})
export class SnackbarModalComponent implements OnInit, OnDestroy {
  snackbarModal$!: Subscription;
  snackList: Snack[] = [];

  constructor(private snackbarService: SnackbarService, private platformService: PlatformService) {}

  ngOnInit(): void {
    this.snackbarModal$ = this.snackbarService.snackbar$
      .pipe(filter(() => this.platformService.isShownOverlay.getValue()))
      .subscribe({
        next: (snack: Snack) => {
          this.onPush({
            id: Date.now(),
            ...snack
          });
        },
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    [this.snackbarModal$].forEach($ => $?.unsubscribe());
  }

  onPush(snack: Snack): void {
    this.snackList.push(snack);

    if (snack.options?.timeout !== 0) {
      const timeout = setTimeout(() => {
        this.onClose(snack?.id as number);

        clearTimeout(timeout);
      }, snack.options?.timeout);
    }
  }

  onCallback(snack: Snack): void {
    snack.options?.button?.callback();

    this.onClose(snack?.id as number);
  }

  onClose(errorId: number): void {
    this.snackList = this.snackList.filter((snack: Snack) => snack?.id !== errorId);
  }
}
