/** @format */

import { Component, OnInit } from '@angular/core';
import { map, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { PlatformService, User, UserService } from '../core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  routeParams$!: Subscription;

  statusCode!: number;
  statusCodeMap: number[][] = [
    [100, 199],
    [200, 299],
    [300, 399],
    [400, 499],
    [500, 599]
  ];

  message!: string;
  messageMap: string[] = [
    $localize`:Ошибка|Статус ошибки - 1xx@@app-error.1xx:Информационное сообщение`,
    $localize`:Ошибка|Статус ошибки - 2xx@@app-error.2xx:Успешно`,
    $localize`:Ошибка|Статус ошибки - 3xx@@app-error.3xx:Перенаправление`,
    $localize`:Ошибка|Статус ошибки - 4xx@@app-error.4xx:Ошибка клиента`,
    $localize`:Ошибка|Статус ошибки - 5xx@@app-error.5xx:Ошибка сервера`
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private userService: UserService,
    private platformService: PlatformService
  ) {}

  ngOnInit(): void {
    this.routeParams$ = this.route.params.pipe(map((data: any) => data.status)).subscribe({
      next: (status: string) => {
        const statusCode = Number(status);
        const message = this.getMessageMap(statusCode);

        if (!statusCode || !message) {
          this.router
            .navigate([[], 404], { replaceUrl: true })
            .then(() => console.debug('Route changed'));
        }

        this.statusCode = statusCode;
        this.message = message as string;

        this.title.setTitle('Dabster | ' + this.message);
      },
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.routeParams$].forEach($ => $?.unsubscribe());
  }

  getMessageMap(status: number): string | undefined {
    const i = this.statusCodeMap.findIndex(([min, max]) => min <= status === status <= max);

    return this.messageMap[i];
  }

  onGoToMyOffice(): void {
    this.userService.currentUser.pipe(first()).subscribe({
      next: (user: User) => {
        if (Boolean(user?.team) && Boolean(user?.team_member_id)) {
          this.router.navigate(['/']).then(() => console.debug('Route changed'));
        } else {
          /** Наличие поддомена команды */

          if (this.platformService.isInTeam()) {
            if (this.platformService.isBrowser()) {
              const window: Window = this.platformService.getWindow();

              const subdomain = window.location.hostname.split('.')[0] + '.';

              const origin: string = window.location.origin.replace(subdomain, '');

              window.location.assign(origin + '/profile');
            }
          } else {
            this.router.navigate(['/profile']).then(() => console.debug('Route changed'));
          }
        }
      },
      error: (error: any) => console.error(error)
    });
  }
}
