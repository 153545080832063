<!-- @format -->

<section>
	<div
		class="flex items-center justify-start mb-6"
		[ngSwitch]="true"
		*ngIf="skillTask.status"
	>
		<label
			*ngSwitchCase="skillTask.status === 'ready'"
			class="label label-green inline md"
		>
			<!-- prettier-ignore -->
			<span
        class="block"
        i18n="Прогресс навыка|Текст: Освоен@@app-skill-tasks-description-html.skill-mastered"
      >
        Освоен
      </span>
			<figure class="relative">
				<i
					class="absolute"
					appSvgIcon
					appIcon="done"
					appSquare="16"
					appViewBox="0 0 24 24"
				></i>
			</figure>
		</label>
		<label
			*ngSwitchCase="skillTask.status === 'done'"
			class="label label-green inline md"
		>
			<!-- prettier-ignore -->
			<span
        class="block"
        i18n="Прогресс навыка|Текст: Освоен@@app-skill-tasks-description-html.skill-confirmed"
      >
        Подтвержден
      </span>
			<figure class="relative">
				<i
					class="absolute"
					appSvgIcon
					appIcon="done-all"
					appHeight="16"
					appWidth="16"
					appViewBox="0 0 16 16"
				></i>
			</figure>
		</label>
		<label
			*ngSwitchCase="skillTask.status === 'in_progress'"
			class="label label-primary inline md"
		>
			<!-- prettier-ignore -->
			<span
        class="block"
        i18n="Прогресс навыка|Текст: В работе@@app-skill-tasks-description-html.skill-in-progress"
      >
        В работе
      </span>
			<figure class="relative">
				<i class="absolute" appSvgIcon appIcon="bolt" appSquare="16"></i>
			</figure>
		</label>
	</div>
	<div class="relative flex flex-col editor-js overflow-hidden">
		<!-- prettier-ignore -->
		<div
      class="desc-accordion"
      data-pw="action-blocks"
      [ngClass]="descIsShowMore ? 'mb-6 pb-2' : ''"
      [style.--desc-duration]="descDuration + 'ms'"
      [style.--desc-height]="descHeight"
      [innerHTML]="skillTaskHTML | sanitizer"
    >
    </div>
		<div
			class="desc-clone mb-6 pb-2"
			[innerHTML]="skillTaskHTML | sanitizer"
			#skillTaskHTMLClone
		></div>
		<button
			class="btn-show-more"
			[class.shadow-more]="!descIsShowMore"
			[class.invisible]="!descIsShown"
			(click)="onDescriptionToggle()"
		>
			<!-- prettier-ignore -->
			<span
        *ngIf="descIsShowMore"
        class="block"
        i18n="Просмотр навыка|Текст: Показать меньше@@app-skill-tasks-description-html.desc-show-less"
      >
        Показать меньше
      </span>
			<!-- prettier-ignore -->
			<span
        *ngIf="!descIsShowMore"
        class="block"
        i18n="Просмотр навыка|Текст: Показать больше@@app-skill-tasks-description-html.desc-show-more"
      >
        Показать больше
      </span>
			<i
				[class.-scale-y-1]="descIsShowMore"
				appSvgIcon
				appIcon="expand"
				appSquare="20"
			></i>
		</button>
	</div>
	<!-- prettier-ignore -->
	<div
    class="relative border-t border-blue-grey-50 mt-4"
    *ngIf="skillTask.status && (user.id === progress.user.id || progressIsEstimate || ['teamlead', 'owner'].includes(user.role_key))"
	>
    <!-- NAVIGATION: Результаты -->
    <!-- prettier-ignore -->
		<span
      class="block text-headline-7 mt-6 mb-4"
      i18n="Просмотр прогресса|Заголовок: Результаты@@app-skill-tasks-description-html.input-result-title"
    >
      Результаты
    </span>
		<!-- NAVIGATION: Поле ввода -->
    <!-- prettier-ignore -->
    <ng-container [ngTemplateOutlet]="taskResult" [ngTemplateOutletContext]="{skillTask: skillTask}"></ng-container>
    <!-- NAVIGATION: Кнопки (прохождение навыка || оценка) -->
    <ng-container [ngSwitch]="true">
      <!-- NAVIGATION: Прохождение навыка -->
      <ng-container *ngSwitchCase="user.id === progress.user.id && !progressIsEstimate">
        <button
          data-pw="confirm-action"
          *ngIf="skillTask.status === 'in_progress' && !progressIsResult"
          class="btn btn-primary inline md"
          (click)="onSetSkillTaskResult()"
        >
          <!-- prettier-ignore -->
          <span
            class="block"
            i18n="Просмотр прогресса|Кнопка: Подтвердить@@app-skill-tasks-description-html.input-result-submit"
          >
            Подтвердить
          </span>
        </button>
        <button
          class="btn btn-primary inline md"
          (click)="onSetSkillTaskStatus('in_progress')"
          *ngIf="skillTask.status === 'ready' && !progressIsResult"
        >
          <!-- prettier-ignore -->
          <span
            class="block"
            i18n="Просмотр прогресса|Кнопка: Изменить результат@@app-skill-tasks-description-html.input-result-change"
          >
            Изменить результат
          </span>
        </button>
      </ng-container>
      <!-- NAVIGATION: Оценка или просмотр чужого навыка -->
      <!-- prettier-ignore -->
      <ng-container *ngSwitchCase="['teamlead', 'owner'].includes(user.role_key) && progressIsEstimate">
        <button
          class="btn btn-primary inline md"
          data-pw="button-result-submit"
          *ngIf="['in_progress', 'ready'].includes(skillTask.status) && !progressIsResult"
          (click)="onSetSkillTaskStatus('done')"
        >
          <!-- prettier-ignore -->
          <span
            class="block"
            i18n="Просмотр прогресса|Кнопка: Подтвердить@@app-skill-tasks-description-html.input-result-submit"
          >
            Подтвердить
          </span>
        </button>
        <button
          class="btn btn-primary inline md"
          data-pw="button-result-return"
          *ngIf="['done'].includes(skillTask.status) && !progressIsResult"
          (click)="onSetSkillTaskStatus('in_progress')"
        >
          <!-- prettier-ignore -->
          <span
            class="block"
            i18n="Просмотр прогресса|Кнопка: Вернуть в работу@@app-skill-tasks-description-html.input-result-return"
          >
            Вернуть в работу
          </span>
        </button>
      </ng-container>
    </ng-container>
	</div>
	<ng-template #taskResult let-skillTask="skillTask">
		<ng-container [ngSwitch]="true">
			<!-- NAVIGATION: Если результат пуст (показываем заглушки) -->
			<!-- prettier-ignore -->
			<ng-container *ngSwitchCase="!skillTask.result && ['ready', 'done'].includes(skillTask.status)">
				<!-- prettier-ignore -->
				<div class="flex items-center justify-start space-x-2 bg-secondary-25 rounded-md p-4 mb-4">
          <img
            class="block"
            src="assets/images/dashboard/progress/task-done-emoji.svg"
            alt="Task completed"
          />
          <span
            class="block"
            i18n="Просмотр прогресса|Текст: Задача выполнена!@@app-skill-tasks-description-html.done-result"
          >
            Задача выполнена!
          </span>
        </div>
			</ng-container>
			<!-- prettier-ignore -->
			<ng-container *ngSwitchCase="!skillTask.result && ['in_progress'].includes(skillTask.status) && (progressIsResult || progressIsEstimate || (user.id !== progress.user.id && ['teamlead', 'owner'].includes(user.role_key)))">
				<!-- prettier-ignore -->
				<div class="flex items-center justify-start space-x-2 bg-tertiary-25 rounded-md p-4 mb-4">
          <img
            class="block"
            src="assets/images/dashboard/progress/task-done-empty-emoji.svg"
            alt="Task completed empty"
          />
          <span
            class="block"
            i18n="Просмотр прогресса|Текст: Нет результатов@@app-skill-tasks-description-html.done-result-empty">
            Нет результатов
          </span>
        </div>
			</ng-container>
			<!-- NAVIGATION: Поле ввода результата (может быть заблокировано) -->
			<!-- prettier-ignore -->
			<ng-container *ngSwitchDefault>
        <!-- prettier-ignore -->
        <form
          class="w-full mb-4"
          [formGroup]="progressForm"
          autocomplete="off"
          [class.result-accordion]="resultIsShown && (progressFormIsSubmitting || ['ready', 'done'].includes(skillTask.status))"
          [class.shift]="!progressIsResult"
          [style.--result-duration]="resultDuration + 'ms'"
          [style.--result-height]="resultHeight"
        >
          <div
            class="result-clone"
            data-pw="result-clone"
            [innerHTML]="progressForm.get('result')?.value"
            #skillTaskResultClone
          ></div>
          <fieldset [disabled]="progressFormIsSubmitting">
            <fieldset class="form-control-textarea flex" [ngSwitch]="['ready', 'done'].includes(skillTask.status)">
              <!-- prettier-ignore -->
              <textarea
                *ngSwitchCase="false"
                appTextarea
                [appUpdate]="areaTrigger"
                class="bg-blue-grey-25 placeholder-blue-grey-400 h-auto min-h-[56px] resize-none overflow-y-hidden w-full"
                placeholder="Ссылка или комментарий"
                i18n-placeholder="Просмотр прогресса|Поле ввода: Ссылка или комментарий@@app-skill-tasks-description-html.input-result"
                name="result"
                id="result"
                [rows]="1"
                formControlName="result"
              ></textarea>
              <div
                *ngSwitchCase="true"
                class="text-body-1 bg-secondary-25 border-shadow-default overflow-y-hidden overflow-ellipsis break-all rounded h-auto min-h-[56px] w-full p-4"
                [innerHTML]="progressForm.get('result')?.value | transform: setHTMLResult | sanitizer: { context: 'html' }"
              >
              </div>
              <label for="result" class="hidden"></label>
            </fieldset>
          </fieldset>
          <button
            class="btn-show-more"
            [class.shadow-more]="!resultIsShowMore"
            [class.invisible]="!resultIsShown || !(progressFormIsSubmitting || ['ready', 'done'].includes(skillTask.status))"
            (click)="onResultToggle()"
          >
            <!-- prettier-ignore -->
            <span
              *ngIf="resultIsShowMore"
              class="block"
              i18n="Просмотр навыка|Текст: Показать меньше@@app-skill-tasks-description-html.result-show-less"
            >
              Показать меньше
            </span>
            <!-- prettier-ignore -->
            <span
              *ngIf="!resultIsShowMore"
              class="block"
              i18n="Просмотр навыка|Текст: Показать больше@@app-skill-tasks-description-html.result-show-more"
            >
              Показать больше
            </span>
            <i [class.-scale-y-1]="resultIsShowMore" appSvgIcon appIcon="expand" appSquare="20"></i>
          </button>
        </form>
      </ng-container>
		</ng-container>
	</ng-template>
</section>
