<!-- @format -->

<ng-container>
	<div class="calendar bg-blue-grey-25 effect-01dp rounded-md w-full p-2">
		<div class="navigation flex items-center justify-between p-2">
			<button
				data-pw="calendar-previous"
				class="transition-colors rounded-md p-4"
				[disabled]="disablePreviousViewNavigation"
				(click)="onViewNavigate(false)"
			>
				<i
					class="block text-blue-grey-300"
					appSvgIcon
					appIcon="arrow-back-2"
					appWidth="7"
					appHeight="10"
					appViewBox="0 0 7 10"
				></i>
			</button>
			<ng-container [ngSwitch]="calendarView">
				<ng-container *ngSwitchCase="'day'">
					<button
						class="flex items-center text-subtitle-1 hover:bg-blue-grey-50 transition-colors rounded-md capitalize py-2.5 px-3"
						(click)="onViewChange('month')"
					>
						<span class="inline-block text-blue-grey-900">
							{{ labelMonths[monthCurrent] }},&nbsp;
						</span>
						<span class="inline-block text-blue-grey-300">
							{{ yearCurrent }}
						</span>
					</button>
				</ng-container>
				<ng-container *ngSwitchCase="'month'">
					<button
						class="flex items-center text-subtitle-1 hover:bg-blue-grey-50 transition-colors rounded-md capitalize py-2.5 px-3"
						(click)="onViewChange('year')"
					>
						<span class="inline-block text-blue-grey-300">
							{{ yearCurrent }}
						</span>
					</button>
				</ng-container>
				<ng-container *ngSwitchCase="'year'">
					<button
						class="flex items-center text-subtitle-1 hover:bg-blue-grey-50 transition-colors rounded-md capitalize py-2.5 px-3"
						(click)="onViewChange('month')"
					>
						<span class="inline-block text-blue-grey-300">
							{{ yearList[0] }}&nbsp;-&nbsp;
							{{ yearList[yearList.length - 1] }}
						</span>
					</button>
				</ng-container>
			</ng-container>
			<button
				data-pw="calendar-next"
				class="hover:bg-blue-grey-50 transition-colors rounded-md p-4"
				(click)="onViewNavigate(true)"
			>
				<i
					class="block text-blue-grey-300 -scale-x-1"
					appSvgIcon
					appIcon="arrow-back-2"
					appWidth="7"
					appHeight="10"
					appViewBox="0 0 7 10"
				></i>
			</button>
		</div>
		<div [ngSwitch]="calendarView">
			<ng-container *ngSwitchCase="'day'">
				<ul class="day-list grid grid-cols-7 grid-rows-1">
					<li
						class="flex items-center justify-center text-caption text-blue-grey-400 capitalize w-12 h-8"
						*ngFor="let dayLabel of labelDays"
					>
						{{ dayLabel }}
					</li>
				</ul>
				<ul class="day-list grid grid-cols-7 grid-rows-6">
					<li
						class="flex items-center justify-center text-subtitle-1 text-blue-grey-900 w-12 h-12"
						*ngFor="let day of dayList[0]"
					>
						<button
							class="flex items-center justify-center w-full h-full"
							[disabled]="true"
						>
							{{ day }}
						</button>
					</li>
					<li
						class="flex items-center justify-center text-subtitle-1 text-blue-grey-900 w-12 h-12 p-0.5"
						*ngFor="let day of dayList[1]"
					>
						<button
							class="flex items-center justify-center text-blue-grey-900 rounded-full w-full h-full"
							[class.selected]="
								day === daySelected &&
								monthCurrent === monthSelected &&
								yearCurrent === yearSelected
							"
							[class.today]="
								day === dayToday &&
								monthCurrent === monthToday &&
								yearCurrent === yearToday
							"
							(click)="onSelectDay(day)"
						>
							{{ day }}
						</button>
					</li>
					<li
						class="flex items-center justify-center text-subtitle-1 text-blue-grey-900 w-12 h-12"
						*ngFor="let day of dayList[2]"
					>
						<button
							class="flex items-center justify-center w-full h-full"
							[disabled]="true"
						>
							{{ day }}
						</button>
					</li>
				</ul>
			</ng-container>
			<ng-container *ngSwitchCase="'month'">
				<ul class="month-list grid grid-cols-3 grid-rows-4">
					<li
						class="flex items-center justify-center text-subtitle-1 w-28 h-20"
						*ngFor="let month of monthList"
					>
						<button
							class="flex items-center justify-center text-blue-grey-900 rounded-md capitalize w-24 h-12"
							[disabled]="
								disablePrevious && month < monthToday && yearCurrent === yearToday
							"
							[class.selected]="month === monthSelected && yearCurrent === yearSelected"
							[class.today]="month === monthToday && yearCurrent === yearToday"
							(click)="onSelectMonth(month)"
						>
							{{ labelMonths[month] }}
						</button>
					</li>
				</ul>
			</ng-container>
			<ng-container *ngSwitchCase="'year'">
				<ul class="year-list grid grid-cols-3 grid-rows-4">
					<li
						class="flex items-center justify-center text-subtitle-1 w-28 h-20"
						*ngFor="let year of yearList"
					>
						<button
							class="flex items-center justify-center text-blue-grey-900 rounded-md capitalize w-24 h-12"
							[disabled]="disablePrevious && year < yearToday"
							[class.selected]="year === yearSelected"
							[class.today]="year === yearToday"
							(click)="onSelectYear(year)"
						>
							{{ year }}
						</button>
					</li>
				</ul>
			</ng-container>
		</div>
	</div>
</ng-container>
